import React from "react";
import { Link } from "react-router-dom";

export default function Suc() {
  return (
    <div className=" text-center w-full mb-4   bg-[#E8DCC0] rounded-[15px] p-4 px-4 py-12 ">
      <h1 className=" text-[84px] font-[600] text-[#65A30D] ">
        <i className="fa-solid fa-circle-check"></i>
      </h1>
      <h1 className=" text-[24px] font-[600] text-[#65A30D] my-2 ">
        {"تم ارسال  بنجاح"}
      </h1>
      <Link className=" my-3 underline "  to="/">الصفحة الرئيسية</Link>
      {/* <h4 className="text-[20px] font-[500] text-[#4F4F4F] my-2">
        تم ارسال تفاصيل الحجز كاملة على البريد الالكتروني{" "}
      </h4> */}
    </div>
  );
}
