import React, { useState } from "react";
import TabJoin from "../../../../../components/pages/TabOfJoins";
import PartnerShipdashboard from "../../../pages/partnerShips/PartnerShipdashboard";
import Reports from "./Reports";

export default function PartnerShipDashSuperAdmin() {
  const [tab, setTab] = useState(1);
  const tabs = ["تقارير التحالفات", "تحالفاتي"];
  return (
    <div>
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <TabJoin
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
          </section>
          <div>
              {tab === 1 && <Reports />}
              {tab === 2 && <PartnerShipdashboard />}
              
        </div>

    </div>
  );
}
