import React, { useContext, useEffect } from "react";
import { isLogged } from "../../utils/inStore";
import { useNavigate } from "react-router";
import CamelOwnerProfile from "./CamelOwner/CamelOwnerProfile";

export default function Profile() {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>{role === "camel-owner" && <CamelOwnerProfile />}</div>;
}
