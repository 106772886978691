import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import useDeleteRequest from "../../../../utils/useDelete";
import { isLogged } from "../../../../utils/inStore";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";

export default function DeleteEvent({ close, id, set }) {
  const { deleteRequest, data, error } = useDeleteRequest();
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = `camelOwner/event/destroy/${id}`;
  }
  if (role === "squer") {
    link = `field-owner/event/destroy/${id}`;
  }
  if (role === "super_admin") {
    link = `superAdmin/event/destroy/${id}`;
  }
  const deletetHandeller = () => {
    deleteRequest(link);
  };

  useEffect(() => {
    if (!!data) {
      set((pre) => {
        let da = pre.filter((item) => item.id !== id);
        return da;
      });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[600px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <i className="fa-solid fa-circle-question text- mx-auto text-7xl my-2 text-red-600"></i>
        <h1 className=" text-center  text-3xl my-2 text-red-600 font-bold ">
          هل انت متأكد من الحذف ؟
        </h1>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={deletetHandeller} className="btn9">
            حذف
          </button>
          <button onClick={() => close()} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error
                      ? error.response.data.error
                      : "  هناك مشكلة"}
                  </>
                ) : (
                  "  هناك مشكلة"
                )}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
