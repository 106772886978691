import React, { useContext, useEffect, useState } from "react";
import usePostFormData from "../../utils/usePostForm";
import { count } from "../../utils/countStore";

export default function NotificationComponent({ data }) {
  const { postFormData, response } = usePostFormData(
    `global/notifications/markAsRead/${data.id}`
  );
  const markAsReadHandler = () => {
    postFormData();
  };
  const [notReadState, setNotReadState] = useState(true);
  // const [notReadState, setNotReadState] = useState(data.status === "pending");
  const { re } = useContext(count);
  useEffect(() => {
    if (response) {
      setNotReadState(false);
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <div className=" w-full  bg-[#E8DCC0] p-[16px] rounded-[8px] my-2 md:flex items-center justify-between gap-2 ">
      <section className=" flex-1 flex items-center gap-4  ">
        <i className="fa-regular fa-bell text-2xl "></i>
        <section>
          <h1 className=" text-[14px] md:text-[20px] font-[600] text-[#0D0C22] ">
            {data.title}
          </h1>
          <h1 className="  text-[12px] md:text-[20px] mt-2 font-[400] text-[#876354] ">
            {data.body}
          </h1>
        </section>{" "}
      </section>
      <section className=" text-center flex justify-between items-center md:grid grid-cols-1 ">
        {notReadState ? (
          <button
            onClick={markAsReadHandler}
            className=" p-2 underline text-[14px] "
          >
            تمييز كمقروءة
          </button>
        ) : (
          <>
            <i className="fa-solid fa-check text-green-700 text-xl "></i>
          </>
        )}
        <section className="  mt-3 md:mt-0 flex items-center gap-1 text-[12px] md:text-[14px] font-[400] text-[#A59382] ">
          <i class="fa-regular fa-clock"></i>
          <h4>{data.created_at.substring(0, 10)}</h4>
        </section>
      </section>
    </div>
  );
}
