import React from "react";
import PartnerCompnent from "../../components/pages/Partner/PartnerCompnent";

export default function PartenerShip({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <PartnerCompnent key={inx} data={e} />
      ))}
    </div>
  );
}
