import React, { useEffect } from "react";
import usePostWithToken from "../../../../../../utils/usePostJson";

export default function Pref({ type, reload }) {
  const { postData, response } = usePostWithToken(
    `superAdmin/Report/package/subscriptions_for_guard`
  );
  const getData = () => {
    const data = {
      guard_type: type,
    };
    postData(data);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  // if (error) {
  //   return (
  //     <div>
  //       <h1 className=" text-red-600 text-xl font-bold text-center ">
  //         هناك مشكلة
  //       </h1>
  //     </div>
  //   );
  // }
  return (
    <>
      {response && (
        <div className=" border border-black/60 p-4 rounded-[16px] my-3 ">
          <h1 className=" text-xl my-3 font-bold text-center ">
            ملخص الاشتراكات{" "}
          </h1>
          <h1 className=" my-2 text-lg ">
            عدد المشتركين :{" "}
            <span className=" text-xl font-bold ">
              {response.total_subscriptions}
            </span>
          </h1>
          <h1 className=" my-2 text-lg ">
            عدد الاشتراكات الفعالة :{" "}
            <span className=" text-xl font-bold ">
              {response.active_subscriptions}
            </span>
          </h1>
          <h1 className=" my-2 text-lg ">
            عدد الاشتراكات الغير فعالة :{" "}
            <span className=" text-xl font-bold ">
              {response.inactive_subscriptions}
            </span>
          </h1>
          <h1 className=" my-2 text-lg ">
            عدد الاشتراكات التي تم الغائها :{" "}
            <span className=" text-xl font-bold ">
              {response.canceled_subscriptions}
            </span>
          </h1>
          <h1 className=" my-2 text-lg ">
            عدد الاشتراكات المنتهية :{" "}
            <span className=" text-xl font-bold ">
              {response.expired_subscriptions}
            </span>
          </h1>
        </div>
      )}
    </>
  );
}
