import React, { useContext, useState } from "react";
import Tab from "../../components/pages/Tab";
import Products from "./Products";
import { isLogged } from "../../utils/inStore";
import Error from "../../components/Error/Error";
// import useFetchWithToken from "../../utils/useGetWithToken";
import useFetchWithReload from "../../utils/useGetWithReload";

export default function Cart() {
  const [reload, setReLoad] = useState(false);
  const re = () => {
    setReLoad(!reload);
  };
  const { data, error } = useFetchWithReload(
    `global/store/card/my-card`,
    reload
  );
  // let price = 0;
  // data.map((e) => {
  //   price = price + +e.num * +e.product.price;
  //   return price;
  // });

  const { logged } = useContext(isLogged);
  if (!logged) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      {/* Body */}
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1  w-deta  ">
          <section className="   flex items-center gap-3 ">
            <Tab text={"عربة التسوق"} val={1} tab={1} setTabState={() => {}} />
          </section>
          <section>
            {error && (
              <h1 className=" text-red-700 text-center my-4 text-xl font-bold ">
                لا توجد منتجات في العربة
              </h1>
            )}
            {data && <Products re={re} data={data} />}
          </section>
        </div>
        {/* Owner */}
        {/* <section className="   col-span-3 ">
          <Info price={price} isConfirmed={isConfirmed} done={setda} />
        </section> */}
      </div>
    </div>
  );
}
