import React from "react";
import HeadCover from "../../../components/pages/HeadCover";
import { Link, useLocation } from "react-router-dom";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
export default function BlogDeatails() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("blog");
  const { data, error } = useFetchGlobal(`global/blogs/show/${invest}`);

  if (error) {
    return <Error />;
  }
  return (
    <div>
      <HeadCover
        img="./imgs/swipper/1.jpg"
        title="المدونة"
        desc="أكتشف كل الموضوعات التي تخص عالم الجمال"
      />
      {data && (
        <div className="p-6 md:py-20 md:px-12">
          <section className=" gap-2 flex items-center justify-between ">
            <h1 className=" flex-1 text-[25px] md:text-[40px] font-[700] font-sans text-[#333333] ">
              {data.title}
            </h1>
            <Link to="/blog">
              <h4 className="  underline text-[#290900] text-[14px] font-[400] ">
                العودة للمدونة
              </h4>
            </Link>
          </section>
          <p className=" w-full max-w-[620px] my-4 text-[#4F4F4F] text-[18px] ">
            {data.bio}
          </p>
          {!!JSON.parse(data.detail_images) && (
            <>
              {JSON.parse(data.detail_images).length > 0 && (
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  autoplay={{
                    delay: 4000, // Time delay in milliseconds
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Autoplay]}
                  className="mySwiper "
                >
                  {JSON.parse(data.detail_images).map((e, index) => (
                    <SwiperSlide className="  " key={index}>
                      <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                        <img
                          className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                          key={e}
                          src={`${process.env.REACT_APP_URL}/${e}`}
                          alt="cover"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </>
          )}
          <div
            className="styled-content rtl leading-loose relative py-4  "
            dangerouslySetInnerHTML={{ __html: data.details }}
            style={{ direction: "rtl", textAlign: "right" }}
          />
          {/* <h1 className=" text-[#333333] text-[18px] font-[600] font- ">
          كلمات مفتاحية
        </h1>
        <section className=" py-2 flex items-center flex-wrap gap-2 ">
          {data.keywords.map((e, inx) => (
            <section
              key={inx}
              className=" h-[34px] rounded-[10px] px-[6px] bg-[#FFEA9D] text-[#4F4F4F] text-[14px] font-[400] flex items-center justify-center "
            >
              {e}
            </section>
          ))}
        </section> */}
          {/* <h1 className=" my-4 text-[#333333] text-[18px] font-[600] font- ">
          مدونات مشابهة
        </h1>

        <section className=" hide-scrollbar overflow-auto  ">
          <section className=" py-3 flex gap-3  items-center justify-start  w-fit overflow-auto  ">
            {blogs.map((prod, inx) => (
              <MobileBlogComponent className="flex-1" key={inx} data={prod} />
            ))}
          </section>
        </section>
        <Comments comments={comments} /> */}
        </div>
      )}
    </div>
  );
}
