import React from "react";
import EventDashbordComponent from "../../components/events/EventDashbordComponent";

export default function Events({ data, setDeleteId, setDetailsId, setEditId }) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <EventDashbordComponent
          setDetailsId={setDetailsId}
          setDeleteId={setDeleteId}
          setEditId={setEditId}
          key={inx}
          data={e}
        />
      ))}
    </div>
  );
}
