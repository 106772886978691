import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default function TripDeta({ data }) {
  return (
    <div className=" mb-3 ">
      {!!data.details_image && (
        <>
          {data.details_image.length > 0 && (
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 4000, // Time delay in milliseconds
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Pagination, Autoplay]}
              className="mySwiper "
            >
              {data.details_image.map((e, index) => (
                <SwiperSlide className="  " key={index}>
                  <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                    <img
                      className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                      key={e}
                      src={`${process.env.REACT_APP_URL}/${e}`}
                      alt="cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}
      <section>
        <h1 className=" my-6 text-[24px] text-[#432315] font-[800] ">
          {data.title}
        </h1>
        {data.tour_type && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            نوع الرحلة : {data.tour_type}
          </p>
        )}
        {data.tour_date && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            تاريخ الرحلة: {data.tour_date}
          </p>
        )}
        {data.tour_location && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            مكان الرحلة : {data.tour_location}
          </p>
        )}

        {data.map_url && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            المكان :{" "}
            <a
              className=" underline "
              href={data.map_url}
              target="_blank"
              rel="noreferrer"
            >
              الذهاب للخريطة
            </a>
          </p>
        )}
        {data.tour_duration && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            مدة الرحلة : {data.tour_duration} ساعة
          </p>
        )}
        {data.pickup_time && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            وقت الانطلاق : {data.pickup_time}
          </p>
        )}
        {data.meeting_point && (
          <p className=" my-2  text-[16px] text-[#290900] font-[600] ">
            مكان الانطلاق : {data.meeting_point}
          </p>
        )}
        {!!data.included_services && data.included_services.length > 0 && (
          <>
            <h1 className=" my-6 text-[24px] text-[#432315] font-[800] ">
              الخدمات المُدرجة
            </h1>
            <ul>
              {data.included_services.map((to, index) => (
                <section key={index} className=" flex items-center gap-4 ">
                  <span className=" w-[8px] h-[8px] bg-[#290900] "></span>
                  <li className=" text-[#290900] text-[18px] my-1 font-[600] leading-[30px] ">
                    {to}
                  </li>
                </section>
              ))}
            </ul>
          </>
        )}
      </section>
    </div>
  );
}
