import React from "react";
import ProductDashboardComponent from "../../components/stores/ProductDashboardComponent";

export default function Products({
  setEditId,
  data,
  setDeleteId,
  setDetailsId,
}) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <ProductDashboardComponent
          setDetailsId={setDetailsId}
          setDeleteId={setDeleteId}
          setEditId={setEditId}
          key={inx}
          data={e}
        />
      ))}
    </div>
  );
}
