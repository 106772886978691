import React from "react";
import LoginLayout from "../Layout";
import useFetchGlobal from "../../../utils/useGetGlobal";
import { Link } from "react-router-dom";

export default function Policies() {
  const { data, error } = useFetchGlobal(`global/policies`);
  return (
    <LoginLayout>
      <div className="w-full relative md:max-h-[80vh] overflow-auto ">
        <Link
          className=" md:absolute flex gap-1 items-center top-2 right-2 "
          to="/register"
        >
          <i className="fa-solid fa-arrow-right"></i>
          <button className="  ">انشاء الحساب</button>
        </Link>
        <h1 className="text-center text-[22px]  font-[700] text-[#290900] my-2">
          الشروط والاحكام
        </h1>
        {error && (
          <h1 className=" my-4 text-center text-lg font-bold text-red-500 ">
            هناك مشكلة
          </h1>
        )}
        {data && (
          <div className=" my-4 ">
            <h1 className=" text-center my-2 text-lg ">سياسة الاستخدام</h1>
            <div
              className="styled-content rtl leading-loose relative py-4  "
              dangerouslySetInnerHTML={{ __html: data.terms_of_use }}
              style={{ direction: "rtl", textAlign: "right" }}
            />
            <h1 className=" text-center my-2 text-lg ">سياسة الخصوصية</h1>
            <div
              className="styled-content rtl leading-loose relative py-4  "
              dangerouslySetInnerHTML={{ __html: data.privacy_policy }}
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </div>
        )}
      </div>
    </LoginLayout>
  );
}
