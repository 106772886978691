import React from "react";
import { Link } from "react-router-dom";

export default function Notegister({ close, msg }) {
  return (
    <div className="z-[70] fadein p-8 flex items-center justify-center fixed inset-0  bg-[#2B2B2B80]/50">
      <section className=" relative text-center w-full max-w-[1000px] bg-[#E8DCC0] px-4 py-12 md:py-24 rounded-[15px] ">
        <button
          onClick={close}
          className=" absolute text-red-500 text-xl top-3 left-5 "
        >
          x
        </button>
        <h1 className=" text-[24px] md:text-[32px] font-[600] my-3 ">
          نحن متحمسون لانضمامك!
        </h1>
        <h1 className=" text-[18px] md:text-[32px] font-[600] my-3 ">{msg}</h1>
        <section className="  ">
          <Link to="/login">
            <button className=" m-1 text-[18px] border border-[#432315] text-[#FFFCF7] font-[400] bg-[#432315] rounded-[10px] h-[50px] w-full max-w-[350px] ">
              تسجيل الدخول
            </button>
          </Link>
          <Link to="/register">
            <button className=" m-1 text-[18px] border border-[#432315] font-[400] text-[#432315] rounded-[10px] h-[50px] w-full max-w-[350px] ">
              انشاء حساب
            </button>
          </Link>
        </section>
      </section>
    </div>
  );
}
