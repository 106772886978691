import React, { useContext, useState } from "react";
import EditInfo from "./EditInfo";
import Edit from "./Edit";
import { isLogged } from "../../../utils/inStore";

import useFetchWithReload from "../../../utils/useGetWithReload";

export default function Info() {
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/settings/get-profile";
  }
  if (role === "provider") {
    link = "serviceProvider/settings/get-profile";
  }
  if (role === "vistor") {
    link = "visitor/settings/get-profile";
  }
  const [re, setRe] = useState(false);
  const reload = () => {
    setRe((pre) => !pre);
  };
  const { data, error } = useFetchWithReload(link, re);

  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      {!!data && (
        <section className=" p-[16px] rounded-[16px] border border-[#A59382] flex items-center justify-between ">
          <section className=" flex-1 flex items-center gap-1 ">
            {!!data.data_info.photo ? (
              <img
                src={`${process.env.REACT_APP_URL}/${data.data_info.photo}`}
                alt="user"
                className=" w-[100px] h-[100px] rounded-full "
              />
            ) : (
              <>
                <section className="w-[100px] h-[100px] rounded-full bg-gray-200  flex items-center justify-center ">
                  <i className="fa-solid fa-user  text-[#432315]/70 text-4xl  "></i>
                </section>
              </>
            )}
            <h1 className=" text-[20px] text-[#432315] font-[600] ">
              {data.data_info.first_name} {data.data_info.last_name}
            </h1>
          </section>
          <section>
            <button
              onClick={() => setOpenEditInfo(true)}
              className=" w-[60px] h-[30px] md:w-[100px] md:h-[50px] border border-[#432315] rounded-[8px] "
            >
              تعديل
            </button>
          </section>
        </section>
      )}
      {!!data && (
        <section className=" p-[16px] rounded-[16px] my-5 border border-[#A59382] ">
          <section className=" py-3 flex items-center justify-between border-b border-[#A59382]  ">
            <h1 className=" text-[24px] font-[700] text-[#432315] ">
              معلومات شخصية
            </h1>
            <button
              onClick={() => setOpenEdit(true)}
              className=" w-[60px] h-[30px] md:w-[100px] md:h-[50px] border border-[#432315] rounded-[8px] "
            >
              تعديل
            </button>
          </section>
          <section className=" mt-6  grid grid-cols-1 md:grid-cols-2 gap-12  ">
            <section className=" col-span-1 ">
              <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
                العنوان
              </h4>
              <section className=" p-2 border border-[#290900] rounded-[5px] ">
                {data.data_info.address}
              </section>
            </section>
            <section className=" col-span-1 ">
              <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
                رقم الهاتف
              </h4>
              <section className=" p-2 border border-[#290900] rounded-[5px] ">
                {data.data_info.phone}
              </section>
            </section>
            <section className=" col-span-1 ">
              <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
                بريد الاكتروني
              </h4>
              <section className=" p-2 border border-[#290900] rounded-[5px] ">
                {data.data_info.email}
              </section>
            </section>
            <section className=" col-span-1 ">
              <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
                الجنسية
              </h4>
              <section className=" p-2 border border-[#290900] rounded-[5px] ">
                {data.data_info.nationality}
              </section>
            </section>
          </section>
        </section>
      )}
      {openEditInfo && (
        <EditInfo data={data.data_info} close={setOpenEditInfo} re={reload} />
      )}
      {openEdit && (
        <Edit re={reload} data={data.data_info} close={setOpenEdit} />
      )}
    </div>
  );
}
