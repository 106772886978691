import React, { useEffect, useState } from "react";
import useFetchGlobal from "../../../../utils/useGetGlobal";
import BlogComponent from "./BlogComponent";
import MobileComponent from "./MobileComponent";
import AddBlog from "./AddBlog";
import Edit from "./Edit";
import Delete from "./Delete";

export default function BlogsDashBoard() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };
  const [filteredData, setFilteredData] = useState([]);

  const { data, error } = useFetchGlobal("global/blogs/all");

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.title.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);

  const [openAdd, setOpenAdd] = useState(false);
  const [deleteId, setDeletId] = useState();
  const [editId, setEditId] = useState();

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}
        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة مدونة
        </button>
      </div>
      <section className="flex-1">
        <>
          <div className="hidden md:flex items-center justify-start gap-3 flex-wrap ">
            {[...filteredData].reverse().map((e, inx) => (
              <BlogComponent
                setEditId={setEditId}
                setDeleteId={setDeletId}
                key={inx}
                data={e}
              />
            ))}
          </div>
          <section className="flex md:hidden items-center justify-center gap-3 flex-wrap ">
            {[...filteredData].reverse().map((e, inx) => (
              <MobileComponent
                setEditId={setEditId}
                setDeleteId={setDeletId}
                key={inx}
                data={e}
              />
            ))}
          </section>
        </>
      </section>
      {editId && <Edit set={setFilteredData} id={editId} close={setEditId} />}
      {deleteId && (
        <Delete set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
      {openAdd && <AddBlog close={setOpenAdd} />}
    </div>
  );
}
