import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

export const isLogged = createContext();

export default function InStore({ children }) {
  const isin = !!Cookies.get("token");
  const roleCookie = Cookies.get("rcom9lsuZsWree9mdXNlcg==");
  let roleofUser = "";
  if (roleCookie === "dmlzaXRvcnRvc2VlMDk4") {
    roleofUser = "vistor";
  }
  if (roleCookie === "aWFtb3duaW5nY2FtZWxzMzA2NA==") {
    roleofUser = "camel-owner";
  }
  if (roleCookie === "c3F1ZXJvd25lcmhhdmVzb21lZXZlbnRzMjA4") {
    roleofUser = "squer";
  }
  if (roleCookie === "SXByb3ZpZHNvbWVzZXJ2aWNlc3lvdWNhbnRlc3RpdDM") {
    roleofUser = "provider";
  }
  if (roleCookie === "c3VwZXJBttZG1pbiB0ZXh0IHRvIGNvZGUxxgNjkgdHlwZSAmmyMTM1") {
    roleofUser = "super_admin";
  }
  // console.log(isin)
  const [logged, setLogged] = useState(isin);
  const [role, setRole] = useState(roleofUser);
  // const [role, setRole] = useState('camel-owner');
  // const [role, setRole] = useState('squer');
  // const [role, setRole] = useState('vistor');
  // const [role, setRole] = useState('provider');

  return (
    <isLogged.Provider value={{ logged, setLogged, role, setRole }}>
      {children}
    </isLogged.Provider>
  );
}
