/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { isLogged } from "../../utils/inStore";
import useFetchGlobal from "../../utils/useGetGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  const { data, error } = useFetchGlobal(`global/social_links`);
  let links = [
    {
      text: "الرئيسية",
      link: "/",
    },
    {
      text: "الطب البيطري",
      link: "/veterinary",
    },
    // {
    //   text: "خدمات",
    //   link: "/services",
    // },
    {
      text: "الخبراء",
      link: "/experts",
    },
    {
      text: "فعاليات",
      link: "/events",
    },
    {
      text: "مزادات",
      link: "/auctions",
    },
    {
      text: "سباقات",
      link: "/races",
    },
    {
      text: "المدونة",
      link: "/blog",
    },
    {
      text: "متجر",
      link: "/stores",
    },
    {
      text: "تواصل معنا ",
      link: "/communication",
    },
  ];
  let linksSer = [
    {
      text: "مشاركة في الفعاليات",
      link: "/events",
    },
    {
      text: "  مشاركة في السباق",
      link: "/races",
    },
    {
      text: " انضمام في التحالف ",
      link: "/alliance",
    },
    {
      text: " سياحة الإبل  ",
      link: "/tourism",
    },
    // {
    //   text: " انشئ صفحتك ك مالك الإبل ",
    //   link: "/",
    // },
  ];
  const { logged } = useContext(isLogged);
  return (
    <div className=" overflow-hidden bg-[#432315] py-[80px] px-[60px] text-[#FFFCF7] relative ">
      <section className=" z-0 absolute top-[-200px] left-[-200px]">
        <img
          className=" w-[400px] opacity-30"
          src="./imgs/frame.png"
          alt="frame"
        />
      </section>
      <section className=" z-0 absolute bottom-[-200px] right-[-200px]">
        <img
          className=" w-[400px] opacity-30"
          src="./imgs/frame.png"
          alt="frame"
        />
      </section>

      <div className=" flex gap-8 flex-wrap-reverse md:flex-wrap items-start justify-around text-[#FFFCF7]">
        {!error && (
          <section className=" w-fit">
            <h1 className=" border-b-2 border-[#FFFCF7] py-3 text-[36px] font-[700] ">
              تواصل معنا
            </h1>
            {data && (
              <section className=" text-3xl my-6 flex items-center justify-between ">
                {/* <a href="">
              <i className="fa-brands fa-tiktok "></i>
            </a>
            <a href="">
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a href="">
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="">
              <i className="fa-brands fa-twitter"></i>
            </a> */}
                {data.facebook && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href={data.facebook}>
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                )}
                {data.twitter && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href={data.twitter}>
                   <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                )}
                {data.instagram && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href={data.instagram}>
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                )}
                {data.whatsapp && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href={data.whatsapp}>
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                )}
              </section>
            )}
            {data && (
              <>
                {data.phone && (
                  <section className=" flex items-center justify-start my-6 ">
                    <i className="fa-solid fa-phone rotate-[270deg] "></i>
                    <h1 className="mx-2">{data.phone}</h1>
                  </section>
                )}
                {data.email && (
                  <section className=" flex items-center justify-start my-6 ">
                    <i className="fa-regular fa-envelope"></i>
                    <h1 className="mx-2"> {data.email}</h1>
                  </section>
                )}
                {data.address && (
                  <section className=" flex items-center justify-start my-6 ">
                    <i className="fa-solid fa-location-dot"></i>
                    <h1 className="mx-2"> {data.address} </h1>
                  </section>
                )}
              </>
            )}
          </section>
        )}
        <section className=" w-fit">
          <h1 className=" border-b-2 border-[#FFFCF7] py-3 text-[36px] font-[700] ">
            روابط المنصة
          </h1>
          <section className=" text-xl my-3  grid grid-cols-2 gap-3 ">
            {links.map((li) => (
              <Link key={li.link} to={li.link} className=" my-3 col-span-1">
                {li.text}
              </Link>
            ))}
          </section>
        </section>
        <section className=" min-w-60 w-fit">
          <h1 className=" border-b-2 border-[#FFFCF7] py-3 text-[36px] font-[700] ">
            خدمات
          </h1>
          <section className=" text-xl my-3  grid grid-cols-2 gap-3 ">
            {linksSer.map((li) => (
              <Link key={li.link} to={li.link} className=" my-3 col-span-2">
                {li.text}
              </Link>
            ))}
            {!logged && (
              <Link
                to="/register?type=camel_owner"
                className=" my-3 col-span-2"
              >
                انشئ صفحتك ك مالك الإبل
              </Link>
            )}
          </section>
        </section>
        <section className=" min-w-60 w-fit">
          <img
            className=" my-12"
            src="./imgs/Logo-removebg-preview.png"
            alt="Logo"
          />
        </section>
      </div>

      <section className=" my-3 flex items-center justify-center gap-3 text-white ">
        <h1>جميع الحقوق محفوظة © 2024 لشركة </h1>
        {/* <img src="./imgs/spons/شراكات.png" alt="logo" className=" w-[60px] " /> */}
        <svg
          width="62"
          height="31"
          viewBox="0 0 62 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6948_22570)">
            <g clip-path="url(#clip1_6948_22570)">
              <path
                d="M5.65563 13.4893C6.23546 13.1254 6.60162 12.5692 6.75332 11.8199C6.79328 11.6251 6.81365 11.4304 6.81365 11.2356C6.81365 10.8253 6.67747 10.4846 6.4051 10.2127C6.13271 9.94084 5.67359 9.69219 5.02769 9.46673C4.85564 9.40541 4.70476 9.35897 4.57346 9.32831C4.15918 9.19486 3.87458 9.0871 3.71799 9.00504C3.56141 8.92299 3.48313 8.81027 3.48313 8.66687C3.48313 8.4414 3.61198 8.28724 3.86888 8.20518C4.12575 8.12313 4.51231 8.08168 5.02687 8.08168C5.33922 8.08168 5.66463 8.11733 6.00305 8.18943C6.34066 8.26154 6.62611 8.35854 6.85769 8.48121L7.22141 6.77373C6.94904 6.67093 6.61793 6.58887 6.22976 6.52756C5.84076 6.46621 5.44036 6.43555 5.02608 6.43555C3.89578 6.43555 3.05091 6.61541 2.49065 6.9743C1.9304 7.33321 1.56995 7.87695 1.40848 8.60471C1.3677 8.76882 1.34813 8.93793 1.34813 9.11196C1.34813 9.52228 1.48432 9.86129 1.75669 10.1274C2.02907 10.3942 2.44254 10.6305 2.9979 10.8352C3.10881 10.8767 3.20749 10.9098 3.29311 10.9355C3.37874 10.9612 3.45703 10.9894 3.52798 11.02C4.0018 11.1842 4.31985 11.3176 4.48132 11.4204C4.64278 11.5232 4.70802 11.6666 4.67786 11.8514C4.63708 12.057 4.51148 12.1921 4.29945 12.2592C4.08741 12.3264 3.71882 12.3595 3.19444 12.3595C2.80055 12.3595 2.38953 12.3214 1.96057 12.2443C1.53162 12.1672 1.16627 12.057 0.862901 11.9136L0.5 13.6368C0.782166 13.7603 1.15567 13.8573 1.61969 13.9294C2.08372 14.0007 2.57791 14.0372 3.1031 14.0372C4.2228 14.0372 5.07338 13.8556 5.65319 13.4909"
                fill="white"
              />
              <path
                d="M13.6712 8.14276C13.8531 8.26626 13.9436 8.47098 13.9436 8.7586C13.9436 8.93267 13.9232 9.11752 13.8833 9.31229L12.9299 13.8811H14.9736L15.9424 9.20454C16.0028 8.89705 16.0329 8.61439 16.0329 8.35827C16.0329 7.72252 15.8438 7.24261 15.4654 6.92018C15.087 6.59691 14.5291 6.43528 13.7928 6.43528C13.3084 6.43528 12.8443 6.5049 12.3999 6.64332C11.9554 6.78173 11.5526 6.96906 11.1888 7.20448L11.8307 4.13514L13.0343 4.27024L12.0712 3.03356L11.1081 1.79688L9.90196 2.7907L8.69666 3.78452L9.8351 3.91217L7.75391 13.8827H9.79759L10.8724 8.71387C11.135 8.4884 11.4376 8.30603 11.7809 8.16762C12.1234 8.0292 12.4521 7.95958 12.7644 7.95958C13.1885 7.95958 13.491 8.02093 13.6729 8.14443"
                fill="white"
              />
              <path
                d="M17.622 13.4567C18.1064 13.8413 18.762 14.0336 19.5898 14.0336C20.084 14.0336 20.5333 13.964 20.937 13.8255C21.3407 13.6871 21.7289 13.4791 22.1024 13.2022L22.0869 13.8794H23.8426L25.3562 6.58779H23.3126L23.2221 7.06438C22.9799 6.82816 22.7124 6.66405 22.4196 6.57204C22.1268 6.48003 21.7737 6.43359 21.3602 6.43359C20.6736 6.43359 20.0359 6.60022 19.4454 6.93341C18.855 7.26663 18.3478 7.74654 17.9245 8.37153C17.5004 8.99732 17.1979 9.73584 17.016 10.5871C16.9353 11.0181 16.8945 11.372 16.8945 11.6489C16.8945 12.4695 17.1367 13.0721 17.6211 13.4567M19.0899 10.5879C19.2717 9.72672 19.5719 9.07275 19.9902 8.62681C20.4086 8.18088 20.9362 7.95709 21.5715 7.95709C22.1366 7.95709 22.5754 8.1726 22.8885 8.60359L22.2222 11.8495C21.9393 12.0857 21.6595 12.2548 21.3823 12.3568C21.105 12.4595 20.7992 12.5109 20.4664 12.5109C19.9421 12.5109 19.5629 12.4289 19.3313 12.2648C19.0989 12.1006 18.983 11.8188 18.983 11.4185C18.983 11.1623 19.0181 10.8855 19.0891 10.5879"
                fill="white"
              />
              <path
                d="M28.685 8.78873C29.008 8.62461 29.4312 8.46877 29.9564 8.31957C30.4808 8.17121 30.9652 8.06593 31.4095 8.00461L31.7277 6.43555C31.2531 6.49686 30.7613 6.61042 30.2516 6.77373C29.742 6.93784 29.3007 7.12765 28.9272 7.34316L28.9876 6.58887H27.0956L25.582 13.8813H27.6257L28.685 8.78873Z"
                fill="white"
              />
              <path
                d="M34.6932 13.8275C35.097 13.6891 35.4849 13.481 35.8586 13.2041L35.8439 13.8813H37.5998L39.1132 6.58971H37.0697L36.9791 7.0663C36.737 6.83008 36.4695 6.66597 36.1767 6.57396C35.8839 6.48195 35.5307 6.43555 35.1171 6.43555C34.4315 6.43555 33.7928 6.60214 33.2023 6.93536C32.6122 7.26855 32.1046 7.74849 31.6816 8.37345C31.2573 8.99925 30.9549 9.73779 30.7731 10.5891C30.6923 11.02 30.6523 11.374 30.6523 11.6508C30.6523 12.4714 30.8945 13.074 31.379 13.4586C31.8634 13.8432 32.5191 14.0355 33.3469 14.0355C33.841 14.0355 34.2903 13.9667 34.6941 13.8283M32.7409 11.4196C32.7409 11.1634 32.7759 10.8866 32.847 10.5891C33.0288 9.72784 33.3289 9.07386 33.7471 8.62793C34.1655 8.18196 34.6932 7.95901 35.3285 7.95901C35.8936 7.95901 36.3322 8.17452 36.6455 8.60555L35.9794 11.8514C35.6972 12.0877 35.4166 12.2567 35.1394 12.3587C34.8621 12.4615 34.5562 12.5128 34.2235 12.5128C33.6992 12.5128 33.3207 12.4308 33.0883 12.2667C32.8558 12.1026 32.7399 11.8207 32.7399 11.4204"
                fill="#338FCB"
              />
              <path
                d="M40.3616 13.8798H41.3833L41.8826 11.4794L42.8815 10.6646L44.3952 13.8798H46.5595L44.3952 9.57217L47.9067 6.5874H45.5759L42.3514 9.21824L43.4189 4.12396L44.6072 4.30714L43.6937 3.0315L42.7805 1.75586L41.5358 2.69912L40.2924 3.64238L41.4398 3.81893L39.3398 13.8798H40.3616Z"
                fill="#338FCB"
              />
              <path
                d="M47.4062 11.6508C47.4062 12.4714 47.6484 13.074 48.1328 13.4586C48.6174 13.8432 49.2728 14.0355 50.1006 14.0355C50.5947 14.0355 51.0443 13.9659 51.4478 13.8275C51.8516 13.689 52.2399 13.481 52.6132 13.2041L52.5977 13.8813H54.3535L55.8673 6.58971H53.8235L53.7329 7.0663C53.4907 6.83008 53.2232 6.66597 52.9304 6.57396C52.6376 6.48195 52.2847 6.43555 51.8711 6.43555C51.1846 6.43555 50.5468 6.60214 49.9564 6.93536C49.3659 7.26855 48.8587 7.74849 48.4353 8.37345C48.0114 8.99925 47.7088 9.73779 47.527 10.5891C47.4462 11.02 47.4062 11.374 47.4062 11.6508ZM49.6007 10.5891C49.7825 9.72784 50.0826 9.07386 50.5011 8.62793C50.9195 8.18196 51.4472 7.95817 52.0831 7.95817C52.6483 7.95817 53.0872 8.17369 53.4001 8.60471L52.734 11.8506C52.451 12.0868 52.1713 12.2559 51.894 12.3578C51.6168 12.4606 51.3118 12.512 50.9781 12.512C50.4529 12.512 50.0747 12.43 49.8429 12.2659C49.6105 12.1017 49.4949 11.8199 49.4949 11.4196C49.4949 11.1634 49.5297 10.8866 49.6007 10.5891Z"
                fill="#338FCB"
              />
              <path
                d="M59.683 6.58833L59.8892 5.5431L61.058 5.70473L60.1259 4.44319L59.1935 3.18164L57.9638 4.14397L56.734 5.10628L57.9092 5.26875L57.6402 6.58833H56.6257L56.293 8.18805H57.3074L56.5961 11.5724C56.5458 11.8492 56.5205 12.0854 56.5205 12.2802C56.5205 12.8339 56.6892 13.2649 57.0277 13.5724C57.3654 13.88 57.8628 14.0341 58.5186 14.0341C59.174 14.0341 59.6992 13.9421 60.1836 13.7573L60.4861 12.3266C60.1537 12.4294 59.8511 12.4808 59.5778 12.4808C59.2344 12.4808 58.9898 12.4344 58.8437 12.3424C58.697 12.2504 58.6244 12.0912 58.6244 11.865C58.6244 11.7423 58.6399 11.5981 58.6701 11.4339L59.3512 8.18805H61.0464L61.5008 6.58833H59.6846H59.683Z"
                fill="#338FCB"
              />
              <path
                d="M33.7989 17.0162L33.2182 16.2852L32.6384 17.0162L32.0586 17.7465H32.6506V24.8292H33.7864V17.7465H34.3787L33.7989 17.0162Z"
                fill="#338FCB"
              />
              <path
                d="M60.2831 19.4802L59.7033 18.75L59.1235 19.4802L58.5434 20.2105H59.1357V22.6739C59.1357 23.4671 58.7041 23.8642 57.8413 23.8642H57.2209C56.3669 23.8642 55.9396 23.4671 55.9396 22.6739V20.0671L54.8035 20.6025V22.6739C54.8035 23.4671 54.3722 23.8642 53.5094 23.8642H52.9018C52.039 23.8642 51.6077 23.4671 51.6077 22.6739V20.46L50.4716 21.0079V22.6739C50.4716 23.4671 50.0443 23.8642 49.1906 23.8642H40.2614C39.4867 23.8642 39.0594 23.5467 38.9804 22.9118V19.6626L38.9673 19.6742V19.6626L37.8311 20.2105V24.2695C37.8311 24.8721 37.6731 25.3445 37.3559 25.686C37.0384 26.0267 36.5983 26.1975 36.0347 26.1975C35.5854 26.1975 35.1368 26.0864 34.6875 25.8643V26.757C35.1898 26.9949 35.7044 27.1142 36.2329 27.1142C36.9634 27.1142 37.571 26.9004 38.0556 26.4718C38.5839 26.0035 38.8883 25.3769 38.9673 24.5911C39.2927 24.7502 39.681 24.829 40.1293 24.829H49.3086C50.0833 24.829 50.66 24.5952 51.0392 24.1269C51.4357 24.5952 52.0121 24.829 52.7697 24.829H53.6814C54.4302 24.8215 54.9935 24.587 55.372 24.1269C55.7505 24.5952 56.3269 24.829 57.1026 24.829H58.0142C58.6748 24.8215 59.2034 24.6425 59.5996 24.2935C60.0489 23.9122 60.2733 23.3851 60.2733 22.7104V20.2113H60.8653L60.2855 19.4811L60.2831 19.4802Z"
                fill="white"
              />
              <path
                d="M4.58203 18.6988C4.58203 19.0958 4.79815 19.2939 5.22955 19.2939C5.66097 19.2939 5.86321 19.0958 5.86321 18.6988C5.86321 18.3017 5.65197 18.1152 5.22955 18.1152C4.80712 18.1152 4.58203 18.31 4.58203 18.6988Z"
                fill="#338FCB"
              />
              <path
                d="M6.26172 18.6988C6.26172 19.0958 6.47702 19.2939 6.90923 19.2939C7.34145 19.2939 7.54287 19.0958 7.54287 18.6988C7.54287 18.3017 7.33166 18.1152 6.90923 18.1152C6.48678 18.1152 6.26172 18.31 6.26172 18.6988Z"
                fill="#338FCB"
              />
              <path
                d="M9.77471 22.6253C9.77471 23.4509 9.34741 23.8629 8.49356 23.8629H4.10858C3.25474 23.8629 2.82741 23.4501 2.82741 22.6253V20.733L1.69141 21.2809V22.7215C1.69141 23.3249 1.86756 23.8131 2.21986 24.1853C2.62435 24.6138 3.18378 24.8277 3.89736 24.8277H8.61263C9.26421 24.8277 9.79674 24.6611 10.211 24.3279C10.6775 23.9466 10.9115 23.4111 10.9115 22.7207V19.6621L9.77554 20.21V22.6262L9.77471 22.6253Z"
                fill="#338FCB"
              />
              <path
                d="M53.7852 17.8293C53.7852 18.3059 54.034 18.5438 54.5321 18.5438C55.0305 18.5438 55.2644 18.3059 55.2644 17.8293C55.2644 17.3527 55.0207 17.1289 54.5321 17.1289C54.0438 17.1289 53.7852 17.3626 53.7852 17.8293Z"
                fill="white"
              />
              <path
                d="M56.1085 18.5439C56.5309 18.5439 56.7422 18.3458 56.7422 17.9488C56.7422 17.5518 56.5309 17.3652 56.1085 17.3652C55.686 17.3652 55.4609 17.56 55.4609 17.9488C55.4609 18.3375 55.6763 18.5439 56.1085 18.5439Z"
                fill="white"
              />
              <path
                d="M55.8466 16.5922C55.8466 16.2432 55.6526 16.0684 55.2652 16.0684C54.8779 16.0684 54.6836 16.2432 54.6836 16.5922C54.6836 16.9412 54.877 17.1277 55.2652 17.1277C55.6535 17.1277 55.8466 16.9494 55.8466 16.5922Z"
                fill="white"
              />
              <path
                d="M29.8237 20.6268C29.2675 20.127 28.5588 19.851 27.6968 19.8021V19.7988H20.4543L21.0292 19.6206L21.7363 19.4009L22.4939 19.1655L23.9585 18.7096L23.9561 18.7005L24.3059 18.5919L25.0635 18.3565L25.7706 18.1369L26.5282 17.9015L27.9937 17.4456L27.7164 16.5239L26.2509 16.9798L25.4933 17.2152L24.7863 17.4348L24.0287 17.6702L23.3216 17.8899L22.564 18.1253L21.0985 18.5811L21.101 18.5903L20.7511 18.6988L19.9935 18.9343L19.2865 19.1539L18.5289 19.3893L17.2143 19.798L17.0634 19.8452V20.7628H17.3399V20.7669L17.3545 20.7628H23.9846C24.827 20.7628 25.6686 20.7628 26.5103 20.7661C27.3413 20.7694 28.0303 20.7528 28.7659 21.1979C29.2765 21.5154 29.5325 21.968 29.5325 22.5548V22.6617C29.5325 23.4641 29.1093 23.8644 28.2644 23.8644H15.452C14.5891 23.8644 14.1578 23.4674 14.1578 22.6741V17.7456H14.749L14.1692 17.0154L13.5893 16.2852L13.0095 17.0154L12.4297 17.7456H13.0218V22.7089C13.0218 23.2966 13.1979 23.7807 13.5502 24.1611C13.9637 24.6054 14.5541 24.8276 15.3198 24.8276H28.3704C29.022 24.8276 29.5545 24.6651 29.9688 24.3394C30.4353 23.9581 30.6685 23.4268 30.6685 22.7438V22.5415C30.6685 21.764 30.3863 21.1258 29.8237 20.6252"
                fill="#338FCB"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_6948_22570">
              <rect
                width="61"
                height="31"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
            <clipPath id="clip1_6948_22570">
              <rect
                width="61"
                height="31"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </section>
    </div>
  );
}
