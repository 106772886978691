import React from "react";
import Hr from "../../components/pages/Hr";
import { Link } from "react-router-dom";

export default function Events() {
  return (
    <div className=" p-4 md:p-16  ">
      <h1 className=" text-[#432315] text-[36px] font-[600] my-2 text-center ">
        عالم الإبل بين يديك
      </h1>
      <Hr color="#432315" />
      <div className=" flex items-center justify-center gap-x-5 flex-wrap ">
        <section className="  relative w-full max-w-[1100px] mx-auto my-4 md:my-20  ">
          <section className=" w-full md:w-[55%] text-center md:text-start p-8 md:p-12 border border-[#3F230E] rounded-[16px] ">
            <h1 className=" text-[#432315] font-[700] text-[40px] ">فعاليات</h1>
            <h4 className=" mt-3 leading-[40px] md:w-[75%] text-[18px] text-[#432315] font-[600] ">
              تعرف وتابع الأحداث التي تجمع عشاق الابل وتحدث الحراك الثقافي
              والتجاري وغيرها
            </h4>
            {/* <h4 className=" mb-3 leading-[40px] md:w-[75%] text-[18px] text-[#432315] font-[600] ">
              انضم إلينا وكن جزءًا من هذا المجتمع المتميز الذي يحتفي بتراثنا
              وجمال الإبل
            </h4> */}
            <Link to="/events">
              <button className="btn2">انضم للفعاليات</button>
            </Link>
            <img
              // src="./imgs/home/events.jfif"
              src="./imgs/home/event.png"
              alt="event"
              className=" rounded-[16px] md:hidden mt-4 "
            />
          </section>
          <section className=" hidden md:flex absolute top-[50px] right-[45%] w-[55%] h-full bg-origin-content bg-cover rounded-[16px]  ">
            <img
              src="./imgs/home/event.png"
              alt="event"
              className="h-full w-full object-cover rounded-[16px] brightness-80"
            />
          </section>
        </section>
        <section className="  relative md:flex justify-end w-full max-w-[1100px] mx-auto my-4 md:my-20  ">
          <section className=" md:pr-[12%] w-full md:w-[55%] text-center md:text-start p-8 md:p-12 border border-[#3F230E] rounded-[16px] ">
            <h1 className=" text-[#432315] font-[700] text-[40px] ">
              السباقات
            </h1>
            <h4 className=" mt-3 leading-[40px]  text-[18px] text-[#432315] font-[600] ">
              سباق المزايين
            </h4>
            <h4 className=" mt-1 leading-[40px]  text-[18px] text-[#432315] font-[600] ">
              و سباق الهجن
            </h4>
            <h4 className=" mb-3 leading-[40px] text-[18px] text-[#432315] font-[600] ">
              من أكثر الانشطة التي تجعل القطاع أكثر تطوراً وحماساً
            </h4>
            <Link to="/races">
              <button className="btn2">انضم للسباقات </button>
            </Link>
            <img
              src="./imgs/home/race.png"
              // src="./imgs/home/races.jfif"
              alt="races"
              className=" rounded-[16px] md:hidden mt-4 "
            />
          </section>
          <section className=" hidden md:flex absolute top-[50px] right-[0] w-[55%] h-full bg-origin-content bg-cover rounded-[16px]  ">
            <img
              src="./imgs/home/race.png"
              alt="races"
              className="h-full w-full object-cover rounded-[16px] brightness-80"
            />
          </section>
        </section>
      </div>
    </div>
  );
}
