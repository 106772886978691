import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default function Sponsers() {
  let imgs = [
    "ميترا اكسبيرت.png",
    "ميترا ايفنت.png",
    "ميترا براند.png",
    "ميترا ستيت.png",
    "ميترا فارم.png",
    "ناجد.png",
    "امتياز المنتج.png",
    "شراكات.png",
    "لوغو جنى الارض الزراعية.png",
    "منصة الفروسية.png",
    "منصة الهجن.png",
  ];

  return (
    <>
      {/* <div className=" flex items-center justify-center gap-12 md:gap-20 flex-wrap bg-[#E8DCC0] p-[40px]">
        {imgs.map((e) => (
          <img
            className=" w-[100px] md:w-[100px] "
            src={`./imgs/spons/${e}`}
            key={e}
            alt="Sponser"
          />
        ))}
      </div> */}
      <div className="bg-[#E8DCC0]  p-[20px] md:px-[40px] ">
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 3000, // Time delay in milliseconds
            disableOnInteraction: false,
          }}
          slidesPerView={2}
          breakpoints={{
            600: { slidesPerView: 2 },
            768: { slidesPerView: 4 },
            1024: { slidesPerView: 6 },
            1224: { slidesPerView: 8 },
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper "
        >
          {imgs.map((e, index) => (
            <SwiperSlide className=" my-auto  " key={index}>
              <img
                className=" my-6 mx-auto w-[100px] md:w-[100px] "
                key={e}
                src={`./imgs/spons/${e}`}
                alt="Sponser"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
