import React from "react";

export default function Pref({ data }) {
  return (
    <div className="pb-4 border text-[#333333] border-[#43231580] rounded-[16px] p-[16px]">
      <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] ">عدد الفعليات</h1>
        <h4 className="text-[18px] font-[400] ">{data.total_events}</h4>
      </section>
      <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] ">عدد الفعليات القادمة </h1>
        <h4 className="text-[18px] font-[400] ">
          {data.upcoming_events_count}
        </h4>
      </section>
      {data.most_popular_event && (
        <section className=" my-4 ">
          <h1 className=" my-2 text-[20px] font-[600] ">
            {" "}
            الفعالبة الاكثر شعبية{" "}
          </h1>
          <h4 className="text-[18px] font-[400] ">{data.most_popular_event}</h4>
        </section>
      )}
      <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] ">
          {" "}
          عدد المشتركين في الفعاليات القادمة{" "}
        </h1>
        <h4 className="text-[18px] font-[400] ">
          {data.total_subscribers_in_upcoming_events} شخص{" "}
        </h4>
      </section>
    </div>
  );
}
