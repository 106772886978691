import React from "react";

export default function SelectBtn2({
  dis,
  text,
  val,
  handelVal,
  name,
  mainval,
}) {
  return (
    <button
      disabled={dis}
      onClick={handelVal}
      className={` text-[12px] md:text-[15px]  font-[700] h-[35px] md:h-[45px]  w-[60px] md:w-[85px]  border rounded-[10px] ${
        val === mainval ? "   bg-[#E8D6B2] " : "   "
      }  text-[#290900] border-[#290900]`}
      name={name}
      value={val}
    >
      {text}
    </button>
  );
}
