import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@mui/material";
import DiscountPrice from "../../../../../components/pages/DiscountPrice";

export default function PackageComponent({
  data,
  setDeleteId,
  setEditId,
  setHoldId,
  setType,
  setSubsID,
}) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let time = {
    days: "يوم",
    months: "شهر",
    years: "سنة",
  };
  return (
    <div className=" relative text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <h1 className=" text-center my-2 text-[18px] font-[600] ">{data.name}</h1>
      {/* <p>
        الحالة : <span className=" border text-green-800 border-green-800 px-2 rounded-full ">نشط</span>
      </p> */}
      <p className="  min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.description} />
      </p>
      <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
        مدة اشتراك الباقة : {data.duration} {time[data.duration_type]}
      </p>
      {data.discount_percentage && Number(data.discount_percentage) > 0 ? (
        <p className=" flex items-center gap-1  text-[15px] font-[600] leading-[28px] my-2 ">
          السعر : <DiscountPrice>{data.price}</DiscountPrice>{" "}
          {(
            ((100 - Number(data.discount_percentage)) / 100) *
            data.price
          ).toFixed(2)}{" "}
          ر.س
        </p>
      ) : (
        <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
          السعر : {data.price} ر.س
        </p>
      )}
      <section className=" my-1   text-[15px] font-[600] leading-[28px] flex items-center justify-between flex-wrap gap-1 ">
        <Link to={`/package?package=${data.id}`}>
          <button className=" btnn2 ">التفاصيل</button>
        </Link>
        {/* <button className=" btnn ">المشتركين</button> */}
        <button
          onClick={() => {
            setSubsID(data.id);
            setType(data.user_type);
          }}
          className=" btnn "
        >
          المشتركين
        </button>
        <button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="  flex items-center justify-center bg-[#E8D6B2]/30 hover:bg-[#E8D6B2]/60 rounded-full h-[50px] w-[50px] "
        >
          <i className="fa-solid fa-ellipsis text-2xl "></i>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              setEditId(data.id);
            }}
            className=" py-2 px-12 border-b "
          >
            تعديل
          </button>
          <br></br>
          {data.package_status === "active" && (
            <>
              <button
                onClick={() => {
                  handleClose();
                  setHoldId(data.id);
                }}
                className=" py-2 px-12 border-b text-blue-600 "
              >
                تعليق
              </button>
              <br></br>
            </>
          )}
          <button
            onClick={() => {
              handleClose();
              setDeleteId(data.id);
            }}
            className=" py-2 px-12 text-red-500 "
          >
            حذف
          </button>
        </Menu>
      </section>
    </div>
  );
}
