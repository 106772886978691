import React from "react";

export default function Hr({ color }) {
  return (
    <section className="  w-fit  mx-auto  ">
      <svg
        width="256"
        height="26"
        viewBox="0 0 256 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.30371 15.1651C4.35171 10.7957 9.43571 2.57441 10.2504 1.99974C10.2504 1.99974 15.5784 10.8411 17.3397 15.2891C12.949 15.0757 6.69438 15.3771 2.30371 15.1651Z"
          fill={color}
        />
        <path
          d="M2.30371 15.1651C4.35171 10.7957 9.43571 2.57441 10.2504 1.99974C10.2504 1.99974 15.5784 10.8411 17.3397 15.2891C12.949 15.0757 6.69438 15.3771 2.30371 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.3767 2.37861C30.3287 6.74928 25.2447 14.9706 24.43 15.5439C24.43 15.5439 19.102 6.70394 17.3394 2.25594C21.7314 2.46794 27.986 2.16661 32.3767 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.5942 15.1651C33.6422 10.7957 38.7263 2.57441 39.5409 1.99974C39.5409 1.99974 44.8689 10.8411 46.6316 15.2891C42.2396 15.0757 35.9849 15.3771 31.5942 15.1651Z"
          fill={color}
        />
        <path
          d="M31.5942 15.1651C33.6422 10.7957 38.7263 2.57441 39.5409 1.99974C39.5409 1.99974 44.8689 10.8411 46.6316 15.2891C42.2396 15.0757 35.9849 15.3771 31.5942 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.6674 2.37861C59.6194 6.74928 54.5367 14.9706 53.7207 15.5439C53.7207 15.5439 48.394 6.70394 46.6313 2.25594C51.022 2.46794 57.2767 2.16661 61.6674 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M60.8848 15.1651C62.9328 10.7957 68.0168 2.57441 68.8314 1.99974C68.8314 1.99974 74.1594 10.8411 75.9221 15.2891C71.5314 15.0757 65.2768 15.3771 60.8848 15.1651Z"
          fill={color}
        />
        <path
          d="M60.8848 15.1651C62.9328 10.7957 68.0168 2.57441 68.8314 1.99974C68.8314 1.99974 74.1594 10.8411 75.9221 15.2891C71.5314 15.0757 65.2768 15.3771 60.8848 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M90.9597 2.37861C88.9117 6.74928 83.8277 14.9706 83.013 15.5439C83.013 15.5439 77.685 6.70394 75.9224 2.25594C80.313 2.46794 86.5677 2.16661 90.9597 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M90.1768 15.1651C92.2248 10.7957 97.3087 2.57441 98.1234 1.99974C98.1234 1.99974 103.451 10.8411 105.213 15.2891C100.822 15.0757 94.5674 15.3771 90.1768 15.1651Z"
          fill={color}
        />
        <path
          d="M90.1768 15.1651C92.2248 10.7957 97.3087 2.57441 98.1234 1.99974C98.1234 1.99974 103.451 10.8411 105.213 15.2891C100.822 15.0757 94.5674 15.3771 90.1768 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M120.25 2.37861C118.202 6.74928 113.118 14.9706 112.304 15.5439C112.304 15.5439 106.976 6.70394 105.213 2.25594C109.605 2.46794 115.86 2.16661 120.25 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M119.467 15.1651C121.515 10.7957 126.599 2.57441 127.414 1.99974C127.414 1.99974 132.742 10.8411 134.505 15.2891C130.113 15.0757 123.858 15.3771 119.467 15.1651Z"
          fill={color}
        />
        <path
          d="M119.467 15.1651C121.515 10.7957 126.599 2.57441 127.414 1.99974C127.414 1.99974 132.742 10.8411 134.505 15.2891C130.113 15.0757 123.858 15.3771 119.467 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M149.541 2.37861C147.493 6.74928 142.41 14.9706 141.594 15.5439C141.594 15.5439 136.268 6.70394 134.505 2.25594C138.896 2.46794 145.15 2.16661 149.541 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M150.788 15.1651C152.836 10.7957 157.918 2.57441 158.734 1.99974C158.734 1.99974 164.061 10.8411 165.824 15.2891C161.433 15.0757 155.178 15.3771 150.788 15.1651Z"
          fill={color}
        />
        <path
          d="M150.788 15.1651C152.836 10.7957 157.918 2.57441 158.734 1.99974C158.734 1.99974 164.061 10.8411 165.824 15.2891C161.433 15.0757 155.178 15.3771 150.788 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M180.861 2.37861C178.813 6.74928 173.729 14.9706 172.914 15.5439C172.914 15.5439 167.586 6.70394 165.823 2.25594C170.214 2.46794 176.47 2.16661 180.861 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M180.078 15.1651C182.126 10.7957 187.21 2.57441 188.025 1.99974C188.025 1.99974 193.353 10.8411 195.115 15.2891C190.723 15.0757 184.469 15.3771 180.078 15.1651Z"
          fill={color}
        />
        <path
          d="M180.078 15.1651C182.126 10.7957 187.21 2.57441 188.025 1.99974C188.025 1.99974 193.353 10.8411 195.115 15.2891C190.723 15.0757 184.469 15.3771 180.078 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M210.152 2.37861C208.104 6.74928 203.02 14.9706 202.205 15.5439C202.205 15.5439 196.877 6.70394 195.116 2.25594C199.506 2.46794 205.761 2.16661 210.152 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M209.369 15.1651C211.417 10.7957 216.501 2.57441 217.315 1.99974C217.315 1.99974 222.643 10.8411 224.406 15.2891C220.015 15.0757 213.761 15.3771 209.369 15.1651Z"
          fill={color}
        />
        <path
          d="M209.369 15.1651C211.417 10.7957 216.501 2.57441 217.315 1.99974C217.315 1.99974 222.643 10.8411 224.406 15.2891C220.015 15.0757 213.761 15.3771 209.369 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M239.442 2.37861C237.396 6.74928 232.312 14.9706 231.497 15.5439C231.497 15.5439 226.169 6.70394 224.406 2.25594C228.797 2.46794 235.052 2.16661 239.442 2.37861Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M238.661 15.1651C240.709 10.7957 245.791 2.57441 246.607 1.99974C246.607 1.99974 251.934 10.8411 253.697 15.2891C249.306 15.0757 243.051 15.3771 238.661 15.1651Z"
          fill={color}
        />
        <path
          d="M238.661 15.1651C240.709 10.7957 245.791 2.57441 246.607 1.99974C246.607 1.99974 251.934 10.8411 253.697 15.2891C249.306 15.0757 243.051 15.3771 238.661 15.1651Z"
          stroke={color}
          stroke-width="2.66667"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </section>
  );
}
