import { useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { load } from "./isLoadingStore";

const useFileUpload = (endPoint) => {
  const { setIsLoading } = useContext(load);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [progress, setProgress] = useState(0); // Upload progress
  const [isUploading, setIsUploading] = useState(false); // Uploading state

  const uploadFile = async (formData) => {
    setIsLoading(true);
    setIsUploading(true);
    setProgress(0);
    let token = Cookies.get("token");
    setError(null);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/${endPoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (event) => {
            const percentage = Math.round((event.loaded * 100) / event.total);
            setProgress(percentage); // Track upload progress
          },
        }
      );
      setResponse(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
      setIsUploading(false);
    }
  };

  return { uploadFile, error, response, progress, isUploading }; // Return isUploading
};

export default useFileUpload;
