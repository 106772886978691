import React, { useState } from "react";
// import useFetchWithToken from "../../../../../utils/useGetWithToken";
import { Link } from "react-router-dom";
import CancelBtn from "./CancelBtn";
import useFetchWithReload from "../../../../../utils/useGetWithReload";

export default function MyInvests() {
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(
    "global/Report/Investment/forInvestor/allReport",
    reload
  );
  const re = () => {
    setReload((pre) => !pre);
  };
  // const { data, error } = useFetchWithToken(
  //   "global/investments/join/myInvestments"
  // );

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة{" "}
        </h1>
      </div>
    );
  }
  // console.log(data);
  return (
    <div>
      {!!data && (
        <>
          {!!data.reports && (
            <>
              <section className=" p-4 border border-black/50 rounded-[15px] my-6 ">
                <h1 className=" text-center font-bold text-xl my-2">
                  ملخص الاستثمار
                </h1>
                <h1 className=" my-3 text-xl font-bold ">
                  اجمالي المبلغ المدفوع في الاستثمارات :{" "}
                  <span className=" text-2xl font-[800] text-[#432315] ">
                    {data.total_amount_paid} ر.س
                  </span>
                </h1>
                <h1 className=" my-3 text-xl font-bold ">
                  اجمالي عدد الاسهم التي تم شرائها :{" "}
                  <span className=" text-2xl font-[800] text-[#432315] ">
                    {data.total_shares} سهم
                  </span>
                </h1>
              </section>
              <h1 className=" text-center font-bold text-xl my-2">
                تفاصيل الاستثمارات
              </h1>
            </>
          )}
          {!!data.reports ? (
            <>
              {
                <table>
                  <thead>
                    <tr>
                      <th>اسم الاستثمار</th>
                      <th>عدد الاسهم</th>
                      <th>سعر السهم</th>
                      <th>اجمالي المدفوع</th>
                      <th>الغاء</th>
                      <th>عرض الاستثمار</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.reports.map((e, inx) => (
                      <tr key={inx}>
                        <td className=" text-center ">{e.investment_title}</td>
                        <td className=" text-center ">{e.shares_purchased}</td>
                        <td className=" text-center ">
                          {e.price_per_share} ر.س
                        </td>
                        <td className=" text-center ">
                          {e.total_amount_paid} ر.س
                        </td>
                        <td className=" text-center ">
                          <CancelBtn
                            re={re}
                            id={e.investment_id}
                            type={e.investment_type}
                          />
                        </td>
                        <td className=" text-center ">
                          <Link
                            className=" text-sky-800 underline font-bold "
                            to={`/investment-details?invest=${e.investment_id}&role=${e.investment_type}`}
                          >
                            عرض{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد مشاركات لك في الاستثمار
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
