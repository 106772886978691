import React, { useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";

export default function Reports() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("CamelOwner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };
  const { data, error } = useFetchWithReload(
    `global/Report/store/superAdmin/gard-report-store/${type}`,
    reload
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"CamelOwner"}>مالك ابل</option>
          <option value={"Visitor"}>زائر</option>
          <option value={"ServiceProvider"}>مزود خدمة</option>
          <option value={"FieldOwner"}>مالك ساحة</option>
        </select>
      </div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير المتجر</h1>
      {data && (
        <>
          {data.report.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>اسم المالك</th>
                  <th>عدد المنتجات</th>
                  <th>اجمالي المنتجات</th>
                  <th>عدد مرات البيع</th>
                  <th>الارباح</th>
                </tr>
              </thead>
              <tbody>
                {data.report.map((user, inx) => (
                  <tr key={inx}>
                    <td className=" text-center ">{user.owner_name}</td>
                    <td className=" text-center ">{user.total_products}</td>
                    <td className=" text-center ">{user.total_price} ر.س</td>
                    <td className=" text-center ">{user.sold_transactions}</td>
                    <td className=" text-center ">{user.sold_price} ر.س</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1 className=" my-3 text-center text-lg text-red-500 font-bold ">
              لا توجد متاجر{" "}
            </h1>
          )}
        </>
      )}
    </div>
  );
}
