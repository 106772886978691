import React, { useState } from "react";

export default function Accept({ pre, data, submit }) {
  const [checked, setChecked] = useState(false);
  const sendHandeller = (e) => {
    e.preventDefault();
    submit();
  };
  return (
    <div className=" mb-3 bg-[#E8DCC0] rounded-[15px] p-4 ">
      <h1 className="text-2xl font-bold my-3">شروط السباق</h1>
      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.race.terms }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
      <h1 className="text-2xl font-bold my-3">
        رسوم المشاركة :{data.race.fees} ر.س
      </h1>
      <input
        type="checkbox"
        id={`customCheckbox-1`}
        className="custom-checkbox"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <label
        htmlFor={`customCheckbox-1`}
        className="text-[16px] text-[#432315] font-[800] mx-3 "
      >
        أقر بأنني قرأت وفهمت وأوافق على جميع اللوائح والشروط المحددة لهذا السباق
        وأنني سألتزم بها بجميع التفاصيل.
      </label>

      <section className=" my-6 col-span-1 lg:col-span-2 flex items-center justify-center gap-2 flex-wrap ">
        <button
          disabled={!checked}
          type="submit"
          onClick={sendHandeller}
          className=" disabled:opacity-65 btn3 "
        >
          الدفع
        </button>
        <button onClick={pre} className=" btn4 ">
          العوده
        </button>
      </section>
    </div>
  );
}
