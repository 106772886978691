import React from "react";
import { Link } from "react-router-dom";

export default function VeterinaryComponent({ data }) {
  return (
    <div className=" w-full max-w-[610px] border border-[#7F7F7F] rounded-[24px] p-[8px] ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[350px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={data.img}
          alt="cover"
        />
      </div>
      <h1 className=" text-center my-2 text-[22px] md:text-[28px] font-[800] ">
        {data.name}
      </h1>
      <h1 className=" text-center my-2 text-[14px] md:text-[18px] font-[600] ">
        {data.desc}
      </h1>
      <Link to={data.link}>
        <button className=" w-full bg-[#432315] rounded-[10px] h-[60px] mt-12 text-center text-[white] text-[18px] font-[600] ">
          {data.btn}
        </button>
      </Link>
    </div>
  );
}
