import React, { useEffect, useState } from "react";
// import useFetchWithToken from "../../../../../utils/useGetWithToken";
import PackageComponent from "./PackageComponent";
import DeletePack from "./DeletePack";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import Hold from "./Hold";
import AddPackage from "./AddPackage";
import EditPackage from "./EditPackage";
import SubsOfPackage from "./SubsOfPackage";

export default function PackagesDahBoardSuper() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    filter: "",
  });

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };
  const [reload, setReload] = useState(false);
  let link = "superAdmin/packages/all";
  const { data, error } = useFetchWithReload(link, reload);

  const re = () => {
    setReload((pre) => !pre);
  };

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filtered = [];

    if (!!data) {
      filtered = data.packages;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.toLowerCase().includes(mainFilter.search.toLowerCase())
        );
      }
      if (mainFilter.filter) {
        filtered = filtered.filter(
          (item) => item.user_type === mainFilter.filter
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);
  const [deleteId, setDeletId] = useState();
  const [holdId, setHoldId] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [editId, setEditId] = useState();

  const [type, setType] = useState(null);
  const [subsID, setSubsID] = useState(null);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}
        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <select
          className=" bg-white/0 outline-none p-2  border rounded-[11px] border-black/60 "
          value={mainFilter.filter}
          onChange={(e) =>
            setMainFilter({ ...mainFilter, filter: e.target.value })
          }
        >
          <option hidden>نوع المستخدم</option>
          <option value={""}>الكل</option>
          <option value={"visitor"}>زائر</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>

        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة باقة
        </button>
      </div>

      <div className="flex items-start flex-wrap gap-4 my-10 ">
        {type && subsID ? (
          <SubsOfPackage
            id={subsID}
            type={type}
            back={() => {
              setSubsID(null);
              setType(null);
            }}
          />
        ) : (
          <section className="flex-1">
            {!!data && (
              <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
                {filteredData.map((e, inx) => (
                  <PackageComponent
                    setType={setType}
                    setSubsID={setSubsID}
                    setEditId={setEditId}
                    setHoldId={setHoldId}
                    setDeleteId={setDeletId}
                    key={inx}
                    data={e}
                  />
                ))}
              </div>
            )}
          </section>
        )}
      </div>
      {deleteId && <DeletePack set={re} id={deleteId} close={setDeletId} />}
      {holdId && <Hold set={re} id={holdId} close={setHoldId} />}
      {openAdd && <AddPackage re={re} close={setOpenAdd} />}
      {editId && <EditPackage re={re} id={editId} close={setEditId} />}
    </div>
  );
}
