import React from "react";
import { Link } from "react-router-dom";

export default function NewComponent({ data }) {
  function TruncatedParagraph({ text }) {
    // Split the text into an array of words
    const words = text.split(" ");

    // Check if the number of words exceeds maxWords
    const shouldTruncate = words.length > 20;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? words.slice(0, 20).join(" ") + "...."
      : text;

    return truncatedText;
  }
  return (
    <Link to={`/news-deatils?news=${data.id}`}>
      <div className=" relative overflow-hidden rounded-[16px] w-[285px] h-[240px] ">
        <div className=" relative bg-origin-content bg-cover  w-full h-full">
          <img
            className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover brightness-[.85] `}
            src={`${process.env.REACT_APP_URL}/${data.card_image}`}
            alt="cover"
          />
        </div>
        <div className=" absolute bottom-0 w-full h-[83px] p-2 text-[#fff] text-center text-[12px] font-[600] bg-[#43231533]  backdrop-blur-[3px]  ">
          <TruncatedParagraph text={data.bio} />
        </div>
      </div>
    </Link>
  );
}
