import React, { useState } from "react";
// import HeadCover from "../../components/pages/HeadCover";
import Monthes from "./Monthes";
import EventsOfMonth from "./EventsOfMonth";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";

export default function Events() {
  const currentMonthIndex = new Date().getMonth();
  const [month, setMonth] = useState(currentMonthIndex + 1);

  const setMonthHandeller = (month) => {
    setMonth(month);
  };

  return (
    <div>
      {" "}
      <Helmet>
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org",
"@type": "WebPage",
"name": "منصة الابل",
"description": "افضل الفعاليات والمناسبات  والاحدث المهمة المتعلقة بالجمل والابل",
"url": "https://mitracamel.com/events"
}
`}
        </script>
      </Helmet>
      {/* <HeadCover img="./imgs/cover1.jpg" title="الفعاليات | Festivals" /> */}
      <NewOver img="./imgs/event.png" title="الفعاليات" />
      <Monthes
        setMonthState={setMonthHandeller}
        month={month}
        curent={currentMonthIndex}
      />
      <EventsOfMonth month={month} />
    </div>
  );
}
