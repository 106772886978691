import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { isLogged } from "../../utils/inStore";
import NotificationComponent from "./NotificationComponent";
import useFetchWithReload from "../../utils/useGetWithReload";
import usePostFormData from "../../utils/usePostForm";
import { count } from "../../utils/countStore";

export default function Notifications() {
  const { logged } = useContext(isLogged);
  //   const { role } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(
    "global/notifications/getUserNotifications",
    reload
  );

  const { postFormData, response } = usePostFormData(
    `global/notifications/markAllAsRead`
  );
  const { re } = useContext(count);
  useEffect(() => {
    if (response) {
      re();
      setReload(!reload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const markAsReadHandler = () => {
    postFormData();
    setReload(!reload);
  };
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <section className="  flex items-center justify-between flex-wrap   ">
        <h1 className=" text-[36px] font-[800] text-[#432315] ">الاشعارات</h1>
        <button onClick={markAsReadHandler} className=" btn ">
          تمييز الكل كمقروء
        </button>
      </section>
      {data && (
        <section className=" my-3 ">
          {data.map((e, inx) => (
            <NotificationComponent key={inx} data={e} />
          ))}
          {data.length === 0 && (
            <h1 className=" my-12 text-center text-xl font-bold ">
              لا توجد اشعارات
            </h1>
          )}
        </section>
      )}
    </div>
  );
}
