import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function Owner({ data, join }) {
  const search = useLocation().search;
  const event = new URLSearchParams(search).get("event");
  const role = new URLSearchParams(search).get("role");

  const currentDate = new Date();
  const endDate = new Date(data.end_date);

  const [copied, setCopiedState] = useState(false);
  const code = window.location.href;
  const title = "";
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };

  const { logged } = useContext(isLogged);
  const [open, setOpen] = useState(false);
  const toggelOpenRegisterMessage = () => {
    setOpen(!open);
  };

  console.log(logged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {logged ? (
        <section>
          {(endDate > currentDate) & !join ? (
            <section className=" text-center ">
              <Link to={`/join-event?event=${event}&role=${role}`}>
                <button className="btn2">انضم للفعالية</button>
              </Link>
            </section>
          ) : (
            <></>
          )}
        </section>
      ) : (
        <section className="text-center">
          {(endDate > currentDate) & !join ? (
            <button onClick={toggelOpenRegisterMessage} className="btn2">
              انضم للفعالية
            </button>
          ) : (
            <></>
          )}
        </section>
      )}
      <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          منسق الفعالية
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.coordinator}
        </h1>
      </section>
      <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          سعر الانضمام
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {Number(data.subscription_price) === 0 ? (
            "مجانية"
          ) : (
            <> {data.subscription_price} ر.س</>
          )}
        </h1>
      </section>
      {/* <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          نبذة عنه
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.desc}
        </h1>
      </section> */}
      <section className=" grid grid-cols-2  ">
        <section className=" col-span-1 my-2">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            البداية
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.start_date}
          </h1>
        </section>
        <section className=" col-span-1 my-2">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            النهاية
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.end_date}
          </h1>
        </section>
        <section className=" col-span-1 my-2">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            من الساعة
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.start_time.slice(0, 5)}
          </h1>
        </section>
        <section className=" col-span-1 my-2">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            الى الساعة
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            {data.end_time.slice(0, 5)}
          </h1>
        </section>
      </section>
      {/* <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          ساحة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.squre}
        </h1>
      </section> */}
      <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.location}
        </h1>
      </section>
      {/* <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          عدد المشاركين
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.subscribers} شخص
        </h1>
      </section> */}
      <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          نسخ الرابط
        </h1>
        <button
          onClick={handleCopy}
          className=" text-[#290900] font-[500] text-[18px] leading-[28px] "
        >
          {copied ? "تم النسخ" : <i className="fa-solid fa-copy text-4xl"></i>}
        </button>
        <div className="flex items-center gap-4 my-3">
          <section className=" border border-[#432315] rounded-[5px] text-[#663520] px-[12px] py-[2px] ">
            <i className="fa-solid fa-share-nodes ml-2 "></i>
            مشاركة
          </section>

          <FacebookShareButton
            url={code}
            quote={title}
            className="share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton
            url={code}
            title={title}
            className="share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton url={code} title={title} className="share-button">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
      </section>
      {open && (
        <Notegister
          close={toggelOpenRegisterMessage}
          msg=" الرجاء التسجيل للانضمام للفعالية"
        />
      )}
    </div>
  );
}
