import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import NewSucces from "../../../components/pages/NewSucces";
import usePostWithToken from "../../../utils/usePostJson";

export default function FormInvest({ data }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("invest");
  const role = new URLSearchParams(search).get("role");

  const { postData, response, error } = usePostWithToken(
    `global/investments/join/${role}`
  );

  // Validation Schema with Arabic error messages
  const validationSchema = Yup.object({
    num: Yup.number()
      .min(1, "يجب أن يكون عدد الأسهم 1 على الأقل")
      .max(
        data.available_shares,
        `يجب ألا يتجاوز عدد الأسهم ${data.available_shares}`
      )
      .required("عدد الأسهم مطلوب"),
    message: Yup.string()
      .min(10, "يجب أن تحتوي الرسالة على 10 أحرف على الأقل")
      .required("هذه الخانة مطلوبة"),
  });

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      num: 1,
      allPrice: +data.price_per_share,
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      let items = {
        investment_id: invest,
        shares: values.num,
        message: values.message,
        callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=investment-details&type=invest&value=${invest}&role=${role}`,
      };
      postData(items);
      // Add submit logic here
    },
  });

  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);

  // Handle change in "num" field to update "allPrice" dynamically
  const handleChangeInfonum = (e) => {
    const value = e.target.value;
    formik.setFieldValue("num", value);
    formik.setFieldValue("allPrice", value * +Number(data.price_per_share));
  };

  // console.log(error.message);

  return (
    <div className="mb-3 bg-[#E8DCC0] rounded-[15px] p-4">
      <form
        className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap"
        onSubmit={formik.handleSubmit}
      >
        {/* Number of shares */}
        <section className="col-span-1">
          <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
            عدد الأسهم
          </h1>
          <input
            name="num"
            value={formik.values.num}
            onChange={handleChangeInfonum}
            className="inpt"
            type="number"
            placeholder="عدد الأسهم"
            min={1}
            max={+data.num}
            required
          />
          {formik.touched.num && formik.errors.num && (
            <p className="text-red-500 text-sm">{formik.errors.num}</p>
          )}
        </section>

        {/* Total Price */}
        <section className="col-span-1">
          <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
            أجمالي سعر الأسهم
          </h1>
          <input
            disabled
            name="allPrice"
            value={formik.values.allPrice}
            className="inpt"
            type="number"
            placeholder="أجمالي سعر الأسهم"
            required
          />
        </section>

        {/* Message */}
        <section className="col-span-1 lg:col-span-2">
          <h1 className="my-1 text-[#432315] text-[20px] font-[800]">رسالة</h1>
          <textarea
            name="message"
            rows={3}
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="inpt"
            placeholder="رسالة"
          />
          {formik.touched.message && formik.errors.message && (
            <p className="text-red-500 text-sm">{formik.errors.message}</p>
          )}
        </section>

        {/* Submit and Back Buttons */}
        <section className="col-span-1 lg:col-span-2 flex items-center justify-center gap-2 flex-wrap">
          <button type="submit" className="btn3">
            الدفع
          </button>

          <Link
            className="btn4"
            to={`/investment-details?invest=${invest}&role=${role}`}
          >
            العودة
          </Link>
        </section>
      </form>
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.error}
        </section>
      )}

      {/* {response && <NewSucces />} */}
    </div>
  );
}
