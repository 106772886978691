import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router";
import CopyBtn from "../../../components/pages/CopyBtn";
import { Link } from "react-router-dom";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";

export default function PartnerInfo({ data, join ,role }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("partnership");

  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const { logged } = useContext(isLogged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {!join ? (
        <section className=" text-center ">
          {logged ? (
            <Link to={`/join-parnership?partnership=${invest}&role=${role}`}>
              <button className="btn2">انضمام للتحالف</button>
            </Link>
          ) : (
            <button onClick={toggleRegisterMessage} className="btn2">
              انضمام للتحالف
            </button>
          )}
        </section>
      ) : (
        <></>
      )}
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          نسبة تحالف
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {Number(data.percentage)}%
        </h1>
      </section> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          تاريخ نشر التحالف:
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.created_at.substring(0, 10)}
        </h1>
      </section>

      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <CopyBtn />
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مكانك في التحالف"
        />
      )}
    </div>
  );
}
