import React, { useEffect, useState } from "react";
import usePostWithToken from "../../../../../utils/usePostJson";

export default function CancelBtn({ id, type, re }) {
  const { postData, response, error } = usePostWithToken(
    `global/tourisms/cancel_join/${type}`
  );
  const canceHandeller = async () => {
    const data = {
      tour_id: id,
    };

    await postData(data);
  };

  const [errorMessage, setError] = useState(null);
  const [suc, setSuc] = useState(null);
  useEffect(() => {
    if (error) {
      setError(error.response.data.message);
      setTimeout(() => {
        setError(null);
      }, [2000]);
    }
    if (response) {
      setSuc(response.message);
      re();
      setTimeout(() => {
        setSuc(null);
        re();
      }, [3000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);
  return (
    <div>
      <button
        onClick={canceHandeller}
        className=" text-sm bg-red-500 px-2 py- rounded-md text-white "
      >
        الغاء الحجز
      </button>
      {errorMessage && (
        <div className=" fixed bottom-4 w-full left-[0%]   flex items-center justify-center ">
          <section className=" text-white bg-red-500  py-2 px-3 rounded-md text-red  ">
            {errorMessage}
          </section>
        </div>
      )}
      {suc && (
        <div className=" fixed bottom-4 w-full left-[0%]   flex items-center justify-center ">
          <section className=" text-white bg-green-800  py-2 px-3 rounded-md text-red  ">
            {suc}
          </section>
        </div>
      )}
    </div>
  );
}
