import React, { useEffect } from "react";
import usePostFormData from "../../../../../utils/usePostForm";

export default function Reject({ re, id }) {
  let link = `superAdmin/field/setting/rejected/reject/${id}`;
  const { postFormData, response } = usePostFormData(link);
  const handelReject = () => {
    postFormData();
  };
  useEffect(() => {
    if (response) {
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <button onClick={handelReject} className=" px-2 py-1 rounded-[10px] bg-red-500 text-white text-[16px] ">
      رفض
    </button>
  );
}
