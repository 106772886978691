import React from "react";
import RaceComponenet from "../../../components/pages/races/RaceComponenet";

export default function Races({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <RaceComponenet key={inx} data={e} />
      ))}
    </div>
  );
}
