import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import usePostWithToken from "../../../../utils/usePostJson";

export default function SendNotification() {
  const [form, setForm] = useState({
    guard: "camel_owner",
    user_ids: [],
    title: "",
    body: "",
  });
  const [reload, setReload] = useState(false);
  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  const handelChangeWithReload = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value, user_ids: [] });
    setReload(!reload);
  };

  let link = `superAdmin/packages/get_all_users/${form.guard}`;
  const { data } = useFetchWithReload(link, reload);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (data) {
      setUsers(data.users);
    }
  }, [data]);
  const getSelectedUserNames = (selectedIds) => {
    return users
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.first_name)
      .join(", ");
  };
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = users.filter((user) => {
    let name = user.first_name + " " + user.last_name;
    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let errs = {};
    if (!form.body) {
      errs.body = "برجاء ادخال تفاصيل الاشعار";
    }
    if (!form.title) {
      errs.title = "برجاء ادخال عنوان الاشعار ";
    }

    setErrors(errs);
    return Object.keys(errs).length === 0;
  };

  const { postData, response } = usePostWithToken(
    `superAdmin/notification/send-notification`
  );

  const [suc, setSuc] = useState(false);
  const updateHandeller = () => {
    if (validate()) {
      postData(form);
    }
  };
  useEffect(() => {
    if (response) {
      setSuc(true);
      setForm({
        guard: "camel_owner",
        user_ids: [],
        title: "",
        body: "",
      });
      setTimeout(() => {
        setSuc(false);
      }, 2000);
    }
  }, [response]);
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold "> ارسال اشعار </h1>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap   ">
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نوع المستخدم
            </h1>
            <select
              className="inpt"
              name="guard"
              value={form.guard}
              onChange={handelChangeWithReload}
            >
              <option value={"camel_owner"}>مالك ابل</option>
              <option value={"visitor"}>زائر</option>
              <option value={"service_provider"}>مزود خدمة</option>
              <option value={"field_owner"}>مالك ساحة</option>
            </select>
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المستخدمين
            </h1>
            {data && (
              <FormControl className=" w-full inpt " sx={{ m: 1 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  جميع المستخدمين
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={form.user_ids}
                  className=" "
                  name="user_ids"
                  onChange={handelChange}
                  input={<OutlinedInput label="جميع المستخدمين" />}
                  renderValue={(selected) => getSelectedUserNames(selected)}
                  // MenuProps={MenuProps}
                >
                  <Box sx={{ padding: 1 }}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </Box>
                  {filteredUsers.map((name) => (
                    <MenuItem key={name} value={name.id}>
                      <Checkbox checked={form.user_ids.indexOf(name.id) > -1} />
                      <ListItemText
                        primary={`${name.first_name} ${name.last_name}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </section>
          <section className="col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              عنوان الاشعار
            </h1>
            <input
              name="title"
              value={form.title}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="عنوان الاشعار"
              required
            />
            {<p className=" error ">{errors.title}</p>}
          </section>
          <section className="col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الاشعار
            </h1>
            <input
              name="body"
              value={form.body}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="تفاصيل الاشعار"
              required
            />
            {<p className=" error ">{errors.body}</p>}
          </section>
        </form>
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            ارسال
          </button>
          {suc && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم ارسال اشعار بنجاح</h1>
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
