import React from "react";
import Hr from "../../components/pages/Hr";
import Owner from "../../components/pages/Home/Owner";
import useFetchGlobal from "../../utils/useGetGlobal";

export default function ALlOwners() {
  const { data } = useFetchGlobal("global/CamelOwners/all");
  return (
    <div>
      <div className="  py-12 px-8 md:px-16 relative   ">
        <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
          <img
            src="./imgs/home/owners.jfif"
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover brightness-50"
          />
        </div>
        <div className=" bg-[#432315A3]/50 absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full"></div>

        <div className=" w-full backdrop-blur-[3px]  max-w-[540px] mx-auto py-[30px] px-[15px] bg-[#6B65618F]/50 text-center rounded-[15px] relative z-10">
          <h1 className=" my-2 text-[#E8DCC0] text-[32px] md:text-[48px] font-[700] ">
            ملاك الابل
          </h1>
          <Hr color="#E8DCC0" />
          <p className=" my-2 text-[#E8DCC0] leading-[40px] text-[18px] font-[600] ">
            هم الاشخاص الذين يملكون الابل ويهتمون بها . هذا الشغف العريق الذي
            يمتد عبر التاريخ حيث كانت الابل عنصرًا أساسيًا في حياة البدو
            والتجارة والتنقل في شبه الجزيرة العربية والعديد من المناطق الاخري .
          </p>
        </div>
      </div>

      {/* Owners */}
      {!!data && (
        <div className=" flex items-start justify-center gap-12 flex-wrap p-6 md:p-14">
          {data["camel_owners"].map((e) => (
            <Owner key={e.id} owner={e} />
          ))}
        </div>
      )}
    </div>
  );
}
