import React from "react";
import ScreenBlogComponenet from "../../components/pages/blogs/ScreenBlogComponenet";
import MobileBlogComponent from "../../components/pages/blogs/MobileBlogComponent";

export default function AllBlogs({ alldata }) {
  return (
    <div>
      <div className="hidden md:flex items-center justify-start gap-3 flex-wrap ">
        {alldata.map((e, inx) => (
          <ScreenBlogComponenet key={inx} data={e} />
        ))}
      </div>
      <section className="flex md:hidden items-center justify-center gap-3 flex-wrap ">
        {alldata.map((e, inx) => (
          <MobileBlogComponent key={inx} data={e} />
        ))}
      </section>
    </div>
  );
}
