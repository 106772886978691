import React, { useState } from "react";
import Details from "./Details";
import PendingReports from "./PendingReports";

export default function Pendings() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
    setDetailsId(null);
  };
  const [detailsId, setDetailsId] = useState(null);
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <div className=" my-4 ">
        {detailsId ? (
          <Details set={setDetailsId} id={detailsId} type={type} />
        ) : (
          <PendingReports
            re={() => setReload(!reload)}
            set={setDetailsId}
            type={type}
            reload={reload}
          />
        )}
      </div>
    </div>
  );
}
