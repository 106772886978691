import React from "react";
import Form from "./Form";

export default function SendForm({ data, set, role, id }) {
  return (
    <div>
      <Form set={set} role={role} data={data} id={id} />
    </div>
  );
}
