import React from "react";

export default function Info({ data, prices }) {
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      <h1 className=" text-[#290900] font-[700] text-[24px]  text-center ">
        الملخص
      </h1>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العدد الكلي
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          <span className=" mx-2 ">{data.adults}</span> كبار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          <span className=" mx-2 ">{data.childs}</span> صغار
        </h1>
      </section>

      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          التكلفة الإجمالية
        </h1>
        <h1 className=" text-[#290900] font-[800] text-[24px] leading-[48px] ">
          {Number(prices.price_adult) * +data.adults +
            Number(prices.price_child) * +data.childs}{" "}
          ر.س
        </h1>
      </section>
    </div>
  );
}
