import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import Info from "./Info";
import SendForm from "./SendForm";
import { isLogged } from "../../../utils/inStore";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";

export default function BookAtrip() {
  const search = useLocation().search;
  const childs = new URLSearchParams(search).get("childs") || 0;
  const adults = new URLSearchParams(search).get("adults") || 0;
  const date = new URLSearchParams(search).get("date");
  const invest = new URLSearchParams(search).get("trip");
  const role = new URLSearchParams(search).get("role");

  const { data, error } = useFetchGlobal(
    `global/tourisms/show/${invest}/${role}`
  );
  const [details, setDetails] = useState({
    childs: childs,
    adults: adults,
    date: date,
  });

  const { logged } = useContext(isLogged);
  if (error || !logged) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/tourism">
              السياحة
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل السياحة `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className=" flex items-center gap-3 ">
            <Tab text={"حجز"} val={1} tab={1} setTabState={() => {}} />
          </section>
          {!!data && <SendForm id={invest} role={role} set={setDetails} data={details} />}
        </div>
        {/* Owner */}
        {!!data && <Info data={details} prices={data.tourism} />}
      </div>
    </div>
  );
}
