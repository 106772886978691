import {  useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
// import { load } from "./isLoadingStore";

const usePostWithReturnLoading = (endPoint) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  //   const { setIsLoading } = useContext(load);
  const [loading, setIsLoading] = useState(false);

  const postData = async (data) => {
    setIsLoading(true);
    setError(null);
    setResponse(null);
    let token = Cookies.get("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/${endPoint}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResponse(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { postData, response, error, loading };
};

export default usePostWithReturnLoading;
