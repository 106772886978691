import React, { useContext, useEffect, useState } from "react";
import usePostFormData from "../../../../../utils/usePostForm";
import { load } from "../../../../../utils/isLoadingStore";
import ReactDOM from "react-dom";
import Loader from "../../../../../components/Loader/Loader";

export default function AddCateg({ close, set }) {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};
    if (!name) {
      formErrors.name = "الرجاء إدخال اسم المنتج";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  let link = "superAdmin/category/create";
  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("name", name);
      formdata.append("description", name);
    //   formdata.append("image", null);
      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      set((pre) => [...pre, response]);
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة منتج</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم القسم
            </h1>
            <input
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" inpt "
              type="text"
              placeholder="اسم القسم"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
