import React from "react";
import usePostWithToken from "../../../utils/usePostJson";
import NewSucces from "../../../components/pages/NewSucces";

export default function JoinBtn({ id, role }) {
  const { postData, response, error } = usePostWithToken(
    `global/alliance/join/${role}`
  );

  const handelJoin = () => {
    const data = { alliance_id: id };
    postData(data);
  };
  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        الانضمام للتحالف
      </button>
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.error
            ? error.response.data.error
            : "هناك مشكلة"}
        </section>
      )}
      {response && <NewSucces />}
    </div>
  );
}
