import React from "react";
// import HeadCover from "../../components/pages/HeadCover";
import MainNewComponent from "../../components/pages/News/MainNewComponent";
import { Link } from "react-router-dom";
import News from "./News";
import useFetchGlobal from "../../utils/useGetGlobal";
// import HeadCover from "../../components/pages/HeadCover";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";

export default function NewsPage() {
  const { data, error } = useFetchGlobal("global/news/all");

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  if (data && data.length === 0) {
    return (
      <div className=" ">
        <NewOver img="./imgs/news2.png" title="الاخبار" />
        <h1 className=" text-red-600 text-xl font-bold text-center my-20 ">
          لا توجد اخبار
        </h1>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org",
"@type": "WebPage",
"name": "منصة الابل",
"description":"احدث الاخبار والاحداث المتعلقة بعالم الابل والجمال والمهتمين بها واخبار الساباقات والمزادات ",
"url": "https://mitracamel.com/news"
}
`}
        </script>
      </Helmet>
      <NewOver img="./imgs/news2.png" title="الاخبار" />
      {/* <HeadCover
        img="./imgs/cover1.jpg"
        title="الأخبار"
        desc="تابع معنا جميع الأخبار"
      /> */}
      {data && (
        <div className="  p-5 md:p-8  ">
          {data.length > 0 && <MainNewComponent data={data[0]} />}
          <section className=" my-8 lg:flex flex-wrap items-start gap-3 ">
            <section className=" flex-1 ">
              <section className=" flex items-center justify-between ">
                <h1 className=" text-[32px] text-black font-[700] leading-[60px] ">
                  احدث الأخبار
                </h1>
                <Link
                  className=" underline text-[#432315] text-[16px] font-[700] "
                  to="/all-news"
                >
                  المزيد
                </Link>
              </section>
              <News data={data} />
            </section>
          </section>
        </div>
      )}
    </div>
  );
}
