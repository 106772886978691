import React from "react";

export default function InfoBadge({ data, expert }) {
  return (
    <div className=" w-full bg-[#E8DCC0] p-[16px] flex-wrap rounded-[16px] lg:flex items-start justify-start gap-3 ">
      <img
        src={
          expert
            ? `${process.env.REACT_APP_URL}/${data.expert_card_image}`
            : `${process.env.REACT_APP_URL}/${data.doctor_card_image}`
        }
        alt="user"
        className=" rounded-[16px] w-full mx-auto max-w-[300px] lg:w-[200px] h-[150px] "
      />
      <div className=" flex-1  ">
        <section className=" flex items-center justify-between ">
          <h1 className=" text-[24px] text-[#432315] font-[600] leading-[49px] ">
            {data.name}
          </h1>
          {data.views && (
            <h4 className=" text-[16px] text-[#432315] font-[600] leading-[29px] ">
              {data.views} مشاهدة
            </h4>
          )}
        </section>
        <h4 className=" text-[14px] text-[#000] font-[600] leading-[29px] ">
          {data.title}
        </h4>
        <h4 className=" text-[15px] text-[#000] font-[600] leading-[29px] ">
          {data.bio}
        </h4>
      </div>
    </div>
  );
}
