import React from "react";

export default function DiscountPrice({ children }) {
  return (
    <div className=" relative w-fit ">
      <p>{children}</p>
      <hr className=" border-red-600 absolute w-full top-[50%] rotate-[160deg] " />
    </div>
  );
}
