import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../utils/inStore";
import { useNavigate } from "react-router";
import TabBtn from "../../components/pages/TabBtn";
import Tab from "../../components/pages/Tab";
import Info from "./Inf/Info";
import Password from "./Inf/Password";
import CamelPassord from "./CamelPassord";
import CamelInfo from "./CamelInfo";
import SuperAdminInfo from "./super/SuperAdminInfo";
import EditSuperPassword from "./super/EditSuperPassword";

export default function Settings() {
  const { logged, role } = useContext(isLogged);
  const navigation = useNavigate();

  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tab, setTab] = useState(1);
  return (
    <div className=" p-4 md:p-10 lg:px-20 ">
      <h1 className=" text-[36px] text-[#432315] font-[800] ">
        الاعدادات و الخصوصية
      </h1>
      <section className=" my-4 lg:hidden ">
        <>
          <Tab val={1} tab={tab} setTabState={setTab} text="معلومات الحساب " />
          <Tab val={2} tab={tab} setTabState={setTab} text="تغير كلمة السر " />
        </>
      </section>
      <div className=" my-5 flex items-start gap-3 justify-center ">
        <section className="  w-[290px] p-[16px] rounded-[16px] hidden lg:flex items-center justify-center flex-wrap border border-[#43231580] ">
          <>
            <TabBtn
              val={1}
              tab={tab}
              setTabState={setTab}
              icon={"fa-solid fa-user"}
              text="معلومات الحساب "
            />
            <TabBtn
              val={2}
              tab={tab}
              setTabState={setTab}
              s
              icon={"fa-solid fa-lock"}
              text="تغير كلمة السر "
            />
          </>
        </section>
        {role === "camel-owner" ? (
          <section className=" flex-1 ">
            {tab === 1 && <CamelInfo />}
            {tab === 2 && <CamelPassord />}
          </section>
        ) : (
          <>
            {role === "super_admin" ? (
              <section className=" flex-1 ">
                {tab === 1 && <SuperAdminInfo />}
                {tab === 2 && <EditSuperPassword />}
              </section>
            ) : (
              <section className=" flex-1 ">
                {tab === 1 && <Info />}
                {tab === 2 && <Password />}
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
}
