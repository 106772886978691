import React, { useEffect, useState } from "react";
import Pref from "./Pref";
import Details from "./Details";
import Trends from "./Trends";
import useFetchWithToken from "../../../../../../utils/useGetWithToken";
import BarCharts from "./BarCharts";
import usePostWithToken from "../../../../../../utils/usePostJson";

export default function PackagsReprts() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };

  const { data } = useFetchWithToken(
    "superAdmin/Report/package/getTotalRevenueAndSubscribers"
  );
  const [period, setPreiod] = useState("month");
  const { postData, response } = usePostWithToken(
    `superAdmin/Report/package/getSubscriptionRateForGuard`
  );
  const getData = () => {
    const data = {
      period: period,
    };
    postData(data);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const names = {
    camel_owner: "مالك ابل",
    field_owner: "مالك ساحة",
    service_provider: "مقدم خدمة",
    visitor: "زائر",
  };

  return (
    <div>
      <h1 className=" text-[36px] font-[800] text-[#432315] ">التقارير</h1>
      <div className=" my-3 ">
        {data && (
          <section className="  my-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
            {data.guard_reports.map((item, inx) => (
              <section
                key={inx}
                className=" shadow-md w-full bg-[#E8DCC0] p-[16px] rounded-[16px] text-[#290900] "
              >
                <h1 className=" text-[17px] font-[700] ">
                  {names[item.guard_type]}
                </h1>
                <h1 className=" my-2  text-[24px] font-[700] ">
                  {item.revenue} ر.س
                </h1>
                <h1 className=" text-[12px] font-[600] text-[#A59382] ">
                  {item.subscribers} مستخدم
                </h1>
              </section>
            ))}
            <section className=" shadow-md lg:col-span-2 w-full bg-[#E8DCC0] p-[16px] rounded-[16px] text-[#290900] ">
              <h1 className=" text-[17px] font-[700] ">اجمالي جميع الارباح </h1>
              <h1 className=" my-2  text-[24px] font-[700] ">
                {data.total_revenue} ر.س
              </h1>
            </section>
            <section className=" shadow-md lg:col-span-2 w-full bg-[#E8DCC0] p-[16px] rounded-[16px] text-[#290900] ">
              <h1 className=" text-[17px] font-[700] ">عدد جميع المستخدمين </h1>
              <h1 className=" my-2  text-[24px] font-[700] ">
                {data.total_subscribers} مستخدم
              </h1>
            </section>
          </section>
        )}
      </div>
      <div className=" my-6 p-[20px] rounded-[20px] bg-[#E8DCC0] ">
        <section className=" my-4 flex items-center justify-between gap-4 flex-wrap ">
          <h1 className=" my-2 text-[#290900] text-[20px] font-[700] ">
            الاشتراكات{" "}
          </h1>
          <section className=" flex items-center gap-2 flex-wrap ">
            <button
              onClick={() => setPreiod("week")}
              className={` py-2 px-4 text-[14px] rounded-[10px] ${
                period === "week"
                  ? " bg-[#432315] text-white "
                  : " text-[#432315] "
              }  `}
            >
              اسبوع
            </button>
            <button
              onClick={() => setPreiod("month")}
              className={` py-2 px-4 text-[14px] rounded-[10px] ${
                period === "month"
                  ? " bg-[#432315] text-white "
                  : " text-[#432315] "
              }  `}
            >
              شهر
            </button>
            <button
              onClick={() => setPreiod("year")}
              className={` py-2 px-4 text-[14px] rounded-[10px] ${
                period === "year"
                  ? " bg-[#432315] text-white "
                  : " text-[#432315] "
              }  `}
            >
              سنة
            </button>
          </section>
        </section>
        {response && <BarCharts data={response.data} />}
      </div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"visitor"}>زائر</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <div className=" my-4 ">
        <section className=" md:grid grid-cols-2 items-center  ">
          <Pref type={type} reload={reload} />
          <Trends type={type} reload={reload} />
        </section>
        <Details type={type} reload={reload} />
      </div>
    </div>
  );
}
