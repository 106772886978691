import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function Filter({ filter, setCheckedState, setMaxPriceState }) {
  // const [checked, setChecked] = useState(filter);

  // const isChecked = (val) => checked.includes(val);

  // const handleClick = (val) => {
  //   const updatedChecked = isChecked(val)
  //     ? checked.filter((item) => item !== val)
  //     : [...checked, val];

  //   setChecked(updatedChecked);
  //   setCheckedState(updatedChecked);
  // };

  // const checkOptions = [
  //   { name: "ساحات", val: "squares" },
  //   { name: "عيادات", val: "hospitals" },
  //   { name: "ادوات صحية", val: "medical tools" },
  //   { name: "ادوات تدريبية", val: "training tools" },
  // ];

  function valuetext(value) {
    return `${value}ر.س`;
  }

  const [limits, setLimists] = useState({ min: 0, max: 1000 });

  const handelLimintsChange = (e) => {
    const { value, name } = e.target;
    setLimists({ ...limits, [name]: value });
  };
  const [value, setValue] = useState();
  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
      setMaxPriceState(newValue);
    }
  };

  return (
    <div className="pb-4 border text-[#333333] border-[#43231580] rounded-[16px]">
      <h1 className="px-3 py-2 text-[20px] font-[700] border-b border-[#432315]">
        فلتر
      </h1>
      {/* <section className="px-3 py-5 border-b text-[#4F4F4F] border-[#432315]">
        {checkOptions.map((option, index) => (
          <section key={index} className="flex items-center gap-3">
            <input
              type="checkbox"
              id={`customCheckbox-${index}`}
              className="custom-checkbox"
              checked={isChecked(option.val)}
              onChange={() => handleClick(option.val)}
            />
            <label
              htmlFor={`customCheckbox-${index}`}
              className="text-[20px] font-[500]"
            >
              {option.name}
            </label>
          </section>
        ))}
      </section> */}
      <section>
        <h1 className="px-3 py-2 text-[18px] font-[600]">السعر</h1>
        <div className={` ${!value && "opacity-55"} p-2`}>
          <Box>
            <Slider
              clas
              aria-label="Always visible"
              defaultValue={0}
              getAriaValueText={valuetext}
              min={+limits.min}
              max={+limits.max}
              onChange={handleChange}
              valueLabelDisplay="auto"
            />
            <div className=" flex items-center justify-between ">
              <input
                name="max"
                value={limits.max}
                onChange={handelLimintsChange}
                min={0}
                className="text-center bg-black/0 outline-none inptFilter border rounded-full text-[#8B8B8B] border-[#8B8B8B] w-[60px] "
                type="number"
              />
              <h1>{value ? value : "-"}</h1>
              <input
                name="min"
                value={limits.min}
                onChange={handelLimintsChange}
                min={0}
                className="text-center bg-black/0 outline-none inptFilter border rounded-full text-[#8B8B8B] border-[#8B8B8B] w-[60px] "
                type="number"
              />
            </div>
          </Box>
        </div>
      </section>
    </div>
  );
}
