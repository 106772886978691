import React from "react";
import DiscountPrice from "../../../../components/pages/DiscountPrice";
import { Link } from "react-router-dom";

export default function PackageComponent({ data }) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  let time = {
    days: "يوم",
    months: "شهر",
    years: "سنة",
  };

  return (
    <div className=" relative text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <h1 className=" text-center my-2 text-[18px] font-[600] ">{data.name}</h1>
      <p className="  min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.description} />
      </p>
      <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
        مدة اشتراك الباقة : {data.duration} {time[data.duration_type]}
      </p>
      {data.discount_percentage && Number(data.discount_percentage) > 0 ? (
        <p className=" flex items-center gap-1  text-[15px] font-[600] leading-[28px] my-2 ">
          السعر : <DiscountPrice>{data.price}</DiscountPrice>{" "}
          {(
            ((100 - Number(data.discount_percentage)) / 100) *
            data.price
          ).toFixed(2)}{" "}
          ر.س
        </p>
      ) : (
        <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
          السعر : {data.price} ر.س
        </p>
      )}
      <section className=" my-2 text-center ">
        {" "}
        <Link to={`/package-details?package=${data.id}`}>
          <button className=" btn2 ">التفاصيل</button>
        </Link>
      </section>
    </div>
  );
}
