import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useFetchGlobal from "../../../../utils/useGetGlobal";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";
import { load } from "../../../../utils/isLoadingStore";
import FileUploded from "../../../../components/pages/FileUploded";
import Loader from "../../../../components/Loader/Loader";

export default function Edit({ set, id, close }) {
  const [details, setDetails] = useState("");
  const [form, setForm] = useState({
    title: "",
    bio: "",
    details: "",
    card_image: "",
    detail_images: [],
  });
  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };
  const { data } = useFetchGlobal(`global/news/show/${id}`);
  useEffect(() => {
    if (data) {
      setDetails(data.details);
      setForm({
        title: data.title,
        bio: data.bio,
        details: "",
        card_image: "",
        detail_images: [],
      });
    }
  }, [data]);
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  let link = `superAdmin/news/update/${id}`;
  const navigate = useNavigate();

  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("title", form.title);
      formdata.append("bio", form.bio);
      formdata.append("details", details);
      if (form.card_image) {
        formdata.append("card_image", form.card_image);
      }

      form.detail_images.forEach((image) => {
        formdata.append("detail_images[]", image);
      });

      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      navigate(`/news-deatils?news=${response.news.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل مدونة</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير الصورة المصغرة
            </h1>
            {form.card_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, card_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.card_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, card_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الاسم
            </h1>
            <input
              name="title"
              value={form.title}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="الاسم"
              required
            />
            {<p className="error">{errors.title}</p>}
          </section>

          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الخبر
            </h1>
            <textarea
              name="bio"
              cols={2}
              value={form.bio}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الخبر "
              required
            />
            {<p className="error">{errors.bio}</p>}
          </section>
          <section className=" min-h-[600px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الخبر
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[500px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير صور الخبر
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.detail_images.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.detail_images;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          detail_images: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      detail_images: [...form.detail_images, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            تعديل
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
