import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import usePostFormData from "../../../../../utils/usePostForm";
import useFetchWithToken from "../../../../../utils/useGetWithToken";
import { load } from "../../../../../utils/isLoadingStore";
import Loader from "../../../../../components/Loader/Loader";


export default function EditCard({ close, id, set }) {
  const [file, setFile] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    color: "",
    age: "",
    gender: "",
    strain: "",
    file: null, // Include file in initialValues
  });
  const { data } = useFetchWithToken(`camelOwner/camelCards/show/${id}`);
  const { postFormData, error, response } = usePostFormData(
    `superAdmin/camelCards/update/${id}`
  );
  useEffect(() => {
    if (!!response) {
      set((pre) => {
        let da = pre.map((item) => {
          if (+item.id === +id) {
            return response.camel_card;
          } else {
            return item;
          }
        });
        return da;
      });

      // set((pre) => {
      //   return [...pre, response.camel_card];
      // });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  useEffect(() => {
    if (!!data) {
      setInitialValues({
        name: data.camel_card.name,
        type: data.camel_card.type,
        color: data.camel_card.color,
        age: data.camel_card.age,
        gender: data.camel_card.gender,
        strain: data.camel_card.season,
        file: null,
      });
    }
  }, [data]);
  // console.log(initialValues)

  const validationSchema = Yup.object({
    name: Yup.string().required("الاسم مطلوب"),
    type: Yup.string().required("النوع مطلوب"),
    color: Yup.string().required("اللون مطلوب"),
    age: Yup.number()
      .min(1, "العمر يجب أن يكون أكبر من 0")
      .required("العمر مطلوب"),
    gender: Yup.string().required("الجنس مطلوب"),
    strain: Yup.string().required("السلالة مطلوبة"),
    // file: Yup.mixed().required("صورة الإبل مطلوبة"), // Ensure file validation
  });

  const handleSubmit = async (values) => {
    // Handle form submission
    const formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("color", values.color);
    formdata.append("type", values.type);
    formdata.append("gender", values.gender);
    formdata.append("age", values.age);
    formdata.append("season", values.strain);
    if (!!file) {
      formdata.append("image", file);
    }

    await postFormData(formdata);
  };
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        {isLoading && <Loader />}
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            تعديل كرت الإبل
          </h1>
          <button onClick={() => close()} className="text-red-600 font-[600]">
            X
          </button>
        </section>

        {!!data && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true} // Add this prop
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ setFieldValue, errors, touched }) => (
              <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
                <section className="col-span-1 lg:col-span-2">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الاسم
                  </h1>
                  <Field
                    name="name"
                    className="inpt"
                    type="text"
                    placeholder="الاسم"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    النوع
                  </h1>
                  <Field
                    name="type"
                    className="inpt"
                    type="text"
                    placeholder="النوع"
                  />
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    اللون
                  </h1>
                  <Field
                    name="color"
                    className="inpt"
                    type="text"
                    placeholder="اللون"
                  />
                  <ErrorMessage
                    name="color"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    العمر بالسنوات
                  </h1>
                  <Field
                    name="age"
                    className="inpt"
                    type="number"
                    placeholder="العمر بالسنوات"
                  />
                  <ErrorMessage
                    name="age"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الجنس
                  </h1>
                  <Field as="select" name="gender" className="inpt">
                    <option hidden value="" label="اختر الجنس" />
                    <option value="ذكر" label="ذكر" />
                    <option value="انثى" label="أنثى" />
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    السلالة
                  </h1>
                  <Field
                    name="strain"
                    className="inpt"
                    type="text"
                    placeholder="السلالة"
                  />
                  <ErrorMessage
                    name="strain"
                    component="div"
                    className="text-red-600"
                  />
                </section>

                <section className="col-span-1 lg:col-span-2">
                  <h4 className="text-[#290900] text-[20px] font-[500] my-5">
                    تغيير الصورة
                  </h4>
                  {file ? (
                    <div className="relative">
                      <button
                        onClick={() => {
                          setFile(null);
                          setFieldValue("file", null);
                        }}
                        className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                      >
                        حذف الصورة
                      </button>
                      <img
                        alt="new cover"
                        className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                        src={URL.createObjectURL(file)}
                      />
                    </div>
                  ) : (
                    <div className="font-bold text-2xl p-8 flex items-center justify-center mx-auto bg-[#F5EFDC] rounded-[16px]">
                      <section>
                        <label
                          className="cursor-pointer h-full w-full"
                          htmlFor="file"
                        >
                          <section className="text-center">
                            <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#A59382] text-center"></i>
                            <h1 className="text-[#A59382]">رفع الصورة</h1>
                          </section>
                        </label>
                        <input
                          type="file"
                          id="file"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            setFieldValue("file", e.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                      </section>
                    </div>
                  )}
                  {errors.file && touched.file && (
                    <div className="text-red-600">{errors.file}</div>
                  )}
                </section>

                <section className="col-span-1 lg:col-span-2 flex items-center justify-end gap-2 flex-wrap">
                  <button type="submit" className="btn2">
                    حفظ
                  </button>
                </section>
                {error && (
                  <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                    <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                      <i className="fa-solid fa-exclamation  "></i>
                    </span>
                    {!!error.response.data.error
                      ? error.response.data.error
                      : "  هناك مشكلة"}
                  </section>
                )}
              </Form>
            )}
          </Formik>
        )}
      </section>
    </div>,
    document.body
  );
}
