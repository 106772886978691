import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import usePostWithToken from "../../../../utils/usePostJson";
import Delete from "./Delete";

export default function Specs() {
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "provider") {
    link = "serviceProvider/expert_cards/specialization/all";
  }
  const { data, error } = useFetchWithToken(link);
  const [all, setAll] = useState([]);
  const [id, setId] = useState();
  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data;
    }

    setAll(filtered);
  }, [data]);
  const [info, setInfo] = useState("");

  let linkToPost = "";
  if (role === "provider") {
    linkToPost = "serviceProvider/expert_cards/specialization/create";
  }
  const { postData, response } = usePostWithToken(linkToPost);
  const handelJoin = () => {
    const data = {
      name: info,
    };
    postData(data);
  };

  useEffect(() => {
    if (!!response) {
      setAll([...all, response]);
      setInfo("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div className=" my-2 ">
      <table>
        <thead>
          <tr>
            <th>اسم التخصص</th>
            <th> حذف</th>
          </tr>
        </thead>
        {!!data && (
          <tbody>
            {all.map((e, inx) => (
              <tr key={inx}>
                <td className=" text-center ">{e.name}</td>
                <td className=" text-center underline text-red-600 ">
                  <button className="underline" onClick={() => setId(e.id)}>
                    حذف
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <hr className=" border-black "></hr>
      <div className=" my-3 flex items-center justify-center flex-wrap gap-2 ">
        <section className="min-w-[300px] max-w-[450px]">
          <input
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            className="inpt shadow-md min-w-[300px] max-w-[400px]"
            placeholder="اضافة تخصص"
          />
        </section>

        <button
          disabled={!info}
          onClick={handelJoin}
          className=" disabled:opacity-75 btn "
        >
          اضافة
        </button>
      </div>
      {id && <Delete close={setId} id={id} set={setAll} />}
    </div>
  );
}
