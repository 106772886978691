import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
// import useFetchWithToken from "../../../../utils/useGetWithToken";
import Specs from "../clincs/Specs";
import ConsaltentDash from "../../components/Clincs/ConsaltentDash";
import DeleteCard from "../../components/Clincs/DeleteCard";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import AddCart from "../../components/Clincs/AddCart";
import EditCart from "../../components/Clincs/EditCart";

export default function ConstDash() {
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "provider") {
    link = "serviceProvider/veterinaryMedicine/doctor_cards/myDoctorCard";
  }

  const [ShowData, setShowData] = useState(null);
  const { data } = useFetchWithReload(link);
  useEffect(() => {
    if (data) {
      setShowData(data);
    }
  }, [data]);

  const [openAdd, setOpenAdd] = useState(false);
  const [deleteId, setDeletId] = useState();
  const [editId, setEditId] = useState();
  const [spec, setSpecs] = useState(false);
  //
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <section className=" flex items-center gap-2 ">
          <button
            onClick={() => setSpecs(!spec)}
            className=" btn flex items-center gap-2 "
          >
            التخصصات
          </button>
          {!ShowData && (
            <button
              onClick={() => setOpenAdd(true)}
              className=" btn flex items-center gap-2 "
            >
              <i class="fa-solid fa-circle-plus"></i>
              اضافة كارت
            </button>
          )}
        </section>
      </div>
      {spec && <Specs />}
      <section className="w-full">
        <>
          <>
            <>
              {!ShowData && (
                <h1 className=" text-red-600 text-xl font-bold text-center ">
                  لا يوجد كارت طبيب حتي الان
                </h1>
              )}
            </>
          </>

          {!!ShowData && (
            <section className=" my-3   flex items-center justify-center ">
              <ConsaltentDash
                setDeleteId={setDeletId}
                setEditId={setEditId}
                data={ShowData}
              />
            </section>
          )}
        </>
      </section>
      {editId && (
        <EditCart close={setEditId} reload={setShowData} alldata={ShowData} />
      )}
      {openAdd && <AddCart close={setOpenAdd} reload={setShowData} />}
      {deleteId && (
        <DeleteCard id={deleteId} close={setDeletId} reload={setShowData} />
      )}
    </div>
  );
}
