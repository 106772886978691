import React from "react";

export default function Tab({ val, tab, setTabState, text }) {
  const handleTabClick = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Parse the URL to extract the current "type" parameter
    const urlParams = new URLSearchParams(currentUrl);
    const currentType = urlParams.get("type");

    // If the "type" parameter exists and is different from the "val" prop, update it
    if (currentType && currentType !== val) {
      // Create a new URL with the updated "type" parameter
      const newUrl = new URL(currentUrl);
      newUrl.searchParams.set("type", val);
      window.history.replaceState(null, null, newUrl.toString());
    } else if (!currentType) {
      // If the "type" parameter doesn't exist, add it to the URL
      const newUrl = new URL(currentUrl);
      newUrl.searchParams.set("type", val);
      window.history.replaceState(null, null, newUrl.toString());
    }

    // Update the tab state
    setTabState(val);
  };

  return (
    <button
      onClick={handleTabClick}
      className={` p-3 border-b my-2 text-[16px] font-[600] leading-[30px] ${
        tab === val
          ? " border-[#432315] text-[#432315]"
          : "border-[#432315]/0 text-[#432315]/50"
      } `}
    >
      {text}
    </button>
  );
}