import React from "react";
import useFetchWithToken from "../../../../../utils/useGetWithToken";
import { Link } from "react-router-dom";

export default function MyPartnership() {
  const { data, error } = useFetchWithToken("global/alliance/join/myAlliances");
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة{" "}
        </h1>
      </div>
    );
  }
  return (
    <div>
      {" "}
      {!!data && (
        <>
          {!!data.alliances ? (
            <>
              {
                <table>
                  <thead>
                    <tr>
                      <th>تاريخ طلب المشاركة</th>
                      <th>عرض التحالف</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.alliances.map((e, inx) => (
                      <tr key={inx}>
                        <td className=" text-center ">
                          {e.created_at.substring(0, 10)}
                        </td>
                        <td className=" text-center ">
                          <Link
                            className=" text-sky-800 underline font-bold "
                            to={`/partnership-details?partnership=${e.alliance_id}&role=${e.owner_type}`}
                          >
                            عرض{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد مشاركات لك في التحالفات
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
