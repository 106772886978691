import React from "react";

export default function File({ da }) {
  const downloadFile = (url) => {
    const fileName = url.split("/").pop(); // Get file name from URL
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set the download attribute with the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link element after download
  };

  return (
    <div className="w-[340px]  bg-[#EEE0C3] p-3 flex items-center justify-between">
      <section className="flex overflow-hidden max-w-[280px] flex-1 items-center gap-2 text-[#333333]">
        <i className="fa-regular fa-file text-3xl"></i>
        <h1 className="text-[16px] font-[600]">{da.split("/").pop()}</h1>
      </section>
      <button className=" w-[20px] " onClick={() => downloadFile(da)}>
        <i className="fa-solid fa-download text-[#333333] text-[18px]"></i>
      </button>
    </div>
  );
}
