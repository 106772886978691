import React from "react";
import HospitalsCompoent from "../../../components/pages/Veterinary/HospitalsCompoent";

export default function HospitalsCont({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <HospitalsCompoent hospital={true} key={inx} data={e} />
      ))}
    </div>
  );
}
