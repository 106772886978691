import React from "react";

export default function Pref({ data }) {
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          اسم المنتج
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.name} 
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          السعر
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.price} ر.س
        </h1>
      </section>
    </div>
  );
}
