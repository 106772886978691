import React from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import ParnerShipInfo from "./ParnerShipInfo";
import PartnerInfo from "./PartnerInfo";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";

export default function PartnerShip() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("partnership");
  const role = new URLSearchParams(search).get("role");

  const { data, error } = useFetchGlobal(
    `global/alliance/show/${invest}/${role}`
  );
  if (error) {
    return <Error />;
  }

  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/alliance">
              التحالف
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل التحالف `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1  w-deta  ">
          <section className="   flex items-center gap-3 ">
            <Tab text={"التفاصيل"} val={1} tab={1} setTabState={() => {}} />
          </section>
          <section>{!!data && <ParnerShipInfo data={data.alliance} />}</section>
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && <PartnerInfo data={data.alliance} role={role} />}
        </section>
      </div>
    </div>
  );
}
