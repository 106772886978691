import React from "react";

export default function ProfileProuduct({ data }) {
  return (
    <div className=" w-[260px] border border-[#432315]/20 sh p-2 bg-[#EEE0C3] rounded-[15px] ">
      <img
        src={data.img}
        alt={`${data.name}`}
        className=" w-full h-[122px] rounded-[15px] "
      />
      <section className=" text-[17px] text-[#000000] font-[800] my-2 ">
        <h1>{data.name}</h1>
      </section>
      <section className=" flex items-center gap-1 text-[16px] text-[#000000] font-[700] my-2 ">
        <h1> أعلى سعر : </h1>
        <h1>{data.price} ر.س </h1>
      </section>
      <section className=" flex items-center justify-between my-2 ">
        <h1 className=" text-[14px] font-[400] ">
          {" "}
          <i className="fa-solid fa-calendar-days"></i> {data.date}{" "}
        </h1>
        <h1 className=" text-[14px] font-[400] ">
          {" "}
          <i className="fa-regular fa-clock"></i> {data.time}{" "}
        </h1>
      </section>
    </div>
  );
}
