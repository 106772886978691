import React, { useState } from "react";

export default function Filter({
  locationOptions,
  setLocationsOptions,
  filter,
  setCheckedState,
}) {
  const [checked, setChecked] = useState(filter);

  const isChecked = (val) => checked.includes(val);

  const handleClick = (val) => {
    const updatedChecked = isChecked(val)
      ? checked.filter((item) => item !== val)
      : [...checked, val];

    setChecked(updatedChecked);
    setCheckedState(updatedChecked);
  };
  const checkOptions = [
    { name: "جمال للبيع", val: "camels" },
    { name: "أدوية", val: "medicens" },
    { name: "النقل والتوصيل", val: "travling" },
    { name: "أكسسوارات", val: "accseceories" },
    { name: "التأمين", val: "insurance" },
  ];
  // Location
  const [checkedLocation, setCheckedLocation] = useState(locationOptions);

  const isCheckedLocation = (val) => checkedLocation.includes(val);

  const handleClickLocation = (val) => {
    const updatedChecked = isCheckedLocation(val)
      ? checkedLocation.filter((item) => item !== val)
      : [...checkedLocation, val];

    setCheckedLocation(updatedChecked);
    setLocationsOptions(updatedChecked);
  };

  const LocationOptions = [
    { name: "الرياض", val: "riyhad" },
    { name: "جدة", val: "jada" },
    { name: "الامارات", val: "uae" },
    { name: "قطر", val: "qutar" },
    { name: "البحرين", val: "bahreen" },
  ];
  return (
    <div className="pb-4 border text-[#333333] border-[#43231580] rounded-[16px]">
      <h1 className="px-3 py-2 text-[20px] font-[700] border-b border-[#432315]">
        فلتر
      </h1>
      <section className="px-3 py-5 border-b text-[#4F4F4F] border-[#432315]">
        {checkOptions.map((option, index) => (
          <section key={index} className="flex items-center gap-3">
            <input
              type="checkbox"
              id={`customCheckbox-${index}`}
              className="custom-checkbox"
              checked={isChecked(option.val)}
              onChange={() => handleClick(option.val)}
            />
            <label
              htmlFor={`customCheckbox-${index}`}
              className="text-[20px] font-[500]"
            >
              {option.name}
            </label>
          </section>
        ))}
      </section>
      {/* Location */}
      <section>
        <h1 className="px-3 py-2 text-[18px] font-[600]">الموقع</h1>
        <section className="px-3 py-5  text-[#4F4F4F] border-[#432315]">
          {LocationOptions.map((option, index) => (
            <section key={index} className="flex items-center gap-3">
              <input
                type="checkbox"
                id={`customCheckbox-${index}`}
                className="custom-checkbox"
                checked={isCheckedLocation(option.val)}
                onChange={() => handleClickLocation(option.val)}
              />
              <label
                htmlFor={`customCheckbox-${index}`}
                className="text-[20px] font-[500]"
              >
                {option.name}
              </label>
            </section>
          ))}
        </section>
      </section>
    </div>
  );
}
