import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePutWithToken from "../../../../utils/usePutJson";
import usePostWithToken from "../../../../utils/usePostJson";

export default function Social() {
  let link = "global/social_links";
  const { data, error } = useFetchWithReload(link);
  const [form, setForm] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    whatsapp: "",
    phone: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    if (data) {
      setForm({
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        whatsapp: data.whatsapp,
        phone: data.phone,
        email: data.email,
        address: data.address,
      });
    }
  }, [data]);

  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const { putData, response } = usePutWithToken(
    `superAdmin/social_links/update`
  );
  const { postData, response: add } = usePostWithToken(
    `superAdmin/social_links/create`
  );

  const updateHandeller = () => {
    if (error) {
      postData(form);
    } else {
      putData(form);
    }
  };
  if (error) {
  }
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold "> روابط التواصل </h1>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap   ">
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رقم الهاتف
            </h1>
            <input
              name="phone"
              value={form.phone}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder=" رقم الهاتف"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              البريد الاكتروني
            </h1>
            <input
              name="email"
              value={form.email}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="   البريد الاكتروني"
              required
            />
          </section>
          <section className=" col-span-1 lg:col-span-2">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="العنوان"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الفيسبوك
            </h1>
            <input
              name="facebook"
              value={form.facebook}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="الفيسبوك"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تويتر
            </h1>
            <input
              name="twitter"
              value={form.twitter}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="الفيسبوك"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              انستجرام
            </h1>
            <input
              name="instagram"
              value={form.instagram}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="انستجرام"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط الواتس اب
            </h1>
            <input
              name="whatsapp"
              value={form.whatsapp}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder=" رابط الواتس اب"
              required
            />
          </section>
        </form>
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            تعديل
          </button>
          {(response || add) && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم تغيير البيانات بنجاح</h1>
            </section>
          )}
          {/* {error && (
            <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
              <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                <i className="fa-solid fa-exclamation  "></i>
              </span>
              {!!error.response.data.message
                ? error.response.data.message
                : "  هناك مشكلة"}
            </section>
          )} */}
        </section>
      </div>
    </div>
  );
}
