import React, { useContext, useEffect } from "react";
import { isLogged } from "../../utils/inStore";
import { useNavigate } from "react-router";
import CamelOwnerDashBoeard from "./CamelOwner/CamelOwnerDashBoeard";
import SquerDashboard from "./squer/SquerDashboard";
import SercviceProvider from "./service/SercviceProvider";
import VisitorDashBoard from "./visitor/Visitor";
import SuperAdminDashBoard from "./SuperAdmin/SuperAdminDashBoard";

export default function Dashboard() {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(role)
  return (
    <div>
      {role === "camel-owner" && <CamelOwnerDashBoeard />}
      {role === "squer" && <SquerDashboard />}
      {role === "provider" && <SercviceProvider />}
      {role === "vistor" && <VisitorDashBoard />}
      {role === "super_admin" && <SuperAdminDashBoard />}
    </div>
  );
}
