import React, { useContext } from "react";
import Nav from "./Nav.js";
import SideNav from "./SideNav.js";
import Footer from "./Footer.js";
import { useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";
import { isLogged } from "../../utils/inStore.js";

export default function Layout({ children }) {
  const navigation = useNavigate();
  const { setLogged, setRole } = useContext(isLogged);
  const logOutHandeller = () => {
    Cookies.remove("token");
    Cookies.remove("name");
    Cookies.remove("image");
    Cookies.remove("value");
    Cookies.remove("rcom9lsuZsWree9mdXNlcg==");
    Cookies.remove("expirein");
    setLogged(false);
    setRole("");
    navigation("/login");
  };
  const thisTime = new Date().getTime();
  const exin = Cookies.get("expirein");
  const OutTime = +exin - thisTime;
  if (exin) {
    setTimeout(logOutHandeller, OutTime);
  }

  const route = useLocation();
  if (
    route.pathname === "/login" ||
    route.pathname === "/register" ||
    route.pathname === "/policies"
  ) {
    return <>{children}</>;
  }
  return (
    <div className=" h-fit overflow-hidden bg-[#F5EFDC] relative">
      <section className=" absolute top-[50rem] md:top-[20rem] lg:top-0 right-0  z-0">
        <section className="">
          <svg
            width="750"
            height="2134"
            viewBox="0 0 750 2134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1113.35 75.357L1174.59 51.9627C1129.45 147.825 1218.24 395.334 1051.27 721.941C820.709 1172.94 1191.59 1772.95 1191.59 1772.95L1169.81 1781.26C1169.81 1781.26 757.068 1178.5 1002.38 745.723C1195.11 405.727 1077.24 152.046 1113.35 75.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M984.244 124.662L1052.37 98.643C1025.28 156.164 1173.02 417.784 953.735 769.397C694.127 1185.69 1148.29 1789.48 1148.29 1789.48L1119.62 1800.43C1119.62 1800.43 624.216 1197.18 897.959 795.812C1144.46 434.394 966.194 163.011 984.244 124.662Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M842.924 178.633L913.723 151.592C904.686 170.765 1114.02 453.742 839.783 823.126C551.843 1211 1088.55 1812.3 1088.55 1812.3L1057.21 1824.26C1057.21 1824.26 478.988 1226.19 781.329 850.564C1083.68 474.916 842.924 178.633 842.924 178.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M750.303 214.009L796.479 196.372C804.728 201.237 1022.73 491.007 749.09 865.486C469.845 1247.61 989.269 1850.21 989.269 1850.21L921.613 1876.05C921.613 1876.05 460.859 1269.79 717.116 880.289C962.103 507.945 766.8 223.738 750.303 214.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M769.987 180L820.487 193.5C845.231 208.097 901.974 525.813 685.485 894.978C452.168 1292.82 854.287 1901.77 854.287 1901.77L787.396 1927.31C787.396 1927.31 444.059 1316.82 654.278 909.506C842.566 544.701 802.979 199.461 769.987 180Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M570.582 282.647L614.299 265.95C655.54 290.276 784.263 564.534 623.711 923.788C436.91 1341.77 817.696 2060.1 817.696 2060.1L752.5 2085C752.5 2085 431.45 1367.53 594.197 937.651C727.672 585.11 620.072 311.84 570.582 282.647Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
        <section className=" opacity-0 md:opacity-100">
          <section>
            <svg
              width="715"
              height="2258"
              viewBox="0 0 715 2258"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1113.35 199.357L1174.59 175.963C1129.45 271.825 1218.24 519.334 1051.27 845.941C820.709 1296.94 1191.59 1896.95 1191.59 1896.95L1169.81 1905.26C1169.81 1905.26 757.068 1302.5 1002.38 869.723C1195.11 529.727 1077.24 276.046 1113.35 199.357Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M984.244 248.662L1052.37 222.643C1025.28 280.164 1173.02 541.784 953.735 893.397C694.127 1309.69 1148.29 1913.48 1148.29 1913.48L1119.62 1924.43C1119.62 1924.43 624.216 1321.18 897.959 919.812C1144.46 558.394 966.194 287.011 984.244 248.662Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M842.924 302.633L913.723 275.592C904.686 294.765 1114.02 577.742 839.783 947.126C551.843 1335 1088.55 1936.3 1088.55 1936.3L1057.21 1948.26C1057.21 1948.26 478.988 1350.19 781.329 974.564C1083.68 598.916 842.924 302.633 842.924 302.633Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M750.303 338.009L796.479 320.372C804.728 325.237 1022.73 615.007 749.09 989.486C469.845 1371.61 989.269 1974.21 989.269 1974.21L921.613 2000.05C921.613 2000.05 460.859 1393.79 717.116 1004.29C962.103 631.945 766.8 347.738 750.303 338.009Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M769.987 304L820.487 317.5C845.231 332.097 901.974 649.813 685.485 1018.98C452.168 1416.82 854.287 2025.77 854.287 2025.77L787.396 2051.31C787.396 2051.31 444.059 1440.82 654.278 1033.51C842.566 668.701 802.979 323.461 769.987 304Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M570.582 406.648L614.298 389.951C655.539 414.277 784.263 688.534 623.712 1047.79C436.91 1465.77 721.133 2076.62 721.133 2076.62L655.937 2101.52C655.937 2101.52 431.45 1491.53 594.198 1061.65C727.672 709.11 620.071 435.841 570.582 406.648Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
            </svg>
          </section>
        </section>
        <section className=" mt-[-60rem] md:mt-0 ">
          <svg
            width="750"
            height="2134"
            viewBox="0 0 750 2134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1113.35 75.357L1174.59 51.9627C1129.45 147.825 1218.24 395.334 1051.27 721.941C820.709 1172.94 1191.59 1772.95 1191.59 1772.95L1169.81 1781.26C1169.81 1781.26 757.068 1178.5 1002.38 745.723C1195.11 405.727 1077.24 152.046 1113.35 75.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M984.244 124.662L1052.37 98.643C1025.28 156.164 1173.02 417.784 953.735 769.397C694.127 1185.69 1148.29 1789.48 1148.29 1789.48L1119.62 1800.43C1119.62 1800.43 624.216 1197.18 897.959 795.812C1144.46 434.394 966.194 163.011 984.244 124.662Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M842.924 178.633L913.723 151.592C904.686 170.765 1114.02 453.742 839.783 823.126C551.843 1211 1088.55 1812.3 1088.55 1812.3L1057.21 1824.26C1057.21 1824.26 478.988 1226.19 781.329 850.564C1083.68 474.916 842.924 178.633 842.924 178.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M750.303 214.009L796.479 196.372C804.728 201.237 1022.73 491.007 749.09 865.486C469.845 1247.61 989.269 1850.21 989.269 1850.21L921.613 1876.05C921.613 1876.05 460.859 1269.79 717.116 880.289C962.103 507.945 766.8 223.738 750.303 214.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M769.987 180L820.487 193.5C845.231 208.097 901.974 525.813 685.485 894.978C452.168 1292.82 854.287 1901.77 854.287 1901.77L787.396 1927.31C787.396 1927.31 444.059 1316.82 654.278 909.506C842.566 544.701 802.979 199.461 769.987 180Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M570.582 282.647L614.299 265.95C655.54 290.276 784.263 564.534 623.711 923.788C436.91 1341.77 817.696 2060.1 817.696 2060.1L752.5 2085C752.5 2085 431.45 1367.53 594.197 937.651C727.672 585.11 620.072 311.84 570.582 282.647Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
        <section className=" mt-[-60rem] md:mt-0 opacity-0 md:opacity-100">
          <section>
            <svg
              width="715"
              height="2258"
              viewBox="0 0 715 2258"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1113.35 199.357L1174.59 175.963C1129.45 271.825 1218.24 519.334 1051.27 845.941C820.709 1296.94 1191.59 1896.95 1191.59 1896.95L1169.81 1905.26C1169.81 1905.26 757.068 1302.5 1002.38 869.723C1195.11 529.727 1077.24 276.046 1113.35 199.357Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M984.244 248.662L1052.37 222.643C1025.28 280.164 1173.02 541.784 953.735 893.397C694.127 1309.69 1148.29 1913.48 1148.29 1913.48L1119.62 1924.43C1119.62 1924.43 624.216 1321.18 897.959 919.812C1144.46 558.394 966.194 287.011 984.244 248.662Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M842.924 302.633L913.723 275.592C904.686 294.765 1114.02 577.742 839.783 947.126C551.843 1335 1088.55 1936.3 1088.55 1936.3L1057.21 1948.26C1057.21 1948.26 478.988 1350.19 781.329 974.564C1083.68 598.916 842.924 302.633 842.924 302.633Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M750.303 338.009L796.479 320.372C804.728 325.237 1022.73 615.007 749.09 989.486C469.845 1371.61 989.269 1974.21 989.269 1974.21L921.613 2000.05C921.613 2000.05 460.859 1393.79 717.116 1004.29C962.103 631.945 766.8 347.738 750.303 338.009Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M769.987 304L820.487 317.5C845.231 332.097 901.974 649.813 685.485 1018.98C452.168 1416.82 854.287 2025.77 854.287 2025.77L787.396 2051.31C787.396 2051.31 444.059 1440.82 654.278 1033.51C842.566 668.701 802.979 323.461 769.987 304Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
              <path
                d="M570.582 406.648L614.298 389.951C655.539 414.277 784.263 688.534 623.712 1047.79C436.91 1465.77 721.133 2076.62 721.133 2076.62L655.937 2101.52C655.937 2101.52 431.45 1491.53 594.198 1061.65C727.672 709.11 620.071 435.841 570.582 406.648Z"
                fill="#DAB67B"
                fill-opacity="0.26"
              />
            </svg>
          </section>
        </section>
      </section>
      <section className="  absolute top-[10rem] md:top-[30rem] left-0  z-0">
        <section className=" opacity-0 md:opacity-100">
          <svg
            width="754"
            height="2258"
            viewBox="0 0 754 2258"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-359.861 199.357L-421.1 175.963C-375.96 271.825 -464.751 519.334 -297.783 845.941C-67.2225 1296.94 -438.104 1896.95 -438.104 1896.95L-416.326 1905.26C-416.326 1905.26 -3.58128 1302.5 -248.895 869.723C-441.618 529.727 -323.75 276.046 -359.861 199.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-230.756 248.662L-298.882 222.643C-271.795 280.164 -419.535 541.784 -200.248 893.397C59.3606 1309.69 -394.803 1913.48 -394.803 1913.48L-366.134 1924.43C-366.134 1924.43 129.272 1321.18 -144.471 919.812C-390.975 558.394 -212.707 287.011 -230.756 248.662Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-89.4371 302.633L-160.236 275.592C-151.198 294.765 -360.53 577.742 -86.2954 947.126C201.645 1335 -335.059 1936.3 -335.059 1936.3L-303.727 1948.26C-303.727 1948.26 274.5 1350.19 -27.8416 974.564C-330.193 598.916 -89.4371 302.633 -89.4371 302.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M3.1852 338.009L-42.9909 320.372C-51.2404 325.237 -269.243 615.007 4.39818 989.486C283.643 1371.61 -235.781 1974.21 -235.781 1974.21L-168.125 2000.05C-168.125 2000.05 292.629 1393.79 36.3718 1004.29C-208.615 631.945 -13.3122 347.738 3.1852 338.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-16.5 304L-67 317.5C-91.7437 332.097 -148.486 649.813 68.002 1018.98C301.319 1416.82 -100.8 2025.77 -100.8 2025.77L-33.9084 2051.31C-33.9084 2051.31 309.428 1440.82 99.2093 1033.51C-89.0792 668.701 -49.4917 323.461 -16.5 304Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M116.5 371.5L72.7835 354.803C31.5424 379.129 -30.7755 688.533 129.776 1047.79C316.578 1465.77 -73.5004 2230.5 -73.5004 2230.5L-8.30446 2255.4C-8.30446 2255.4 322.037 1491.53 159.29 1061.65C25.8151 709.109 67.0104 400.693 116.5 371.5Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
        <section>
          <svg
            width="754"
            height="1906"
            viewBox="0 0 754 1906"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-359.861 199.357L-421.1 175.963C-375.96 271.825 -464.751 519.334 -297.783 845.941C-67.2225 1296.94 -438.104 1896.95 -438.104 1896.95L-416.326 1905.26C-416.326 1905.26 -3.58128 1302.5 -248.895 869.723C-441.618 529.727 -323.75 276.046 -359.861 199.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-230.756 248.663L-298.882 222.644C-271.795 280.165 -419.535 541.785 -200.248 893.398C59.3606 1309.69 -394.803 1913.48 -394.803 1913.48L-366.134 1924.43C-366.134 1924.43 129.272 1321.18 -144.471 919.813C-390.975 558.395 -212.707 287.012 -230.756 248.663Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-89.4371 302.633L-160.236 275.592C-151.198 294.765 -360.53 577.742 -86.2954 947.126C201.645 1335 -335.059 1936.3 -335.059 1936.3L-303.727 1948.26C-303.727 1948.26 274.5 1350.19 -27.8416 974.564C-330.193 598.916 -89.4371 302.633 -89.4371 302.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M3.1852 338.009L-42.9909 320.372C-51.2404 325.237 -269.243 615.007 4.39818 989.486C283.643 1371.61 -235.781 1974.21 -235.781 1974.21L-168.125 2000.05C-168.125 2000.05 292.629 1393.79 36.3718 1004.29C-208.615 631.945 -13.3122 347.738 3.1852 338.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-16.5 304L-67 317.5C-91.7437 332.097 -148.486 649.813 68.002 1018.98C301.319 1416.82 -100.8 2025.77 -100.8 2025.77L-33.9084 2051.31C-33.9084 2051.31 309.428 1440.82 99.2093 1033.51C-89.0792 668.701 -49.4917 323.461 -16.5 304Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M182.906 406.648L139.189 389.951C97.9478 414.277 -30.7756 688.534 129.776 1047.79C316.578 1465.77 32.3541 2076.62 32.3541 2076.62L97.55 2101.52C97.55 2101.52 322.037 1491.53 159.29 1061.65C25.815 709.11 133.416 435.841 182.906 406.648Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
        <section className=" opacity-0 md:opacity-100">
          <svg
            width="754"
            height="2258"
            viewBox="0 0 754 2258"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-359.861 199.357L-421.1 175.963C-375.96 271.825 -464.751 519.334 -297.783 845.941C-67.2225 1296.94 -438.104 1896.95 -438.104 1896.95L-416.326 1905.26C-416.326 1905.26 -3.58128 1302.5 -248.895 869.723C-441.618 529.727 -323.75 276.046 -359.861 199.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-230.756 248.662L-298.882 222.643C-271.795 280.164 -419.535 541.784 -200.248 893.397C59.3606 1309.69 -394.803 1913.48 -394.803 1913.48L-366.134 1924.43C-366.134 1924.43 129.272 1321.18 -144.471 919.812C-390.975 558.394 -212.707 287.011 -230.756 248.662Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-89.4371 302.633L-160.236 275.592C-151.198 294.765 -360.53 577.742 -86.2954 947.126C201.645 1335 -335.059 1936.3 -335.059 1936.3L-303.727 1948.26C-303.727 1948.26 274.5 1350.19 -27.8416 974.564C-330.193 598.916 -89.4371 302.633 -89.4371 302.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M3.1852 338.009L-42.9909 320.372C-51.2404 325.237 -269.243 615.007 4.39818 989.486C283.643 1371.61 -235.781 1974.21 -235.781 1974.21L-168.125 2000.05C-168.125 2000.05 292.629 1393.79 36.3718 1004.29C-208.615 631.945 -13.3122 347.738 3.1852 338.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-16.5 304L-67 317.5C-91.7437 332.097 -148.486 649.813 68.002 1018.98C301.319 1416.82 -100.8 2025.77 -100.8 2025.77L-33.9084 2051.31C-33.9084 2051.31 309.428 1440.82 99.2093 1033.51C-89.0792 668.701 -49.4917 323.461 -16.5 304Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M116.5 371.5L72.7835 354.803C31.5424 379.129 -30.7755 688.533 129.776 1047.79C316.578 1465.77 -73.5004 2230.5 -73.5004 2230.5L-8.30446 2255.4C-8.30446 2255.4 322.037 1491.53 159.29 1061.65C25.8151 709.109 67.0104 400.693 116.5 371.5Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
        <section>
          <svg
            width="754"
            height="1906"
            viewBox="0 0 754 1906"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-359.861 199.357L-421.1 175.963C-375.96 271.825 -464.751 519.334 -297.783 845.941C-67.2225 1296.94 -438.104 1896.95 -438.104 1896.95L-416.326 1905.26C-416.326 1905.26 -3.58128 1302.5 -248.895 869.723C-441.618 529.727 -323.75 276.046 -359.861 199.357Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-230.756 248.663L-298.882 222.644C-271.795 280.165 -419.535 541.785 -200.248 893.398C59.3606 1309.69 -394.803 1913.48 -394.803 1913.48L-366.134 1924.43C-366.134 1924.43 129.272 1321.18 -144.471 919.813C-390.975 558.395 -212.707 287.012 -230.756 248.663Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-89.4371 302.633L-160.236 275.592C-151.198 294.765 -360.53 577.742 -86.2954 947.126C201.645 1335 -335.059 1936.3 -335.059 1936.3L-303.727 1948.26C-303.727 1948.26 274.5 1350.19 -27.8416 974.564C-330.193 598.916 -89.4371 302.633 -89.4371 302.633Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M3.1852 338.009L-42.9909 320.372C-51.2404 325.237 -269.243 615.007 4.39818 989.486C283.643 1371.61 -235.781 1974.21 -235.781 1974.21L-168.125 2000.05C-168.125 2000.05 292.629 1393.79 36.3718 1004.29C-208.615 631.945 -13.3122 347.738 3.1852 338.009Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M-16.5 304L-67 317.5C-91.7437 332.097 -148.486 649.813 68.002 1018.98C301.319 1416.82 -100.8 2025.77 -100.8 2025.77L-33.9084 2051.31C-33.9084 2051.31 309.428 1440.82 99.2093 1033.51C-89.0792 668.701 -49.4917 323.461 -16.5 304Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
            <path
              d="M182.906 406.648L139.189 389.951C97.9478 414.277 -30.7756 688.534 129.776 1047.79C316.578 1465.77 32.3541 2076.62 32.3541 2076.62L97.55 2101.52C97.55 2101.52 322.037 1491.53 159.29 1061.65C25.815 709.11 133.416 435.841 182.906 406.648Z"
              fill="#DAB67B"
              fill-opacity="0.26"
            />
          </svg>
        </section>
      </section>
      <header className=" relative z-[50]">
        <section className=" hidden lg:grid">
          <Nav />
        </section>
        <section className="  lg:hidden">
          <SideNav />
        </section>
      </header>
      <main className=" z-[10] relative">{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
