import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div className=" w-full h-screen flex items-center justify-center ">
      <section className=" text-center " >
        <h1 className=" text-sky-800 text-2xl font-bold ">
          هذه الصفحة غير موجودة
              </h1>
              <Link to='/' className=" underline text-center " >العودة الي الصفحة الرئيسية</Link>
      </section>
    </div>
  );
}
