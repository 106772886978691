import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import DiscountPrice from "../../../../../components/pages/DiscountPrice";
import { Link } from "react-router-dom";
import DeletePack from "./DeletePack";

export default function HoledPackages() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    filter: "",
  });
  const [reload, setReload] = useState(false);
  let link = "superAdmin/packages/getAllPackageHold";
  const { data, error } = useFetchWithReload(link, reload);
  const re = () => {
    setReload((pre) => !pre);
  };
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filtered = [];

    if (!!data) {
      filtered = data.packages;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.toLowerCase().includes(mainFilter.search.toLowerCase())
        );
      }
      if (mainFilter.filter) {
        filtered = filtered.filter(
          (item) => item.user_type === mainFilter.filter
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);
  const [deleteId, setDeletId] = useState();

  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  let time = {
    days: "يوم",
    months: "شهر",
    years: "سنة",
  };

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      {/* <h1 className=" text-xl font-bold text-center my-6 ">الباقات المعلقة</h1> */}
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 outline-none p-2  border rounded-[11px] w-[250px] border-black/60 "
          value={mainFilter.filter}
          onChange={(e) =>
            setMainFilter({ ...mainFilter, filter: e.target.value })
          }
        >
          <option hidden>نوع المستخدم</option>
          <option value={""}>الكل</option>
          <option value={"visitor"}>زائر</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        <section className="flex-1">
          {!!data && (
            <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
              {filteredData.map((e, inx) => (
                // <PackageComponent setDeleteId={setDeletId} key={inx} data={e} />
                <section
                  key={inx}
                  className=" relative text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 "
                >
                  <h1 className=" text-center my-2 text-[18px] font-[600] ">
                    {e.name}
                  </h1>
                  <p className="  min-h-[110px] text-[15px] font-[600] leading-[28px] ">
                    <TruncatedParagraph text={e.description} />
                  </p>
                  <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
                    مدة اشتراك الباقة : {e.duration} {time[e.duration_type]}
                  </p>
                  {e.discount_percentage &&
                  Number(e.discount_percentage) > 0 ? (
                    <p className=" flex items-center gap-1  text-[15px] font-[600] leading-[28px] my-2 ">
                      السعر : <DiscountPrice>{e.price}</DiscountPrice>{" "}
                      {(
                        ((100 - Number(e.discount_percentage)) / 100) *
                        e.price
                      ).toFixed(2)}{" "}
                      ر.س
                    </p>
                  ) : (
                    <p className="  text-[15px] font-[600] leading-[28px] my-2 ">
                      السعر : {e.price} ر.س
                    </p>
                  )}
                  <section className=" my-1   text-[15px] font-[600] leading-[28px] flex items-center justify-between flex-wrap gap-1 ">
                    <Link to={`/package?package=${e.id}`}>
                      <button className=" btnn2 ">التفاصيل</button>
                    </Link>
                    <button
                      onClick={() => setDeletId(e.id)}
                      className="  h-[58px] w-[58px] flex items-center justify-center bg-[#E8D6B2]/30 hover:bg-[#E8D6B2]/60 rounded-full  "
                    >
                      {/* {deleteId} */}
                      <i className="fa-solid fa-trash text-xl text-red-800 "></i>
                    </button>
                  </section>
                </section>
              ))}
            </div>
          )}
        </section>
      </div>
      {deleteId && <DeletePack set={re} id={deleteId} close={setDeletId} />}
    </div>
  );
}
