import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const names = {
  camel_owner: "مالك ابل",
  field_owner: "مالك ساحة",
  service_provider: "مقدم خدمة",
  visitor: "زائر",
};
export default function BarCharts({ data: subs }) {
  const labels = subs.map((item) => names[item.guard_type]);
  const values = subs.map((item) => item.subscriptions_in_period);
  console.log(values);
  const data = {
    labels: labels,
    datasets: [
      {
        label: "عدد المشاريع",
        data: values, // Example data
        backgroundColor: ["#3F7746", "#92C9FB", "#E26305AB", "#6EB57B"],
        borderWidth: 1,
        borderRadius: 0, // Controls the corner radius of bars (rounded bars)
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // min: 0,
        // max: 110,
        ticks: {
          stepSize: 1,
        },
        grid: {
          color: "#e5e5e5",
        },
      },
    },
  };
  return (
    <div className="chart-container ">
      <section className=" md:hidden ">
        <Bar height={200} data={data} options={options} />
      </section>
      <section className=" hidden md:grid ">
        <Bar height={100} data={data} options={options} />
      </section>
    </div>
  );
}
