import React from "react";
import usePostWithToken from "../../../../../utils/usePostJson";

export default function UnSbScribe() {
  const { postData, response, error } = usePostWithToken(
    `global/packages/unsubscribe`
  );
  const handelJoin = () => {
    const data = {
      callback_url: process.env.REACT_APP_CAll_BACK,
    };
    postData(data);
  };
  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        الغاء الاشتراك
      </button>
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message
            ? error.response.data.message
            : "هناك مشكلة"}
        </section>
      )}
      {response && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-green-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-green-500 rounded-full flex items-center justify-center ">
            <i class="fa-solid fa-check"></i>
          </span>
          تم الغاء الاشتراك بنجاح
        </section>
      )}
    </div>
  );
}
