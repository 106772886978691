import React from "react";
import { Link } from "react-router-dom";

export default function RaceComponenet({ data }) {
  return (
    <div className=" text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.race_image}`}
          alt="cover"
        />
      </div>
      <h1 className=" text-[#000] my-2 text-[22px] font-[800] ">{data.name}</h1>
      <section className=" my-2 flex gap-[6px] items-center ">
        <i className="fa-solid fa-calendar-days"></i>
        <span className=" flex items-center ltr ">
          <h4 className=" font-[600] leading-4">
          {data.end_date} - {data.start_date.substring(0, 2)} 
          </h4>
        </span>
      </section>
      <section className=" flex my-2 gap-[6px] items-center ">
        <i className="fa-solid fa-location-dot"></i>
        <span className=" flex items-center ltr ">
          <h4 className=" font-[600] leading-4">{data.address}</h4>
        </span>
      </section>
      <section className=" text-center mt-4 ">
        <Link to={`/race-details?race=${data.id}&role=${data.role}`}>
          <button className=" btn2 ">المزيد</button>
        </Link>
      </section>
    </div>
  );
}
