import React, { useEffect } from "react";
import usePutWithToken from "../../../../../../utils/usePutJson";

export default function Reject({ re, id }) {
  let link = `superAdmin/approves/serviceProviders/reject/${id}`;
  const { putData, response } = usePutWithToken(link);
  const handelReject = () => {
    putData();
  };
  useEffect(() => {
    if (response) {
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <button
      onClick={handelReject}
      className=" px-2 py-1 rounded-[10px] bg-red-500 text-white text-[16px] "
    >
      رفض
    </button>
  );
}
