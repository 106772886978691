import React from "react";
import Hr from "../../components/pages/Hr";
import useFetchGlobal from "../../utils/useGetGlobal";
import CamelComponent from "../../components/pages/OwnerProfile/CamelComponent";
import { Link } from "react-router-dom";

export default function Camels() {
  const { data } = useFetchGlobal("global/CamelOwners/allCamelCard");
  return (
    <div>
      {data && (
        <>
          {data["camel_cards"].length > 0 && (
            <section className=" p-4 md:p-12  text-center ">
              <>
                <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
                  كروت الابل
                </h1>
                <Hr color="#432315" />
              </>
            </section>
          )}
        </>
      )}
      {!!data && (
        <>
          {data["camel_cards"].length > 0 && (
            <div className=" flex items-start justify-center gap-12 flex-wrap p-6 md:p-14">
              {data["camel_cards"].slice(0, 3).map((e) => (
                <CamelComponent key={e.id} data={e} />
              ))}
            </div>
          )}
        </>
      )}
      {!!data && (
        <section className=" text-center ">
          {data["camel_cards"].length > 3 && (
            <Link className=" btn2 " to="/all-camel-cards">
              عرض الكل
            </Link>
          )}
        </section>
      )}
    </div>
  );
}
