import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Info from "./Info";
import Form from "./Form";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";

export default function Communication() {
  const { logged } = useContext(isLogged);
  return (
    <div className="p-6 md:p-10 ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/">
              الرئيسية
            </Link>
            <h1 className="text-[#432315] ">{`> تواصل معنا   `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      <section className=" my-3 text-center ">
        <h1 className=" text-[32px] font-[600] text-[#290900] leading-[48px]">
          تواصل معنا
        </h1>
        <p className="text-[18px] font-[500] max-w-[600px] mx-auto text-[#A59382] ">
          "تواصل معنا وشاركنا تجربتك: اترك بصمتك وأفكارك، واستفسر عن أي شيء
          تحتاجه في موقعنا. فريقنا مستعد لمساعدتك في أي وقت."
        </p>
      </section>
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className="  items-center gap-3 "></section>
          <Form />
        </div>
        {/* Owner */}
        <Info join={true} />
      </div>
      {!logged && (
        <Notegister close={() => {}} msg="الرجاء التسجيل لتتمكن من التواصل" />
      )}
    </div>
  );
}
