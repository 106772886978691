import React, { useEffect, useState } from "react";
// import CamelComponent from "../../../components/pages/OwnerProfile/CamelComponent";
import AddCamelCard from "./AddCamelCard";
import EditCamelComponent from "../../../components/pages/OwnerProfile/EditCamelComponent";
import EditCard from "./EditCard";
import DeleteCard from "./DeleteCard";
import useFetchWithToken from "../../../utils/useGetWithToken";

export default function Camels() {
  const { data, error } = useFetchWithToken("camelOwner/camelCards/all");

  const [showData, setShowData] = useState([]);

  useEffect(() => {
    if (!!data) {
      if (!!data.camel_cards) {
        setShowData(data.camel_cards);
      }
    }
  }, [data]);

  const [showAllCards, setShowAllCards] = useState(false);
  const [openAddCamel, setOpenAddCamel] = useState(false);
  const [openEditCards, setEditCards] = useState();
  const [openDeleteCard, setDeleteCard] = useState();
  if (error) {
    return (
      <div>
        <h1 className=" my-6 text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" py-12 px-6 md:p-20 ">
      {/* Edit and Add Section */}
      <section className=" flex items-center justify-between flex-wrap gap-2 ">
        <h1 className=" text-[32px] font-[700] text-[#432315] ">
          الإبل الخاص للمالك
        </h1>

        <section className="  flex items-center gap-1 ">
          <button
            onClick={() => setOpenAddCamel(true)}
            className=" btn w-[180px]   flex items-center gap-2 justify-center "
          >
            <i className="fa-solid fa-circle-plus"></i> اضف كرت الإبل
          </button>
          {/* <button className=" text-center rounded-[10px] py-[12px] w-full px-[8px] border border-[#432315] text-[#432315] text-[18px] font-[500] max-w-[100px] ">
            تعديل
          </button> */}
        </section>
      </section>
      {/* Camels Cards */}
      {!!data && (
        <div className=" my-6">
          <section className=" flex gap-8  items-start justify-center   flex-wrap  ">
            {showData.slice(0, 6).map((camel, inx) => (
              // <CamelComponent key={inx} data={camel} />
              <EditCamelComponent
                key={inx}
                setDelete={setDeleteCard}
                setEdit={setEditCards}
                data={camel}
              />
            ))}
          </section>
          {showAllCards && (
            <section className=" mt-8 flex gap-8  items-start justify-center   flex-wrap  ">
              {showData.slice(6).map((camel, inx) => (
                <EditCamelComponent
                  key={inx}
                  setDelete={setDeleteCard}
                  setEdit={setEditCards}
                  data={camel}
                />
                // <CamelComponent key={inx} data={camel} />
              ))}
            </section>
          )}
          {showData.length > 6 && !showAllCards && (
            <section className=" text-center mt-[30px] ">
              <button onClick={() => setShowAllCards(true)} className="btnLink">
                المزيد
              </button>
            </section>
          )}
          {showData.length === 0 && (
            <>
              <h1 className=" text-red-400 text-xl text-center my-3 font-bold ">
                لا توجد كروت ابل حتي الان
              </h1>
            </>
          )}
        </div>
      )}
      {openAddCamel && (
        <AddCamelCard set={setShowData} close={setOpenAddCamel} />
      )}
      {openEditCards && (
        <EditCard set={setShowData} id={openEditCards} close={setEditCards} />
      )}
      {openDeleteCard && (
        <DeleteCard
          set={setShowData}
          id={openDeleteCard}
          close={setDeleteCard}
        />
      )}
    </div>
  );
}
