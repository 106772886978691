import React, { useContext, useEffect, useState } from "react";
import Pref from "./Pref";
import Events from "./Events";
import DeleteEvent from "../../components/events/Delete";
import Attends from "./Attends";
import EventsTabel from "./EventsTabel";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import AddEvent from "../../components/events/AddEvent";
import EditEvent from "../../components/events/EditEvent";
import Reports from "./Reports";

export default function DashBoardEventsPage() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = "camelOwner/event/all";
  }
  // console.log(role)
  if (role === "squer") {
    link = "field-owner/event/index";
  }
  if (role === "super_admin") {
    link = "superAdmin/event/index";
  }
  const { data, error } = useFetchWithToken(link);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data.events;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);

  const [deleteId, setDeletId] = useState();
  const [editId, setEditId] = useState();
  const [detailsId, setDetailsId] = useState();
  const [reports, setRports] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <button onClick={() => setRports((pre) => !pre)} className=" btn ">
          عرض التقارير <i className="fa-solid fa-sheet-plastic"></i>
        </button>
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة فعالية
        </button>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        <section className="flex-1">
          {reports ? (
            <>
              <Reports back={() => setRports(false)} />
            </>
          ) : (
            <>
              {detailsId ? (
                <Attends id={detailsId} back={() => setDetailsId()} />
              ) : (
                <>
                  {mainFilter.card ? (
                    <Events
                      setDetailsId={setDetailsId}
                      setDeleteId={setDeletId}
                      setEditId={setEditId}
                      data={[...filteredData].reverse()}
                    />
                  ) : (
                    <EventsTabel
                      setDetailsId={setDetailsId}
                      setDeleteId={setDeletId}
                      data={filteredData}
                    />
                  )}
                  {filteredData.length === 0 && (
                    <h1 className=" text-center my-4 text-xl ">
                      لا توجد فعاليات
                    </h1>
                  )}
                </>
              )}
            </>
          )}
        </section>
        <section className=" w-full max-w-[390px] md:max-w-[230px] ">
          {!!data && <Pref data={data} />}
        </section>
      </div>
      {deleteId && (
        <DeleteEvent set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
      {editId && (
        <EditEvent set={setFilteredData} id={editId} close={setEditId} />
      )}
      {openAdd && <AddEvent close={setOpenAdd} />}
    </div>
  );
}
