import React, { useContext, useEffect, useState } from "react";
import Pref from "./Pref";
import DeleteMedia from "./DeleteMedia";
import View from "./View";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import Add from "./Add";
// import VideoThumbnail from "../../../../components/Tum";

export default function MediaDashboard() {
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/media/get_user_media";
  }
  if (role === "vistor") {
    link = "visitor/media/get_user_media";
  }
  if (role === "camel-owner") {
    link = "camelOwner/media/get_user_media";
  }
  if (role === "provider") {
    link = "serviceProvider/media/get_user_media";
  }
  if (role === "super_admin") {
    link = "superAdmin/media/get_user_media";
  }
  const { data, error } = useFetchWithToken(link);
  const [dataShow, setDataShow] = useState([]);

  useEffect(() => {
    if (!!data) {
      setDataShow(data.media_files);
    }
  }, [data]);

  const [deleteId, setDeletId] = useState();
  const [detailsId, setDetailsId] = useState();
  const [open, setOpen] = useState();
  const toggelOpen = () => {
    setOpen((pre) => !pre);
  };
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <button onClick={toggelOpen} className=" btn flex items-center gap-2 ">
          <i class="fa-solid fa-circle-plus"></i>
          اضافة صورة أو فيديو
        </button>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        <section className="flex-1">
          {!!data && (
            <div className="  flex items-center justify-center gap-3 flex-wrap ">
              {[...dataShow].reverse().map((e, inx) => (
                <section
                  // onClick={() => setDetailsId(e.id)}
                  key={inx}
                  className=" cursor-pointer relative  w-full max-w-[350px] md:max-w-[300px] rounded-[16px] h-[280px]  "
                >
                  {e.media_type === "image" ? (
                    <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-full">
                      <img
                        className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
                        src={`${process.env.REACT_APP_URL}/${e.path}`}
                        alt="cover"
                      />
                    </div>
                  ) : (
                    <section className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-full ">
                      <video
                        className="absolute left-0 top-0 h-full w-full rounded-[16px] object-cover"
                        src={`${process.env.REACT_APP_URL}/${e.path}`}
                        // controls
                      />
                      <i className="fa-solid fa-play absolute z-20 bottom-4 right-4 text-3xl text-gray-400 "></i>
                    </section>
                    // <VideoThumbnail videoPath={e.path} />
                  )}
                  {}
                  <div
                    onClick={() => setDetailsId(e)}
                    className=" transition-all ease-in-out duration-500 opacity-0 hover:opacity-100 rounded-[16px] absolute top-0 w-full h-full bg-gradient-to-b from-[#00000000] to-[#000000]/70 flex items-end p-4  "
                  >
                    <section className=" w-full flex items-center gap-3 ">
                      {/* <img
                        alt="img publisher "
                        src={e.user.img}
                        className="h-[40px] w-[40px] rounded-full "
                      />
                      <h1 className=" text-[18px] font-[400] text-[#F5EFDC] ">
                        {e.user.name}
                      </h1> */}
                    </section>
                  </div>
                  <button
                    onClick={() => setDeletId(e.id)}
                    className=" absolute top-3 right-3 w-[40px] h-[40px] rounded-full bg-[#E8DCC0]/30 hover:bg-[#E8DCC0]/60 "
                  >
                    <i className="fa-solid fa-trash text-red-600/80 "></i>
                  </button>
                </section>
              ))}
              {dataShow.length === 0 && (
                <h1 className=" text-center my-4 text-xl ">لا توجد وسائط</h1>
              )}
            </div>
          )}
        </section>
        <section className=" w-full max-w-[390px] md:max-w-[230px] ">
          {!!data && <Pref data={dataShow} />}
        </section>
      </div>
      {deleteId && (
        <DeleteMedia set={setDataShow} id={deleteId} close={setDeletId} />
      )}
      {!!detailsId && (
        <View selectedId={detailsId} setSelectedId={setDetailsId} />
      )}
      {open && <Add close={toggelOpen} set={setDataShow} />}
    </div>
  );
}
