import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import usePostFormData from "../../../utils/usePostForm";
import { load } from "../../../utils/isLoadingStore";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";

export default function EditSuper({ close, data, re }) {
  const validationSchema = Yup.object({
    mail: Yup.string()
      .email("البريد الإلكتروني غير صحيح")
      .required("بريد الالكتروني مطلوب"),
    firstName: Yup.string()
      //   .email("البريد الإلكتروني غير صحيح")
      .required("  الاسم الاول مطلوب"),
    lastName: Yup.string()
      //   .email("البريد الإلكتروني غير صحيح")
      .required("  الاسم الاخير مطلوب"),
  });
  const { postFormData, error, response } = usePostFormData(
    "superAdmin/Profile/update"
  );
  const handleSubmit = async (values) => {
    const formdata = new FormData();
    if (data.mail !== values.mail) {
      formdata.append("email", values.mail);
    }

    formdata.append("first_name", values.firstName);
    formdata.append("last_name", values.lastName);

    await postFormData(formdata);
  };
  useEffect(() => {
    if (response) {
      Cookies.set(
        "name",
        `${response.camel_owner.first_name} ${response.camel_owner.last_name}`,
        {
          expires: 1,
        }
      );
      close();
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            معلومات شخصية
          </h1>
          <button
            onClick={() => close(false)}
            className="text-red-600 font-[600]"
          >
            X
          </button>
        </section>

        <Formik
          initialValues={{
            mail: data.mail,
            firstName: data.firstName,
            lastName: data.lastName,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <>
              <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الاسم الاول
                  </h1>
                  <Field
                    name="firstName"
                    className="inpt"
                    type="text"
                    placeholder="الاسم الاول"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>
                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الاسم الاخير
                  </h1>
                  <Field
                    name="lastName"
                    className="inpt"
                    type="text"
                    placeholder="الاسم الاخير"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>
                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    بريد الالكتروني
                  </h1>
                  <Field
                    name="mail"
                    className="inpt"
                    type="text"
                    placeholder="بريد الالكتروني"
                  />
                  <ErrorMessage
                    name="mail"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>

                <section className="col-span-1 lg:col-span-2 flex justify-end">
                  <button type="submit" className="btn2">
                    حفظ
                  </button>
                </section>
              </Form>
              <>
                {error && (
                  <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                    <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                      <i className="fa-solid fa-exclamation  "></i>
                    </span>
                    {!!error.response.data.message
                      ? error.response.data.message
                      : "  هناك مشكلة"}
                  </section>
                )}
              </>
            </>
          )}
        </Formik>
      </section>
    </div>,
    document.body
  );
}
