import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAsXrtQpF-Y7uw3uh_FF4Cfa713KU40sk0",
  authDomain: "camel-project-14584.firebaseapp.com",
  projectId: "camel-project-14584",
  storageBucket: "camel-project-14584.appspot.com",
  messagingSenderId: "1044088769866",
  appId: "1:1044088769866:web:dcfcc1d42fca8505bcc5b3",
  measurementId: "G-W8EZX57Q25",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(firebaseApp);

export { messaging };
