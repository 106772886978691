import React from "react";
import CopyBtn from "../../../../components/pages/CopyBtn";

export default function Info({ data }) {
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          رقم الهاتف
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.phone}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          البريد الإلكتروني
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.email}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      {data.location_map_link && (
        <section className=" my-4 ">
          <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
            رابط العنوان من الخريطة
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
            <a
              className=" underline "
              href={data.location_map_link}
              target="_blank"
              rel="noreferrer"
            >
              الذهاب للعنوان
            </a>
          </h1>
        </section>
      )}
      <CopyBtn />
    </div>
  );
}
