import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import NewComponent from "../../../components/pages/News/NewComponent";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default function NewDetails() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("news");
  const { data, error } = useFetchGlobal(`global/news/show/${invest}`);

  const shareUrl = window.location.href;
  const title = "";

  const related = useFetchGlobal("global/news/all").data;

  if (error) {
    return <Error />;
  }

  return (
    <div className="p-6 md:p-10 ">
      {data && (
        <div>
          <div className=" flex items-center justify-between  ">
            <section className="   text-[16px] font-[600] ">
              <section className="flex items-center gap-1 my-1 ">
                <Link className="text-[#432315BF] " to="/news">
                  الاخبار
                </Link>
                <h1 className="text-[#432315] ">{`> تفاصيل الخبر `}</h1>
              </section>
              <hr className=" w-24 h-[3px] bg-[black] border-black " />
            </section>
          </div>
          <section className=" my-2 gap-2 flex items-center justify-between ">
            <h1 className=" flex-1 text-[25px] md:text-[32px] font-[700]  text-[#000] ">
              {data.title}
            </h1>
          </section>
          <div className="flex items-center gap-4 my-3">
            <section className=" border border-[#432315] rounded-[5px] text-[#663520] px-[12px] py-[2px] ">
              <i className="fa-solid fa-share-nodes ml-2 "></i>
              مشاركة
            </section>

            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <WhatsappShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          <p className=" w-full max-w-[620px] my-4 text-[#4F4F4F] text-[18px] ">
            {data.bio}
          </p>
          {!!JSON.parse(data.detail_images) && (
            <>
              {JSON.parse(data.detail_images).length > 0 && (
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  autoplay={{
                    delay: 4000, // Time delay in milliseconds
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Autoplay]}
                  className="mySwiper "
                >
                  {JSON.parse(data.detail_images).map((e, index) => (
                    <SwiperSlide className="  " key={index}>
                      <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                        <img
                          className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                          key={e}
                          src={`${process.env.REACT_APP_URL}/${e}`}
                          alt="cover"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </>
          )}
          <div
            className="styled-content rtl leading-loose relative py-4  "
            dangerouslySetInnerHTML={{ __html: data.details }}
            style={{ direction: "rtl", textAlign: "right" }}
          />
        </div>
      )}
      {related && (
        <>
          <h1 className=" my-4 text-[#000] text-[32px] font-[800] font- ">
            اخبار ذات صلة
          </h1>
          <div className=" flex mb-4 gap-3 flex-wrap justify-center ">
            {[...related].slice(0, 4).map((e, inx) => (
              <NewComponent data={e} key={inx} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
