import React, { useState } from "react";

export default function CamelInfo({ next, pre, userInfo, setUserInfo }) {
  const {
    nameCamel,
    ageCamel,
    colorCamel,
    typeCamel,
    idCamel,
    cirtificateCamel,
  } = userInfo;
  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate camel name
    if (!nameCamel) {
      formErrors.nameCamel = "الرجاء إدخال اسم الجمل";
    }

    // Validate camel age
    if (!ageCamel) {
      formErrors.ageCamel = "الرجاء إدخال عمر الجمل";
    }

    // Validate camel color
    if (!colorCamel) {
      formErrors.colorCamel = "الرجاء إدخال لون الجمل";
    }

    // Validate camel type
    if (!typeCamel) {
      formErrors.typeCamel = "الرجاء إدخال نوع الجمل";
    }

    // Validate camel ID
    if (!idCamel) {
      formErrors.idCamel = "الرجاء إدخال معرف الجمل";
    }

    // Validate camel certificate
    if (!cirtificateCamel) {
      formErrors.cirtificateCamel = "الرجاء إدخال شهادة الجمل";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const sendHandeller = (e) => {
    e.preventDefault();
    if (validate()) {
      next();
    }
  };
  return (
    <div className=" mb-3 bg-[#E8DCC0] rounded-[15px] p-4 ">
      <form
        className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  "
        onSubmit={sendHandeller}
      >
        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            اسم الإبل
          </h1>
          <input
            name="nameCamel"
            value={nameCamel}
            onChange={handleChangeInfo}
            className=" inpt "
            type="text"
            placeholder="اسم الإبل"
          />
          <p className=" error ">{errors.nameCamel}</p>
        </section>
        <section className=" col-span-1 relative  ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            العمر
          </h1>
          <input
            name="ageCamel"
            value={ageCamel}
            onChange={handleChangeInfo}
            className=" inpt sumBtn "
            type="number"
            placeholder="العمر "
          />
          <h1 className=" absolute bottom-4 left-4 text-[#432315C7] font-[600] text-[18px] ">
            سنة
          </h1>
          <p className=" error ">{errors.ageCamel}</p>
        </section>

        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            اللون
          </h1>
          <input
            name="colorCamel"
            value={colorCamel}
            onChange={handleChangeInfo}
            className=" inpt "
            type="text"
            placeholder="اللون"
          />
          <p className=" error ">{errors.colorCamel}</p>
        </section>
        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            السلالة/النوع
          </h1>
          <input
            name="typeCamel"
            value={typeCamel}
            onChange={handleChangeInfo}
            className=" inpt "
            type="text"
            placeholder="  السلالة/النوع"
          />
          <p className=" error ">{errors.typeCamel}</p>
        </section>
        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            رقم التعريف/الشريحة الإلكترونية
          </h1>
          <input
            name="idCamel"
            value={idCamel}
            onChange={handleChangeInfo}
            className=" inpt "
            type="text"
            placeholder="رقم التعريف/الشريحة الإلكترونية"
          />
          <p className=" error ">{errors.idCamel}</p>
        </section>
        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            شهادات الملكية/السجلات العرقية
          </h1>
          <input
            name="cirtificateCamel"
            value={cirtificateCamel}
            onChange={handleChangeInfo}
            className=" inpt "
            type="text"
            placeholder="شهادات الملكية/السجلات العرقية"
          />
          <p className=" error ">{errors.cirtificateCamel}</p>
        </section>
        <section className=" col-span-1 lg:col-span-2 flex items-center justify-center gap-2 flex-wrap ">
          <button type="submit" className=" btn3 ">
            التالي
          </button>
          {/* <button onClick={pre} className=" btn4 ">
            العوده
          </button> */}
        </section>
      </form>
    </div>
  );
}
