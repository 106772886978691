import React, { useState } from "react";
import TabJoin from "../../../../../components/pages/TabOfJoins";
import Pending from "./Pending";
import Accepted from "./Accepted";
import Rejected from "./Rejected";
import SquersDashboard from "../../../pages/squer/SquersDashboard";
import Reports from "./Reports";

export default function FieldDashSuper() {
  const [tab, setTab] = useState(1);
  const tabs = [
    "الساحات المعلقة",
    "الساحات المقبولة",
    "الساحات المرفوضة",
    "تقارير الساحات",
    "ساحاتي",
  ];
  return (
    <div>
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <TabJoin
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>
        {tab === 1 && <Pending />}
        {tab === 2 && <Accepted />}
        {tab === 3 && <Rejected />}
        {tab === 4 && <Reports />}
        {tab === 5 && <SquersDashboard />}
      </div>
    </div>
  );
}
