import React, { useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import { Link } from "react-router-dom";
import Accept from "./Accept";
import Reject from "./Reject";

export default function Pending() {
  let link = "superAdmin/field/setting/pending/index";
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(link, reload);
  const re = () => {
    setReload((pre) => !pre);
  };

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" p-6 ">
      <h1 className=" text-center my-2 text-lg font-bold ">الطلبات المعلقة</h1>
      {data && (
        <table>
          <thead>
            <tr>
              <th>اسم الساحة</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, inx) => (
              <tr key={inx}>
                <td className="text-center">
                  <Link
                    className=" underline "
                    to={`/pendingFields?filed=${e.id}`}
                  >
                    {" "}
                    {e.name}
                  </Link>
                </td>
                <td className=" flex items-center justify-center gap-2 ">
                  <Accept id={e.id} re={re} />
                  <Reject id={e.id} re={re} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <h1 className=" text-center my-4 text-red-600 text-lg ">
              لا توجد ساحات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
