import React from "react";
import { Link } from "react-router-dom";

export default function MobileBlogComponent({ data }) {

  function TruncatedParagraph({ text }) {
    // Split the text into an array of words
    const words = text.split(" ");

    // Check if the number of words exceeds maxWords
    const shouldTruncate = words.length > 20;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? words.slice(0, 20).join(" ") + "...."
      : text;

    return truncatedText;
  }
  return (
    <div className=" my-3 w-[310px] md:w-[400px] border border-[#7F7F7F] p-[10px] rounded-[16px] ">
      <section className=" flex items-center justify-center relative w-full h-[195px] rounded-[16px] ">
        <img
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt={data.name}
          className="w-full h-[195px] rounded-[16px]"
        />
        <section className=" absolute bottom-[-14px] px-[8px] py-[4px] bg-[#F6F7F6] rounded-[8px] flex items-center gap-2 text-[14px]  ">
          <h4 className=" text-[#828282] text-[12px] ">
            {data.created_at.substring(0, 10)}
          </h4>
        </section>
      </section>
      <section className=" mt-8 ">
        <h1 className=" text-[25px] text-[#000] font-[800] leading-[49px] ">
          {data.title}
        </h1>
        <p className=" text-[16px] font-[600] leading-[28px] ">
          <TruncatedParagraph text={data.bio} />
        </p>
        
        <Link to={`/blog-details?blog=${data.id}`}>
          <button className="btn mt-2">اعرف اكثر</button>
        </Link>
      </section>
    </div>
  );
}
