import React from "react";

export default function Pref({ data }) {
  return (
    <div className="pb-4 border text-[#333333] border-[#43231580] rounded-[16px] p-[16px]">
      <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] ">عدد الاستثمار</h1>
        <h4 className="text-[18px] font-[400] ">{data.length} </h4>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] "> عدد الاستثمار في المنصة</h1>
        <h4 className="text-[18px] font-[400] ">{data.investInPlatform} </h4>
      </section>
      <section className=" my-4 ">
        <h1 className=" my-2 text-[20px] font-[600] "> عدد نقرات التواصل معك</h1>
        <h4 className="text-[18px] font-[400] ">{data.contact} </h4>
      </section> */}
    </div>
  );
}
