import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isLogged } from "../../../utils/inStore";
import usePostFormData from "../../../utils/usePostForm";

export default function Password() {
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required("كلمة السر الجديدة مطلوبة")
      .min(8, "كلمة السر الجديدة يجب أن تكون 8 أحرف على الأقل"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "كلمة السر غير متطابقة")
      .required("تأكيد كلمة السر مطلوب"),
  });
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/settings/update-password";
  }
  if (role === "provider") {
    link = "serviceProvider/settings/update-password";
  }
  if (role === "vistor") {
    link = "visitor/settings/update-password";
  }
  const { postFormData, error, response } = usePostFormData(link);

  const handleSubmit = async (values) => {
    const formdata = new FormData();
    formdata.append("password", values.newPassword);
    formdata.append("password_confirmation", values.confirmNewPassword);
    await postFormData(formdata);
  };

  return (
    <div className="bg-[#E8DCC0] p-[16px] rounded-[16px] my-5 border border-[#A59382]">
      <section className="py-3 flex items-center justify-between border-b border-[#A59382]">
        <h1 className="text-[24px] font-[700] text-[#432315]">
          تغيير كلمة السر
        </h1>
      </section>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="grid my-3 grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
            <section className="col-span-1">
              <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                كلمة السر الجديدة
              </h1>
              <Field
                name="newPassword"
                className="inpt"
                type="password"
                placeholder="كلمة السر الجديدة"
              />
              <ErrorMessage
                name="newPassword"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </section>
            <section className="col-span-1">
              <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                تأكيد كلمة السر
              </h1>
              <Field
                name="confirmNewPassword"
                className="inpt"
                type="password"
                placeholder="تأكيد كلمة السر"
              />
              <ErrorMessage
                name="confirmNewPassword"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </section>
            <section className="col-span-1 lg:col-span-2 flex justify-end">
              <button type="submit" className="btn2">
                حفظ
              </button>
            </section>
            {error && (
              <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                  <i className="fa-solid fa-exclamation  "></i>
                </span>
                {!!error.response.data.message
                  ? error.response.data.message
                  : "  هناك مشكلة"}
              </section>
            )}
            {response && (
              <section className=" flex my-3 w-full p-3 text-lg text-white bg-green-500 rounded-md ">
                <span className=" mx-3 bg-white h-[30px] w-[30px] text-green-500 rounded-full flex items-center justify-center ">
                  <i className="fa-solid fa-check"></i>
                </span>
                تم تغيير كلمة السر بنجاح
              </section>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
