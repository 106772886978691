import React, { useContext, useState } from "react";
// import HeadCover from "../../components/pages/HeadCover";
import View from "./View";
import { useLocation } from "react-router";
import { isLogged } from "../../utils/inStore";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import useFetchGlobal from "../../utils/useGetGlobal";
import NewOver from "../../components/pages/NewOver";

export default function Media() {
  const { logged, role } = useContext(isLogged);
  const serverType = Cookies.get("type");
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("item");
  const roleOfOwner = new URLSearchParams(search).get("owner");
  const [selectedId, setSelectedId] = useState(id);
  const [ownerType, setOwnerType] = useState(roleOfOwner);
  const { data, error } = useFetchGlobal("global/media/all");

  const getOwnerType = (item) => {
    let types = {
      field_owner_id: "field_owner",
      visitor_id: "visitor",
      camel_owner_id: "camel_owner",
      service_provider_id: "service_provider",
      super_admin_id: "super_admin",
    };

    for (let key in item) {
      if (types.hasOwnProperty(key)) {
        return types[key];
      }
    }
  };

  // console.log(getOwnerType(test));
  return (
    <div>
      {/* <HeadCover
        img="./imgs/cover2 (2).png"
        title="الصور و الفيديوهات"
        desc="معرض رقمي يتيح للمستخدمين مشاركة ومشاهدة المحتوى المرئي المتعلق بالإبل، بما في ذلك تحميل الصور والفيديوهات الخاصة بهم"
      /> */}
      <NewOver img="./imgs/media.png" title="الوسائط" />
      <div className="p-6 md:px-10 flex justify-end">
        <section className="py-3 flex justify-end">
          {!logged ? (
            <></>
          ) : (
            <>
              {role === "provider" && (
                <>
                  {serverType === "veterinary" && (
                    <Link to={"/dashboard?type=7"} className="btn">
                      <i className="fa-solid fa-circle-plus mx-2 "></i>
                      اضافة وسائط
                    </Link>
                  )}
                  {serverType === "expert" && (
                    <Link to={"/dashboard?type=6"} className="btn">
                      <i className="fa-solid fa-circle-plus mx-2 "></i>
                      اضافة وسائط
                    </Link>
                  )}
                  {serverType === "freelancer" && (
                    <Link to={"/dashboard?type=5"} className="btn">
                      <i className="fa-solid fa-circle-plus mx-2 "></i>
                      اضافة وسائط
                    </Link>
                  )}
                </>
              )}
              {role === "squer" && (
                <Link to={"/dashboard?type=8"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  اضافة وسائط
                </Link>
              )}
              {role === "vistor" && (
                <Link to={"/dashboard?type=3"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  اضافة وسائط
                </Link>
              )}
              {role === "camel-owner" && (
                <Link to={"/dashboard?type=6"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  اضافة وسائط
                </Link>
              )}
              {role === "super_admin" && (
                <Link to={"/dashboard?type=8"} className="btn">
                  ادارة الوسائط
                </Link>
              )}
            </>
          )}
        </section>
      </div>
      {error && (
        <h1 className=" text-center text-2xl text-red-500 my-3 ">هناك مشكلة</h1>
      )}
      {data && data.media && (
        <div className=" p-5 md:p-8  flex items-center justify-center gap-3 flex-wrap ">
          {/* Img */}
          {data.media.map((e, inx) => (
            <section
              onClick={() => {
                setSelectedId(e.id);
                setOwnerType(getOwnerType(e));
              }}
              key={inx}
              className=" cursor-pointer relative  w-full max-w-[350px] md:max-w-[300px] rounded-[16px] h-[280px]  "
            >
              <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-full">
                {e.media_type === "image" ? (
                  <img
                    className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
                    src={`${process.env.REACT_APP_URL}/${e.media_path}`}
                    alt="cover"
                  />
                ) : (
                  <section className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-full ">
                    <video
                      className="absolute left-0 top-0 h-full w-full rounded-[16px] object-cover"
                      src={`${process.env.REACT_APP_URL}/${e.media_path}`}
                      // controls
                    />
                    <i className="fa-solid fa-play absolute z-20 bottom-4 right-4 text-3xl text-gray-400 "></i>
                  </section>
                )}
              </div>
              <div className=" transition-all ease-in-out duration-500 opacity-0 hover:opacity-100 rounded-[16px] absolute top-0 w-full h-full bg-gradient-to-b from-[#00000000] to-[#000000]/70 flex items-end p-4  ">
                {/* <section className=" w-full flex items-center gap-3 ">
                  <img
                    alt="img publisher "
                    src={e.user.img}
                    className="h-[40px] w-[40px] rounded-full "
                  />
                  <h1 className=" text-[18px] font-[400] text-[#F5EFDC] ">
                    {e.user.name}
                  </h1>
                </section> */}
              </div>
            </section>
          ))}
        </div>
      )}
      {!!selectedId && (
        <View
          selectedId={selectedId}
          ownerType={ownerType}
          setSelectedId={setSelectedId}
        />
      )}
    </div>
  );
}
