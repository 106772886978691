/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Img from "./owner/Img";
import useFetchWithReload from "../../utils/useGetWithReload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Owner() {
  let link = "global/platform_owner/show";
  const { data } = useFetchWithReload(link);
  return (
    <>
      {data && (
        <div className=" lg:flex gap-4 flex-row-reverse justify-between  background bg-[#F5EFDC] pt-8 ">
          <section className="  flex justify-around lg:grid grid-cols-1 my-auto p-12 text-center   ">
            {data.platform_owner.facebook && (
              <a
                className=" text-4xl font-bold text-[#432315] md:m-4  "
                href={data.platform_owner.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            )}
            {data.platform_owner.twitter && (
              <a
                className=" text-4xl font-bold text-[#432315] md:m-4  "
                href={data.platform_owner.twitter}
                target="_blank"
                rel="noreferrer"
              >
                
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            )}
            {data.platform_owner.instagram && (
              <a
                className=" text-4xl font-bold text-[#432315] md:m-4  "
                href={data.platform_owner.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
            )}
            {data.platform_owner.whatsapp && (
              <a
                className=" text-4xl font-bold text-[#432315] md:m-4  "
                href={data.platform_owner.whatsapp}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </a>
            )}
          </section>
          <section className=" z-10 relative text-[#290900] owner-regular font-[800] ">
            <h1 className="  text-[40px] my-2 text-center ">
              {data.platform_owner.position}
            </h1>
            {/* <svg
              className=" mx-auto "
              width="261"
              height="28"
              viewBox="0 0 261 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 25C19.8216 0.187929 108.576 20.7859 130.5 3C152.424 20.7939 241.164 0.187929 258 25"
                stroke="#C89150"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
            <h2 className="  text-[55px] my-10 text-center ">
              {data.platform_owner.name}
            </h2>
            {/* <svg
              className=" mx-auto "
              width="261"
              height="28"
              viewBox="0 0 261 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M258 3C241.178 27.8121 152.424 7.21414 130.5 25C108.576 7.20613 19.8363 27.8121 3 3"
                stroke="#C89150"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
            <h2 className=" px-1 text-[31px] mx-auto my-6 text-center max-w-[600px] ">
              {data.platform_owner.description}
            </h2>
          </section>
          <section className=" w-full max-w-[400px] ">
            <Img photo={data.platform_owner.photo} />
          </section>
        </div>
      )}
    </>
  );
}
