import React, { useCallback, useContext, useState } from "react";
import CopyBtn from "../../../components/pages/CopyBtn";
import { Link, useLocation } from "react-router-dom";
import Notegister from "../../../components/Notegister";
import { isLogged } from "../../../utils/inStore";

export default function Info({ data, join }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("race");
  const role = new URLSearchParams(search).get("role");

  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);
  const currentDate = new Date();
  const { logged } = useContext(isLogged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {!join ? (
        <section className=" text-center ">
          <>
            {new Date(data.end_date) > currentDate && (
              <>
                {logged ? (
                  <Link to={`/join-race?race=${invest}&role=${role}`}>
                    <button className="btn2">انضم للسباق</button>
                  </Link>
                ) : (
                  <button onClick={toggleRegisterMessage} className="btn2">
                    انضم للسباق
                  </button>
                )}
              </>
            )}
          </>
        </section>
      ) : (
        <></>
      )}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          مسئول الساحة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.organizer}
        </h1>
      </section>
      <section className=" my-4 grid grid-cols-2 items-start justify-between gap-1  ">
        <section className="col-span-1">
          <h1 className=" text-[#290900] font-[600] text-[18px] leading-[38px] ">
            البداية
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[15px] leading-[28px] ">
            {data.start_date}
          </h1>
        </section>
        <section className="col-span-1">
          <h1 className=" text-[#290900] font-[600] text-[18px] leading-[38px] ">
            النهاية
          </h1>
          <h1 className=" text-[#290900] font-[500] text-[15px] leading-[28px] ">
            {data.end_date}
          </h1>
        </section>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          عدد الاشواط
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.rounds_number} شوط
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          الفترة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.period}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          المسافة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.distance} متر
        </h1>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          ساحة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.squre}
        </h1>
      </section> */}

      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <CopyBtn />
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لحجز مكانك في السباق"
        />
      )}
    </div>
  );
}
