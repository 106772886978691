import React, { useState } from "react";
import TabJoin from "../../../../../../components/pages/TabOfJoins";
import PackagesDahBoardSuper from "../PackagesDahBoardSuper";
import PackagsReprts from "../reports/PackagsReprts";
import ManuallyAdd from "../Manuallyadd";
import HoledPackages from "../HoldedPackages";

export default function MainPackageDash() {
  const [tab, setTab] = useState(1);
  const tabs = [
    "الباقات",
    "الباقات المعلقة",
    "تقارير الباقات",
    "التفعيل اليدوي",
  ];
  return (
    <div>
      <div>
        <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
          {tabs.map((e, inx) => (
            <TabJoin
              val={inx + 1}
              key={inx}
              text={e}
              tab={tab}
              setTabState={setTab}
            />
          ))}
        </section>
        <div>{tab === 1 && <PackagesDahBoardSuper />}</div>
        <div>{tab === 2 && <HoledPackages />}</div>
        <div>{tab === 3 && <PackagsReprts />}</div>
        <div>{tab === 4 && <ManuallyAdd />}</div>
      </div>
    </div>
  );
}
