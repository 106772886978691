import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { isLogged } from "../../../../utils/inStore";
import useFileUpload from "../../../../utils/useUploade";

export default function Add({ close, set }) {
  const [file, setFile] = useState();
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/media/upload_media";
  }
  if (role === "vistor") {
    link = "visitor/media/upload_media";
  }
  if (role === "camel-owner") {
    link = "camelOwner/media/upload_media";
  }
  if (role === "provider") {
    link = "serviceProvider/media/upload_media";
  }
  if (role === "super_admin") {
    link = "superAdmin/media/upload_media";
  }

  const { uploadFile, error, response, progress, isUploading } =
    useFileUpload(link);

  const handleSubmit = async () => {
    const formdata = new FormData();
    formdata.append("media_files[]", file);
    await uploadFile(formdata);
    // console.log(file);
  };
  useEffect(() => {
    if (response) {
      close(null);
      set((pre) => [...pre, response.media_files[0]]);
      //   console.log(response.media_files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        {file ? (
          <>
            <section className=" flex items-center gap-2 justify-center my-3 text-xl   ">
              <h1 className=" max-w-[60%] overflow-hidden font-bold text-center ">
                {file.name}
              </h1>
              {!isUploading && (
                <button className="text-red-600" onClick={() => setFile(null)}>
                  <i className="fa-solid fa-trash"></i>
                </button>
              )}
            </section>
          </>
        ) : (
          <>
            <label className=" w-full  rounded-[15px]  " htmlFor="imgs">
              <section className=" w-full bg-[#432315] border-[#432315]  border rounded-[15px] p-[20px] ">
                <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                <h1 className="text-[#f2f2f2]">رفع صورة او فيديو</h1>
              </section>
            </label>
            <input
              type="file"
              id="imgs"
              accept="image/*,video/*"
              onChange={(e) => {
                if (!!e.target.files[0]) {
                  setFile(e.target.files[0]);
                }
              }}
              style={{ display: "none" }}
            />
          </>
        )}
        {isUploading ? (
          <div>
            <h1 className=" text-center my-1 text-[18px] ">
              جاري التحميل ....
            </h1>
            <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-[#432315]/80 h-2 rounded-full transition-all ease-in-out duration-300 "
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        ) : (
          <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
            <button
              disabled={!file}
              onClick={handleSubmit}
              className=" disabled:opacity-65 btn9"
            >
              اضافة
            </button>
            <button onClick={() => close(false)} className="btn8">
              الغاء
            </button>
          </section>
        )}
        {/* <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button
            disabled={!file}
            onClick={handleSubmit}
            className=" disabled:opacity-65 btn9"
          >
            اضافة
          </button>
          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section> */}

        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error
                      ? error.response.data.error
                      : "  هناك مشكلة"}
                  </>
                ) : (
                  "  هناك مشكلة"
                )}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
        {response && <p>done</p>}
      </section>
    </div>,
    document.body
  );
}
