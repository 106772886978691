import React from "react";
import useFetchGlobal from "../../utils/useGetGlobal";
import Hr from "../../components/pages/Hr";
import CamelComponent from "../../components/pages/OwnerProfile/CamelComponent";

export default function AllCamels() {
  const { data } = useFetchGlobal("global/CamelOwners/allCamelCard");
  return (
    <div>
      <section className=" p-4 md:p-12  text-center ">
        <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
          كروت الابل
        </h1>
        <Hr color="#432315" />
      </section>
      {!!data && (
        <div className=" flex items-start justify-center gap-12 flex-wrap p-6 md:p-14">
          {data["camel_cards"].map((e) => (
            <CamelComponent key={e.id} data={e} />
          ))}
        </div>
      )}
    </div>
  );
}
