import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import EditCamelComponent from "./EditCamelComponent";
import AddCamelCard from "./Add";
import EditCard from "./EditCard";
import DeleteCard from "./DeleteCard";

export default function CamelCardsDash() {
  // const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload("superAdmin/camelCards/all");

  const [showData, setShowData] = useState([]);
  useEffect(() => {
    if (!!data) {
      if (!!data.camel_cards) {
        setShowData(data.camel_cards);
      }
    }
  }, [data]);

  const [openAdd, setOpenAdd] = useState(false);
  const [editId, setEditCards] = useState(null);
  const [deleteCard, setDeleteCard] = useState(null);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة كارت
        </button>
      </div>
      {data && (
        <>
          {data.camel_cards ? (
            <section className=" mt-8 flex gap-8  items-start justify-center   flex-wrap  ">
              {showData.map((camel, inx) => (
                <EditCamelComponent
                  key={inx}
                  setDelete={setDeleteCard}
                  setEdit={setEditCards}
                  data={camel}
                />
                // <CamelComponent key={inx} data={camel} />
              ))}
            </section>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا يوجد كروت حتي الان
            </h1>
          )}
        </>
      )}
      {openAdd && <AddCamelCard set={setShowData} close={setOpenAdd} />}
      {editId && (
        <EditCard set={setShowData} id={editId} close={setEditCards} />
      )}
      {deleteCard && (
        <DeleteCard
          set={setShowData}
          id={deleteCard}
          close={setDeleteCard}
        />
      )}
    </div>
  );
}
