import React, { useCallback, useContext, useEffect, useState } from "react";
// import { useLocation } from "react-router";
import { Link, useLocation } from "react-router-dom";
import CopyBtn from "../../../components/pages/CopyBtn";
import Rating from "@mui/material/Rating";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";
// import usePostWithToken from "../../../utils/usePostJson";
import usePostWithReturnLoading from "../../../utils/usePostWithLoading";

export default function Info({ join, data }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("product");
  const owner = new URLSearchParams(search).get("role");
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);
  const { logged } = useContext(isLogged);
  const { postData, response, error, loading } = usePostWithReturnLoading(
    `global/store/card/add-to-card`
  );
  const addToCartHandeller = () => {
    const data = {
      product_id: invest,
      owner_type: owner,
    };
    postData(data);
  };
  const [err, setErr] = useState(null);
  const [suc, setSuc] = useState(null);
  useEffect(() => {
    if (error) {
      setSuc(null);
      setErr(
        error.response.data.message
          ? error.response.data.message
          : "هناك مشكلة في الاضافة"
      );
      setTimeout(() => {
        setErr(null);
      }, [4000]);
    }
    if (response) {
      setSuc(true);
      setErr(null);
      setTimeout(() => {
        setSuc(null);
      }, [4000]);
    }
  }, [response, error]);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {Number(data.quantity) > 0 ? (
        <>
          {!join ? (
            <section className=" text-center ">
              {logged ? (
                <>
                  <Link to={`/order?product=${invest}&role=${owner}`}>
                    <button className="btn2">شراء المنتج</button>
                  </Link>
                  <button onClick={addToCartHandeller} className="btn2 my-2">
                    {loading ? "جاري الاضافة...." : "اضف الي العربة"}
                  </button>
                </>
              ) : (
                <button onClick={toggleRegisterMessage} className="btn2">
                  شراء المنتج
                </button>
              )}
              {/* {err && (
                <section className=" flex items-center gap-2 bg-red-800 px-2 md:px-8 w-fit max-w-full py-2 rounded-lg text-white ">
                  <i className="fa-solid fa-exclamation"></i> {err}
                </section>
              )} */}
            </section>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <section className=" px-2 border text-red-500 rounded-full w-fit mx-auto text-lg border-red-500 ">
            غير متوفر
          </section>
        </>
      )}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          السعر
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.price} ر.س
        </h1>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          وصول المنتج في خلال
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.ship} ايام
        </h1>
      </section> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          التقيم
        </h1>

        <section className=" ltr flex justify-end ">
          <Rating
            name="read-only"
            value={+data.general_rate}
            precision={0.5}
            readOnly
          />
        </section>
      </section>

      <CopyBtn />
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل حتي تتمكن من الشراء"
        />
      )}
      {err && (
        <div className=" fixed bottom-4 left-0 w-full flex items-center justify-center ">
          <section className=" flex items-center gap-2 bg-red-800 px-2 md:px-8 w-fit max-w-full py-2 rounded-lg text-white ">
            <i className="fa-solid fa-exclamation"></i> {err}
          </section>
        </div>
      )}
      {suc && (
        <div className=" fixed bottom-4 left-0 w-full flex items-center justify-center ">
          <section className=" flex items-center gap-2 bg-green-800 px-2 md:px-8 w-fit max-w-full py-2 rounded-lg text-white ">
            <i className="fa-solid fa-check"></i>
            تم الاضافة الي العربة
          </section>
        </div>
      )}
    </div>
  );
}
