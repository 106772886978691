import React, { useContext, useEffect, useState } from "react";
import Pref from "./Pref";
import Squers from "./Squers";
import DeleteSquer from "../../components/squesrs/Delete";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import AddField from "../../components/squesrs/Add";
import EditField from "../../components/squesrs/EditField";
import Reports from "./Reports";

export default function SquersDashboard() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/field/index";
  }
  if (role === "super_admin") {
    link = "superAdmin/field/index";
  }
  const { data, error } = useFetchWithToken(link);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filtered = [];

    if (!!data) {
      filtered = data;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);
  const [deleteId, setDeletId] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [editId, setEditId] = useState();
  const [reports, setReports] = useState(false);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <button onClick={() => setReports((pre) => !pre)} className=" btn ">
          عرض التقارير <i className="fa-solid fa-sheet-plastic"></i>
        </button>
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة ساحة
        </button>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        <section className="flex-1">
          {!!data && (
            <>
              {reports ? (
                <Reports back={() => setReports(false)} />
              ) : (
                <>
                  <Squers
                    setEditId={setEditId}
                    setDeleteId={setDeletId}
                    data={[...filteredData].reverse()}
                  />
                  {filteredData.length === 0 && (
                    <h1 className=" text-center my-4 text-xl ">
                      لا توجد ساحات
                    </h1>
                  )}
                </>
              )}
            </>
          )}
        </section>
        <section className=" w-full max-w-[390px] md:max-w-[230px] ">
          {!!data && <Pref data={data} />}
        </section>
      </div>
      {deleteId && (
        <DeleteSquer set={setFilteredData} id={deleteId} close={setDeletId} />
      )}

      {openAdd && <AddField close={setOpenAdd} />}
      {editId && (
        <EditField set={setFilteredData} id={editId} close={setEditId} />
      )}
    </div>
  );
}
