import React, { useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import { Link } from "react-router-dom";
import Delete from "./Delete";

export default function Mails() {
  let link = "superAdmin/contact/all_message";
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(link, reload);
  const re = () => {
    setReload((pre) => !pre);
  };
  const [deleteId, setDeleteId] = useState(null);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold ">البريد الوارد </h1>
      {data && (
        <table>
          <thead>
            <tr>
              <th>الاسم</th>
              <th>الموضوع</th>
              <th>عرض</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {data.map((mail) => (
              <tr key={mail.id}>
                <td className=" text-center ">{mail.name}</td>
                <td className=" text-center ">{mail.subject}</td>
                <td className=" text-center ">
                  <Link to={`/show-mail?mail=${mail.id}`}>
                    <i className="fa-solid fa-eye text-2xl text-green-800 "></i>
                  </Link>
                </td>
                <td className=" text-center ">
                  <button onClick={() => setDeleteId(mail.id)}>
                    <i className="fa-solid fa-trash text-2xl text-red-800"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {deleteId && <Delete set={re} id={deleteId} close={setDeleteId} />}
    </div>
  );
}
