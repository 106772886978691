import React, { useEffect, useState } from "react";
import usePostWithToken from "../../../../../utils/usePostJson";
import NewSucces from "../../../../../components/pages/NewSucces";
// import NewSucces from "../../../../../components/pages/NewSucces";

export default function JoinBtn({ id, isFree }) {
  const { postData, response, error } = usePostWithToken(
    `global/packages/subscribe`
  );
  const handelJoin = () => {
    const data = {
      package_id: id,
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=my-package&type=package&value=${id}&role=package`,
    };
    postData(data);
  };
  const [suc, setSuc] = useState(false);
  useEffect(() => {
    if (response) {
      // console.log(response);
      if (isFree) {
        setSuc(true);
      } else {
        window.location.href = response.redirect_url;
      }
    }
  }, [response, isFree]);
  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        الاشتراك في الباقة
      </button>

      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message
            ? error.response.data.message
            : "هناك مشكلة"}
        </section>
      )}
      {suc && <NewSucces />}
    </div>
  );
}
