import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";

export default function Attend({ back, id }) {
  let link = "global/Report/store/seller/detailed-report";
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && data.report && (
        <div>
          {data.report.products.length > 0 ? (
            <>
              {data.report.products.map((item, inx) => (
                <div className=" my-6 border border-black/80 p-4 rounded-xl " key={inx}>
                  <table>
                    <thead>
                      <tr className="  grid-cols-4 ">
                        <th>اسم المنتج</th>
                        <th>كمية المنتج</th>
                        <th>السعر</th>
                        <th>المبيعات</th>
                        <th>الارباح</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">{item.product_name}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-center">
                          {item.price_per_unit} ر.س
                        </td>
                        <td className="text-center"> {item.sold_quantity}</td>
                        <td className="text-center">{item.sold_price} ر.س</td>
                      </tr>
                    </tbody>
                  </table>
                  <h1 className=" text-center my-2 text-lg  ">المشتريين </h1>
                  <table>
                    <thead>
                      <tr>
                        <th>الاسم</th>
                        <th>البريد الاكتروني</th>
                        <th>رقم الهاتف</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.customers.map((user, ind) => (
                        <tr key={ind}>
                          <td className=" text-center ">{user.name}</td>
                          <td className=" text-center ">{user.email}</td>
                          <td className=" text-center ">{user.phone}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا توجد منتاجات
            </h1>
          )}
        </div>
      )}
      {/* {data && (
        <section>
          <table>
            <thead>
              <tr className="  grid-cols-4 ">
                <th>اسم المنتج</th>
                <th>كمية المنتج</th>
                <th>السعر</th>
                <th>المبيعات</th>
                <th>الارباح</th>
              </tr>
            </thead>
            <tbody>
              {data.report.products.map((e, inx) => (
                <tr className="  grid-cols-4 text-center " key={inx}>
                  <td>{e.product_name}</td>
                  <td>{e.quantity}</td>
                  <td>{e.price_per_unit} ر.س</td>
                  <td>{e.sold_quantity}</td>
                  <td>{e.sold_price} ر.س</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )} */}
    </div>
  );
}
