import React from "react";

export default function Top({ num }) {
  const Done = () => {
    return (
      <section className=" z-10 relative mx-auto w-[50px] h-[50px] rounded-full border-[2px] border-[#432315] bg-[#432315] text-white flex items-center justify-center ">
        <i className="fa-solid fa-check text-2xl "></i>
      </section>
    );
  };
  const Progress = () => {
    return (
      <section className=" z-10 relative mx-auto w-[50px] h-[50px] rounded-full border-[2px] border-[#E8D6B2] bg-[#F5EFDC] text-white flex items-center justify-center ">
        <section className=" bg-[#E8D6B2] rounded-full h-[18px] w-[18px] "></section>
      </section>
    );
  };
  const Still = () => {
    return (
      <section className=" z-10 relative mx-auto w-[50px] h-[50px] rounded-full border-[2px] border-[#E8D6B2] bg-[#F5EFDC] text-white flex items-center justify-center "></section>
    );
  };

  return (
    <>
      <div className="my-3 md:mx-16 relative  grid grid-cols-3 ">
        <section className=" relative col-span-1 ">
          <hr className=" z-0 bg-[#432315] border-[#432315] h-[4px] w-1/2 absolute top-[23px] left-0  " />
          {num < 1 && <Still />}
          {num === 1 && <Progress />}
          {num > 1 && <Done />}
          <h1 className=" text-center text-[16px] font-[400] text-[#432315] ">
            معلومات الإبل
          </h1>
        </section>
        {/* <section className=" relative   col-span-1 ">
          <hr className=" z-0 bg-[#432315] border-[#432315] h-[4px] w-full absolute top-[23px]   " />
          {num < 1 && <Still />}
          {num === 1 && <Progress />}
          {num > 1 && <Done />}
          <h1 className=" text-center text-[16px] font-[400] text-[#432315] ">
            معلومات الإبل
          </h1>
        </section> */}
        <section className=" relative   col-span-1 ">
          <hr className=" z-0 bg-[#432315] border-[#432315] h-[4px] w-full absolute top-[23px]   " />
          {num < 2 && <Still />}
          {num === 2 && <Progress />}
          {num > 2 && <Done />}
          <h1 className=" text-center text-[16px] font-[400] text-[#432315] ">
            فئة السباق المراد المشاركة
          </h1>
        </section>
        <section className=" relative   col-span-1 ">
          <hr className=" z-0 bg-[#432315] border-[#432315] h-[4px] w-1/2 absolute top-[23px]   " />
          {num < 3 && <Still />}
          {num === 3 && <Progress />}
          {num > 3 && <Done />}
          <h1 className=" text-center text-[16px] font-[400] text-[#432315] ">
            رسوم المشاركة
          </h1>
        </section>
      </div>
    </>
  );
}
