import React, { useCallback, useContext, useEffect, useState } from "react";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";
import AllStores from "../AllStores";
import Filter from "./Filter";
import { Link } from "react-router-dom";

export default function Store({ data }) {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "newest",
  });
  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);

  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const toggleFilterOpen = useCallback(() => {
    setFilterOpen((prev) => !prev);
    setSortOpen(false);
  }, []);
  const [checkedLocations, setCheckedLocations] = useState([
    "riyhad",
    "jada",
    "uae",
    "qutar",
    "bahreen",
  ]);
  const [checkedTypes, setCheckedTypes] = useState([
    "camels",
    "medicens",
    "travling",
    "accseceories",
    "insurance",
  ]);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filtered = data.filter((item) => checkedTypes.includes(item.type));

    filtered = filtered.filter((item) => checkedLocations.includes(item.city));

    if (mainFilter.search) {
      filtered = filtered.filter((item) =>
        item.name.includes(mainFilter.search)
      );
    }
    if (mainFilter.sort === "oldest") {
      filtered = filtered.reverse();
    }

    setFilteredData(filtered);
    // eslint-disable-next-line no-sparse-arrays
  }, [checkedLocations, checkedTypes, mainFilter, data]);
  return (
    <div className="p-6 md:py-20 md:px-12">
      <div className="flex items-center justify-between flex-wrap md:gap-2">
        {/* Search */}
        <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
          <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
          <h1 className="hidden md:flex">{filteredData.length} منتج</h1>
        </section>

        {/* Sort */}
        <section className="hidden md:flex items-center gap-2">
          <h1 className="text-[15px] font-[500]">ترتيب بواسطة:</h1>
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
          >
            <option value="newest">من الأحدث الي الأقدم</option>
            <option value="oldest">من الأقدم الي الأحدث</option>
          </select>
        </section>

        {/* Mobile Filter & Sort */}
        <section className="md:hidden flex gap-2">
          <button
            onClick={toggleFilterOpen}
            className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
          >
            <i className="fa-solid fa-filter ml-1"></i> فلتر
          </button>
          <button
            onClick={toggleSortOpen}
            className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
          >
            <i className="fa-solid fa-filter ml-1"></i> ترتيب
          </button>
        </section>

        {/* Create Investment */}
        <section className="py-3 flex justify-end">
          {!logged ? (
            <>
              <button
                className="btn hidden md:flex"
                onClick={toggleRegisterMessage}
              >
                انشاء متجر خاص بك
              </button>
              <button className="btn md:hidden" onClick={toggleRegisterMessage}>
                انشاء متجر
              </button>
            </>
          ) : (
            <>
              {role === "camel-owner" && (
                <Link to={"/dashboard?type=2"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  انشاء متجر
                </Link>
              )}
              {role === "squer" && (
                <Link to={"/dashboard?type=6"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  انشاء متجر
                </Link>
              )}
            </>
          )}
        </section>
      </div>
      {isSortOpen && (
        <select
          onChange={handleMainFilterChange}
          value={mainFilter.sort}
          name="sort"
          className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
        >
          <option value="newest">من الأحدث الي الأقدم</option>
          <option value="oldest">من الأقدم الي الأحدث</option>
        </select>
      )}

      {isFilterOpen && (
        <Filter
          filter={checkedTypes}
          setCheckedState={setCheckedTypes}
          locationOptions={checkedLocations}
          setLocationsOptions={setCheckedLocations}
        />
      )}
      <div className="flex items-start gap-4 mt-10 mb-3">
        <section className="hidden md:grid w-[210px]">
          <Filter
            filter={checkedTypes}
            setCheckedState={setCheckedTypes}
            locationOptions={checkedLocations}
            setLocationsOptions={setCheckedLocations}
          />
        </section>
        <section className="flex-1">
          <AllStores alldata={filteredData} />
        </section>
      </div>

      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مكانك في المتجر"
        />
      )}
    </div>
  );
}
