/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../components/pages/Tab";
import Owner from "./Owner";
import Details from "./Details";
import Secudels from "./Secudels";
import useFetchGlobal from "../../utils/useGetGlobal";
import Error from "../../components/Error/Error";
// import OtherEvents from "./OtherEvents";

export default function EventDetails() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("event");
  const role = new URLSearchParams(search).get("role");

  const { data, error } = useFetchGlobal(
    `global/events/show/${invest}/${role}`
  );
  const currentDate = new Date();

  const [tab, setTab] = useState(1);

  const setTabHandeller = (val) => {
    setTab(val);
  };

  if (error) {
    return <Error />;
  }

  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/events">
              الفعاليات
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل الفعالية `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1   w-deta  ">
          <section className="   flex items-center justify-between ">
            <section className="   flex items-center gap-3 ">
              <Tab
                text={"تفاصيل الفعالية"}
                val={1}
                tab={tab}
                setTabState={setTabHandeller}
              />
              <Tab
                text={"اجندة الفعالية"}
                val={2}
                tab={tab}
                setTabState={setTabHandeller}
              />
            </section>
            {!!data && (
              <section className=" flex items-center gap-1 ">
                <h1>حالة الفعالية : </h1>

                <span className=" p-[5px] rounded-full border border-black ">
                  {new Date(data.event.end_date) < currentDate && (
                    <p className=" text-red-600 ">منتهية</p>
                  )}
                  {(new Date(data.event.start_date) < currentDate) &
                  (new Date(data.event.end_date) > currentDate) ? (
                    <p className=" text-sky-600 ">جارية</p>
                  ) : (
                    <></>
                  )}
                  {new Date(data.event.start_date) > currentDate && (
                    <p className=" text-green-600 ">قادمة</p>
                  )}
                </span>
              </section>
            )}
          </section>
          {!!data && (
            <>
              <section>{tab === 1 && <Details data={data.event} />}</section>
              <section>{tab === 2 && <Secudels data={data} />}</section>
            </>
          )}
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && <Owner data={data.event} />}
        </section>
      </div>
      {/* <div className=" hidden md:grid ">
        <OtherEvents />
      </div> */}
    </div>
  );
}
