import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Info from "../race/Info";
import Top from "./Top";
import SendForm from "./SendForm";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import { isLogged } from "../../../utils/inStore";

export default function JoinRace() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("race");
  const role = new URLSearchParams(search).get("role");
  const { data, error } = useFetchGlobal(`global/race/show/${invest}/${role}`);
  const [progress, setProgress] = useState(1);
  const { logged } = useContext(isLogged);
  const currentDate = new Date();
  if (error || !logged) {
    return <Error />;
  }
  if (!!data) {
    if (new Date(data.race.end_date) < currentDate) {
      return <Error />;
    }
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/races">
              السباقات
            </Link>
            <h1 className="text-[#432315] ">{`> الانضمام الي السباق `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className="  items-center gap-3 ">
            <Top num={progress} />
          </section>
          {!!data && (
            <SendForm val={progress} setValState={setProgress} data={data} />
          )}
        </div>
        {/* Owner */}
        {!!data && <Info join={true} data={data.race} />}
      </div>
    </div>
  );
}
