import React from "react";
import Trip from "../../components/pages/Tourism/Trip";

export default function Trips({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <Trip key={inx} data={e} />
      ))}
    </div>
  );
}
