import React, { useContext, useEffect, useState } from "react";
import Pref from "./Pref";
import Invests from "./Invests";
import DeleteInvest from "../../components/invets/Delete";
import Subs from "./Subs";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import { isLogged } from "../../../../utils/inStore";
import AddInvestMent from "../../components/invets/AddInvestMent";
import EditInvestMent from "../../components/invets/EditInvestMent";

export default function InvestDashboard() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });
  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = "camelOwner/investments/all";
  }
  if (role === "squer") {
    link = "field-owner/investments/all";
  }
  if (role === "provider") {
    link = "serviceProvider/investments/all";
  }
  if (role === "super_admin") {
    link = "superAdmin/investments/all";
  }

  const { data, error } = useFetchWithToken(link);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filtered = [];
    if (data) {
      filtered = data;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.title.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);

  const [deleteId, setDeletId] = useState();
  const [editId, setEditId] = useState();
  const [detailsId, setDetailsId] = useState();
  const [openAdd, setOpenAdd] = useState(false);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>

        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة استثمار
        </button>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-10 ">
        {!!data && (
          <section className="flex-1">
            {detailsId ? (
              <Subs id={detailsId} back={() => setDetailsId()} />
            ) : (
              <>
                <Invests
                  setDetailsId={setDetailsId}
                  setDeleteId={setDeletId}
                  setEditId={setEditId}
                  data={filteredData}
                />
                {filteredData.length === 0 && (
                  <h1 className=" text-center my-4 text-xl ">
                    لا توجد استثمارات
                  </h1>
                )}
              </>
            )}
          </section>
        )}
        <section className=" w-full max-w-[390px] md:max-w-[230px] ">
          {!!data && <Pref data={data} />}
        </section>
      </div>
      {deleteId && (
        <DeleteInvest set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
      {editId && (
        <EditInvestMent set={setFilteredData} id={editId} close={setEditId} />
      )}
      {openAdd && <AddInvestMent close={setOpenAdd} />}
    </div>
  );
}
