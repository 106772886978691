import React from "react";
import NewComponent from "../../components/pages/News/NewComponent";

export default function News({ data }) {
  return (
    <div className=" flex mb-4 gap-3 flex-wrap justify-center " >
      {data.slice(0, 8).map((e, inx) => (
        <NewComponent data={e} key={inx} />
      ))}
    </div>
  );
}
