import React from "react";

export default function Terms({ data }) {
  return (
    <div>
      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.terms }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
    </div>
  );
}
