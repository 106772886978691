import React, { useCallback, useContext, useEffect, useState } from "react";
// import HeadCover2 from "../../components/pages/HeadCover2";
// import Sections from "./Sections";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";
import Filter from "./Filter";
import AllStores from "./AllStores";
import { Link } from "react-router-dom";
import useFetchGlobal from "../../utils/useGetGlobal";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";

export default function Stores() {
  // const [section, setSection] = useState("all");
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);
  const [filteredData, setFilteredData] = useState([]);

  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "",
  });
  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);
  const [checkedLocations, setCheckedLocations] = useState([
    "riyhad",
    "jada",
    "uae",
    "qutar",
    "bahreen",
  ]);

  const categs = useFetchGlobal("global/store/all-Category").data;
  const [categers, setCategs] = useState([]);
  useEffect(() => {
    if (!!categs) {
      setCategs(categs);
    }
  }, [categs]);

  const { data, error } = useFetchGlobal("global/store/item/all");

  useEffect(() => {
    let filtered = [];

    if (!!data) {
      filtered = data.items;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.toLowerCase().includes(mainFilter.search.toLowerCase())
        );
      }
      if (mainFilter.sort) {
        filtered = filtered.filter(
          (item) => +item.category_id === +mainFilter.sort
        );
      }
    }

    setFilteredData(filtered);
    // eslint-disable-next-line no-sparse-arrays
  }, [mainFilter, data]);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org",
"@type": "WebPage",
"name": "منصة الابل",
"description": "المنتاجات والادوية المتعلقة بعالم الابل والجمال وافضل المنتاجات وافضل اسعار مميزة",
"url": "https://mitracamel.com/stores"
}
`}
        </script>
      </Helmet>
      {/* <HeadCover2
        title="المتجر "
        desc={
          "المكان الأمثل لعشاق الإبل لمشاهدة وشراء أفضل وأجمل الإبل مع توافر جميع مستلزمات الجمال"
        }
        img={"./imgs/swipper/3.jpg"}
        // img={"./imgs/swipper/2.jpg"}
      /> */}{" "}
      <NewOver img="./imgs/store.png" title="المتجر" />
      {/* <Sections sec={section} setSec={setSection} /> */}
      <div className="p-6 md:py-20 md:px-12">
        <div className="flex items-center justify-between flex-wrap md:gap-2">
          {/* Search */}
          <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
            <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
              <i className="fa-solid fa-magnifying-glass m-2"></i>
              <input
                type="text"
                value={mainFilter.search}
                name="search"
                onChange={handleMainFilterChange}
                placeholder="أبحث هنا..."
                className="outline-none bg-[black]/0 flex-1 p-2"
              />
            </div>
            <h1 className="hidden md:flex">{filteredData.length} منتج</h1>
          </section>

          {/* Sort */}
          <section className="hidden md:flex items-center gap-2">
            <h1 className="text-[15px] font-[500]">فلتر : </h1>
            <select
              onChange={handleMainFilterChange}
              value={mainFilter.sort}
              name="sort"
              className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
            >
              <option hidden>الاقسام</option>
              {categers.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </section>

          {/* Mobile Filter & Sort */}
          <section className="md:hidden flex gap-2">
            {/* <button
              onClick={toggleFilterOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> فلتر
            </button> */}
            <button
              onClick={toggleSortOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> فلتر
            </button>
          </section>

          {/* Create Investment */}
          <section className="py-3 flex justify-end">
            {!logged ? (
              <>
                <button
                  className="btn hidden md:flex"
                  onClick={toggleRegisterMessage}
                >
                  انشاء متجر خاص بك
                </button>
                <button
                  className="btn md:hidden"
                  onClick={toggleRegisterMessage}
                >
                  انشاء متجر
                </button>
              </>
            ) : (
              <>
                {role === "camel-owner" && (
                  <Link to={"/dashboard?type=2"} className="btn">
                    <i className="fa-solid fa-circle-plus mx-2 "></i>
                    انشاء متجر
                  </Link>
                )}
                {role === "squer" && (
                  <Link to={"/dashboard?type=6"} className="btn">
                    <i className="fa-solid fa-circle-plus mx-2 "></i>
                    انشاء متجر
                  </Link>
                )}
                {role === "provider" && (
                  <Link to={"/dashboard?type=3"} className="btn">
                    <i className="fa-solid fa-circle-plus mx-2 "></i>
                    انشاء متجر
                  </Link>
                )}
                {role === "vistor" && (
                  <Link to={"/dashboard?type=2"} className="btn">
                    <i className="fa-solid fa-circle-plus mx-2 "></i>
                    انشاء متجر
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link to={"/dashboard?type=2"} className="btn">
                    {/* <i className="fa-solid fa-circle-plus mx-2 "></i> */}
                    ادارة المتاجر
                  </Link>
                )}
              </>
            )}
          </section>
        </div>
        {isSortOpen && (
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
          >
            <option hidden>الاقسام</option>
            {categers.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        )}

        {isFilterOpen && (
          <Filter
            locationOptions={checkedLocations}
            setLocationsOptions={setCheckedLocations}
          />
        )}

        <div className="flex items-start gap-4 mt-10 mb-3">
          {/* <section className="hidden md:grid w-[210px]">
            <Filter
              locationOptions={checkedLocations}
              setLocationsOptions={setCheckedLocations}
            />
          </section> */}
          {data && (
            <section className="flex-1">
              {error ? (
                <h1 className=" text-center text-2xl text-red-500 my-3 ">
                  هناك مشكلة
                </h1>
              ) : (
                <>
                  <AllStores categers={categers} alldata={filteredData} />
                </>
              )}
            </section>
          )}
        </div>
      </div>
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مكانك في المتجر"
        />
      )}
    </div>
  );
}
