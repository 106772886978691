import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  // useMemo,
} from "react";
// import HeadCover from "../../components/pages/HeadCover";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";
import Filter from "./Filter";
import Invests from "./Invests";
import { Link } from "react-router-dom";
import useFetchGlobal from "../../utils/useGetGlobal";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";

export default function Investment() {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);

  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "newest",
  });

  const [checkedFilters, setCheckedFilters] = useState([
    "squares",
    "hospitals",
    "medical tools",
    "training tools",
  ]);

  const [maxPrice, setMaxPrice] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);

  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const toggleFilterOpen = useCallback(() => {
    setFilterOpen((prev) => !prev);
    setSortOpen(false);
  }, []);

  const { data, error } = useFetchGlobal("global/investments/all");

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data.Investments;
      // filtered = data.Investments.filter((item) =>
      //   checkedFilters.includes(item.type)
      // );
      if (maxPrice > 0) {
        filtered = filtered.filter((item) => item.price_per_share < maxPrice);
      }
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.title.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [maxPrice, checkedFilters, mainFilter, data]);

  return (
    <div>
      {/* <HeadCover
        img="./imgs/cover1.jpg"
        title="الاستثمار | Investment"
        desc="يمكنك الأن الاستثمار في انواع الجمال ومزارع الجمال المتاحة عل منصتنا"
      /> */}
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "منصة الابل",
        "description": "افضل الاستثمارات ومجال تجارة الابل والربح منها",
        "url": "https://mitracamel.com/invset"
      }
    `}
        </script>
      </Helmet>{" "}
      <NewOver
        img="./imgs/invest.png"
        title="الاستثمار"
        desc={"اطرح مشروعك للاستثمار"}
      />
      <div className="p-6 md:py-20 md:px-12">
        <div className="flex items-center justify-between flex-wrap md:gap-2">
          {/* Search */}
          <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
            <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
              <i className="fa-solid fa-magnifying-glass m-2"></i>
              <input
                type="text"
                value={mainFilter.search}
                name="search"
                onChange={handleMainFilterChange}
                placeholder="أبحث هنا..."
                className="outline-none bg-[black]/0 flex-1 p-2"
              />
            </div>
            <h1>{filteredData.length} استثمار</h1>
          </section>

          {/* Sort */}
          <section className="hidden md:flex items-center gap-2">
            <h1 className="text-[15px] font-[500]">ترتيب بواسطة:</h1>
            <select
              onChange={handleMainFilterChange}
              value={mainFilter.sort}
              name="sort"
              className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
            >
              <option value="newest">من الأحدث الي الأقدم</option>
              <option value="oldest">من الأقدم الي الأحدث</option>
            </select>
          </section>

          {/* Mobile Filter & Sort */}
          <section className="md:hidden flex gap-2">
            <button
              onClick={toggleFilterOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> فلتر
            </button>
            <button
              onClick={toggleSortOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> ترتيب
            </button>
          </section>

          {/* Create Investment */}
          <section className="py-3 flex justify-end">
            {!logged ? (
              <>
                <button
                  className="btn hidden md:flex"
                  onClick={toggleRegisterMessage}
                >
                  انشاء استثمار خاص بك
                </button>
                <button
                  className="btn md:hidden"
                  onClick={toggleRegisterMessage}
                >
                  انشاء استثمار
                </button>
              </>
            ) : (
              <>
                {role === "camel-owner" && (
                  <Link to={"/dashboard?type=4"} className="btn">
                    انشاء استثمار
                  </Link>
                )}
                {role === "squer" && (
                  <Link to={"/dashboard?type=5"} className="btn">
                    انشاء استثمار
                  </Link>
                )}
                {role === "provider" && (
                  <Link to={"/dashboard?type=2"} className="btn">
                    انشاء استثمار
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link to={"/dashboard?type=7"} className="btn">
                    ادارة الاستثمار
                  </Link>
                )}
              </>
            )}
          </section>
        </div>

        {isSortOpen && (
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
          >
            <option value="newest">من الأحدث الي الأقدم</option>
            <option value="oldest">من الأقدم الي الأحدث</option>
          </select>
        )}

        {isFilterOpen && (
          <Filter
            setMaxPriceState={setMaxPrice}
            filter={checkedFilters}
            setCheckedState={setCheckedFilters}
          />
        )}

        <div className="flex items-start gap-4 mt-10 mb-3">
          <section className="hidden md:grid w-[210px]">
            <Filter
              setMaxPriceState={setMaxPrice}
              filter={checkedFilters}
              setCheckedState={setCheckedFilters}
            />
          </section>
          <section className="flex-1">
            {error ? (
              <h1 className=" text-center text-2xl text-red-500 my-3 ">
                هناك مشكلة
              </h1>
            ) : (
              <>
                {mainFilter.sort === "newest" ? (
                  <Invests alldata={filteredData} />
                ) : (
                  <Invests alldata={[...filteredData].reverse()} />
                )}
              </>
            )}
          </section>
        </div>
      </div>
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مكانك في الاستثمار"
        />
      )}
    </div>
  );
}
