import React from "react";
import PartnerShipComponetDashboard from "../../components/partnerShip/PartnerShipComponetDashboard";

export default function Partners({
  data,
  setDeleteId,
  setDetailsId,
  setEditId,
}) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <PartnerShipComponetDashboard
          setDetailsId={setDetailsId}
          setDeleteId={setDeleteId}
          key={inx}
          setEditId={setEditId}
          data={e}
        />
      ))}
    </div>
  );
}
