import { useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { load } from "./isLoadingStore";

const useDeleteRequest = () => {
  const { setIsLoading } = useContext(load);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const deleteRequest = async (endPoint) => {
    setIsLoading(true);
    let token = Cookies.get("token");
    setError(null);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/${endPoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteRequest, data, error };
};

export default useDeleteRequest;
