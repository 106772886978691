import React, { useEffect } from "react";
import usePostFormData from "../../../../../utils/usePostForm";

export default function Accept({ re, id }) {
  let link = `superAdmin/field/setting/accepted/accept/${id}`;
  const { postFormData, response } = usePostFormData(link);
  const handelAccept = () => {
    postFormData();
  };
  useEffect(() => {
    if (response) {
      re();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <button
      onClick={handelAccept}
      className=" px-2 py-1 rounded-[10px] bg-green-500 text-white text-[16px] "
    >
      قبول
    </button>
  );
}
