import React, { useState } from "react";
import TabJoin from "../../../../../components/pages/TabOfJoins";
import ClincsDashboard from "../../../pages/clincs/ClincsDashboard";

export default function ClincsSuperAdminDashboard() {
  const [tab, setTab] = useState(1);
  const tabs = [
    // "تقارير العيادات",
    "العيادات",
  ];
  return (
    <div>
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <TabJoin
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>{tab === 1 && <ClincsDashboard />}</div>
    </div>
  );
}
