import React from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

export default function Status({ packages }) {
  // console.log(packages);
  const names = packages.map((pack) => pack.package_name);
  const values = packages.map((pack) => pack.usage_percentage);
  const data = {
    // labels: ["aa", "aas", "ss"],
    labels: names,
    datasets: [
      {
        // labels: ["att", "abs"],
        backgroundColor: ["#FF0101", "#6DB153", "#2e4569"],
        borderColor: ["#FF0101", "#6DB153", "#2e4569"],
        // data: [10, 30, 80],
        data: values,
      },
    ],
  };
  return (
    <div>
      {" "}
      <section className=" max-w-[300px] mx-auto">
        <Doughnut data={data} />
      </section>
    </div>
  );
}
