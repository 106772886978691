import React from "react";
import { Link } from "react-router-dom";
function TruncatedParagraph({ text }) {
  // Split the text into an array of words
  const words = text.split(" ");

  // Check if the number of words exceeds maxWords
  const shouldTruncate = words.length > 18;

  // Create the truncated text
  const truncatedText = shouldTruncate
    ? words.slice(0, 18).join(" ") + "...."
    : text;

  return truncatedText;
}

export default function InvestMestComponent({ data }) {
  // const toDay = new Date();
  // const end = new Date(data.end_date);
  // const diffInMilliseconds = end - toDay;

  // Convert milliseconds to days
  // const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  // let left = diffInDays > 0 ? `${diffInDays} يوم` : "انتهت";
  return (
    <div className=" text-[#432315] w-full max-w-[330px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
      </div>

      <h1 className=" my-2 text-[22px] font-[800] ">{data.title}</h1>
      <p className=" min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.summary} />
      </p>
      <div className=" my-2 flex-wrap flex items-center gap-1 justify-around ">
        <section className=" flex gap-[6px] items-center ">
          <i className="fa-solid fa-users text-sm "></i>
          <span>
            <h4 className=" text-xs  font-[600] leading-4">{Number(data.total_investment)} ر.س </h4>
            <h4 className=" text-sm leading-3">استثمار</h4>
          </span>
        </section>
        <section className=" flex gap-[4px]  items-center ">
          <i className="fa-solid fa-money-bill text-sm"></i>
          <span>
            <h4 className=" text-xs font-[600] leading-4">
              {Number(data.price_per_share)} ر.س{" "}
            </h4>
            <h4 className=" text-sm leading-4">سعر السهم</h4>
          </span>
        </section>
        {/* <section className=" flex gap-[4px] items-center ">
          <i className="fa-regular fa-clock text-sm"></i>
          <span>
            <h4 className=" font-[600] leading-4">{left}</h4>
            <h4 className=" text-sm leading-4">الوقت المتبقي</h4>
          </span>
        </section> */}
      </div>
      <section className=" text-center mt-4 ">
        <Link to={`/investment-details?invest=${data.id}&role=${data.role}`}>
          <button className=" btn2 ">تفاصيل للأستثمار</button>
        </Link>
      </section>
    </div>
  );
}
