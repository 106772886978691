import React, { useState } from "react";

export default function NumBtn({ val, nameofVal, handelChangeMain, min, max }) {
  const [value, setValue] = useState(val);
  const handelChange = (e) => {
    setValue(e.target.value);
    handelChangeMain(e);
  };
  return (
    <div className=" flex items-start ">
      <button
        name={nameofVal}
        onClick={handelChange}
        value={+value + 1}
        className="  h-[40px] w-[50px] border rounded border-[#432315] text-[#432315] text-3xl flex items-center justify-center  "
      >
        +
      </button>
      <input
        min={min}
        max={max}
        name={nameofVal}
        value={value}
        className=" sumBtn outline-none bg-[#F8EBCF] p-[8px] h-[40px] w-[50px] text-center "
        type="number"
        onChange={handelChange}
      />
      <button
        name={nameofVal}
        disabled={+value === 0}
        onClick={handelChange}
        value={+value - 1}
        className="  h-[40px] w-[50px] border rounded border-[#432315] text-[#432315] text-3xl flex items-center justify-center  "
      >
        -
      </button>
    </div>
  );
}
