import React from "react";
import useFetchWithToken from "../../../../../utils/useGetWithToken";
import { Link } from "react-router-dom";

export default function MyRaces() {
  const { data, error } = useFetchWithToken("global/race/join/myraces");
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      {!!data && (
        <>
          {!!data.races ? (
            <>
              {
                <table>
                  <thead>
                    <tr>
                      <th>اسم الجمل</th>
                      <th>العمر</th>
                      <th>اللون</th>
                      <th>فئة المشاركة</th>
                      <th>عرض</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.races.map((e, inx) => (
                      <tr key={inx}>
                        <td className=" text-center ">{e.camel_name}</td>
                        <td className=" text-center ">{e.age}</td>
                        <td className=" text-center ">{e.color}</td>
                        <td className=" text-center ">{e.category}</td>
                        <td className=" text-center ">
                          <Link
                            className=" text-sky-800 underline font-bold "
                            to={`/race-details?race=${e.race_id}&role=${e.owner_type}`}
                          >
                            عرض{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد مشاركات لك في السباق
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
