import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePutWithToken from "../../../../utils/usePutJson";
import usePostWithToken from "../../../../utils/usePostJson";

export default function Vision() {
  const [vision, setVision] = useState();
  let link = "global/vision";
  const { data, error } = useFetchWithReload(link);
  useEffect(() => {
    if (data) {
      setVision(data.description);
    }
  }, [data]);
  const { putData, response } = usePutWithToken(`superAdmin/vision/update`);
  const { postData, response: add } = usePostWithToken(
    `superAdmin/vision/store`
  );
  const updateHandeller = () => {
    if (error) {
      postData({
        description: vision,
      });
    } else {
      putData({
        description: vision,
      });
    }
  };
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold "> الرؤية </h1>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap   ">
          <section className=" col-span-1 lg:col-span-2">
            <textarea
              rows={2}
              name="vision"
              value={vision}
              onChange={(e) => setVision(e.target.value)}
              className=" inpt "
              type="text"
              placeholder="الرؤية"
              required
            />
          </section>
        </form>
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            تعديل
          </button>
          {(response || add) && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم تغيير البيانات بنجاح</h1>
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
