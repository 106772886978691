import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import PartnerInfo from "../PartnerShip/PartnerInfo";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import { isLogged } from "../../../utils/inStore";
import JoinBtn from "./JoinBtn";

export default function JoinParnerShip() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("partnership");
  const role = new URLSearchParams(search).get("role");

  const { data, error } = useFetchGlobal(
    `global/alliance/show/${invest}/${role}`
  );

  const { logged } = useContext(isLogged);
  if (error || !logged) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/alliance">
              التحالف
            </Link>
            <h1 className="text-[#432315] ">{`> الانضمام الى التحالف `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className=" flex items-center gap-3 ">
            <Tab
              text={"الانضمام الى التحالف "}
              val={1}
              tab={1}
              setTabState={() => {}}
            />
          </section>
          {/* <SendForm data={data} /> */}
          {!!data && (
            <div>
              <h1 className=" my-8 text-[#432315] text-[30px]  font-[800] ">
                اسم التحالف : {data.alliance.name}
              </h1>
              <JoinBtn id={invest} role={role} />
            </div>
          )}
        </div>
        {/* Owner */}
        {!!data && <PartnerInfo join={true} data={data.alliance} />}
      </div>
    </div>
  );
}
