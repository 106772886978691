import React from "react";
import HeadCover from "../../components/pages/HeadCover";

export default function Servaces() {
  return (
    <div>
      <HeadCover
        img="./imgs/cover1.jpg"
        title="خدمات | Services"
        desc="يوجد اكثر من 20 خدمة"
      />
    </div>
  );
}
