import { Menu } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { isLogged } from "../../../../utils/inStore";

export default function RaceComponentDashboard({
  data,
  setDeleteId,
  setEditId,
  setDetailsId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { role } = useContext(isLogged);
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };
  return (
    <div className=" relative text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.race_image}`}
          alt="cover"
        />
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.name}</h1>
      <section className=" my-2 flex gap-[6px] items-center ">
        <i className="fa-solid fa-calendar-days"></i>
        <span className=" flex items-center ltr ">
          <h4 className=" font-[600] leading-4">
            {data.end_date} - {data.start_date.substring(0, 2)}
          </h4>
        </span>
      </section>
      <section className=" my-1 text-[15px] font-[600] leading-[28px] flex items-center gap-1 ">
        <i className="fa-solid fa-location-dot"></i>
        <p>{data.address}</p>
      </section>
      <section className=" my-1   text-[15px] font-[600] leading-[28px] flex items-center justify-between flex-wrap gap-1 ">
        <Link to={`/race-details?race=${data.id}&role=${roles[role]}`}>
          <button className=" btnn2 ">التفاصيل</button>
        </Link>
        <button onClick={() => setDetailsId(data.id)} className=" btnn ">
          المشتركين
        </button>
        <button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="  flex items-center justify-center bg-[#E8D6B2]/30 hover:bg-[#E8D6B2]/60 rounded-full h-[50px] w-[50px] "
        >
          <i className="fa-solid fa-ellipsis text-2xl "></i>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              setEditId(data.id);
            }}
            className=" py-2 px-12 border-b "
          >
            تعديل
          </button>
          <br></br>
          <button
            onClick={() => {
              handleClose();
              setDeleteId(data.id);
            }}
            className=" py-2 px-12 text-red-500 "
          >
            حذف
          </button>
        </Menu>
      </section>
    </div>
  );
}
