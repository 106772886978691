import React from "react";
import { Link } from "react-router-dom";

export default function Owner({ owner }) {
  return (
    <div className="  hover:shadow-lg hover:scale-[1.03] transition-all ease-in-out duration-300 p-[16px] shadow-md rounded-[16px] border bg-[#F5EFDC] w-full max-w-[350px] border-[#EEE0C3] ">
      <section className=" relative w-full h-[230px] bg-origin-content bg-cover  ">
        <img
          src={
            owner.image
              ? `${process.env.REACT_APP_URL}/${owner.image}`
              : "./imgs/noImage.jpg"
          }
          alt={owner.first_name}
          // className="absolute rounded-[16px] left-0 top-0 h-full w-full object-cover "
          className="absolute rounded-[16px] left-0 top-0 h-full w-full "
        />
      </section>
      <section className=" my-6 flex items-center justify-start gap-3 font-[600] text-[20px] text-[#432315] ">
        <i className="fa-regular fa-user"></i>
        <h1>
          {owner.first_name} {owner.last_name}
        </h1>
      </section>
      <section className=" my-6 flex items-center justify-start gap-3 font-[600] text-[20px] text-[#432315] ">
        <i className="fa-solid fa-location-dot"></i>
        <h1> {owner.address} </h1>
      </section>
      <section className=" my-6 flex items-center justify-start gap-3 font-[600] text-[20px] text-[#432315] ">
        <i className="fa-solid fa-users"></i>
        <h1> {owner.nationality} </h1>
      </section>
      <section className=" text-center ">
        <Link to={`/owner?owner=${owner.id}`}>
          <button className="btn2">تفاصيل المالك</button>
        </Link>
      </section>
    </div>
  );
}
