import React from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import PackageComponent from "../../components/packages/PackageComponent";
import { Link } from "react-router-dom";

export default function PackagesDashboard() {
  let link = "global/packages/all";
  let current = "global/packages/CurrentPackage";
  const { data, error } = useFetchWithReload(link);
  const myPackge = useFetchWithReload(current).data;
  const myPackgeError = useFetchWithReload(current).error;
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div>
        {!myPackgeError && !!myPackge && (
          <div className=" w-full bg-[#E8DCC0] p-[16px] flex-wrap rounded-[16px] ">
            <h1 className="text-[#432315] text-[20px] font-[600]">
              {myPackge.subscription.package.name}
            </h1>
            <p className=" my-2  text-[15px] font-[600] leading-[28px] ">
              {myPackge.subscription.package.description}
            </p>
            <section className="flex items-center gap-2 flex-wrap ">
              <h4 className="  text-[15px] my-2 font-[600] ">
                {myPackge.subscription.start_date.substring(0, 10)}
              </h4>{" "}
              -
              <h4 className=" text-red-400 text-[15px] my-2 font-[600] ">
                {myPackge.subscription.end_date.substring(0, 10)}
              </h4>
            </section>
            <Link to="/my-package">
              <button className=" my-3 btn ">
                المزيد <i className="fa-solid fa-caret-left mx-3 "></i>
              </button>
            </Link>
          </div>
        )}
      </div>
      {!!data && (
        <div className=" my-10 flex items-satrt justify-center gap-3 flex-wrap  ">
          {!!data.packages && (
            <>
              {data.packages.map((e, inx) => (
                <PackageComponent key={inx} data={e} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}
