import React from "react";
import Menu from "@mui/material/Menu";

export default function EventsTabel({ data, setDeleteId, setDetailsId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>اسم الفعالية</th>
            <th> الساحة</th>
            <th>عدد الزائرين</th>
            <th>المنضمين</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((e, inx) => (
            <tr className=" text-center " key={inx}>
              <td>{e.name}</td>
              <td>{e.location}</td>
              <td>{123}</td>
              <td>
                <button
                  onClick={() => setDetailsId(e.id)}
                  className=" underline "
                >
                  عرض المنضمين
                </button>
              </td>
              <td>
                {" "}
                <button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="  "
                >
                  <i className="fa-solid fa-ellipsis text-2xl rotate-90 "></i>
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <button className=" py-2 px-12 border-b ">تعديل</button>
                  <br></br>
                  <button
                    onClick={() => setDeleteId(e.id)}
                    className=" py-2 px-12 text-red-500 "
                  >
                    حذف
                  </button>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
