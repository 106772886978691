import React, { useEffect, useState } from "react";
import NumBtn from "../../../components/pages/NumBtn";
import PhoneInput from "react-phone-input-2";
import usePostWithToken from "../../../utils/usePostJson";
// import NewSucces from "../../../components/pages/NewSucces";

export default function Form({ data, set, role, id }) {
  const [userInfo, setUserInfo] = useState({
    name: "",
    date: data.date,
    mail: "",
    phone: "",
    nati: "",
    message: "",
    childs: data.childs,
    adults: data.adults,
  });

  const { name, mail, phone, nati, message, adults, childs } = userInfo;
  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const handleChange = (value) => {
    setUserInfo({ ...userInfo, phone: value });
  };

  const handelNums = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
    set((pre) => ({ ...pre, [name]: value }));
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!userInfo.name) {
      formErrors.name = "الرجاء إدخال الاسم ";
    }
    // if (!userInfo.date) {
    //   formErrors.date = "الرجاء إدخال التاريخ ";
    // }
    if (!userInfo.mail) {
      formErrors.mail = "الرجاء إدخال البريد الالكتروني ";
    }
    if (!userInfo.nati) {
      formErrors.nati = "الرجاء إدخال الجنسية ";
    }
    if (!userInfo.phone) {
      formErrors.phone = "الرجاء إدخال رقم الهاتف ";
    }
    if (+userInfo.adults === 0 && +userInfo.childs === 0) {
      formErrors.numbers = "الرجاء إدخال عدد افراد الرحلة ";
    }
    if (+userInfo.adults === 0 && +userInfo.childs !== 0) {
      formErrors.numbers = "عدد الكبار لا يمكن ان يكون 0";
    }
    if (!userInfo.message) {
      formErrors.message = "الرجاء إدخال  الرسالة ";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  const { postData, response, error } = usePostWithToken(
    `global/tourisms/join/${role}`
  );

  const sendHandeller = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        tour_id: id,
        number_of_adults: adults,
        number_of_children: childs,
        name: name,
        email: mail,
        phone: phone,
        nationality: nati,
        message: message,
        callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=trip-details&type=trip&value=${id}&role=${role}`,
      };
      postData(data);
      // console.log(data);
    }
  };
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      <h1 className=" my-3 text-[20px] font-[700] text-[#432315]   ">
        املأ النموذج أدناه وسوف نتصل بك في أقرب وقت ممكن
      </h1>
      <div className=" mb-3  bg-[#E8DCC0] rounded-[15px] p-4 ">
        <section className="  flex items-center justify-start gap-2 md:gap-12 ">
          <section className=" my-4 ">
            <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
              عدد الكبار
            </h1>
            <NumBtn
              val={adults}
              nameofVal="adults"
              handelChangeMain={handelNums}
              min={0}
            />
          </section>
          <section className=" my-4 ">
            <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
              عدد الصغار
            </h1>
            <NumBtn
              val={childs}
              nameofVal="childs"
              handelChangeMain={handelNums}
              min={0}
            />
          </section>
        </section>
        {<p className="error">{errors.numbers}</p>}
        <form
          className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  "
          onSubmit={sendHandeller}
        >
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              الاسم
            </h1>
            <input
              name="name"
              value={name}
              onChange={handleChangeInfo}
              className=" inpt "
              type="text"
              placeholder="الاسم"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          {/* <section className="col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              التاريخ
            </h1>
            <input
              name="date"
              value={date}
              onChange={handleChangeInfo}
              className=" inpt "
              type="date"
              placeholder="العنوان "
              required
            />
            {<p className="error">{errors.date}</p>}
          </section> */}
          <section className="col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              البريد الاكتروني
            </h1>
            <input
              name="mail"
              value={mail}
              onChange={handleChangeInfo}
              className=" inpt "
              type="email"
              placeholder="البريد الاكتروني "
              required
            />
            {<p className="error">{errors.mail}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              الجنسية
            </h1>
            <input
              name="nati"
              value={nati}
              onChange={handleChangeInfo}
              className=" inpt "
              type="text"
              placeholder="الجنسية"
              required
            />
            {<p className="error">{errors.nati}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              رقم الهاتف
            </h1>

            <section className=" w-full  ">
              <PhoneInput
                required
                country={"sa"}
                value={phone}
                onChange={handleChange}
                className="  ltr outline-none my-1 w-full  bg-[#F5EFDC] p-2 rounded-[10px] border-b-[2px] border-[#432315] text-[#432315C7] text-[15px] font-[600] "
                inputStyle={{
                  outline: "none",
                  width: "100%",
                  maxWidth: "340px",
                  backgroundColor: "#F5EFDC",
                  padding: "12px",
                  paddingLeft: "45px",
                  borderRadius: "10px",
                  border: "none",
                  // borderBottom: "2px solid #432315",
                  color: "#432315C7",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                dropdown={{
                  outline: "none",
                  width: "100%",
                  maxWidth: "340px",
                  backgroundColor: "#F5EFDC",
                  padding: "12px",
                  paddingLeft: "45px",
                  borderRadius: "10px",
                  border: "none",
                  // borderBottom: "2px solid #432315",
                  color: "#432315C7",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                containerClass="custom-phone-input"
                inputClass="custom-phone-input-input"
                dropdownClass="custom-phone-dropdown"
              />
            </section>
            {<p className="error">{errors.phone}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              رسالة
            </h1>
            <section className=" w-full  ">
              <textarea
                name="message"
                value={message}
                rows={4}
                onChange={handleChangeInfo}
                className=" inpt "
                type="text"
                placeholder="رسالة "
              />
              {<p className="error">{errors.message}</p>}
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 flex items-center justify-start gap-2 flex-wrap ">
            <button type="submit" className=" btn2 ">
              الدفع
            </button>
            {error && (
              <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                  <i className="fa-solid fa-exclamation  "></i>
                </span>
                {error.response.data.message
                  ? error.response.data.message
                  : "هناك مشكلة"}
              </section>
            )}
            {/* {response && <NewSucces />} */}
          </section>
        </form>
      </div>
    </div>
  );
}
