import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUploded from "../../../../components/pages/FileUploded";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";

export default function AddRace({ close }) {
  const [form, setForm] = useState({
    name: "",
    description: "",
    details: "",
    start_date: "",
    end_date: "",
    field_id: "",
    rounds_number: "",
    period: "",
    distance: "",
    // link: "",
    race_image: "",
    terms: "",
    fees: "",

    race_files: [],
    categories: [],
    details_images: [],
    agenda: [],
  });
  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setForm({ ...form, details: value });
  };
  const handelChanegeTerms = (value) => {
    setForm({ ...form, terms: value });
  };
  const handleChangeAgenda = (e, inx) => {
    const { name, value } = e.target;
    const updatedAgenda = form.agenda.map((item, index) =>
      index === inx ? { ...item, [name]: value } : item
    );

    setForm((prevState) => ({
      ...prevState,
      agenda: updatedAgenda,
    }));
  };

  const { role } = useContext(isLogged);
  let link = "";
  let linkToFetch = "";
  if (role === "squer") {
    link = "field-owner/race/create";
    linkToFetch = "field-owner/field/index";
  }
  if (role === "super_admin") {
    link = "superAdmin/race/create";
    linkToFetch = "superAdmin/field/index";
  }
  const { data } = useFetchWithToken(linkToFetch);
  const [fileds, setFileds] = useState([]);

  useEffect(() => {
    if (role === "squer") {
      if (!!data) {
        setFileds(data);
      }
    }
    if (role === "super_admin") {
      if (!!data) {
        setFileds(data);
      }
    }
  }, [data, role]);

  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!form.name) {
      formErrors.name = "الرجاء إدخال اسم السباق";
    }

    // Validate description
    if (!form.description) {
      formErrors.description = "الرجاء إدخال وصف السباق";
    }

    // Validate details
    if (!form.details) {
      formErrors.details = "الرجاء إدخال تفاصيل السباق";
    }

    // Validate start date
    if (!form.start_date) {
      formErrors.start_date = "الرجاء إدخال تاريخ بداية السباق";
    }

    // Validate end date
    if (!form.end_date) {
      formErrors.end_date = "الرجاء إدخال تاريخ نهاية السباق";
    }

    // Validate field (address in the previous version)
    if (!form.field_id) {
      formErrors.field_id = "الرجاء اختيار الساحة";
    }

    // Validate rounds number
    if (!form.rounds_number) {
      formErrors.rounds_number = "الرجاء إدخال عدد الجولات";
    }

    // Validate period
    if (!form.period) {
      formErrors.period = "الرجاء إدخال مدة السباق";
    }

    // Validate distance
    if (!form.distance) {
      formErrors.distance = "الرجاء إدخال مسافة السباق";
    }

    // Validate race image
    if (!form.race_image) {
      formErrors.race_image = "الرجاء رفع الصورة المصغرة للسباق";
    }

    // Validate terms
    if (!form.terms) {
      formErrors.terms = "الرجاء إدخال شروط السباق";
    }

    // Validate fees
    if (!form.fees) {
      formErrors.fees = "الرجاء إدخال رسوم السباق";
    } else if (form.fees <= 0) {
      formErrors.fees = 'رسوم السباق يجب ان تكون اكبر من 0';
    }

    // Validate categories
    if (!form.categories || form.categories.length === 0) {
      formErrors.categories = "الرجاء إدخال فئات السباق";
    }

    // Validate agenda
    if (!form.agenda || form.agenda.length === 0) {
      formErrors.agenda = "الرجاء إدخال جدول أعمال السباق";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();

  const { postFormData, error, response } = usePostFormData(link);

  const [err, setErr] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("name", form.name);
      formdata.append("description", form.description);
      formdata.append("details", form.details);
      formdata.append("start_date", form.start_date);
      formdata.append("end_date", form.end_date);
      formdata.append("field_id", form.field_id);
      formdata.append("rounds_number", form.rounds_number);
      formdata.append("period", form.period);
      formdata.append("distance", form.distance);
      formdata.append("race_image", form.race_image);
      formdata.append("terms", form.terms);
      formdata.append("fees", form.fees);

      form.categories.forEach((item) => {
        formdata.append("categories[]", item); // Check if your server expects "event_files" or "event_files[]"
      });
      form.race_files.forEach((file) => {
        formdata.append("race_files[]", file); // Check if your server expects "event_files" or "event_files[]"
      });

      // Append details images
      form.details_images.forEach((image) => {
        formdata.append("details_images[]", image); // Check if your server expects "details_image" or "details_image[]"
      });

      // Append agenda
      form.agenda.forEach((item, index) => {
        formdata.append(`agenda[${index}][title]`, item.title);
        formdata.append(`agenda[${index}][description]`, item.description);
        formdata.append(`agenda[${index}][day]`, item.day);
        formdata.append(`agenda[${index}][start_time]`, item.start_time);
        formdata.append(`agenda[${index}][end_time]`, item.end_time);
      });

      await postFormData(formdata);
    } else {
      setErr(true);
    }
  };
  useEffect(() => {
    if (!!response) {
      navigate(`/race-details?race=${response.race.id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const [categorie, setCategr] = useState("");
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة سباق</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {form.race_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, race_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.race_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, race_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.race_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم السباق
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم السباق"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الساحة
            </h1>
            <select
              name="field_id"
              value={form.field_id}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden>الساحة </option>
              {fileds.map((e, inx) => (
                <option key={inx} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>

            {<p className="error">{errors.field_id}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن السباق
            </h1>
            <textarea
              name="description"
              cols={2}
              value={form.description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن السباق "
              required
            />
            {<p className="error">{errors.description}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل السباق
            </h1>
            <ReactQuill
              value={form.details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ البداية
            </h1>
            <input
              name="start_date"
              value={form.start_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ البداية"
              required
            />
            {<p className="error">{errors.start_date}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ النهاية
            </h1>
            <input
              name="end_date"
              value={form.end_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ النهاية"
              required
            />
            {<p className="error">{errors.end_date}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              فترة السباق
            </h1>
            <select
              name="period"
              value={form.period}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden> فترة السباق</option>
              <option value={"صباحية"}>صباحية</option>
              <option value={"مسائية"}>مسائية</option>
            </select>

            {<p className="error">{errors.period}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المسافة بالمتر
            </h1>
            <input
              name="distance"
              value={form.distance}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder=" المسافة بالمتر"
              required
            />
            {<p className="error">{errors.distance}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              عدد الاشواط
            </h1>
            <input
              name="rounds_number"
              value={form.rounds_number}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder="   عدد الاشواط"
              required
            />
            {<p className="error">{errors.rounds_number}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رسوم الاشتراك
            </h1>
            <input
              name="fees"
              value={form.fees}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder=" رسوم الاشتراك "
              required
            />
            {<p className="error">{errors.fees}</p>}
          </section>
          <section className=" relative min-h-[340px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الشروط
            </h1>
            <ReactQuill
              value={form.terms}
              onChange={handelChanegeTerms}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
            {<p className="error absolute bottom-5 ">{errors.terms}</p>}
          </section>
          <section className="  col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              فئات السباق
            </h1>
            <div className="flex items-center gap-2 flex-wrap">
              {form.categories.map((e, inx) => (
                <section
                  key={inx}
                  className=" py-1 px-3 border rounded-md text-[#432315] text-[15px]  font-[400]  "
                >
                  {e}
                </section>
              ))}
              <div className=" flex items-center gap-2 w-[300px] ">
                <input
                  value={categorie}
                  onChange={(e) => setCategr(e.target.value)}
                  type="text"
                  className="inpt  "
                  placeholder="اضافة فئة"
                />
                <button
                  disabled={!categorie}
                  onClick={() => {
                    setForm({
                      ...form,
                      categories: [...form.categories, categorie],
                    });
                    setCategr("");
                  }}
                  type="button"
                  className="btn disabled:opacity-75 "
                >
                  اضافة
                </button>
              </div>
            </div>
            {<p className="error text-start">{errors.categories}</p>}
          </section>

          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور السباق
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_images.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_images;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_images: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_images: [
                        ...form.details_images,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              ملفات السباق
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.race_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.race_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        race_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      race_files: [...form.race_files, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 text-xl font-bold lg:col-span-2 text-center ">
            جدول أعمال السباق
          </section>
        </form>
        {form.agenda.map((e, inx) => (
          <div
            key={inx}
            className="  border-b col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 "
          >
            <section className=" col-span-1 lg:col-span-2 flex items-center justify-between p-2 ">
              <h1 className=" h-[30px] w-[30px] flex items-center justify-center text-lg rounded-full text-[#fafafa] bg-[#432315] ">
                {inx + 1}
              </h1>
              <button
                onClick={() => {
                  let newAgenda = form.agenda.filter(
                    (e, index) => inx !== index
                  );
                  setForm({ ...form, agenda: newAgenda });
                }}
              >
                <i className="fa-solid fa-trash text-red-600"></i>
              </button>
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                اسم اليوم
              </h1>
              <input
                name="title"
                value={e.title}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="text"
                placeholder=" اليوم"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                اليوم
              </h1>
              <input
                name="day"
                value={e.day}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="date"
                placeholder="اليوم"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                بداية اليوم
              </h1>
              <input
                name="start_time"
                value={e.start_time}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="  بداية اليوم"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                نهاية اليوم
              </h1>
              <input
                name="end_time"
                value={e.end_time}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="  نهاية الحدث"
                required
              />
            </section>
            <section className=" col-span-1 lg:col-span-2 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                التفاصيل
              </h1>
              <textarea
                name="description"
                value={e.description}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="التفاصيل"
                required
              />
            </section>
          </div>
        ))}
        <section className=" col-span-1 lg:col-span-2 text-center ">
          <button
            onClick={() => {
              setForm({
                ...form,
                agenda: [
                  ...form.agenda,
                  {
                    title: "",
                    day: "",
                    start_time: "",
                    end_time: "",
                    description: "",
                  },
                ],
              });
            }}
            className="btn2 my-3"
          >
            اضافة يوم
          </button>
          <br />
          {<p className="error">{errors.agenda}</p>}
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {err && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            بعض البيانات الاساسية غير موجودة
          </section>
        )}
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.error
              ? error.response.data.error
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
