import React from "react";

export default function StowerOwner({ desc, name, loc, img, cover }) {
  return (
    <div>
      <div className=" h-[180px] md:h-[280px]  py-12 px-8 md:px-16 relative   ">
        <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
          <img
            src={cover}
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover"
          />
        </div>
      </div>
      <div className=" md:flex  flex-row-reverse items-start gap-12 lg:gap-24  bg-[#E8DCC0] py-8 px-8 lg:px-24 ">
        <section className="  mb-8 md:w-fit  mt-[-108px] md:mt-[-132px] z-10 relative  ">
          <img
            src={img}
            alt="user profile"
            className="mx-auto h-[150px] w-[150px]  md:h-[200px] md:w-[200px] rounded-full  "
          />

          <h1 className="  text-center text-[#432315]  text-[20px] lg:text-[36px] font-[700]  md:my-4 ">
            متجر {name}
          </h1>
          <h1 className=" text-center flex items-center justify-center text-[#432315] text-[12px] lg:text-[18px] ">
            <i className=" fa-solid fa-location-dot " />
            <h1 className=" mx-3 ">{loc}</h1>
          </h1>
        </section>

        <section className=" flex-1 ">
          <section className=" my-4 text-[#432315] text-[35px] py-1 font-[600]  rounded-[10px]  font-sans ">
            حول المتجر
          </section>
          <p className=" text-[#432315] text-[18px] font-[700] leading-[35px] ">
            {desc}
          </p>
        </section>
      </div>
    </div>
  );
}
