import React from "react";
import { Link } from "react-router-dom";

export default function New({ data }) {
  return (
    <div className=" newCont  w-full max-w-[580px] h-[341px] rounded-[25px] overflow-hidden relative  ">
      <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
        <img
          src={data.img}
          alt="Vision"
          className="absolute left-0 top-0 h-full w-full object-cover brightnes "
        />
      </div>
      <div className=" overflow-hidden  newtextCont transition-all ease-in-out duration-700 text-start  bg-[#EEE0C3] h-[100px] z-10 absolute w-full bottom-0  ">
        <h1 className=" newTiltle bottom-6 opacity-100 pt-3  z-10    transition-all ease-in-out duration-700 absolute w-full text-[28px] md:text-[36px]  text-[#432315] font-[500] text-center ">
          {data.name}
        </h1>
        <section className=" px-6 opacity-0  text-[#432315] textHidden  transition-all ease-in-out duration-700 absolute bottom-6  ">
          <h1 className=" text-[24px] font-[800] my-2 md:my-4 ">
            {data.title}
          </h1>
          <h4 className=" leading-[30px] md:w-[70%] text-[15px] md:text-[18px] font-[700] ">
            {data.text1}
          </h4>
          <h4 className=" leading-[30px] md:w-[70%] text-[15px] md:text-[18px] font-[700] ">
            {data.text2}
          </h4>
          <Link to={data.link}>
                      <button className="btn2 mt-6">اطلع علي { data.title}</button>
          </Link>
        </section>
      </div>
    </div>
  );
}
