import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Owner from "./Owner";
import Tab from "../../components/pages/Tab";
import EventDetailsJoinComponent from "../../components/pages/Events/EventDetailsJoinComponent";
// import Send from "./Send";
import useFetchGlobal from "../../utils/useGetGlobal";
import Error from "../../components/Error/Error";
import { isLogged } from "../../utils/inStore";
import JoinBtns from "./JoinBtns";

export default function JoinEvent() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("event");
  const role = new URLSearchParams(search).get("role");
  const { logged } = useContext(isLogged);
  const { data, error } = useFetchGlobal(
    `global/events/show/${invest}/${role}`
  );

  if (error || !logged) {
    return <Error />;
  }

  if (!!data) {
    const currentDate = new Date();
    const endDat = new Date(data.event.end_date);
    if (endDat < currentDate) {
      return <Error />;
    }
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/events">
              الفعاليات
            </Link>
            <h1 className="text-[#432315] ">{`> انضم الفعالية `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className=" flex items-center gap-3 ">
            <Tab
              text={" انضم للفعالية"}
              val={1}
              tab={1}
              setTabState={() => {}}
            />
          </section>
          {!!data && <EventDetailsJoinComponent data={data.event} />}
          <h1 className=" my-4 text-[#432315] text-[32px]  font-[900] ">
            انضم لنا
          </h1>
          {/* {!!data && (
            <Send
              name={data.name}
              date={data.event.start_date}
              owner={data.name}
            />
          )} */}
          {!!data && <JoinBtns data={data.event} id={invest} role={role} />}
        </div>
        {/* Owner */}
        {!!data && <Owner join={true} data={data.event} />}
      </div>
    </div>
  );
}
