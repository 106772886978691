import React from "react";
import Cookies from "js-cookie";
import Veterinary from "./Veterinary";
import ExpertsDashboard from "./Experts";
import Freelancer from "./Freelancer";

export default function SercviceProvider() {
  const type = Cookies.get("type");

  if (type === "veterinary") {
    return <Veterinary />
  }
  if (type === "expert") {
    return <ExpertsDashboard />
  }
  if (type === "freelancer") {
    return <Freelancer />
  }
}
