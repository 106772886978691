import React from "react";
import { Link } from "react-router-dom";

export default function DropDown({ name, links }) {
  return (
    <div className="dropdown relative inline-block">
      <button className="dropbtn">
        {name} <i className="fa-solid fa-caret-down mx-1"></i>
      </button>
      <div className="dropdown-content z-50  relative ">
        <div>
          {links.map((li) => (
            <Link key={li.link} className="w-full" to={li.link}>
              <section className=" text-black py-2 border-b border-[#432315]/50 w-full ">
                {li.text}
              </section>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
