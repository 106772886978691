import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePostFormData from "../../../../utils/usePostForm";

export default function Owner() {
  const [form, setForm] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    whatsapp: "",
    name: "",
    position: "",
    description: "",
    photo: "",
  });
  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  let link = "global/platform_owner/show";
  const { data } = useFetchWithReload(link);
  useEffect(() => {
    if (data) {
      setForm({
        facebook: data.platform_owner.facebook,
        twitter: data.platform_owner.twitter,
        instagram: data.platform_owner.instagram,
        whatsapp: data.platform_owner.whatsapp,
        name: data.platform_owner.name,
        position: data.platform_owner.position,
        description: data.platform_owner.description,
        photo: "",
      });
    }
  }, [data]);
  const { postFormData, error, response } = usePostFormData(
    `superAdmin/platform_owner/update`
  );
  const updateHandeller = async () => {
    const formdata = new FormData();
    formdata.append("name", form.name);
    formdata.append("position", form.position);
    formdata.append("description", form.description);
    formdata.append("facebook", form.facebook);
    formdata.append("twitter", form.twitter);
    formdata.append("instagram", form.instagram);
    formdata.append("whatsapp", form.whatsapp);
    if (form.photo) {
      formdata.append("photo", form.photo);
    }
    await postFormData(formdata);
  };
  return (
    <div>
      {" "}
      <h1 className=" text-xl my-4 text-center font-bold "> مالك المنصة</h1>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32   ">
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الوظيفة
            </h1>
            <input
              name="position"
              value={form.position}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="  الوظيفة"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الاسم
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="  الاسم"
              required
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الوصف
            </h1>
            <input
              name="description"
              value={form.description}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="  الوصف"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الفيسبوك
            </h1>
            <input
              name="facebook"
              value={form.facebook}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="الفيسبوك"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تويتر
            </h1>
            <input
              name="twitter"
              value={form.twitter}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="الفيسبوك"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              انستجرام
            </h1>
            <input
              name="instagram"
              value={form.instagram}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="انستجرام"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط الواتس اب
            </h1>
            <input
              name="whatsapp"
              value={form.whatsapp}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder=" رابط الواتس اب"
              required
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير الصورة
            </h1>
            {form.photo ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, photo: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.photo)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, photo: e.target.files[0] });
                  }}
                />
              </section>
            )}
          </section>
        </form>
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            تعديل
          </button>
          {response && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم تغيير البيانات بنجاح</h1>
            </section>
          )}
          {error && (
            <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
              <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                <i className="fa-solid fa-exclamation  "></i>
              </span>
              {!!error.response.data.message
                ? error.response.data.message
                : "  هناك مشكلة"}
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
