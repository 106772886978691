import React, { useContext, useEffect, useState } from "react";
import usePostWithToken from "../../../../utils/usePostJson";
import { isLogged } from "../../../../utils/inStore";

export default function Send({ id, back }) {
  const [form, setForm] = useState({
    title: "",
    body: "",
  });
  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errs = {};
    if (!form.body) {
      errs.body = "برجاء ادخال تفاصيل الاشعار";
    }
    if (!form.title) {
      errs.title = "برجاء ادخال عنوان الاشعار ";
    }

    setErrors(errs);
    return Object.keys(errs).length === 0;
  };
  const { role } = useContext(isLogged);
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };
  const { postData, response } = usePostWithToken(
    `global/alliance/send-notification-to-alliance-members/${roles[role]}/${id}`
  );
  const [suc, setSuc] = useState(false);
  const updateHandeller = () => {
    if (validate()) {
      postData(form);
    }
  };
  useEffect(() => {
    if (response) {
      setSuc(true);
      setForm({
        guard: "camel_owner",
        user_ids: [],
        title: "",
        body: "",
      });
      setTimeout(() => {
        setSuc(false);
        back();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  return (
    <div>
      <section>
        <button onClick={back} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      <h1 className=" text-xl my-4 text-center font-bold ">
        ارسال اشعار للمشتركين{" "}
      </h1>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap   ">
          <section className="col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              عنوان الاشعار
            </h1>
            <input
              name="title"
              value={form.title}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="عنوان الاشعار"
              required
            />
            {<p className=" error ">{errors.title}</p>}
          </section>
          <section className="col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الاشعار
            </h1>
            <input
              name="body"
              value={form.body}
              onChange={handelChange}
              className=" inpt "
              type="text"
              placeholder="تفاصيل الاشعار"
              required
            />
            {<p className=" error ">{errors.body}</p>}
          </section>
        </form>{" "}
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            ارسال 
          </button>
          {suc && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم ارسال اشعار بنجاح</h1>
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
