import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import CopyBtn from "../../../components/pages/CopyBtn";
import NumBtn from "../../../components/pages/NumBtn";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";

export default function Info({ data, join }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("trip");
  const role = new URLSearchParams(search).get("role");

  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const [info, setInfo] = useState({ date: "", childs: 0, adults: 0 });
  const { date, childs, adults } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const { logged } = useContext(isLogged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {data.status === "upcoming" ? (
        <>
          {" "}
          {!join ? (
            <section className=" text-center ">
              {logged ? (
                <Link
                  to={`/book-trip?trip=${invest}&role=${role}&childs=${childs}&adults=${adults}&date=${date}`}
                >
                  <button className="btn2">حجز الرحلة</button>
                </Link>
              ) : (
                <button onClick={toggleRegisterMessage} className="btn2">
                  حجز الرحلة
                </button>
              )}
            </section>
          ) : (
            <></>
          )}
        </>
      ) : (
        <section className=" w-fit mx-auto text-xl px-3 py-1 text-red-700 border-red-700 font-bold border rounded-full ">
          منتهية
        </section>
      )}
      {/* <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
        التاريخ
      </h1> */}
      {/* <input
        type="date"
        name="date"
        value={date}
        onChange={handelChange}
        className=" my-4 w-full outline-none border rounded-[10px] p-2 border-black bg-black/0 "
      /> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          عدد الكبار
        </h1>
        <NumBtn
          val={adults}
          nameofVal="adults"
          handelChangeMain={handelChange}
          min={0}
        />
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          عدد الصغار
        </h1>
        <NumBtn
          val={childs}
          nameofVal="childs"
          handelChangeMain={handelChange}
          min={0}
        />
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العدد الكلي
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          <span className=" mx-2 ">{adults}</span> كبار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          <span className=" mx-2 ">{childs}</span> صغار
        </h1>
      </section>

      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          التكلفة الإجمالية
        </h1>
        <h1 className=" text-[#290900] font-[800] text-[24px] leading-[48px] ">
          {Number(data.price_adult) * adults +
            Number(data.price_child) * childs}{" "}
          ر.س
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          تكلفة الرحلة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          للبالغين <span className=" mx-2 ">{data.price_adult}</span> ر.س
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          للصغار <span className=" mx-2 ">{data.price_child}</span> ر.س
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          تاريخ الرحلة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.tour_date}
        </h1>
      </section>
      <CopyBtn />
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لحجز مكانك في الرحلة"
        />
      )}
    </div>
  );
}
