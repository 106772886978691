import React from "react";
import { useLocation } from "react-router";
import useFetchWithToken from "../../../../../utils/useGetWithToken";
import Error from "../../../../../components/Error/Error";
import DiscountPrice from "../../../../../components/pages/DiscountPrice";

export default function PackageDetailsSuper() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("package");

  let link = `superAdmin/packages/show/${invest}`;
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return <Error />;
  }
  let time = {
    days: "يوم",
    months: "شهر",
    years: "سنة",
  };
  return (
    <div className=" py-8 px-4 ">
      {data && (
        <div className=" text-[#432315] text-[24px] font-[600] ">
          <h1>اسم الباقة : {data.package.name}</h1>
          <p className=" md:max-w-[60%]  my-6  text-[20px] font-[600] leading-[28px] ">
            {data.package.description}
          </p>
          <p className="  text-[20px] font-[600] leading-[28px] my-2 ">
            مدة اشتراك الباقة :{" "}
            <span className=" text-[#432315] font-[800] ">
              {data.package.duration} {time[data.package.duration_type]}
            </span>
          </p>
          {data.package.discount_percentage &&
          Number(data.package.discount_percentage) > 0 ? (
            <p className=" flex items-center gap-2  text-[20px] font-[600] leading-[28px] my-4 ">
              السعر :{" "}
              <span className=" flex items-center gap-3  text-[#432315] font-[800] ">
                <DiscountPrice>{data.package.price}</DiscountPrice>{" "}
                {((100 - Number(data.package.discount_percentage)) / 100) *
                  data.package.price}{" "}
                ر.س
              </span>
            </p>
          ) : (
            <p className="  text-[20px] font-[600] leading-[28px] my-4 ">
              السعر :{" "}
              <span className=" text-[#432315] font-[800] ">
                {data.package.price} ر.س
              </span>
            </p>
          )}
          <h1 className=" my-2 text-center text-xl ">المميزات</h1>
          <table>
            <thead>
              <tr>
                <th>الميزة</th>
                <th>العدد</th>
              </tr>
            </thead>
            <tbody>
              {data.package.features.map((e, inx) => (
                <tr key={inx}>
                  <td className=" text-center ">{e.name}</td>
                  <td className=" text-center ">
                    {e.usage_limit ? e.usage_limit : "غير محدود"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
