import React, { useEffect, useState } from "react";
import usePostWithToken from "../../utils/usePostJson";
import NewSucces from "../../components/pages/NewSucces";
// import NewSucces from "../../components/pages/NewSucc5es";

export default function JoinBtns({ id, role, data }) {
  const { postData, response, error } = usePostWithToken(
    `global/events/join/${role}`
  );
  let isFree = Number(data.subscription_price) === 0;

  const handelJoin = () => {
    const data = {
      event_id: id,
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=event-details&type=event&value=${id}&role=${role}`,
    };
    postData(data);
  };
  const [suc, setSuc] = useState(false);
  useEffect(() => {
    if (response) {
      if (isFree) {
        setSuc(true);
      } else {
        window.location.href = response.redirect_url;
      }
    }
  }, [isFree, response]);

  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        الانضمام للفعالية
      </button>

      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message
            ? error.response.data.message
            : "هناك مشكلة"}
        </section>
      )}
      {suc && <NewSucces />}
    </div>
  );
}
