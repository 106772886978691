import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { load } from "../../utils/isLoadingStore";
import Loader from "../../components/Loader/Loader";
import useFetchGlobal from "../../utils/useGetGlobal";

export default function View({ selectedId, setSelectedId, ownerType }) {
  const { isLoading } = useContext(load);
  // const data = {
  //   id: 4,
  //   src: "./imgs/profile/event.jfif",
  //   user: { name: "فهد الظاهري ابراهيم", img: "./imgs/profile/user11.jpg" },
  // };
  const { data, error } = useFetchGlobal(`global/media/show/${selectedId}/${ownerType}`);
  useEffect(() => {
    if (!!selectedId) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup function to ensure scroll is enabled when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedId]);

  if (!selectedId) return null;

  const shareUrl = window.location.href + `?item=${selectedId}&owner=${ownerType}`;
  const title = "";

  return ReactDOM.createPortal(
    <div className=" almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0 ">
      {isLoading && <Loader />}
      <section className=" max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8D6B2] w-full rounded-[16px] ">
        <button
          onClick={() => setSelectedId(null)}
          className=" text-xl font-bold text-red-600 absolute top-4 right-4 "
        >
          X
        </button>

        {/* <section className=" my-8 ">
          <section className=" w-full flex items-center gap-3 ">
            <img
              alt="img publisher "
              src={data.user.img}
              className="h-[40px] w-[40px] rounded-full "
            />
            
            <h1 className=" text-[18px] font-[400] text-[#432315] ">
              {data.user.name}
            </h1>
          </section>
        </section> */}
        {error && (
          <h1 className=" text-center text-2xl text-red-500 my-3 ">
            هذا المحتوي غير موجود 
          </h1>
        )}
        <section className="  flex items-center ">
          {data && (
            <>
              {data.media.media_type === "image" ? (
                <img
                  alt=""
                  className=" mx-auto rounded-[16px] max-w-full max-h-[500px] "
                  src={`${process.env.REACT_APP_URL}/${data.media.media_path}`}
                />
              ) : (
                <video
                  className=" mx-auto rounded-[16px] max-w-full max-h-[500px] "
                  src={`${process.env.REACT_APP_URL}/${data.media.media_path}`}
                  controls
                />
              )}
            </>
          )}
        </section>
        <div className="flex items-center gap-4 my-3">
          <section className=" border border-[#432315] rounded-[5px] text-[#663520] px-[12px] py-[2px] ">
            <i className="fa-solid fa-share-nodes ml-2 "></i>
            مشاركة
          </section>

          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={title}
            className="share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
      </section>
    </div>,
    document.body // Renders the component at the root level of the DOM
  );
}
