import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loader from "../../../../../components/Loader/Loader";
import { load } from "../../../../../utils/isLoadingStore";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import usePostWithToken from "../../../../../utils/usePostJson";
export default function AddPackageManually({ user, close, type, re }) {
  const { isLoading } = useContext(load);
  const role = {
    camel_owner: "مالك ابل",
    visitor: "زائر",
    service_provider: "مزود خدمة",
    field_owner: "مالك ساحة",
  };

  const [packageId, setPackageId] = useState(null);
  const [packages, setPackages] = useState([]);

  let link = "superAdmin/packages/all";
  const { data } = useFetchWithReload(link);

  useEffect(() => {
    if (data) {
      setPackages(() =>
        data.packages.filter((pack) => pack.user_type === type)
      );
    }
  }, [data, type]);
  const { postData, response, error } = usePostWithToken(
    "superAdmin/packages/activate"
  );

  const handleSubmit = () => {
    const data = {
      guard: type,
      user_id: user.id,
      package_id: +packageId,
    };
    postData(data);
    // console.log(data);
  };

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        close();
        re();
      }, [1000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        {" "}
        <h1 className=" text-xl my-2 ">اضافة باقة الي المستخدم</h1>
        <section className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم المستخدم : {user.first_name} {user.last_name}
            </h1>
          </section>
          <section>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رقم الهاتف : {user.phone}
            </h1>
          </section>
          <section>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              البريد الاكتروني : {user.email}
            </h1>
          </section>
          <section>
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نوع المستخدم : {role[user.role]}
            </h1>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الباقات
            </h1>
            <select
              onChange={(e) => setPackageId(e.target.value)}
              value={packageId}
              className=" w-full bg-black/0 outline-none text-xl p-2 border border-black/70 rounded-md "
            >
              <option hidden>اختيار الباقة</option>
              {packages.map((pack) => (
                <option value={pack.id}>{pack.name}</option>
              ))}
            </select>
          </section>
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button
            disabled={!packageId}
            onClick={handleSubmit}
            className="btn9 disabled:opacity-80 "
          >
            اضافة
          </button>
          <button onClick={close} className="btn8">
            الغاء
          </button>
        </section>
        {response && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-green-800 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-green-800 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-check"></i>
            </span>
            تم اضافة الباقة الي المستخدم بنجاح
          </section>
        )}
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
