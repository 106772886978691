import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import Error from "../../../../../components/Error/Error";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default function PendingFiled() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("filed");
  let link = "superAdmin/field/setting/pending/index";
  const { data, error } = useFetchWithReload(link);
  const [sho, setShow] = useState([{}]);
  useEffect(() => {
    if (!!data) {
      let item = data.filter((e) => +e.id === +invest);
      setShow(item);
      console.log(item);
    }
  }, [data, invest]);

  if (error || sho.length === 0) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1  w-deta  ">
          <section>
            {data && (
              <div className=" mb-3 ">
                {sho[0].details_images && (
                  <>
                    {sho[0].details_images.length > 0 && (
                      <Swiper
                        pagination={{
                          dynamicBullets: true,
                        }}
                        autoplay={{
                          delay: 4000, // Time delay in milliseconds
                          disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper "
                      >
                        {sho[0].details_images.map((e, index) => (
                          <SwiperSlide className="  " key={index}>
                            <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                              <img
                                className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                                key={e}
                                src={`${process.env.REACT_APP_URL}/${e}`}
                                alt="cover"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </>
                )}
                <section>
                  <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
                    عن {sho[0].name}
                  </h1>
                  <div
                    className="styled-content rtl leading-loose relative py-4  "
                    dangerouslySetInnerHTML={{ __html: sho[0].details }}
                    style={{ direction: "rtl", textAlign: "right" }}
                  />
                </section>
              </div>
            )}
          </section>
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && (
            <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  مساحة
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].space}
                </h1>
              </section>
              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  طول مضمار
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].race_track_length}
                </h1>
              </section>
              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  طول مضمار التدريبي
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].training_track_length}
                </h1>
              </section>
              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  سعة المدرجات
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].spectator_capacity} متفرج
                </h1>
              </section>
              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  المدينة
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].city}
                </h1>
              </section>

              <section className=" my-4 ">
                <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
                  العنوان
                </h1>
                <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
                  {sho[0].address}
                </h1>
              </section>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
