import React from "react";

export default function TabBtn({ val, tab, setTabState, text, icon }) {
  return (
    <button
      onClick={() => setTabState(val)}
      className={` w-[230px] h-[62px] rounded-[16px] my-2 text-[16px] font-[600] leading-[30px] ${
        tab === val
          ? " bg-[#432315] text-[#E8DCC0]"
          : " bg-[#E8DCC0] text-[#290900] "
      } `}
    >
      {icon && <i className={`${icon} ml-2 `} />}
      {text}
    </button>
  );
}
