import React from "react";

export default function ProfileEvent({ data }) {
  return (
    <div className=" flex flex-wrap items-start gap-3 w-[320px] md:w-[510px] border border-[#432315]/20 p-2  rounded-[15px] ">
      <img
        src={data.img}
        alt="Event"
        className=" rounded-[15px] h-[220px] w-full md:w-[255px] "
      />
      <section className=" flex-1 " >
        <section className=" text-[17px] text-[#432315] font-[800] my-2 ">
          <h1>{data.name}</h1>
        </section>
        <section className=" text-[12px] text-[#432315] font-[500] leading-[22px] my-1 ">
          <h1>{data.desc}</h1>
        </section>
        <section className=" flex items-center gap-1 text-[15px] text-[#432315] font-[600] leading-[22px] my-1 ">
          <h1>تاريخ البداية : </h1>
          <h1>{data.dateStart}</h1>
        </section>
        <section className=" flex items-center gap-1 text-[15px] text-[#432315] font-[600] leading-[22px] my-1 ">
          <h1>تاريخ الانتهاء : </h1>
          <h1>{data.dateEnd}</h1>
        </section>
        <section className=" flex items-center gap-1 text-[13px] text-[#432315] font-[500] leading-[18px] mt-2 ">
          <i className=" text-xl fa-solid fa-location-dot " />
          <h1>{data.loc}</h1>
        </section>
      </section>
    </div>
  );
}
