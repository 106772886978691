import React, { useState } from "react";
import useFetchWithReload from "../../../../../../utils/useGetWithReload";
import Accept from "./Accept";
import Reject from "./Reject";
import { Link } from "react-router-dom";

export default function Services() {
  let link = "superAdmin/approves/serviceProviders/pending";
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(link, reload);
  const re = () => {
    setReload((pre) => !pre);
  };
  const types = {
    veterinary: "طب بيطري",
    freelancer: "مستقل",
    expert: "خبير",
  };

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div>
      {data && (
        <table>
          <thead>
            <tr>
              <th>الاسم</th>
              <th>رقم الهاتف</th>
              <th>الجنسية</th>
              <th>النوع</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {data.pending_service_providers && (
              <>
                {data.pending_service_providers.length > 0 && (
                  <>
                    {data.pending_service_providers.map((user) => (
                      <tr key={user.id}>
                        <td className=" text-center ">
                          <Link
                            className="underline"
                            to={`/pending-accounts?account=${user.id}`}
                          >
                            {user.first_name} {user.last_name}
                          </Link>
                        </td>
                        <td className=" text-center ">{user.phone}</td>
                        <td className=" text-center ">{user.nationality}</td>
                        <td className=" text-center ">{types[user.type]}</td>
                        <td className="flex items-center justify-center gap-2 ">
                          <Accept id={user.id} re={re} />
                          <Reject id={user.id} re={re} />
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
