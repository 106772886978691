import React from "react";
import Doctors from "../../../components/pages/Veterinary/Doctors";

export default function Cont({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <Doctors key={inx} data={e} />
      ))}
    </div>
  );
}
