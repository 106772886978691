import React, { useEffect } from "react";
import usePostWithToken from "../../../../../utils/usePostJson";

export default function ReSub() {
  const { postData, response, error } = usePostWithToken(
    `global/packages/reNewSubscription`
  );
  const handelJoin = () => {
    const data = {
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=my-package&type=package&value=myPackage&role=package`,
    };
    postData(data);
  };
  useEffect(() => {
    if (response) {
      console.log(response);
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        تجديد الاشتراك
      </button>
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message
            ? error.response.data.message
            : "هناك مشكلة"}
        </section>
      )}
    </div>
  );
}
