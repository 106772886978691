import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
import { useLocation, useNavigate } from "react-router";
import Error from "../../../../components/Error/Error";
import Tab from "../../../../components/pages/Tab";
import Services from "./Pages/Service/Services";
import Filds from "./Pages/fields/Filds";

export default function PendingRequest() {
  const { logged, role } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (!logged) {
      navigation("/login");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type") || 1;
  const [tab, setTab] = useState(+type);
  const tabs = ["مقدمين الخدمات", "الساحات"];

  if (role !== "super_admin") {
    return <Error />;
  }

  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-[#432315] ">
        الطلبات المعلقة
      </h1>
      {/* Tabs */}
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <Tab
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>{tab === 1 && <Services />}</div>
      <div>{tab === 2 && <Filds />}</div>
    </div>
  );
}
