import React from "react";
import { Link } from "react-router-dom";

export default function MainNewComponent({ data }) {
  function TruncatedParagraph({ text }) {
    // Split the text into an array of words
    const words = text.split(" ");

    // Check if the number of words exceeds maxWords
    const shouldTruncate = words.length > 80;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? words.slice(0, 80).join(" ") + "...."
      : text;

    return truncatedText;
  }
  return (
    <div className=" flex flex-wrap items-start gap-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full lg:w-1/2 h-[250px] lg:h-[380px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
      </div>
      {/* Info section */}
      <div className=" flex-1 ">
        {/* Head Section */}
        <section className=" w-full flex gap-1 my-2 items-center justify-between flex-wrap  ">
          <section className=" w-fit flex items-center gap-3 ">
            <h1 className=" text-[18px] font-[400] text-[#432315] ">
              {data.created_at.substring(0, 10)}
            </h1>
          </section>
        </section>
        {/* Tiltle */}
        <h1 className=" my-3 text-[#000000] text-[32px] font-[700] ">
          {data.title}
        </h1>
        <p className=" text-[17px] text-[#432315] font-[600] leading-[29.98px] ">
          <TruncatedParagraph text={data.bio} />{" "}
          <Link
            className=" text-[#432315] font-[700] text-[17px] underline "
            to={`/news-deatils?news=${data.id}`}
          >
            اقراء المزيد
          </Link>
        </p>
      </div>
    </div>
  );
}
