import React from "react";
import './style.css'
export default function Loader() {
  return (
    <div className=" fixed top-0 left-0 z-[1000] h-screen w-full bg-black/30 flex items-center justify-center">
      <section className=" loader " >
        <svg className="ll" viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
      </section>
    </div>
  );
}
