import React from "react";
import CamelComponent from "../../components/pages/OwnerProfile/CamelComponent";
import Profile from "./Profile";

export default function AllCamelsOfOwner() {
  const camels = [
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
    {
      name: " جمال الصحراء",
      type: "هجن",
      color: "بني فاتح",
      age: "سنوات 5",
      gender: "ذكر",
      strain: "نجدية",
      img: "./imgs/home/user2.png",
    },
  ];

  return (
    <div>
      <Profile />

      <section className="  my-20 flex gap-x-8  items-center justify-center   flex-wrap  ">
        {camels.map((camel, inx) => (
          <CamelComponent key={inx} data={camel} />
        ))}
      </section>
    </div>
  );
}
