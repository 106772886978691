import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import usePutWithToken from "../../../../utils/usePutJson";
import usePostWithToken from "../../../../utils/usePostJson";

export default function Policies() {
  let link = "global/policies";
  const { data } = useFetchWithReload(link);
  const [form, setForm] = useState({
    terms_of_use: "",
    privacy_policy: "",
  });
  const handelChanegeDetails = (value) => {
    setForm({ ...form, terms_of_use: value });
  };
  const handelChanegePolice = (value) => {
    setForm({ ...form, privacy_policy: value });
  };
  useEffect(() => {
    if (data) {
      setForm({
        terms_of_use: data.terms_of_use,
        privacy_policy: data.privacy_policy,
      });
    }
  }, [data]);
  const { putData, response } = usePutWithToken(`superAdmin/policies/update`);
  const { postData, response: add } = usePostWithToken(
    `superAdmin/policies/create`
  );

  const updateHandeller = () => {
    if (!data.terms_of_use) {
      postData(form);
    } else {
      putData(form);
    }
  };

  // if (!data.terms_of_use) {
  //   return (
  //     <div>
  //       <h1 className=" text-red-600 text-xl font-bold text-center ">
  //         هناك مشكلة
  //       </h1>
  //     </div>
  //   );
  // }
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold ">
        سياسات الاستخدام والخصوصية
      </h1>
      <div className="bg-[#E8DCC0] rounded-[15px]  p-4 mx-auto max-w-[1100px]">
        <h1 className=" text-center my-2 text-lg ">سياسة الاستخدام</h1>
        <ReactQuill
          value={form.terms_of_use}
          onChange={handelChanegeDetails}
          className="text-editor  h-[300px] mb-16  rtl"
          style={{ direction: "rtl", textAlign: "right" }}
        />
        <h1 className=" text-center my-2 text-lg ">سياسة الخصوصية</h1>
        <ReactQuill
          value={form.privacy_policy}
          onChange={handelChanegePolice}
          className="text-editor  h-[300px] mb-16  rtl"
          style={{ direction: "rtl", textAlign: "right" }}
        />
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            تعديل
          </button>
          {(response || add) && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم تغيير البيانات بنجاح</h1>
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
