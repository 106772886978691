import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { load } from "../../../../utils/isLoadingStore";
import FileUploded from "../../../../components/pages/FileUploded";
import Loader from "../../../../components/Loader/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";

export default function AddClinc({ close }) {
  const [form, setForm] = useState({
    name: "",
    location: "",
    specializations: [],
    bio: "",
    details: "",
    card_image: "",
    details_images: [],
    doctor_certifications: [],
    phone: "",
    email: "",
    address: "",
    location_map_link: "",
  });

  const { role } = useContext(isLogged);
  let link = "";
  let linkToFetch = "";

  if (role === "provider") {
    link = "serviceProvider/veterinaryMedicine/clinics/create";
    linkToFetch = "serviceProvider/veterinaryMedicine/specialization/all";
  }
  if (role === "super_admin") {
    link = "superAdmin/veterinaryMedicine/clinics/create";
    linkToFetch = "superAdmin/veterinaryMedicine/clinics/specialization/all";
  }
  const { data } = useFetchWithToken(linkToFetch);
  const [specs, setSpecs] = useState([]);
  useEffect(() => {
    if (!!data) {
      setSpecs(data);
    }
  }, [data, role]);

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setForm({ ...form, details: value });
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!form.name) {
      formErrors.name = "الرجاء إدخال اسم العيادة";
    }

    // Validate location
    if (!form.location) {
      formErrors.location = "الرجاء إدخال الموقع";
    }

    // Validate specializations
    if (!form.specializations || form.specializations.length === 0) {
      formErrors.specializations = "الرجاء اختيار التخصصات";
    }

    // Validate bio
    if (!form.bio) {
      formErrors.bio = "الرجاء إدخال نبذة عن العيادة";
    }

    // Validate card image
    if (!form.card_image) {
      formErrors.card_image = "الرجاء رفع المصغرة للعيادة";
    }

    // Validate details images
    if (!form.details_images || form.details_images.length === 0) {
      formErrors.details_images = "الرجاء رفع صور تفاصيل العيادة";
    }

    // Validate doctor certifications
    if (
      !form.doctor_certifications ||
      form.doctor_certifications.length === 0
    ) {
      formErrors.doctor_certifications = "الرجاء إدخال شهادات العيادة";
    }

    // Validate phone
    if (!form.phone) {
      formErrors.phone = "الرجاء إدخال رقم الهاتف";
    }

    // Validate email
    if (!form.email) {
      formErrors.email = "الرجاء إدخال البريد الإلكتروني";
    }

    // Validate address
    if (!form.address) {
      formErrors.address = "الرجاء إدخال العنوان";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();

  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();
      formdata.append("name", form.name);
      formdata.append("email", form.email);
      formdata.append("location", form.location);
      formdata.append("address", form.address);
      formdata.append("bio", form.bio);
      formdata.append("details", form.details);
      formdata.append("phone", form.phone);
      formdata.append("card_image", form.card_image);
      formdata.append("location_map_link", form.location_map_link);
      form.specializations.forEach((file) => {
        formdata.append("specializations[]", file); // Check if your server expects "event_files" or "event_files[]"
      });
      form.details_images.forEach((file) => {
        formdata.append("details_images[]", file); // Check if your server expects "event_files" or "event_files[]"
      });
      form.doctor_certifications.forEach((file) => {
        formdata.append("doctor_certifications[]", file); // Check if your server expects "event_files" or "event_files[]"
      });

      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      navigate(`/hospital-details?hospital=${response.id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة عيادة</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {form.card_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, card_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.card_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, card_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم العيادة
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم العيادة"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" العنوان"
              required
            />
            {<p className="error">{errors.address}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المدينة
            </h1>
            <select
              name="location"
              value={form.location}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden> المدينة </option>
              <option value={"الرياض"}>الرياض</option>
              <option value={"جدة"}>جدة</option>
              <option value={"الامارات"}>الامارات</option>
              <option value={"قطر"}>قطر</option>
              <option value={"البحرين"}>البحرين</option>
              <option value={"اخري"}>اخري</option>
            </select>

            {<p className="error">{errors.location}</p>}
          </section>

          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط العنوان من الخريطة
            </h1>
            <input
              name="location_map_link"
              value={form.location_map_link}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" رابط العنوان من الخريطة"
              required
            />
            {<p className="error">{errors.location_map_link}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن العيادة
            </h1>
            <textarea
              name="bio"
              cols={2}
              value={form.bio}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن العيادة "
              required
            />
            {<p className="error">{errors.bio}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل العيادة
            </h1>
            <ReactQuill
              value={form.details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رقم الهاتف
            </h1>
            <input
              name="phone"
              value={form.phone}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" رقم الهاتف"
              required
            />
            {<p className="error">{errors.phone}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              البريد الاكتروني
            </h1>
            <input
              name="email"
              value={form.email}
              onChange={handelChanege}
              className=" inpt "
              type="email"
              placeholder=" البريد الاكتروني"
              required
            />
            {<p className="error">{errors.email}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              التخصصات
            </h1>
            <section className=" flex items-center justify-start gap-2 flex-wrap ">
              {form.specializations.map((e, inx) => {
                let name = specs.filter((item) => +item.id === +e);
                // console.log(name);
                return (
                  <h1
                    key={inx}
                    className=" border border-black/50 p-2 rounded-md "
                  >
                    {name[0].name}
                  </h1>
                );
              })}
            </section>

            <section className=" max-w-[300px]  ">
              <select
                className="inpt"
                onChange={(e) => {
                  setForm({
                    ...form,
                    specializations: [...form.specializations, e.target.value],
                  });
                }}
              >
                <option hidden>التخصصات</option>
                {specs.map((e) => (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </section>

            {<p className="error">{errors.specializations}</p>}
          </section>

          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور العيادة
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_images.map((e, inx) => {
                // console.log(e.name);
                return (
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.details_images;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        details_images: da,
                      });
                    }}
                  />
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_images: [
                        ...form.details_images,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
            {<p className="error">{errors.details_images}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              شهادات وملفات العيادة
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.doctor_certifications.map((e, inx) => {
                // console.log(e.name);
                return (
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.doctor_certifications;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        doctor_certifications: da,
                      });
                    }}
                  />
                );
              })}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      doctor_certifications: [
                        ...form.doctor_certifications,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
            {<p className="error">{errors.doctor_certifications}</p>}
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error
                      ? error.response.data.error
                      : "  هناك مشكلة"}
                  </>
                ) : (
                  <>هناك مشكلة</>
                )}
              </>
            ) : (
              <>هناك مشكلة</>
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
