import { Rating } from "@mui/material";
import React from "react";

export default function CommentComponenet({ data }) {
  return (
    <div className=" my-2 shadow-md w-full bg-[#EEE0C3] p-[16px] rounded-[16px] ">
      <section className=" flex items-center gap-3 ">
        <section className=" w-full flex items-center justify-between ">
          {data.img ? (
            <img
              src={data.img}
              alt="user"
              className=" h-[40px] w-[40px] rounded-full"
            />
          ) : (
            <section className=" h-[40px] w-[40px] flex items-center justify-center rounded-full bg-[#F2F2F2] text-[#C2A747] overflow-hidden ">
              <i className="fa-solid fa-user text-2xl "></i>
            </section>
          )}
          <section className=" ltr flex justify-end ">
            <Rating
              name="read-only"
              value={+data.rate}
              precision={0.5}
              readOnly
            />
          </section>
        </section>

        <section>
          <h1 className=" text-[18px] font-[600] text-[#0D0C22] ">
            {data.name}
          </h1>
          <h1 className=" text-[10px] font-[500] text-[#432315] ">
            {data.country}
          </h1>
        </section>
      </section>
      <section className=" my-4 flex items-center gap-2 md:gap-6  ">
        <p className=" flex-1 tex-[15px] md:text-[24px] font-[500] text-[#432315] ">
          {data.comment}
        </p>
        <h4 className=" text-[12px] font-[500] text-[#432315] ">
          في {data.created_at.substring(0, 10)}
        </h4>
      </section>
    </div>
  );
}
