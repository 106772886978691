import React from "react";

export default function TabJoin({ val, tab, setTabState, text }) {
  return (
    <button
      onClick={() => setTabState(val)}
      className={` p-3 border-b my-2 text-[14px] font-[600] leading-[30px] ${
        tab === val
          ? " border-[#27160e] text-[#27160e]"
          : "border-[#27160e]/0 text-[#27160e]/50"
      } `}
    >
      {text}
    </button>
  );
}