import React from "react";

export default function Monthes({ curent, month, setMonthState }) {
  const monthNamesArabic = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  const Btn = ({ inx }) => {
    let num = inx;
    if (inx > 11) {
      num = inx - 12;
    }
    return (
      <button
        onClick={() => setMonthState(num + 1)}
        className={` ${
          month === num + 1 ? " bg-[#432315] text-[#FFFCF7] " : "text-[#432315]"
        } text-[20px] p-4 md:text-[36px] transition-all ease-in-out duration-500 font-[600] md:w-[210px] md:h-[110px] rounded-[15px] border-[1px] border-[#432315] border-b-[3px]  `}
      >
        {monthNamesArabic[num]}
      </button>
    );
  };
  return (
    <div className=" mt-20 ">
      <div className=" flex items-center justify-center gap-4 flex-wrap ">
        <Btn inx={curent} />
        <Btn inx={curent + 1} />
        <Btn inx={curent + 2} />
      </div>
      <h1 className=" text-center mt-6 text-[22px] text-[#432315] font-[600] md:text-[32px] ">
        شهر {monthNamesArabic[month - 1]}
      </h1>
    </div>
  );
}
