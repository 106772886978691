import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUploded from "../../../../components/pages/FileUploded";
import usePostFormData from "../../../../utils/usePostForm";
import Loader from "../../../../components/Loader/Loader";
import { load } from "../../../../utils/isLoadingStore";
import { isLogged } from "../../../../utils/inStore";
import { useNavigate } from "react-router";
import useFetchWithToken from "../../../../utils/useGetWithToken";

export default function EditPartnerShip({ close, id, set }) {
  const [form, setForm] = useState({
    name: "",
    percentage: "",
    description: "",
    details: "",
    address: "",
    published_date: "",
    card_image: "",
    alliance_files: [],
    details_images: [],
  });
  const [details, setDetails] = useState("");
  let linkToFetch = "";
  const { role } = useContext(isLogged);
  if (role === "camel-owner") {
    linkToFetch = `camelOwner/alliances/show/${id}`;
  }
  if (role === "squer") {
    linkToFetch = `field-owner/alliances/show/${id}`;
  }
  if (role === "provider") {
    linkToFetch = `serviceProvider/alliances/show/${id}`;
  }
  if (role === "vistor") {
    linkToFetch = `visitor/alliances/show/${id}`;
  }
  if (role === "super_admin") {
    linkToFetch = `superAdmin/alliances/show/${id}`;
  }
  const { data } = useFetchWithToken(linkToFetch);
  useEffect(() => {
    if (!!data) {
      setDetails(data.details);
      setForm({
        name: data.name,
        percentage: data.percentage,
        description: data.description,
        details: "",
        address: data.address,
        published_date: data.published_date,
        card_image: "",
        alliance_files: [],
        details_images: [],
      });
    }
  }, [data]);

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };

  // Manual validation logic
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // // Validate name
    // if (!form.name) {
    //   formErrors.name = "الرجاء إدخال اسم التحالف";
    // }

    // // Validate percentage
    // if (!form.percentage) {
    //   formErrors.percentage = "الرجاء إدخال النسبة التحالف";
    // } else if (
    //   isNaN(form.percentage) ||
    //   form.percentage <= 0 ||
    //   form.percentage > 100
    // ) {
    //   formErrors.percentage = "يجب أن تكون النسبة المئوية رقماً بين 1 و 100";
    // }

    // // Validate description
    // if (!form.description) {
    //   formErrors.description = "الرجاء إدخال وصف التحالف";
    // }

    // // Validate details
    // if (!form.details) {
    //   formErrors.details = "الرجاء إدخال تفاصيل التحالف";
    // }

    // // Validate address
    // if (!form.address) {
    //   formErrors.address = "الرجاء إدخال العنوان";
    // }

    // // // Validate card image
    // // if (!form.card_image) {
    // //   formErrors.card_image = "الرجاء رفع الصورة المصغرة للتحالف";
    // // }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  let link = "";
  if (role === "camel-owner") {
    link = `camelOwner/alliances/update/${id}`;
  }
  if (role === "squer") {
    link = `field-owner/alliances/update/${id}`;
  }
  if (role === "provider") {
    link = `serviceProvider/alliances/update/${id}`;
  }
  if (role === "vistor") {
    link = `visitor/alliances/update/${id}`;
  }
  if (role === "super_admin") {
    link = `superAdmin/alliances/update/${id}`;
  }

  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();
  const { postFormData, error, response } = usePostFormData(link);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();
      formdata.append("name", form.name);
      formdata.append("percentage", form.percentage);
      formdata.append("description", form.description);
      formdata.append("details", details);
      formdata.append("address", form.address);
      //   formdata.append("published_date", form.published_date);
      // Append investment files and details images correctly
      form.alliance_files.forEach((file) => {
        formdata.append("alliance_files[]", file);
      });

      form.details_images.forEach((image) => {
        formdata.append("details_images[]", image);
      });
      if (!!form.card_image) {
        formdata.append("card_image", form.card_image);
      }

      await postFormData(formdata);
    }
  };

  useEffect(() => {
    if (!!response) {
      navigate(
        `/partnership-details?partnership=${response.alliance.id}&role=${roles[role]}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل تحالف</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير الصورة المصغرة
            </h1>
            {form.card_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, card_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.card_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, card_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم التحالف
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم التحالف"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نسبة التحالف
            </h1>
            <input
              name="percentage"
              value={form.percentage}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              placeholder=" نسبة التحالف"
              required
            />
            {<p className="error">{errors.percentage}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن التحالف
            </h1>
            <textarea
              name="description"
              cols={2}
              value={form.description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن التحالف "
              required
            />
            {<p className="error">{errors.description}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل التحالف
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="العنوان"
              required
            />
            {<p className="error">{errors.address}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير صور التحالف
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_images.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_images;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_images: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_images: [
                        ...form.details_images,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير ملفات التحالف
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.alliance_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.alliance_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        alliance_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      alliance_files: [
                        ...form.alliance_files,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            تعديل
          </button>
          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data ? (
              <>
                {!!error.response.data.message
                  ? error.response.data.message
                  : "  هناك مشكلة"}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
