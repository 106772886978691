import React, { useState } from "react";
import TabJoin from "../../../../../components/pages/TabOfJoins";
import InvestDashboard from "../../../pages/invest/InvestDashboard";
import InvestReports from "./InvestReports";
import Pendings from "./Pendings";

export default function InvestDashSuper() {
  const [tab, setTab] = useState(1);
  const tabs = ["تقارير الاستثمارات", "المدفوعات المعلقة", "الاستثمارات"];
  return (
    <div>
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
        {tabs.map((e, inx) => (
          <TabJoin
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>{tab === 1 && <InvestReports />}</div>
      <div>{tab === 2 && <Pendings />}</div>
      <div>{tab === 3 && <InvestDashboard />}</div>
    </div>
  );
}
