import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import File from "../../../components/pages/File";

export default function InvestInfo({ data }) {
  return (
    <div className=" mb-3 ">
      {JSON.parse(data.details_image).length > 0 && (
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 4000, // Time delay in milliseconds
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper "
        >
          {JSON.parse(data.details_image).map((e, index) => (
            <SwiperSlide className="  " key={index}>
              <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                <img
                  className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                  key={e}
                  src={`${process.env.REACT_APP_URL}/${e}`}
                  alt="cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <section>
        {/* <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
          نبذة عن الاستثمار
        </h1> */}
        <div
          className="styled-content rtl leading-loose relative py-4  "
          dangerouslySetInnerHTML={{ __html: data.details }}
          style={{ direction: "rtl", textAlign: "right" }}
        />
        
        {JSON.parse(data.investment_files).length > 0 && (
          <>
            <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
              ملفات تعريفية للاستثمار
            </h1>
            <section className=" flex gap-2 flex-wrap ">
              {JSON.parse(data.investment_files).map((e, inx) => (
                <File key={inx} da={`${process.env.REACT_APP_URL}/${e}`} />
              ))}
            </section>
          </>
        )}
      </section>
    </div>
  );
}
