import { useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { load } from "./isLoadingStore";

const usePostFormData = (endPoint) => {
  const { setIsLoading } = useContext(load);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const postFormData = async (formData) => {
    setIsLoading(true);
    let token = Cookies.get("token");
    setError(null);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/${endPoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResponse(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { postFormData, error, response };
};

export default usePostFormData;
