import React from "react";
import StoreComponents from "../../components/pages/Stores/StoreComponents";

export default function AllStores({ alldata , categers }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap ">
      {alldata.map((e, inx) => (
        <StoreComponents key={inx} categers={categers} data={e} />
      ))}
    </div>
  );
}
