import React, { useEffect } from "react";
import usePostWithToken from "../../../../../../utils/usePostJson";

export default function Details({ type, reload }) {
  const { postData, response, error } = usePostWithToken(
    `superAdmin/Report/package/revenue_for_guard`
  );
  const getData = () => {
    const data = {
      guard_type: type,
    };
    postData(data);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" my-8 ">
      {response && (
        <>
          <h1 className=" text-xl my-3 font-[900] text-center ">
            اجمالي الارباح : {response.total_revenue} ر.س
          </h1>
          {response.details && (
            <table>
              <thead>
                <tr>
                  <th>اسم الباقة</th>
                  <th>سعر الباقة</th>
                  <th>عدد مرات الشراء</th>
                  <th>الارباح</th>
                </tr>
              </thead>
              <tbody>
                {response.details.map((pa, inx) => (
                  <tr key={inx}>
                    <td className=" text-center ">{pa.package_name}</td>
                    <td className=" text-center ">{pa.package_price} ر.س</td>
                    <td className=" text-center ">{pa.subscription_count}</td>
                    <td className=" text-center ">{pa.package_revenue} ر.س</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
}
