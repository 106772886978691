import React from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";

export default function Accepted() {
  let link = "superAdmin/field/setting/accepted/index";

  const { data, error } = useFetchWithReload(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" p-6 ">
      <h1 className=" text-center my-2 text-lg font-bold ">الطلبات المقبولة</h1>
      {data && (
        <table>
          <thead>
            <tr>
              <th>اسم الساحة</th>
              <th>تاريخ القبول</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, inx) => (
              <tr key={inx}>
                <td className="text-center"> {e.name}</td>
                <td className=" flex items-center justify-center gap-2 ">
                  {e.updated_at.substring(0, 10)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <h1 className=" text-center my-4 text-red-600 text-lg ">
              لا توجد ساحات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
