import React from "react";
import Hr from "../../components/pages/Hr";
import New from "../../components/pages/Home/New";

export default function News() {
  const cards = [
    {
      img: "./imgs/home/race.png",
      // img: "./imgs/home/owners.jfif",
      name: "اخر الاخبار",
      title: " الاخبار",
      text1:
        "   اطلع علي اخر الاخبار والاحداث المتعلقة بعالم الابل و علي السباقات والفعاليات",
      text2:
        "انضم إلينا وكن جزءًا من هذا المجتمع المتميز الذي يحتفي بتراثنا وجمال الإبل",
      link: "/news",
    },
    {
      // img: "./imgs/swipper/5.jpg",
      img: "./imgs/home/event.png",
      name: "اخر الفعاليات",
      title: " الفعاليات",
      text1:
        // "تعرف على آخر فعاليات الهجن والأحداث المثيرة التي تجمع عشاق الإبل والهجن",
        ` تعرف وتابع الأحداث التي تجمع عشاق الابل وتحدث الحراك الثقافي
              والتجاري وغيرها`,
      text2: "",
      // "انضم إلينا وكن جزءًا من هذا المجتمع المتميز الذي يحتفي بتراثنا وجمال الإبل",
      link: "/events",
    },
  ];
  return (
    <div className=" p-4 md:p-12  text-center ">
      <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
        اخر الاخبار و الفعاليات
      </h1>
      <Hr color="#432315" />
      <div className=" p-6 flex items-center justify-center gap-6 flex-wrap ">
        {cards.map((ca, inx) => (
          <New key={inx} data={ca} />
        ))}
      </div>
    </div>
  );
}
