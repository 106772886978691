import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { load } from "./isLoadingStore";

const useFetchGlobal = (endPoint, options = {}) => {
  const [data, setData] = useState(null);
  const { setIsLoading } = useContext(load);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/${endPoint}`,
          options
        );

        setData(response.data); // Save fetched data
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint]);

  return { data, error };
};

export default useFetchGlobal;
