import React from "react";
import { Link } from "react-router-dom";

export default function EventDetailsComponent({ data }) {
  const daysInArabic = [
    "الأحد", // Sunday
    "الاثنين", // Monday
    "الثلاثاء", // Tuesday
    "الأربعاء", // Wednesday
    "الخميس", // Thursday
    "الجمعة", // Friday
    "السبت", // Saturday
  ];
  const indexOfStartDay = new Date(data.start_date).getDay();
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  return (
    <div className=" gap-4 my-2 p-4 rounded-[10px] border border-[#432315] w-full max-w-[1200px] flex flex-row-reverse items-start  flex-wrap  ">
      <img
        src={`${process.env.REACT_APP_URL}/${data.card_image}`}
        className=" rounded-[10px] w-full lg:w-[390px] h-[250px]  "
        alt={data.name}
      />
      <section className="  max-w-full   break-words flex-1 flex gap-3 ">
        <section className=" text-center text-[22px] font-[800] ">
          <h1>{daysInArabic[indexOfStartDay]}</h1>
          <h1>{data.start_date.substring(8, 10)}</h1>
        </section>
        <section className=" max-w-[80%]  flex-1  ">
          <h1 className=" text-[20px] font-[700] leading-[36px]  ">
            {data.name}
          </h1>
          <section className=" text-[#290900] text-[14px]  font-[600] leading-[28px] flex items-center gap-2  ">
            <i className="fa-solid fa-calendar-days"></i>
            <span>
              {data.start_date.substring(8, 10)} -الي- {data.end_date}
            </span>
          </section>
          <section className=" text-[#290900] text-[14px]  font-[600] leading-[28px] flex items-center gap-2  ">
            <i className="fa-solid fa-location-dot"></i>
            <span>{data.location}</span>
          </section>
          <h1 className="w-full break-words wtext min-h-[100px] text-[16px] font-[600] leading-[30px]  ">
            <TruncatedParagraph text={data.short_description} />
            {/* {data.short_description} */}
          </h1>
          {/* <h1 className=" text-[#432315E0]/80 text-[15px] font-[400] leading-[30px]  ">
            {data.desc2}
          </h1> */}
          <Link to={`/event-details?event=${data.id}&role=${data.role}`}>
            <button className="btn2 mt-2 ">تفاصيل الفعالية</button>
          </Link>
        </section>
      </section>
    </div>
  );
}
