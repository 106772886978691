import React, { useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";

export default function Reports() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };
  const { data, error } = useFetchWithReload(
    `global/Report/event/superAdmin/gard-report-event/${type}`,
    reload
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير الفعاليات</h1>
      {data && (
        <>
          {data.report ? (
            <>
              {data.report.length > 0 && (
                <>
                  {data.report.map((user, indx) => (
                    <div key={indx}>
                      <h1 className="my-2 text-center text-lg font-semibold ">
                        {user.owner_name}
                      </h1>
                      <table>
                        <thead>
                          <tr>
                            <th>اسم الفعالية</th>
                            <th>عدد المشاركين</th>
                            <th>اجمالي الارباح</th>
                            <th>نسبة المنصة</th>
                            <th>تاريخ البداية</th>
                            <th>تاريخ النهاية</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user.events.map((event, inx) => (
                            <tr key={inx}>
                              <td className=" text-center ">
                                {event.event_name}
                              </td>
                              <td className=" text-center ">
                                {event.total_participants}
                              </td>
                              <td className=" text-center ">
                                {event.total_revenue} ر.س
                              </td>
                              <td className=" text-center ">
                                {event.platform_percentage}%
                              </td>
                              <td className=" text-center ">
                                {event.start_date}
                              </td>
                              <td className=" text-center ">
                                {event.end_date}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا توجد فعاليات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
