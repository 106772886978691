import React from "react";

export default function TypeInfo({ next, pre, userInfo, setUserInfo, data }) {
  const { typeOfrace } = userInfo;
  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const sendHandeller = (e) => {
    e.preventDefault();
    next();
  };
  return (
    <div className=" mb-3 bg-[#E8DCC0] rounded-[15px] p-4 ">
      <form
        className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  "
        onSubmit={sendHandeller}
      >
        <section className=" col-span-1 ">
          <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
            فئة السباق
          </h1>
          <select
            name="typeOfrace"
            value={typeOfrace}
            onChange={handleChangeInfo}
            className="inpt "
            required
          >
            <option
              hidden
              selected
              className=" text-[#432315C7] text-[15px] font-[600] "
            >
              اختر الفئة
            </option>
            {!!data.race.categories &&
              data.race.categories.map((e, inx) => (
                <option key={inx} value={e}>
                  {e}
                </option>
              ))}
          </select>
        </section>
        <section className=" col-span-1 lg:col-span-2 flex items-center justify-center gap-2 flex-wrap ">
          <button disabled={!typeOfrace} type="submit" className=" btn3 ">
            التالي
          </button>
          <button onClick={pre} className=" btn4 ">
            العوده
          </button>
        </section>
      </form>
    </div>
  );
}
