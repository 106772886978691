import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useFetchWithReload from "../../../../../../utils/useGetWithReload";
import Error from "../../../../../../components/Error/Error";
import File from "../../../../../../components/pages/File";
import Accept from "./Accept";
import Reject from "./Reject";

export default function PendingAccount() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("account");
  let link = "superAdmin/approves/serviceProviders/pending";
  const { data, error } = useFetchWithReload(link);
  const [sho, setShow] = useState([{}]);
  useEffect(() => {
    if (!!data) {
      let item = data.pending_service_providers.filter(
        (e) => +e.id === +invest
      );
      setShow(item);
      //   console.log(item);
    }
  }, [data, invest]);

  const navigate = useNavigate();
  const afterAction = () => {
    navigate("/pending?type=1");
  };

  if (error || sho.length === 0) {
    return <Error />;
  }
  const types = {
    veterinary: "طب بيطري",
    freelancer: "مستقل",
    expert: "خبير",
  };
  return (
    <div>
      {data && (
        <section className=" md:max-w-[1000px] grid grid-cols-1 md:grid-cols-2 gap-8 m-4 p-6 border border-black/50 rounded-[16px] md:mx-auto ">
          <section className=" col-span-1 md:col-span-2 py-4 flex items-center justify-center gap-2 ">
            <Accept id={invest} re={afterAction} />
            <Reject id={invest} re={afterAction} />
          </section>
          <section>
            <h1>
              الاسم الاول:{" "}
              <span className=" font-bold text-xl ">{sho[0].first_name}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              الاسم الاخير:{" "}
              <span className=" font-bold text-xl ">{sho[0].last_name}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              رقم الهاتف:{" "}
              <span className=" font-bold text-xl ">{sho[0].phone}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              البريد الالكتروني:{" "}
              <span className=" font-bold text-xl ">{sho[0].email}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              الجنسية:{" "}
              <span className=" font-bold text-xl ">{sho[0].nationality}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              الجنس:{" "}
              <span className=" font-bold text-xl ">{sho[0].gender}</span>{" "}
            </h1>
          </section>
          <section>
            <h1>
              نوع التسجيل:{" "}
              <span className=" font-bold text-xl ">{types[sho[0].type]}</span>{" "}
            </h1>
          </section>
          <section className=" col-span-1 md:col-span-2 ">
            {!!sho[0].documents && (
              <>
                {JSON.parse(sho[0].documents).length > 0 && (
                  <>
                    <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
                      ملفات الحساب
                    </h1>
                    <section className=" flex gap-2 flex-wrap ">
                      {JSON.parse(sho[0].documents).map((e, inx) => (
                        <File
                          key={inx}
                          da={`${process.env.REACT_APP_URL}/${e}`}
                        />
                      ))}
                    </section>
                  </>
                )}
              </>
            )}
          </section>
        </section>
      )}
    </div>
  );
}
