import React, { useState } from "react";
import { useLocation } from "react-router";
import Tab from "../../../components/pages/Tab";
import PartnerShipdashboard from "../pages/partnerShips/PartnerShipdashboard";
import PackagesDashboard from "../pages/packages/PackagesDashboard";
import MyJoins from "../pages/MyJoins/MyJoins";
import DashboardStoresPage from "../pages/stores/DashboardStoresPage";
import MediaDashboard from "../pages/media/MediaDashboard";
import MyRequests from "../pages/My request/MyRequests";

export default function VisitorDashBoard() {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type") || 1;
  const [tab, setTab] = useState(+type);
  const tabs = [
    "التحالف",
    "المتجر",
    "الصور والفيديوهات",
    "الباقات",
    "طلباتي",
    "مشاركاتي",
  ];
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-[#432315] ">لوحة التحكم</h1>
      {/* Tabs */}
      <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto lg:flex-wrap w-full ">
        {tabs.map((e, inx) => (
          <Tab
            val={inx + 1}
            key={inx}
            text={e}
            tab={tab}
            setTabState={setTab}
          />
        ))}
      </section>
      <div>
        {tab === 1 && <PartnerShipdashboard />}
        {tab === 2 && <DashboardStoresPage />}
        {tab === 3 && <MediaDashboard />}
        {tab === 4 && <PackagesDashboard />}
        {tab === 5 && <MyRequests />}
        {tab === 6 && <MyJoins />}
      </div>
    </div>
  );
}
