import React from "react";
import InfoBadge from "../../../../components/pages/InfoBadge";

export default function Deatils({ data }) {
  return (
    <div className=" mb-3 ">
      <InfoBadge data={data} />
      <h4 className=" my-6 text-[18px] text-[#432315] font-[700] flex items-center gap-2 justify-start flex-wrap ">
        التخصص :{" "}
        <h1 className=" rounded-md border border-black/50 p-2 ">
          {data.specialization.name}
        </h1>
      </h4>
      <h1 className=" my-6 text-[24px] text-[#432315] font-[800] ">
        معلومات عن الدكتور
      </h1>
      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.about_doctor }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
    </div>
  );
}
