import React, { useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import { Link } from "react-router-dom";
import CancelBtn from "./CancelBtn";

export default function MyTrips() {
  const [reload, setReload] = useState(false);
  const { data, error } = useFetchWithReload(
    "global/tourisms/join/myTours",
    reload
  );
  const re = () => {
    setReload((pre) => !pre);
  };
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة{" "}
        </h1>
      </div>
    );
  }
  console.log(data);
  return (
    <div>
      {!!data && (
        <>
          {!!data.tours ? (
            <>
              <h1 className=" text-center font-bold text-xl my-2">
                تفاصيل الرحلات
              </h1>
              <table>
                <thead>
                  <tr>
                    <th>تاريخ الحجز</th>
                    <th>عدد الكبار</th>
                    <th>عدد الصغار</th>
                    <th>التكلفة</th>
                    <th>الغاء</th>
                    <th>عرض</th>
                  </tr>
                </thead>
                <tbody>
                  {data.tours.map((trip) => (
                    <tr key={trip.id}>
                      <td className="text-center">{trip.booking_date}</td>
                      <td className="text-center">{trip.number_of_adults}</td>
                      <td className="text-center">{trip.number_of_children}</td>
                      <td className="text-center">{trip.total_cost} ر.س</td>
                      <td className="text-center">
                        {trip.status === "CANCELED" ? (
                          <p className=" text-red-800 text-[16px] ">
                            تم الغاء الحجز{" "}
                          </p>
                        ) : (
                          <CancelBtn
                            re={re}
                            id={trip.tour_id}
                            type={trip.owner_type}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        <Link
                          className=" text-sky-800 underline font-bold "
                          to={`/trip-details?trip=${trip.tour_id}&role=${trip.owner_type}`}
                        >
                          عرض{" "}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا توجد مشاركات لك في الرحلات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
