import React, { useState } from 'react'
import TabJoin from '../../../../../components/pages/TabOfJoins';
import DashBoardEventsPage from '../../../pages/events/DashBoardEventsPage';
import Reports from './Reports';

export default function EventsDashSuperAdmin() {
    const [tab, setTab] = useState(1);
    const tabs = ["تقارير الفعاليات", "الفعاليات"];
    return (
      <div>
        <section className=" my-2 flex items-center justify-start gap-1 md:gap-2 overflow-auto w-full ">
          {tabs.map((e, inx) => (
            <TabJoin
              val={inx + 1}
              key={inx}
              text={e}
              tab={tab}
              setTabState={setTab}
            />
          ))}
        </section>
        <div>{tab === 1 && <Reports />}</div>
        <div>{tab === 2 && <DashBoardEventsPage />}</div>
      </div>
    );
}
