import React from "react";
// import HeadCover2 from "../../components/pages/HeadCover2";
import Hr from "../../components/pages/Hr";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";
// import EndedEvents from "./EndedEvents";

export default function Auctions() {
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org",
"@type": "WebPage",
"name": "منصة الابل",
"description":" المزادات المهتمة بالابل ومساحات مخصصة لها ومميزة",
"url": "https://mitracamel.com/auctions"
}
`}
        </script>
      </Helmet>
      {/* <HeadCover2
        img="./imgs/cover22.jpg"
        title="المزاد"
        desc="المكان الأمثل لعشاق الإبل لمشاهدة وشراء أفضل وأجمل الإبل المعروضة للبيع عن طريق المزاد العلني،"
      /> */}
        <NewOver img="./imgs/auct.png" title="المزاد" />
      <section className="  my-24 text-center  ">
        <i className="fa-regular fa-clock text-[90px] text-[#432315] font-bold  "></i>
        <h4 className=" my-10 text-[30px] font-bold text-[#432315]  ">
          {" "}
          هذه الخدمة غير متوفرة في الوقت الحالي سوف يتم اضافتها قريبا{" "}
        </h4>
        <Hr color="#432315" />
      </section>
    </div>
  );
}
