import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function CopyBtn() {
  const [copied, setCopiedState] = useState(false);
  const code = window.location.href;
  const title = "";
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };
  return (
    <section className=" my-4 ">
      <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
        نسخ الرابط
      </h1>
      <button
        onClick={handleCopy}
        className=" text-[#290900] font-[500] text-[18px] leading-[28px] "
      >
        {copied ? "تم النسخ" : <i className="fa-solid fa-copy text-4xl"></i>}
      </button>
      <section>
        <div className="flex items-center gap-4 my-3">
          <section className=" border border-[#432315] rounded-[5px] text-[#663520] px-[12px] py-[2px] ">
            <i className="fa-solid fa-share-nodes ml-2 "></i>
            مشاركة
          </section>

          <FacebookShareButton
            url={code}
            quote={title}
            className="share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton
            url={code}
            title={title}
            className="share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton url={code} title={title} className="share-button">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
      </section>
    </section>
  );
}
