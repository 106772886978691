import React, { useContext } from "react";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import { isLogged } from "../../../../utils/inStore";

export default function InvestDashboardComponent({
  data,
  setDeleteId,
  setDetailsId,
  setEditId,
}) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const toDay = new Date();
  // const end = new Date(data.end_date);
  // const diffInMilliseconds = end - toDay;

  // Convert milliseconds to days
  // const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  // let left = diffInDays > 0 ? `${diffInDays} يوم` : "انتهت";
  const { role } = useContext(isLogged);
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };
  return (
    <div className=" relative text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.title}</h1>
      <p className="  min-h-[120px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.summary} />
      </p>
      <div className=" my-2 flex-wrap flex items-center gap-1 justify-around ">
        <section className=" flex gap-[6px] items-center ">
          <i className="fa-solid fa-users text-sm "></i>
          <span>
            <h4 className=" text-xs  font-[600] leading-4">
              {Number(data.total_investment)} ر.س{" "}
            </h4>
            <h4 className=" text-sm leading-3">استثمار</h4>
          </span>
        </section>
        <section className=" flex gap-[4px]  items-center ">
          <i className="fa-solid fa-money-bill text-sm"></i>
          <span>
            <h4 className=" text-xs font-[600] leading-4">
              {Number(data.price_per_share)} ر.س{" "}
            </h4>
            <h4 className=" text-sm leading-4">سعر السهم</h4>
          </span>
        </section>
        {/* <section className=" flex gap-[4px] items-center ">
          <i className="fa-regular fa-clock text-sm"></i>
          <span>
            <h4 className=" font-[600] leading-4">{left}</h4>
            <h4 className=" text-sm leading-4">الوقت المتبقي</h4>
          </span>
        </section> */}
      </div>

      <section className=" my-1   text-[15px] font-[600] leading-[28px] flex items-center justify-between flex-wrap gap-1 ">
        <Link to={`/investment-details?invest=${data.id}&role=${roles[role]}`}>
          <button className=" btnn2 ">التفاصيل</button>
        </Link>
        <button onClick={() => setDetailsId(data.id)} className=" btnn ">
          المنضمين
        </button>
        <button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="  flex items-center justify-center bg-[#E8D6B2]/30 hover:bg-[#E8D6B2]/60 rounded-full h-[50px] w-[50px] "
        >
          <i className="fa-solid fa-ellipsis text-2xl "></i>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              setEditId(data.id);
            }}
            className=" py-2 px-12 border-b "
          >
            تعديل
          </button>
          <br></br>
          <button
            onClick={() => {
              handleClose();
              setDeleteId(data.id);
            }}
            className=" py-2 px-12 text-red-500 "
          >
            حذف
          </button>
        </Menu>
      </section>
    </div>
  );
}
