import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { isLogged } from "../../../../utils/inStore";
import FileUploded from "../../../../components/pages/FileUploded";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../../components/Loader/Loader";
import { load } from "../../../../utils/isLoadingStore";
import usePostFormData from "../../../../utils/usePostForm";
import { useNavigate } from "react-router";

export default function AddField({ close }) {
  const [form, setForm] = useState({
    name: "",
    space: "",
    race_track_length: "",
    training_track_length: "",
    spectator_capacity: "",
    subscription_price: "",
    city: "",
    address: "",
    details: "",
    description: "",
    field_image: "",
    details_images: [],
  });

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setForm({ ...form, details: value });
  };

  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!form.name) {
      formErrors.name = "الرجاء إدخال اسم الساحة";
    }

    // Validate space
    if (!form.space) {
      formErrors.space = "الرجاء إدخال المساحة";
    }

    // Validate race track length
    if (!form.race_track_length) {
      formErrors.race_track_length = "الرجاء إدخال طول مضمار السباق";
    }
    // Validate short description
    if (!form.subscription_price || form.subscription_price < 1) {
      formErrors.subscription_price = "الرجاء إدخال سعر الاشتراك  ";
    }

    // Validate training track length
    if (!form.training_track_length) {
      formErrors.training_track_length = "الرجاء إدخال طول مضمار التدريب";
    }

    // Validate spectator capacity
    if (!form.spectator_capacity) {
      formErrors.spectator_capacity = "الرجاء إدخال سعة المتفرجين";
    }

    // Validate city
    if (!form.city) {
      formErrors.city = "الرجاء إدخال المدينة";
    }

    // Validate address
    if (!form.address) {
      formErrors.address = "الرجاء إدخال العنوان";
    }

    // Validate details
    if (!form.details) {
      formErrors.details = "الرجاء إدخال تفاصيل الساحة";
    }

    // Validate description
    if (!form.description) {
      formErrors.description = "الرجاء إدخال الوصف المختصر للساحة";
    }

    // Validate field image
    if (!form.field_image) {
      formErrors.field_image = "الرجاء رفع صورة الساحة";
    }

    // Validate details images
    if (!form.details_images || form.details_images.length === 0) {
      formErrors.details_images = "الرجاء رفع صور تفصيلية للساحة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  const { role } = useContext(isLogged);
  let link = "";

  if (role === "squer") {
    link = "field-owner/field/create";
  }

  if (role === "super_admin") {
    link = "superAdmin/field/create";
  }
  const { postFormData, error, response } = usePostFormData(link);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("name", form.name);
      formdata.append("space", form.space);
      formdata.append("race_track_length", form.race_track_length);
      formdata.append("training_track_length", form.training_track_length);
      formdata.append("spectator_capacity", form.spectator_capacity);
      formdata.append("subscription_price", form.subscription_price);
      formdata.append("city", form.city);
      formdata.append("address", form.address);
      formdata.append("details", form.details);
      formdata.append("description", form.description);
      formdata.append("field_image", form.field_image);
      form.details_images.forEach((image) => {
        formdata.append("details_images[]", image);
      });

      await postFormData(formdata);
    }
  };
  const navigte = useNavigate();
  useEffect(() => {
    if (!!response) {
      if (role === "super_admin") {
        navigte(`/squre-details?squre=${response["0"].id}&role=super_admin`);
      } else {
        setTimeout(() => {
          close(false);
        }, [2000]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة ساحة</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {form.field_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, field_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.field_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, field_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.field_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الساحة
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الساحة"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              مساحة الساحة
            </h1>
            <input
              name="space"
              value={form.space}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="مساحة الساحة"
              required
            />
            {<p className="error">{errors.space}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              طول المضمار
            </h1>
            <input
              name="race_track_length"
              value={form.race_track_length}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="طول المضمار"
              required
            />
            {<p className="error">{errors.race_track_length}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              طول مضمار التدريبي
            </h1>
            <input
              name="training_track_length"
              value={form.training_track_length}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" طول مضمار التدريبي"
              required
            />
            {<p className="error">{errors.training_track_length}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعة المدرجات (عدد المشجعين)
            </h1>
            <input
              name="spectator_capacity"
              value={form.spectator_capacity}
              onChange={handelChanege}
              className=" inpt "
              min={1}
              type="number"
              placeholder=" سعة المدرجات (عدد المشجعين)"
              required
            />
            {<p className="error">{errors.spectator_capacity}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر الاشتراك
            </h1>
            <input
              name="subscription_price"
              value={form.subscription_price}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              placeholder="السعر بالريال"
              min={1}
              required
            />
            {<p className="error">{errors.subscription_price}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الساحة
            </h1>
            <textarea
              name="description"
              cols={2}
              value={form.description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الساحة "
              required
            />
            {<p className="error">{errors.description}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الساحة
            </h1>
            <ReactQuill
              value={form.details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المدينة
            </h1>
            <input
              name="city"
              value={form.city}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="المدينة"
              required
            />
            {<p className="error">{errors.city}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="العنوان"
              required
            />
            {<p className="error">{errors.address}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور الساحة
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_images.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_images;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_images: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_images: [
                        ...form.details_images,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
            <p className="error">{errors.details_images}</p>
          </section>
        </form>

        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.error
              ? error.response.data.error
              : "  هناك مشكلة"}
          </section>
        )}
        {response && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-green-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-green-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-check"></i>
            </span>
            تم اضافة الساحة سوف يتم مراجعته كن في انتظار الموافقة عليه
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
