import React, { useEffect, useState } from "react";
import useFetchWithToken from "../../../../../utils/useGetWithToken";
import AddCateg from "./AddCateg";
import DeleteCateg from "./DeleteCateg";

export default function CategsDash() {
  let link = "superAdmin/category/index";
  const { data, error } = useFetchWithToken(link);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const [deleteId, setDeletId] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة قسم
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>الاسم</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((e) => (
            <tr key={e.id}>
              <td className=" text-center ">{e.name}</td>
              <td className=" text-center ">
                <button
                  className="text-red-600"
                  onClick={() => setDeletId(e.id)}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {openAdd && <AddCateg set={setFilteredData} close={setOpenAdd} />}
      {deleteId && (
        <DeleteCateg set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
    </div>
  );
}
