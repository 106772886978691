import React, { useContext, useEffect } from "react";
import { isLogged } from "../../utils/inStore";
import { useNavigate } from "react-router";

export default function LoginLayout({ children }) {
  const { logged } = useContext(isLogged);

  const navigation = useNavigate();
  useEffect(() => {
    if (logged) {
      navigation("/");
      // return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="min-h-screen bg-[#F5EFDC] flex items-center justify-start">
      <section className=" hidden md:grid w-[250px] lg:w-[400px] h-full">
        <img src="./imgs/navLogoCropped.png" alt="logo" className=" mx-auto " />
      </section>
      <div className="  bg-[#FBFBFBFB] p-4 flex-1 min-h-screen flex items-center justify-center ">
        <section className="w-full  loginSh max-w-[700px] bg-[#FFFFFF] border-[#0000004D] flex items-center justify-center border  p-[8px] md:p-[16px] rounded-[8px] ">
          {children}
        </section>
      </div>
    </div>
  );
}
