import React from "react";
// import { Link } from "react-router-dom";
import Cover from "../../components/pages/Home/Cover";
import Hr from "../../components/pages/Hr";

export default function Servs() {
  let texts = [
    "الجوائز والشهادات",
    "فرص الاستثمار في مشاريع الهجن",
    "الفعاليات والمهرجانات",
    " الجمعيات والهيئات",
    " البيع والشراء",
    "مزودي الخدمات ",
    " معلومات وخرائط الإبل",
    " تحميل وتوصيل للمنتجات",
    " دعم للابتكارات والتطوير",
    " بناء العلامة التجارية الشخصية",
  ];
  return (
    <div className=" p-4 md:p-12 break-normal  text-center ">
      <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">الخدمات</h1>
      <Hr color="#432315" />
      <section className=" flex items-center justify-center gap-6 flex-wrap md:gap-12 my-10">
        {texts.map((text, inx) => (
          <Cover key={inx} text={text} />
        ))}
      </section>
      {/* <Link to="/services">
        <button className="btn2">المزيد</button>
      </Link> */}
    </div>
  );
}
