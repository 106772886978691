import React from "react";

export default function EventDetailsJoinComponent({ data }) {
  const daysInArabic = [
    "الأحد", // Sunday
    "الاثنين", // Monday
    "الثلاثاء", // Tuesday
    "الأربعاء", // Wednesday
    "الخميس", // Thursday
    "الجمعة", // Friday
    "السبت", // Saturday
  ];
  const indexOfStartDay = new Date(data.start_date).getDay();
  return (
    <div className=" gap-4 my-2 p-4 rounded-[10px] border border-[#432315] w-full max-w-[1200px] flex flex-row-reverse items-start flex-wrap ">
      <section className="  max-w-full flex-1 flex gap-3 ">
        <section className=" text-center text-[22px] font-[800] ">
          <h1>{daysInArabic[indexOfStartDay]}</h1>
          <h1>{data.start_date.substring(8, 10)}</h1>
        </section>
        <section className=" flex-1 max-w-[85%]  ">
          <h1 className=" text-[20px] font-[700] leading-[36px]  ">
            {data.name}
          </h1>
          <section className=" text-[#290900] text-[14px]  font-[600] leading-[28px] flex items-center gap-2  ">
            <i className="fa-solid fa-calendar-days"></i>
            <span>
              {data.start_date.substring(8, 10)} -الي- {data.end_date}
            </span>
          </section>
          <h1 className=" max-w-full text-[16px] font-[600] leading-[30px]  ">
            {data.short_description}
          </h1>
          {/* <h1 className=" text-[#432315E0]/80 text-[15px] font-[400] leading-[30px]  ">
            {data.desc2}
          </h1> */}
        </section>
      </section>
    </div>
  );
}
