import React, { useContext, useEffect, useState } from "react";
import Pref from "./Pref";
import Products from "./Products";
import DeleteProduct from "../../components/stores/DeleteProduct";
import Attend from "./Attend";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import AddProduct from "../../components/stores/AddProduct";
import EditProduct from "../../components/stores/EditProduct";
import Requests from "./Requests";

export default function DashboardStoresPage() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    card: true,
  });
  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = "camelOwner/item/index";
  }
  if (role === "squer") {
    link = "field-owner/item/index";
  }
  if (role === "provider") {
    link = "serviceProvider/item/index";
  }
  if (role === "vistor") {
    link = "visitor/item/index";
  }
  if (role === "super_admin") {
    link = "superAdmin/item/index";
  }
  const { data, error } = useFetchWithToken(link);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filtered = [];

    if (!!data) {
      filtered = data;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);

  const [deleteId, setDeletId] = useState();
  const [detailsId, setDetailsId] = useState();
  const [editId, setEditId] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [request, setRequest] = useState(false);

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}

        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <button onClick={() => setRequest((pre) => !pre)} className=" btn ">
          عرض الطلبات <i className="fa-solid fa-list"></i>
        </button>
        <button onClick={() => setDetailsId((pre) => !pre)} className=" btn ">
          عرض التقارير <i className="fa-solid fa-sheet-plastic"></i>
        </button>
        <button
          onClick={() => setOpenAdd(true)}
          className=" btn flex items-center gap-2 "
        >
          <i class="fa-solid fa-circle-plus"></i>
          اضافة منتج
        </button>
      </div>
      {!request ? (
        <div className="flex items-start flex-wrap gap-4 my-10 ">
          {data && (
            <section className="flex-1">
              {detailsId ? (
                <Attend id={detailsId} back={() => setDetailsId()} />
              ) : (
                <>
                  <Products
                    setDetailsId={setDetailsId}
                    setEditId={setEditId}
                    setDeleteId={setDeletId}
                    data={[...filteredData].reverse()}
                  />
                  {filteredData.length === 0 && (
                    <h1 className=" text-center my-4 text-xl ">
                      لا توجد منتجات
                    </h1>
                  )}
                </>
              )}
            </section>
          )}
          <section className=" w-full max-w-[390px] md:max-w-[230px] ">
            {!!data && <Pref data={data} />}
          </section>
        </div>
      ) : (
        <div className="  items-start flex-wrap gap-4 my-10 ">
          <Requests />
        </div>
      )}
      {deleteId && (
        <DeleteProduct set={setFilteredData} id={deleteId} close={setDeletId} />
      )}
      {openAdd && <AddProduct close={setOpenAdd} />}
      {editId && (
        <EditProduct set={setFilteredData} id={editId} close={setEditId} />
      )}
    </div>
  );
}
