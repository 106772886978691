import React, { useMemo } from "react";
import StowerOwner from "../../../components/pages/Stores/StowerOwner";
import Hr from "../../../components/pages/Hr";
import Store from "./Store";

export default function StoreOwner() {
  const allData = useMemo(
    () => [
      {
        id: 1,
        rate: 4.5,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/med.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "medicens",
        city: "riyhad",
      },
      {
        id: 1,
        rate: 5,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/med.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "medicens",
        city: "jada",
      },
      {
        id: 1,
        rate: 4,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/med.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "medicens",
        city: "uae",
      },
      {
        id: 1,
        rate: 2.5,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/squres.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "camels",
        city: "qutar",
      },
      {
        id: 1,
        rate: 4.5,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/squres.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "camels",
        city: "bahreen",
      },
      {
        id: 1,
        rate: 4.5,
        owner: {
          img: "./imgs/profile/user.jfif",
          name: "الشيخ صالح العربي",
          id: 1,
        },
        img: "./imgs/profile/club.jfif",
        name: "Endura-Camel Injection",
        desc: "تحالف رائد يضم نخبة من أشهر وأكبر مربي الإبل في المنطقة. يمتلك  التحالف مزارع متكاملة وقرى سياحية ومرافق لتنظيم الفعاليات",
        location: "الرياض، شارع الملك فهد",
        type: "camels",
        city: "uae",
      },
    ],
    []
  );
  return (
    <div>
      <StowerOwner
        cover="./imgs/profile/coverStore.jpg"
        img="./imgs/profile/user.jfif"
        loc="الرياض السعوديه"
        name=" الشيخ صالح"
        desc="Endura-Camel منتج يحتوى على عدة مكونات لزيادة فاعلية و كفائة هجن  السباقات عن طريق زيادة تغذية العضلات بالأكسجين (نسم) و زيادة إنتاج  الطاقة و تحسين التمثيل الغذائى."
      />
      <h1 className=" text-[#432315] text-[36px] font-[800] my-2  text-center">
        منتجات المتجر
      </h1>
      <Hr color="#432315" />
      <Store data={allData} />
    </div>
  );
}
