import React, { useEffect, useState } from "react";
import CommentComponenet from "../../../components/pages/Stores/CommentComponenet";
import Rating from "@mui/material/Rating";
// import usePostFormData from "../../../utils/usePostForm";
import { useLocation } from "react-router";
import usePostWithToken from "../../../utils/usePostJson";

export default function Commets({ comments }) {
  const [comment, setComment] = useState("");
  const [value, setValue] = useState(0);
  // const [showData, setShowData] = useState([...comments]);
  const [errors, setErrors] = useState({});
  const validate = () => {
    let errs = {};
    if (!comment) {
      errs.comment = "برجاء ادخال التعليق";
    }
    // if (+value === 0) {
    //   errs.rate = "برجاء ادخال التقييم";
    // }
    setErrors(errs);


    return Object.keys(errs).length === 0;
  };
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("product");
  const role = new URLSearchParams(search).get("role");
  const { postData, response } = usePostWithToken(
    `global/store/item/comment-rate/${invest}/${role}`
  );
  const [suc, setSuc] = useState(false);
  useEffect(() => {
    if (response) {
      setSuc(true);
      setTimeout(() => {
        setSuc(false);
      }, [3000]);
    }
  }, [response]);

  const addHandeller = () => {
    if (validate()) {
      postData({ comment: comment, rate: value });
    }
  };
  return (
    <div>
      <h1 className=" text-[38px] font-[800]  my-4 ">التعليقات</h1>
      <div>
        {comments.map((comment, inx) => (
          <CommentComponenet data={comment} key={inx} />
        ))}
      </div>
      <div className=" my-6 ">
        <h1 className=" text-[#663520] text-[28px] font-[800] ">
          + شاركنا تعليقك
        </h1>
        <section className="  ltr flex justify- ">
          <Rating
            name="simple-controlled"
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          {<p className="error">{errors.rate}</p>}
        </section>
        <textarea
          className=" shadow-md w-full my-3 outline-none border border-[#878691] rounded-[15px] text-[18px] p-4 bg-white/0 "
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={3}
          placeholder="التعليق ..........."
        />
        {<p className="error">{errors.comment}</p>}
        <section className=" flex justify-end ">
          <button onClick={addHandeller} className=" btn2 ">
            أرسال
          </button>
        </section>
        {suc && (
          <section className=" my-2 bg-green-600 p-3 text-white flex items-center gap-2 text-lg rounded-lg ">
            <i className="fa-solid fa-check"></i>
            تم الاضافة بنجاح
          </section>
        )}
      </div>
    </div>
  );
}
