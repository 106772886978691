import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUploded from "../../../../components/pages/FileUploded";
import { isLogged } from "../../../../utils/inStore";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";
import useFetchWithToken from "../../../../utils/useGetWithToken";

export default function EditEvent({ close, id, set }) {
  const [details, setDetails] = useState("");
  const [form, setForm] = useState({
    name: "",
    address: "",
    short_description: "",
    subscription_price: "",
    details: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    card_image: "",
    event_files: [],
    details_image: [],
    agenda: [],
  });

  let linkToFetch = "";
  let linkOfFileds = "";
  const { role } = useContext(isLogged);
  if (role === "camel-owner") {
    linkToFetch = `camelOwner/event/show/${id}`;
  }
  if (role === "squer") {
    linkToFetch = `field-owner/event/show/${id}`;
    linkOfFileds = "field-owner/field/index";
  }
  if (role === "super_admin") {
    linkToFetch = `superAdmin/event/show/${id}`;
    linkOfFileds = "superAdmin/field/index";
  }
  const { data } = useFetchWithToken(linkToFetch);
  const dataofFileds = useFetchWithToken(linkOfFileds).data;
  const [fileds, setFileds] = useState([]);
  useEffect(() => {
    if (role === "squer" || role === "super_admin") {
      if (!!dataofFileds) {
        setFileds(dataofFileds);
      }
    }
  }, [dataofFileds, role]);

  useEffect(() => {
    if (!!data) {
      // console.log(data.event.start_time.substring(0,6))
      setDetails(data.event.details);
      setForm({
        name: data.event.name,
        address:
          role === "squer" || role === "super_admin"
            ? data.event.field_id
            : data.event.location,
        short_description: data.event.short_description,
        subscription_price: data.event.subscription_price,
        details: "",
        start_date: data.event.start_date,
        end_date: data.event.end_date,
        start_time: data.event.start_time,
        end_time: data.event.end_time,
        card_image: "",
        event_files: [],
        details_image: [],
        // agenda: data.agendas,
        agenda: [],
      });
    }
  }, [data, role]);

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };

  const handleChangeAgenda = (e, inx) => {
    const { name, value } = e.target;
    const updatedAgenda = form.agenda.map((item, index) =>
      index === inx ? { ...item, [name]: value } : item
    );

    setForm((prevState) => ({
      ...prevState,
      agenda: updatedAgenda,
    }));
  };
  const handleChangeAgendaFile = (e, inx) => {
    const { name } = e.target;
    const updatedAgenda = form.agenda.map((item, index) =>
      index === inx ? { ...item, [name]: e.target.files[0] } : item
    );

    setForm((prevState) => ({
      ...prevState,
      agenda: updatedAgenda,
    }));
  };

  // Manual validation logic
  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // // Validate name
    // if (!form.name) {
    //   formErrors.name = "الرجاء إدخال اسم الفعالية";
    // }

    // // Validate address
    // if (!form.address) {
    //   if (role === "squer") {
    //     formErrors.address = "الرجاء اختيار الساحة";
    //   } else {
    //     formErrors.address = "الرجاء إدخال العنوان";
    //   }
    // }

    // // Validate short description
    // if (!form.short_description) {
    //   formErrors.short_description = "الرجاء إدخال الوصف المختصر للفعالية";
    // }

    // // Validate details
    // if (!form.details) {
    //   formErrors.details = "الرجاء إدخال تفاصيل الفعالية";
    // }

    // // Validate start date
    // if (!form.start_date) {
    //   formErrors.start_date = "الرجاء إدخال تاريخ بداية الفعالية";
    // }

    // // Validate end date
    // if (!form.end_date) {
    //   formErrors.end_date = "الرجاء إدخال تاريخ نهاية الفعالية";
    // }

    // // Validate start time
    // if (!form.start_time) {
    //   formErrors.start_time = "الرجاء إدخال وقت بداية الفعالية";
    // }

    // // Validate end time
    // if (!form.end_time) {
    //   formErrors.end_time = "الرجاء إدخال وقت نهاية الفعالية";
    // }

    // // // Validate card image
    // // if (!form.card_image) {
    // //   formErrors.card_image = "الرجاء رفع الصورة المصغرة للفعالية";
    // // }

    // // Validate agenda
    // if (!form.agenda || form.agenda.length === 0) {
    //   formErrors.agenda = "الرجاء إدخال جدول أعمال الفعالية";
    // }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  let link = "";
  if (role === "camel-owner") {
    link = `camelOwner/event/update/${id}`;
  }
  if (role === "squer") {
    link = `field-owner/event/update/${id}`;
  }
  if (role === "super_admin") {
    link = `superAdmin/event/update/${id}`;
  }
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();
  const { postFormData, error, response } = usePostFormData(link);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("name", form.name);
      if (role === "squer" || role === "super_admin") {
        formdata.append("field_id", form.address);
      } else {
        formdata.append("address", form.address);
      }
      formdata.append("short_description", form.short_description);
      formdata.append("subscription_price", form.subscription_price);
      formdata.append("details", details);
      formdata.append("start_date", form.start_date);
      formdata.append("end_date", form.end_date);
      formdata.append("start_time", form.start_time.slice(0, 5));
      formdata.append("end_time", form.end_time.slice(0, 5));
      // formdata.append("start_time", '09:00');
      // formdata.append("end_time", '11:00');

      // Append card image
      if (!!form.card_image) {
        formdata.append("card_image", form.card_image);
      }

      // Append event files
      form.event_files.forEach((file) => {
        formdata.append("event_files[]", file); // Check if your server expects "event_files" or "event_files[]"
      });

      // Append details images
      form.details_image.forEach((image) => {
        formdata.append("details_images[]", image); // Check if your server expects "details_image" or "details_image[]"
      });

      // Append agenda
      form.agenda.forEach((item, index) => {
        formdata.append(`agenda[${index}][title]`, item.title);
        formdata.append(`agenda[${index}][description]`, item.description);
        formdata.append(`agenda[${index}][day]`, item.day);
        if (item.files) {
          formdata.append(`agenda[${index}][files][]`, item.files);
        }
        formdata.append(
          `agenda[${index}][start_time]`,
          item.start_time.slice(0, 5)
        );
        formdata.append(
          `agenda[${index}][end_time]`,
          item.end_time.slice(0, 5)
        );
      });

      await postFormData(formdata);
    }
  };

  useEffect(() => {
    if (!!response) {
      navigate(`/event-details?event=${id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل فعالية</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير الصورة المصغرة
            </h1>
            {form.card_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, card_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.card_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, card_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الفعالية
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الفعالية"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          {role === "squer" || role === "super_admin" ? (
            <>
              <section className=" col-span-1 ">
                <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                  الساحة
                </h1>
                <select
                  name="address"
                  value={form.address}
                  onChange={handelChanege}
                  className=" inpt "
                >
                  <option hidden>الساحة </option>
                  {fileds.map((e, inx) => (
                    <option key={inx} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>

                {<p className="error">{errors.address}</p>}
              </section>
            </>
          ) : (
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                العنوان
              </h1>
              <input
                name="address"
                value={form.address}
                onChange={handelChanege}
                className=" inpt "
                type="text"
                placeholder="العنوان"
                required
              />
              {<p className="error">{errors.address}</p>}
            </section>
          )}
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر الاشتراك
            </h1>
            <input
              name="subscription_price"
              value={form.subscription_price}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              placeholder="السعر بالريال"
              min={1}
              required
            />
            {<p className="error">{errors.subscription_price}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الفعالية
            </h1>
            <textarea
              name="short_description"
              cols={2}
              value={form.short_description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الفعالية "
              required
            />
            {<p className="error">{errors.short_description}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الفعالية
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ البداية
            </h1>
            <input
              name="start_date"
              value={form.start_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ البداية"
              required
            />
            {<p className="error">{errors.start_date}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ النهاية
            </h1>
            <input
              name="end_date"
              value={form.end_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ النهاية"
              required
            />
            {<p className="error">{errors.end_date}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              وقت البداية
            </h1>
            <input
              name="start_time"
              value={form.start_time}
              onChange={handelChanege}
              className=" inpt "
              type="time"
              placeholder=" وقت البداية"
              required
            />
            {<p className="error">{errors.start_time}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              وقت النهاية
            </h1>
            <input
              name="end_time"
              value={form.end_time}
              onChange={handelChanege}
              className=" inpt "
              type="time"
              placeholder="وقت النهاية"
              required
            />
            {<p className="error">{errors.end_time}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير صور الفعالية
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_image.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_image;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_image: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_image: [...form.details_image, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تغيير ملفات الفعالية
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.event_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.event_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        event_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      event_files: [...form.event_files, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 text-xl font-bold lg:col-span-2 text-center ">
            تعديل اجندة الفعالية
          </section>
        </form>
        {form.agenda.map((e, inx) => (
          <div
            key={inx}
            className="  border-b col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 "
          >
            <section className=" col-span-1 lg:col-span-2 flex items-center justify-between p-2 ">
              <h1 className=" h-[30px] w-[30px] flex items-center justify-center text-lg rounded-full text-[#fafafa] bg-[#432315] ">
                {inx + 1}
              </h1>
              <button
                onClick={() => {
                  let newAgenda = form.agenda.filter(
                    (e, index) => inx !== index
                  );
                  setForm({ ...form, agenda: newAgenda });
                }}
              >
                <i className="fa-solid fa-trash text-red-600"></i>
              </button>
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                اسم الحدث
              </h1>
              <input
                name="title"
                value={e.title}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="text"
                placeholder=" اسم الحدث"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                اليوم
              </h1>
              <input
                name="day"
                value={e.day}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="date"
                placeholder="اليوم"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                بداية الحدث
              </h1>
              <input
                name="start_time"
                value={e.start_time}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="  بداية الحدث"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                نهاية الحدث
              </h1>
              <input
                name="end_time"
                value={e.end_time}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="  نهاية الحدث"
                required
              />
            </section>
            <section className=" col-span-1 lg:col-span-2 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                التفاصيل
              </h1>
              <textarea
                name="description"
                value={e.description}
                onChange={(e) => handleChangeAgenda(e, inx)}
                className=" inpt "
                type="time"
                placeholder="التفاصيل"
                required
              />
            </section>
            <section className=" col-span-1 lg:col-span-2 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                ملف الحدث
              </h1>
              <section className=" flex items-center flex-wrap gap-2 ">
                <input
                  name="files"
                  // value={e.files ? e.files.name : null}
                  onChange={(e) => handleChangeAgendaFile(e, inx)}
                  type="file"
                />
              </section>
            </section>
          </div>
        ))}
        <section className=" col-span-1 lg:col-span-2 text-center ">
          <button
            onClick={() => {
              setForm({
                ...form,
                agenda: [
                  ...form.agenda,
                  {
                    title: "",
                    day: "",
                    start_time: "",
                    end_time: "",
                    description: "",
                    files: null,
                  },
                ],
              });
            }}
            className="btn2 my-3"
          >
            اضافة حدث
          </button>
          <br />
          {<p className="error">{errors.agenda}</p>}
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            تعديل
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.error
              ? error.response.data.error
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
