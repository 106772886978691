import React, { useState } from "react";
import usePutWithToken from "../../../../utils/usePutJson";

export default function Request({ data }) {
  const [status, setState] = useState(data.product_arrival_status);
  const { putData, error } = usePutWithToken(
    `global/store/requested-items/update-status/${data.id}`
  );

  return (
    <div className=" my-2 p-2 rounded-[16px] border border-black/70 ">
      <h1 className=" text-center text-2xl font-bold  ">المنتج : {data.product_name} </h1>
      <table className=" my-2 ">
        <thead>
          <tr>
            <th>الاسم</th>
            <th>رقم الهاتف</th>
            <th>البريد الاكتروني</th>
            <th>الكمية</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className=" text-center ">{data.name}</td>
            <td className=" text-center ">{data.phone}</td>
            <td className=" text-center ">{data.email}</td>
            <td className=" text-center ">{data.quantity}</td>
          </tr>
        </tbody>
      </table>
      <h1 className=" text-center my-2 text-lg font-semibold ">
        عنوان التوصيل :
      </h1>
      <h1 className=" text-center my-2 text-xl font-bold ">
        {data.details_address}
      </h1>
      <hr className=" border-black/60 "></hr>
      <section className=" text-center my-3 ">
        <h1 className=" text-center my-2 text-lg font-semibold ">
          حالة التوصيل :
        </h1>
        <section className=" flex justify-center ">
          <select
            value={status}
            onChange={(e) => setState(e.target.value)}
            className=" mx-auto  w-[200px] outline-none border p-2 border-black/60 rounded-md "
          >
            <option value="Pending">منتظر</option>
            <option value="OutOfStock">خرج من المتجر </option>
            <option value="Shipped"> قيد التوصيل</option>
            <option value="Delivered">تم التوصيل </option>
            <option value="Delayed">ملغي</option>
          </select>
        </section>

        <button
          onClick={() => {
            putData({
              status: status,
            });
          }}
          disabled={status === data.product_arrival_status}
          className="btnn2 my-2 disabled:opacity-80 "
        >
          تغيير الحالة
        </button>
        {error && (
          <section className=" bg-red-700 p-2 text-white rounded-md ">
            هناك مشكلة في تغيير الحالة
          </section>
        )}
      </section>
    </div>
  );
}
