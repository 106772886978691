import React, { useEffect, useState } from "react";
import CamelComponent from "../../components/pages/OwnerProfile/CamelComponent";
import useFetchGlobal from "../../utils/useGetGlobal";
import Error from "../../components/Error/Error";

export default function Camels({ owner }) {
  const [showAllCards, setShowAllCards] = useState(false);
  const [filtered, setFilterd] = useState([]);
  const { data, error } = useFetchGlobal("global/CamelOwners/allCamelCard");

  useEffect(() => {
    let fi = [];
    if (!!data && !!data.camel_cards) {
      // console.log(data.camel_cards[0].camel_owner_id == owner);
      fi = data.camel_cards.filter((item) => +item.camel_owner_id === +owner);
    }
    setFilterd(fi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return <Error />;
  }
  return (
    <div className=" py-12 px-6 md:p-20 ">
      {!!data && (
        <div className="">
          <section className=" flex gap-x-8  items-center justify-center   flex-wrap  ">
            {filtered.slice(0, 6).map((camel, inx) => (
              <CamelComponent key={inx} data={camel} />
            ))}
          </section>
          {showAllCards && (
            <section className=" mt-8 flex gap-8  items-center justify-center   flex-wrap  ">
              {data.camel_cards.slice(6).map((camel, inx) => (
                <CamelComponent key={inx} data={camel} />
              ))}
            </section>
          )}
          {filtered.length > 6 && (
            <section className=" text-center mt-[-15px] ">
              <section className=" text-center mt-[30px] ">
                <button
                  onClick={() => setShowAllCards(true)}
                  className="btnLink"
                >
                  المزيد
                </button>
              </section>
            </section>
          )}
          {filtered.length === 0 && (
            <>
              <h1 className=" text-red-400 text-xl text-center my-3 font-bold ">
                لا توجد كروت ابل حتي الان
              </h1>
            </>
          )}
        </div>
      )}
    </div>
  );
}
