import React from 'react'

export default function Sechad({ data }) {
  return (
    <div className=" days my-4 mx-[20px]">
      {data.map((day, inx) => (
        <div
          key={inx}
          className="  w-full max-w-[420px] pb-[15px] pr-[40px] relative  border-r-[4px] border-[#432315] "
        >
          <section className=" w-[50px] h-[50px] rounded-full bg-[#432315] absolute top-0 right-[-25px]  "></section>
          <section className="  flex items-center justify-between h-[50px] ">
            <h1 className=" text-[20px] text-[#290900] font-[700] ">
              {day.day}
            </h1>
            <h1 className=" text-[15px] text-[#290900] font-[500] ">
              {day.start_time.slice(0, 5)} - {day.end_time.slice(0, 5)}
            </h1>
          </section>
          <h1 className=" text-[24px] text-[#290900] font-[800] my-10 ">
            {day.title}
          </h1>
          <p className="my-2 text-[#290900] text-[18px] text-[500] ">
            {day.description}
          </p>
        </div>
      ))}
    </div>
  )
}
