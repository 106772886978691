import React from "react";
import useFetchWithToken from "../../../../../utils/useGetWithToken";

export default function SubsOfPackage({ type, id, back }) {
  const { data, error } = useFetchWithToken(
    `superAdmin/Report/package/show/${id}}/${type}`
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className="flex-1">
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <>
          <h1 className=" my-3 text-[28px]  text-[#290900] ">
            الباقة :{" "}
            <span className="font-[700] text-[32px]">{data.package_name}</span>
          </h1>
          <table className=" w-full ">
            <thead>
              <tr>
                <th>الاسم</th>
                <th>البريد</th>
                <th>الهاتف</th>
                <th>تاريخ الاشتراك</th>
                <th>تاريخ الانتهاء</th>
              </tr>
            </thead>
            <tbody>
              {data.subscribers.map((i, inx) => (
                <tr key={inx}>
                  <td className=" text-center ">
                    {i.subscriber.first_name} {i.subscriber.last_name}
                  </td>
                  <td className=" text-center ">{i.subscriber.email}</td>
                  <td className=" text-center ">{i.subscriber.phone}</td>
                  <td className=" text-center ">
                    {i.start_date.substring(0, 10)}
                  </td>
                  <td className=" text-center ">
                    {i.end_date.substring(0, 10)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
