import React from "react";
import InfoBadge from "../../../components/pages/InfoBadge";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import File from "../../../components/pages/File";

export default function Deatils({ data }) {
  return (
    <div className=" mb-3 ">
      <InfoBadge data={data} expert={true} />
      <h4 className=" my-6 text-[18px] text-[#432315] font-[700] flex items-center gap-2 justify-start flex-wrap ">
        التخصص :{" "}
        <h1 className=" rounded-md border border-black/50 p-2 ">
          {data.specialization.name}
        </h1>
      </h4>
      {!!JSON.parse(data.detail_images) && (
        <>
          {JSON.parse(data.detail_images).length > 0 && (
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 4000, // Time delay in milliseconds
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Pagination, Autoplay]}
              className="mySwiper "
            >
              {JSON.parse(data.detail_images).map((e, index) => (
                <SwiperSlide className="  " key={index}>
                  <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                    <img
                      className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                      key={e}
                      src={`${process.env.REACT_APP_URL}/${e}`}
                      alt="cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}

      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.about_expert }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
      {JSON.parse(data.experience_files) && (
        <>
          {JSON.parse(data.experience_files).length > 0 && (
            <>
              <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
                ملفات تعريفية وشهادات الخبير
              </h1>
              <section className=" flex gap-2 flex-wrap ">
                {JSON.parse(data.experience_files).map((e, inx) => (
                  <File key={inx} da={`${process.env.REACT_APP_URL}/${e}`} />
                ))}
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
}
