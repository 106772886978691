import React from "react";
import Profile from "./Profile";
import Camels from "./Camels";
// import ProfileEvents from "../../components/pages/OwnerProfile/ProfileEvents";
import { useLocation } from "react-router";
// import StoresOfProfile from "./StoresOfProfile";
// import ProfileGallery from "./ProfileGallery";
import useFetchGlobal from "../../utils/useGetGlobal";
import Error from "../../components/Error/Error";

export default function OwnerProfile() {
  const search = useLocation().search;
  const owner = new URLSearchParams(search).get("owner");

  const { data, error } = useFetchGlobal(`global/CamelOwners/show/${owner}`);

  if (error) {
    return <Error />;
  }
  return (
    <div>
      {!!data && (
        <>
          <Profile data={data["Camel Owners"]} />
          <Camels owner={owner} />
          {/* <StoresOfProfile owner={owner} /> */}
          {/* <ProfileEvents owner={owner} /> */}
          {/* <ProfileGallery /> */}
        </>
      )}
    </div>
  );
}
