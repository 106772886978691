import React from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router";

export default function NewSucces() {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/dashboard");
  }, 3000);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      <div className="  text-center w-full m-4 rounded-[15px] bg-[#E8DCC0] px-4 py-12 ">
        <h1 className=" text-[84px] font-[600] text-[#65A30D] ">
          <i className="fa-solid fa-circle-check"></i>
        </h1>
        <h1 className=" text-[24px] font-[600] text-[#65A30D] my-2 ">
          تم التسجيل بنجاح
        </h1>
        {/* <h4 className="text-[20px] font-[500] text-[#4F4F4F] my-2">
          تم ارسال التفاصيل كاملة على البريد الالكتروني
        </h4> */}
        {/* <div className=" my-3 flex items-start justify-center gap-2 flex-wrap ">
          {data.map((item, inx) => (
            <section
              key={inx}
              className=" w-[260px] bg-[#E8D6B2] rounded-[10px] p-4 text-start "
            >
              <h1 className=" tetx-[#4F4F4F] text-[18px] font-[500] mb-3 ">
                {item.title}
              </h1>
              <h1 className=" text-[15px] text-[#333333] font-[800] ">
                {item.value}
              </h1>
            </section>
          ))}
        </div> */}
      </div>
    </div>,
    document.body
  );
}
