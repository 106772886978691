import React from "react";
import ClincsDashboardComponent from "../../components/Clincs/ClincsDashboardComponent";

export default function Clincs({ data, setDeleteId, setEditId }) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <ClincsDashboardComponent
          setDeleteId={setDeleteId}
          setEditId={setEditId}
          key={inx}
          data={e}
        />
      ))}
    </div>
  );
}
