import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import useDeleteRequest from "../../utils/useDelete";

export default function Products({ data, re }) {
  const { deleteRequest, data: dataOfDelete } = useDeleteRequest();
  const { deleteRequest: deleteBeforeGo } = useDeleteRequest();
  const deleteHandler = useCallback((id) => {
    // const updatedData = data.filter((e) => e.id !== id);
    deleteRequest(`global/store/card/delete-from-card/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigat = useNavigate();

  const goHandeller = useCallback(async (id, prodId, owner) => {
    await deleteBeforeGo(`global/store/card/delete-from-card/${id}`);
    navigat(`/order?product=${prodId}&role=${owner}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataOfDelete) {
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOfDelete]);

  return (
    <div className="bg-[#E8DCC0] my-2 p-4 rounded-[16px]">
      <section className="prods">
        {data.map((prod, index) => (
          <Prod
            key={prod.id}
            inf={prod}
            index={index}
            deleteHandler={deleteHandler}
            goHandeller={goHandeller}
          />
        ))}
        {data.length === 0 && (
          <h1 className="text-2xl font-[500] text-center">
            لا توجد منتجات في العربة
          </h1>
        )}
      </section>
    </div>
  );
}

function Prod({ inf, index, deleteHandler, goHandeller }) {
  return (
    <section className="border-b border-black/30 py-4 flex relative items-center gap-2">
      <button
        onClick={() => deleteHandler(inf.card_id)}
        className="absolute top-2 left-2 text-red-600 text-xl font-[600]"
      >
        X
      </button>
      <section className="flex-1 flex items-center gap-2">
        <img
          // src={inf.product.img}
          src={`${process.env.REACT_APP_URL}/${inf.product.card_image}`}
          className="h-[75px] w-[75px] md:h-[150px] md:w-[150px] rounded-[16px]"
          alt="product"
        />
        <section>
          <h1 className="text-[#432315] text-[14px] md:text-[20px] font-[600]">
            {inf.product.name}
          </h1>
          {/* <h4 className="text-black text-[12px] md:text-[16px] font-[400]">
            متجر : {inf.product.store}
          </h4> */}
          <h4 className="text-[#432315] text-[14px] md:text-[20px] font-[600]">
            {inf.product.price} ر.س
          </h4>
          {/* <h4 className="text-black text-[12px] md:text-[16px] font-[400]">
            وصول المنتج في خلال
          </h4>
          <h4 className="text-black text-[12px] md:text-[16px] font-[400]">
            {inf.product.shipping} أيام
          </h4> */}
        </section>
      </section>
      <section className="ml-3">
        {/* <Link to={`/order?product=${inf.product.id}&role=${inf.product.role}`}> */}
        <button
          onClick={() =>
            goHandeller(inf.card_id, inf.product.id, inf.product.role)
          }
          className="btn2"
        >
          شراء المنتج
        </button>
        {/* </Link> */}
      </section>
    </section>
  );
}

function Num({ value, index, handleNumChange }) {
  return (
    <div className="flex items-start">
      <button
        onClick={(e) =>
          handleNumChange({ target: { value: +value + 1 } }, index)
        }
        className="h-[40px] w-[50px] border rounded border-[#432315] text-[#432315] text-3xl flex items-center justify-center"
      >
        +
      </button>
      <input
        min={1}
        value={value}
        className="sumBtn outline-none bg-[#F8EBCF] p-[8px] h-[40px] w-[50px] text-center"
        type="number"
        onChange={(e) => handleNumChange(e, index)}
      />
      <button
        onClick={(e) =>
          handleNumChange({ target: { value: +value - 1 } }, index)
        }
        disabled={+value === 1}
        className="h-[40px] w-[50px] border rounded border-[#432315] text-[#432315] text-3xl flex items-center justify-center"
      >
        -
      </button>
    </div>
  );
}

// PropTypes for validation
Products.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      num: PropTypes.number.isRequired,
      product: PropTypes.shape({
        img: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        store: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        shipping: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  set: PropTypes.func.isRequired,
};

Prod.propTypes = {
  inf: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  handleNumChange: PropTypes.func.isRequired,
};

Num.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleNumChange: PropTypes.func.isRequired,
};
