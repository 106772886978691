import React, { useEffect, useState } from "react";
import NumBtn from "../../../../components/pages/NumBtn";
import usePostWithToken from "../../../../utils/usePostJson";

export default function OrderForm({ data, id, type }) {
  const [userInfo, setUserInfo] = useState({
    quantity: 1,
    name: "",
    email: "",
    phone: "",
    details_address: "",
  });
  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const handelNums = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!userInfo.name) {
      formErrors.name = "الرجاء إدخال الاسم";
    }

    // Validate email
    if (!userInfo.email) {
      formErrors.email = "الرجاء إدخال البريد الإلكتروني";
    } else if (!/\S+@\S+\.\S+/.test(userInfo.email)) {
      formErrors.email = "الرجاء إدخال بريد إلكتروني صحيح";
    }

    // Validate phone
    if (!userInfo.phone) {
      formErrors.phone = "الرجاء إدخال رقم الهاتف";
    }

    // Validate details address
    if (!userInfo.details_address) {
      formErrors.details_address = "الرجاء إدخال العنوان التفصيلي";
    }

    // Validate quantity
    if (!userInfo.quantity) {
      formErrors.quantity = "الرجاء إدخال الكمية";
    }
    // console.log(userInfo.quantity);
    if (+userInfo.quantity <= 0) {
      formErrors.quantity = "الرجاء إدخال كمية صحيحة";
    }
    if (+userInfo.quantity > Number(data.quantity)) {
      formErrors.quantity = `اقصي كمية يمكن طلبها من هذا المنتج ${data.quantity}`;
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const { postData, response, error } = usePostWithToken(
    `global/store/item/purchase-item/${type}`
  );
  const sendHandeller = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        item_id: id,
        quantity: userInfo.quantity,
        name: userInfo.name,
        email: userInfo.email,
        phone: userInfo.phone,
        details_address: userInfo.details_address,
        callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=product-details&type=product&value=${id}&role=${type}`,
      };
      postData(data);
      //   // console.log(data);
    }
  };
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      <h1 className=" my-6 text-[28px] font-[800] text-[#432315]   ">
        طلب منتج
      </h1>
      <div className=" mb-3  bg-[#E8DCC0] rounded-[15px] p-4 ">
        <section className="  flex items-center justify-start gap-2 md:gap-12 ">
          <section className=" my-4 ">
            <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
              الكمية
            </h1>
            <NumBtn
              val={userInfo.quantity}
              nameofVal="quantity"
              handelChangeMain={handelNums}
              min={1}
              max={Number(data.quantity)}
            />
          </section>
        </section>
        {<p className="error">{errors.quantity}</p>}
        <form
          className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  "
          onSubmit={sendHandeller}
        >
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              الاسم
            </h1>
            <input
              name="name"
              value={userInfo.name}
              onChange={handleChangeInfo}
              className=" inpt "
              type="text"
              placeholder="الاسم"
              //   required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              البريد الاكتروني
            </h1>
            <input
              name="email"
              value={userInfo.email}
              onChange={handleChangeInfo}
              className=" inpt "
              type="email"
              placeholder="البريد الاكتروني"
              //   required
            />
            {<p className="error">{errors.email}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              رقم الهاتف
            </h1>
            <input
              name="phone"
              value={userInfo.phone}
              onChange={handleChangeInfo}
              className=" inpt "
              type="text"
              placeholder="   رقم الهاتف"
              //   required
            />
            {<p className="error">{errors.phone}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" my-1 text-[#432315] text-[20px]  font-[800] ">
              عنوان التوصيل
            </h1>
            <input
              name="details_address"
              value={userInfo.details_address}
              onChange={handleChangeInfo}
              className=" inpt "
              type="text"
              placeholder="العنوان"
              //   required
            />
            {<p className="error">{errors.details_address}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 flex items-center justify-start gap-2 flex-wrap ">
            <button type="submit" className=" btn2 ">
              الدفع (
              {Number(userInfo.quantity) > 0
                ? Number(data.price) * Number(userInfo.quantity)
                : 0}{" "}
              ر.س)
            </button>
            {error && (
              <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                  <i className="fa-solid fa-exclamation  "></i>
                </span>
                {error.response.data.message
                  ? error.response.data.message
                  : "هناك مشكلة"}
              </section>
            )}
            {/* {response && <NewSucces />} */}
          </section>
        </form>
      </div>
    </div>
  );
}
