import React from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import Error from "../../../../../components/Error/Error";
import UnSbScribe from "./UnSbScribe";
import ReSub from "./ReSub";

export default function MyPackages() {
  let link = "global/packages/CurrentPackage";
  const { data, error } = useFetchWithReload(link);
  const current = new Date();
  // const endDat = new Date(data.subscription.end_date)
  // console.log(endDat > current)
  if (error) {
    return <Error />;
  }
  return (
    <div className=" py-8 px-4 ">
      {data && (
        <div>
          {new Date(data.subscription.end_date) > current ? (
            <section className=" py-1 px-4 text-lg w-fit text-green-800 font-semibold rounded-full border border-green-800 ">
              نشطة
            </section>
          ) : (
            <section className=" py-1 px-4 text-lg w-fit text-red-800 font-semibold rounded-full border border-red-800 ">
              منتهية
            </section>
          )}
          <h1 className=" text-[#432315] text-[24px] font-[600] ">
            اسم الباقة : {data.subscription.package.name}
          </h1>
          <p className=" md:max-w-[60%]  my-6  text-[20px] font-[600] leading-[28px] ">
            {data.subscription.package.description}
          </p>
          <p className="  text-[20px] font-[600] leading-[28px] my-2 ">
            فترة الاشتراك:{" "}
            <span className=" text-[#432315] font-[800] ">
              {data.subscription.start_date.substring(0, 10)} -{" "}
              <span className=" text-red-400 ">
                {data.subscription.end_date.substring(0, 10)}
              </span>
            </span>
          </p>
          <section className=" my-2  items-center flex-wrap gap-2 ">
            {new Date(data.subscription.end_date) > current ? (
              <>
                <UnSbScribe />
              </>
            ) : (
              <>
                {data.subscription.package.package_status === "active" && (
                  <ReSub />
                )}
              </>
            )}
          </section>
          <h1 className=" my-2 text-center text-xl ">المميزات</h1>
          <table>
            <thead>
              <tr>
                <th>الميزة</th>
                <th>العدد</th>
                <th>مرات الاستخدام</th>
              </tr>
            </thead>
            <tbody>
              {data.subscription.features_usage.map((e, inx) => (
                <tr key={inx}>
                  <td className=" text-center ">{e.name}</td>
                  <td className=" text-center ">
                    {e.usage_limit ? e.usage_limit : "غير محدود"}
                  </td>
                  <td className=" text-center ">{e.usage_count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
