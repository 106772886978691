import React from "react";
import SquerDashboardComponent from "../../components/squesrs/SquerDashboardComponent";

export default function Squers({ setEditId, data, setDeleteId }) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <SquerDashboardComponent
          setEditId={setEditId}
          setDeleteId={setDeleteId}
          key={inx}
          data={e}
        />
      ))}
    </div>
  );
}
