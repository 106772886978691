import React from "react";
import Banner from "./Banner";
import Sponsers from "./Sponsers";
import Vision from "./Vision";
import Servs from "./Servs";
import Events from "./Events";
import Owners from "./Owners";
import News from "./News";
import Gallery from "./Gallery";
import Camels from "./Camels";
import Owner from "./Owner";
import "./style.css";
import { Helmet } from "react-helmet-async";

export default function Home() {
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "منصة الابل",
        "description": "منصة متخصصة في كل ما يخص الابل وسباقات الابل والجمال والمنتجات المتعلقة بها والفعليات التي ينظمها المهتمون بها والطب البيطري المتخصص للابل من مستشفيات و متخصصون",
        "url": "https://mitracamel.com"
      }
    `}
        </script>
      </Helmet>
      <Banner />
      <Sponsers />
      <Vision />
      <Servs />
      <Owner />
      <Events />
      <Owners />
      <Camels />
      <News />
      <Gallery />
    </div>
  );
}
