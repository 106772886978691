import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Error from "../../components/Error/Error";
// import useFetchGlobal from "../../utils/useGetGlobal";
import Cookies from "js-cookie";

export default function Calback() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("tap_id");
  const redirect = new URLSearchParams(search).get("redirect");
  const type = new URLSearchParams(search).get("type");
  const value = new URLSearchParams(search).get("value");
  const role = new URLSearchParams(search).get("role");

  //   const { data, error } = useFetchGlobal(
  //     `global/payment/callback?tap_id=${id}`
  //   );
  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     if (data) {
  //       setTimeout(() => {
  //         navigate("/dashboard");
  //       }, 2000);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [data]);

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [doit, setDoIt] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDoIt(true);
    }, 100);
  }, []);

  const navigate = useNavigate();
  const token = Cookies.get("token");
  useEffect(() => {
    if (doit) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_URL}/api/global/payment/callback?tap_id=${id}`,
        requestOptions
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => setData(data));
          setTimeout(() => {
            if (redirect) {
              navigate(`/${redirect}?${type}=${value}&role=${role}`);
            } else {
              navigate("/dashboard");
            }
          }, [2000]);
        } else {
          res.json().then((data) => {
            // console.log(data);
            setError(data);
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doit]);

  if (!id) {
    return <Error />;
  }
  return (
    <div className="  min-h-[80vh] flex items-center justify-center w-full ">
      {!error && (
        <>
          {!data ? (
            <h1>جاري التحميل .......</h1>
          ) : (
            <>
              {data.success ? (
                <div className="  text-center w-full m-4 rounded-[15px]  px-4 py-12 ">
                  <h1 className=" text-[84px] font-[600] text-[#65A30D] ">
                    <i className="fa-solid fa-circle-check"></i>
                  </h1>
                  <h1 className=" text-[34px] font-[600] text-[#65A30D] my-2 ">
                    تمت عملية الدفع بنجاح
                  </h1>
                </div>
              ) : (
                <div className="  text-center w-full m-4 rounded-[15px]  px-4 py-12 ">
                  <h1 className=" text-[84px] font-[600] text-red-700">
                    <i className="fa-solid fa-circle-exclamation"></i>
                  </h1>
                  <h1 className=" text-[34px] font-[600] text-red-700 my-2 ">
                    فشلت عملية الدفع
                  </h1>
                  <h1 className=" text-[20px] font-[500] text-red-700 my-2 ">
                    {data.message}
                  </h1>
                </div>
              )}
            </>
          )}
        </>
      )}
      {error && (
        <div className="  text-center w-full m-4 rounded-[15px]  px-4 py-12 ">
          <h1 className=" text-[84px] font-[600] text-red-700">
            <i className="fa-solid fa-circle-exclamation"></i>
          </h1>
          <h1 className=" text-[34px] font-[600] text-red-700 my-2 ">
            فشلت عملية الدفع
          </h1>
          <h1 className=" text-[20px] font-[500] text-red-700 my-2 ">
            {error.reason ? error.reason : error.message}
          </h1>
        </div>
      )}
    </div>
  );
}
