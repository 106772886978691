import React from "react";
import { Link } from "react-router-dom";

export default function StoreComponents({ data, categers }) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  let type = categers.filter((e) => e.id === data.category_id);
  return (
    <div className=" text-[#432315] w-full max-w-[330px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" overflow-hidden relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
        <section className=" absolute text-[white] bg-[#432315] rounded-r-full top-0 left-0 w-[60px] h-[28px] flex items-center justify-center gap-[5px]  ">
          <h1>{data.general_rate}</h1>
          <i className="fa-solid fa-star text-[#F4A700]"></i>
        </section>
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.name}</h1>
      <p className=" min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.short_description} />
      </p>
      {type.length > 0 && (
        <section className=" text-[#432315] border-[#432315] border rounded-full text-center w-fit py-[1px] my-2 px-3 ">
          {type[0].name}
        </section>
      )}
      {/* <Link to={`/store-owner?owner=${data.owner.id}`}>
        <section className=" w-fit  my-3 flex items-center gap-4 ">
          <div className=" overflow-hidden relative bg-origin-content bg-cover rounded-full  w-[38px] h-[38px]">
            <img
              className={`  absolute left-0 top-0 h-full w-full  object-cover `}
              src={data.owner.img}
              alt="cover"
            />
          </div>
          <h1 className=" text-[#432315] text-[15px] font-[700] ">
            البائع : {data.owner.name}
          </h1>
        </section>
      </Link> */}
      <section className=" text-center mt-4 ">
        <Link to={`/product-details?product=${data.id}&role=${data.role}`}>
          <button className=" btn2 ">استعرض المنتج</button>
        </Link>
      </section>
    </div>
  );
}
