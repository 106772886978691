import React, { useCallback, useContext, useEffect, useState } from "react";
// import HeadCover4 from "../../../components/pages/HeadCover4";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";
import Filter from "./Filter";
import HospitalsCont from "./HospitalsCont";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import useFetchGlobal from "../../../utils/useGetGlobal";
import { Helmet } from "react-helmet-async";
import NewOver from "../../../components/pages/NewOver";

export default function Hospitals() {
  const { logged, role } = useContext(isLogged);
  const serverType = Cookies.get("type");
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "newest",
  });
  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);
  const [filteredData, setFilteredData] = useState([]);
  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const toggleFilterOpen = useCallback(() => {
    setFilterOpen((prev) => !prev);
    setSortOpen(false);
  }, []);

  const [checkedLocations, setCheckedLocations] = useState([
    "الرياض",
    "جدة",
    "الامارات",
    "قطر",
    "البحرين",
    "اخري",
  ]);

  const { data, error } = useFetchGlobal(
    "global/veterinaryMedicine/clinics/all"
  );

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data;
      filtered = filtered.filter((item) =>
        checkedLocations.includes(item.location)
      );
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [checkedLocations, mainFilter, data]);
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "منصة الابل",
        "description": "المستشفيات البيطرية و المتخصصون في علاج الابل ",
        "url": "https://mitracamel.com/veterinary-hospitals"
      }
    `}
        </script>
      </Helmet>
      {/* <HeadCover4
        img="./imgs/cover1.jpg"
        title="مستشفيات و عيادات | Hospitals and clinics"
        desc="اكتشف وتعرف على أبرز المرافق الطبية المتخصصة في رعاية الإبل في مكان واحد لسهولة الاختيار"
      /> */}{" "}
      <NewOver img="./imgs/hospitals.png" title="مستشفيات  وعيادات" />
      <div className="p-6 md:py-20 md:px-12">
        <div className="flex items-center justify-between flex-wrap md:gap-2">
          {/* Search */}
          <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
            <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
              <i className="fa-solid fa-magnifying-glass m-2"></i>
              <input
                type="text"
                value={mainFilter.search}
                name="search"
                onChange={handleMainFilterChange}
                placeholder="أبحث هنا..."
                className="outline-none bg-[black]/0 flex-1 p-2"
              />
            </div>
            <h1 className="hidden md:flex">{filteredData.length} عيادة</h1>
          </section>

          {/* Sort */}
          <section className="hidden md:flex items-center gap-2">
            <h1 className="text-[15px] font-[500]">ترتيب بواسطة:</h1>
            <select
              onChange={handleMainFilterChange}
              value={mainFilter.sort}
              name="sort"
              className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
            >
              <option value="newest">من الأحدث الي الأقدم</option>
              <option value="oldest">من الأقدم الي الأحدث</option>
            </select>
          </section>

          {/* Mobile Filter & Sort */}
          <section className="md:hidden flex gap-2">
            <button
              onClick={toggleFilterOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> فلتر
            </button>
            <button
              onClick={toggleSortOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> ترتيب
            </button>
          </section>

          {/* Create Investment */}
          <section className="py-3 flex justify-end">
            {!logged ? (
              <>
                <button
                  className="btn hidden md:flex"
                  onClick={toggleRegisterMessage}
                >
                  انشاء عيادة خاصة بك
                </button>
                <button
                  className="btn md:hidden"
                  onClick={toggleRegisterMessage}
                >
                  انشاء عيادة
                </button>
              </>
            ) : (
              <>
                {role === "provider" && (
                  <>
                    {serverType === "veterinary" && (
                      <Link to={"/dashboard?type=5"} className="btn">
                        <i className="fa-solid fa-circle-plus mx-2 "></i>
                        انشاء عيادة
                      </Link>
                    )}
                  </>
                )}
                {role === "super_admin" && (
                  <>
                    {
                      <Link to={"/dashboard?type=3"} className="btn">
                        {/* <i className="fa-solid fa-circle-plus mx-2 "></i> */}
                        ادارة العيادات
                      </Link>
                    }
                  </>
                )}
              </>
            )}
          </section>
        </div>
        {isSortOpen && (
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
          >
            <option value="newest">من الأحدث الي الأقدم</option>
            <option value="oldest">من الأقدم الي الأحدث</option>
          </select>
        )}
        {isFilterOpen && (
          <Filter
            locationOptions={checkedLocations}
            setLocationsOptions={setCheckedLocations}
          />
        )}
        <div className="flex items-start gap-4 mt-10 mb-3">
          <section className="hidden md:grid w-[210px]">
            <Filter
              locationOptions={checkedLocations}
              setLocationsOptions={setCheckedLocations}
            />
          </section>
          <section className="flex-1">
            {error ? (
              <h1 className=" text-center text-2xl text-red-500 my-3 ">
                هناك مشكلة
              </h1>
            ) : (
              <>
                {mainFilter.sort === "newest" ? (
                  <HospitalsCont alldata={filteredData} />
                ) : (
                  <HospitalsCont alldata={[...filteredData].reverse()} />
                )}
              </>
            )}
          </section>
        </div>
      </div>
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مستشفي خاصة بك"
        />
      )}
    </div>
  );
}
