import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import DropDownPhone from "./DropDownPhone";
import { isLogged } from "../../utils/inStore";
import Cookies from "js-cookie";
import { count } from "../../utils/countStore";
// import { load } from "../../utils/isLoadingStore";

export default function SideNav() {
  let links = [
    {
      text: "متجر",
      link: "/stores",
    },
    {
      text: "مزاد",
      link: "/auctions",
    },
    {
      text: "ساحات",
      link: "/squres",
    },
  ];
  const [open, setOpen] = useState(false);

  const colseHandeller = () => {
    setOpen(false);
  };

  let serLinks = [
    // {
    //   text: "خدمات حكومية / الكترونية",
    //   link: "/eServices",
    // },
    {
      text: "الطب البيطري",
      link: "/veterinary",
    },
    {
      text: "استثمار",
      link: "/invset",
    },
    {
      text: "تحالفات",
      link: "/alliance",
    },
    {
      text: "خبراء",
      link: "/experts",
    },
  ];
  // let caLinks = [
  //   {
  //     text: "سباقات",
  //     link: "/races",
  //   },
  //   {
  //     text: "متجر",
  //     link: "/stores",
  //   },
  //   {
  //     text: "مزادات",
  //     link: "/auctions",
  //   },
  //   {
  //     text: "خبراء",
  //     link: "/experts",
  //   },
  //   {
  //     text: "الساحات",
  //     link: "/squres",
  //   },
  //   {
  //     text: "سياحة",
  //     link: "/tourism",
  //   },
  // ];
  const events = [
    {
      text: "فعالية",
      link: "/events",
    },
    {
      text: "سباق",
      link: "/races",
    },
    {
      text: "سياحة",
      link: "/tourism",
    },
  ];
  const infos = [
    {
      text: "اخبار",
      link: "/news",
    },
    {
      text: "مدونة",
      link: "/blog",
    },
    {
      text: "وسائط",
      link: "/media",
    },
  ];

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden"); // cleanup on component unmount
    };
  }, [open]);
  const ref = useRef(null);
  const handelClickOutSide = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handelClickOutSide && handelClickOutSide();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handelClickOutSide]);

  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const name = Cookies.get("name");
  const img = Cookies.get("image");
  // const token = Cookies.get("token");
  const roleCookie = Cookies.get("rcom9lsuZsWree9mdXNlcg==");
  let roleofUser = "";
  // let link = "";
  if (roleCookie === "dmlzaXRvcnRvc2VlMDk4") {
    roleofUser = "زائر";
    // link = "visitor/logout";
  }
  if (roleCookie === "aWFtb3duaW5nY2FtZWxzMzA2NA==") {
    roleofUser = "مالك ابل";
    // link = "camelOwner/logout";
  }
  if (roleCookie === "c3F1ZXJvd25lcmhhdmVzb21lZXZlbnRzMjA4") {
    roleofUser = "صاحب ساحة";
    // link = "field-owner/logout";
  }
  if (roleCookie === "SXByb3ZpZHNvbWVzZXJ2aWNlc3lvdWNhbnRlc3RpdDM") {
    roleofUser = "مزود خدمة";
    // link = "logout";
  }
  if (roleCookie === "c3VwZXJBttZG1pbiB0ZXh0IHRvIGNvZGUxxgNjkgdHlwZSAmmyMTM1") {
    roleofUser = "الادارة";
    // link = "logout";
  }
  const data = {
    name: name,
    img: "./imgs/profile/user.jfif",
    type: roleofUser,
  };
  // const { setIsLoading } = useContext(load);
  const { setLogged, setRole } = useContext(isLogged);
  const navigation = useNavigate();
  const logOuthandeller = () => {
    // setIsLoading(true);
    // let token = Cookies.get("token");
    // const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };
    // fetch(
    //   `${process.env.REACT_APP_URL}/api/${link}`,
    //   requestOptions
    // ).then((res) => {
    //   if (res.status === 200 || res.status === 201) {
    //     setIsLoading(false);
    //     setLogged(false);
    //     setRole("");

    //     Cookies.remove("name");
    //     Cookies.remove("token");
    //     Cookies.remove("rcom9lsuZsWree9mdXNlcg==");
    //     navigation("/login");
    //   }
    // });
    Cookies.remove("name");
    Cookies.remove("token");
    Cookies.remove("expirein");
    Cookies.remove("image");
    Cookies.remove("type");
    Cookies.remove("value");
    Cookies.remove("rcom9lsuZsWree9mdXNlcg==");
    navigation("/login");
    setLogged(false);
    setRole("");
  };
  const { countNum } = useContext(count);
  return (
    <div className="  z-[100] relative">
      <div className=" w-full overflow-hidden flex items-center justify-between gap-3 bg-[#F5EFDC] border-b border-black p-2 ">
        <section>
          <button onClick={() => setOpen(true)}>
            <i className="fa-solid fa-bars mx-2 text-3xl text-[#51526C]"></i>
          </button>
        </section>
        <section>
          <Link to="/">
            <img
              className="h-[45px]"
              src="./imgs/navLogoCropped.png"
              alt="Logo"
            />
          </Link>
        </section>
        <section className=" text-[#3F230E] font-[800] text-[21px] flex items-center gap-6">
          {/* <Link to="/search">
            <i className="fa-solid fa-magnifying-glass "></i>
          </Link> */}
          {logged ? (
            <>
              <Link className=" relative " to="/notification">
                <i className="fa-solid fa-bell "></i>
                <span className=" absolute text-[10px] font-normal flex items-center justify-center text-white h-[20px] w-[20px] rounded-full bg-[#876354] bottom-[-4px] left-[-8px] ">
                  {" "}
                  {countNum}
                </span>
              </Link>
              <Link to="/shop">
                <i className="fa-solid fa-bag-shopping ml-2"></i>
              </Link>
            </>
          ) : (
            <Link to="/login">
              <button
                onClick={colseHandeller}
                className=" text-sm text-[#E8DCC0]  bg-[#432315] font-[400] p-1 rounded-md "
              >
                تسجيل الدخول
              </button>
            </Link>
          )}
        </section>
      </div>
      <div className="  h-screen z-[100] fixed top-0 right-0">
        <section
          ref={ref}
          className={`h-screen transition-all ease-in-out duration-700 bg-[#E8DCC0] ${
            open ? " mr-0 " : "mr-[-250px]"
          } p-6 w-[250px] overflow-auto pb-16 `}
        >
          <section className=" text-center py-4 border-b border-[#A59382]">
            {!logged ? (
              <Link to="/login">
                <button onClick={colseHandeller} className="btn">
                  تسجيل الدخول
                </button>
              </Link>
            ) : (
              <>
                <section className=" w-full ">
                  {!!img && img !== "null" ? (
                    <img
                      src={`${process.env.REACT_APP_URL}/${img}`}
                      alt="userImage"
                      className=" w-[60px] mx-auto h-[60px] rounded-full "
                    />
                  ) : (
                    <section className=" flex items-center justify-center  w-[60px] mx-auto h-[60px] rounded-full shadow-lg bg-gray-200 ">
                      <i className="fa-solid fa-user text-xl text-[#432315] "></i>
                    </section>
                  )}
                  <h4 className="text-[#432315]  font-[600] my-2">
                    {data.name}
                  </h4>
                  <h1 className=" text-[12px] text-[#432315BF] font-[500] ">
                    {data.type}
                  </h1>
                  <hr className=" my-2 border-[#A59382] w-[60%] mx-auto " />
                  <section className=" rtl almarai-regular   w-[200px] p-[10px] ">
                    {role === "camel-owner" && (
                      <section
                        onClick={colseHandeller}
                        className=" mb-3 text-center "
                      >
                        <Link
                          className=" text-center w-fit border-[#290900] px-2 py-1 border mx-auto text-[#290900] text-[14px] fot-[400] rounded-full "
                          to="/profile"
                        >
                          عرض الصفحة الشخصية
                        </Link>
                      </section>
                    )}
                    <Link onClick={colseHandeller} to="/dashboard">
                      <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full  flex items-center gap-2 ">
                        <i className="fa-solid fa-sliders"></i>
                        <h4>الادارة</h4>
                      </section>
                    </Link>
                    {role === "super_admin" && (
                      <Link onClick={colseHandeller} to="/pending">
                        <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full  flex items-center gap-2 ">
                          <i className="fa-solid fa-hourglass-end"></i>
                          <h4>الطلبات المعلقة</h4>
                        </section>
                      </Link>
                    )}
                    {role === "super_admin" && (
                      <Link onClick={colseHandeller} to="/contact-dash">
                        <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full  flex items-center gap-2 ">
                          <i className="fa-solid fa-message"></i>
                          <h4>معلومات التواصل</h4>
                        </section>
                      </Link>
                    )}

                    <Link onClick={colseHandeller} to="/settings">
                      <section className=" border-[#A59382] text-black py-2 text-[15px] font-[600] w-full flex items-center gap-2 ">
                        <i className="fa-solid fa-gear"></i>
                        <h4 className="  ">الاعدادات و الخصوصية</h4>
                      </section>
                    </Link>
                    <section className="  text-center">
                      <button
                        onClick={logOuthandeller}
                        className=" pt-4 flex items-center gap-2 justify-center w-full  text-[#C62020] text-[15px] font-[600] "
                      >
                        {" "}
                        تسجيل خروج{" "}
                        <i className="fa-solid fa-right-from-bracket rotate-180 "></i>{" "}
                      </button>
                    </section>
                  </section>
                </section>
              </>
            )}
          </section>
          <section className=" flex text-start flex-col text-[#3F230E] font-[600] text-[21px] gap-6 py-3">
            <Link to="/">
              <button className="  w-full  text-start" onClick={colseHandeller}>
                الرئيسية
              </button>
            </Link>
            <DropDownPhone
              colseHandeller={colseHandeller}
              name={"الخدمات"}
              links={serLinks}
            />
            <DropDownPhone
              colseHandeller={colseHandeller}
              name={"فعاليات"}
              links={events}
            />
            {links.map((li) => (
              <Link key={li.link} to={li.link}>
                <button
                  className="  w-full  text-start"
                  onClick={colseHandeller}
                >
                  {li.text}
                </button>
              </Link>
            ))}
            <DropDownPhone
              colseHandeller={colseHandeller}
              name={"معلومات"}
              links={infos}
            />
            {logged && (
              <>
                {role === "super_admin" ? (
                  <Link to="/dashboard?type=1">
                    <button
                      className="  w-full  text-start"
                      onClick={colseHandeller}
                    >
                      الباقات
                    </button>
                  </Link>
                ) : (
                  <Link to="/packages">
                    <button
                      className="  w-full  text-start"
                      onClick={colseHandeller}
                    >
                      الباقات
                    </button>
                  </Link>
                )}

                {/* {role === "vistor" && <Link to="/dashboard?type=4">الباقات</Link>}
            {role === "camel-owner" && <Link to="/dashboard?type=7">الباقات</Link>}
            {role === "squer" && <Link to="/dashboard?type=9">الباقات</Link>} */}
              </>
            )}
            <Link to="/communication">
              <button className="  w-full  text-start" onClick={colseHandeller}>
                تواصل معنا
              </button>
            </Link>
          </section>
        </section>
      </div>
    </div>
  );
}
