import React from "react";
import Hr from "../../components/pages/Hr";
import { Link } from "react-router-dom";

export default function Gallery() {
  return (
    <div className=" my-6 ">
      <div className="  py-12 px-8 md:px-16 relative   ">
        <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
          <img
            src="./imgs/swipper/5.jpg"
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover brightness-50"
          />
        </div>
        <div className=" mb-[150px] text-center  items-center justify-around gap-8 relative z-10 ">
          {" "}
          <section className="   ">
            <h1 className=" my-2 text-[#E8DCC0] text-[32px] md:text-[48px] font-[700] ">
              الصور و الفيديوهات
            </h1>
            <Hr color="#E8DCC0" />
          </section>
          {/* <p className="  flex-1 my-2 text-[#E8DCC0] leading-[40px] text-[18px] font-[600] ">
            نتيح لك فرصة مشاركة أجمل ما لديك مع آلاف محبي ومربي الإبل ليستمتعوا
            به مثلما استمتعت أنت بالتقاطه.
          </p> */}
          <Link to="/media">
            <button className="btn2 mt-4">عرض المزيد</button>
          </Link>
        </div>
      </div>
      {/* <div className="relative flex gap-6 flex-wrap justify-around z-10 mt-[-140px] mb-6 px-6 ">
        <section className=" relative w-[293px] h-[534px] rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
          <img
            src="./imgs/home/ga1.jfif"
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover "
          />
        </section>
        <section className=" w-fit ">
          <section className=" relative w-[440px] h-[270px] rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
            <img
              src="./imgs/home/ga2.jfif"
              alt="Vision"
              className="absolute left-0 top-0 h-full w-full object-cover "
            />
          </section>
          <section className=" mt-4 relative w-[440px] h-[270px] rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
            <img
              src="./imgs/home/ga3.jfif"
              alt="Vision"
              className="absolute left-0 top-0 h-full w-full object-cover "
            />
          </section>
        </section>
        <section className=" relative w-[293px] h-[534px] rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
          <img
            src="./imgs/home/ga4.jfif"
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover "
          />
        </section>
      </div> */}
      <div className="relative flex gap-6 flex-wrap justify-around z-10 mt-[-140px] mb-6 px-6 ">
        <section className="  w-full md:w-1/3 min-w-[200px] max-w-[500px] relative  rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
          <img
            src="./imgs/home/ga1.jpg"
            alt="Vision"
            className=" left-0 top-0 h-full w-full object-cover "
          />
        </section>
        <section className=" min-w-[200px] max-w-[500px] w-full md:w-1/3 flex-1 ">
          <section className=" relative  rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
            <img
              src="./imgs/home/ga2.jfif"
              alt="Vision"
              className=" left-0 top-0 h-full w-full object-cover "
            />
          </section>
          <section className=" mt-4 relative rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
            <img
              src="./imgs/home/ga4.jfif"
              alt="Vision"
              className=" left-0 top-0 h-full w-full object-cover "
            />
          </section>
        </section>
        <section className=" hidden lg:flex w-1/3 min-w-[200px] max-w-[500px]  relative  rounded-[16px] shadow-md overflow-hidden bg-origin-content bg-cover  ">
          <img
            src="./imgs/home/ga3.jpg"
            alt="Vision"
            className=" left-0 top-0 h-full w-full object-cover "
          />
        </section>
      </div>
    </div>
  );
}
