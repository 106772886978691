import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import InvestmentDetails from "../invest/InvestmentDetails";
import FormInvest from "./Form";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import { isLogged } from "../../../utils/inStore";

export default function JoinInvest() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("invest");
  const role = new URLSearchParams(search).get("role");
  const { data, error } = useFetchGlobal(
    `global/investments/show/${invest}/${role}`
  );

  const { logged } = useContext(isLogged);
  if (error || !logged ) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/invset">
              الاستثمار
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل الاستثمار `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className=" flex items-center gap-3 ">
            <Tab
              text={"تقديم الإستثمار"}
              val={1}
              tab={1}
              setTabState={() => {}}
            />
          </section>
          {!!data && <FormInvest data={data.investment} />}
        </div>
        {/* Owner */}
        {!!data && <InvestmentDetails join={true} data={data.investment} />}
      </div>
    </div>
  );
}
