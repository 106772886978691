import React from "react";
import File from "../../components/pages/File";

export default function Secudels({ data }) {
  return (
    <div className=" days my-4 mx-[20px]">
      {data.agendas.map((day, inx) => (
        <div
          key={inx}
          className="  w-full max-w-[420px] pb-[15px] pr-[40px] relative  border-r-[4px] border-[#432315] "
        >
          <section className=" w-[50px] h-[50px] rounded-full bg-[#432315] absolute top-0 right-[-25px]  "></section>
          <section className="  flex items-center justify-between h-[50px] ">
            <h1 className=" text-[20px] text-[#290900] font-[700] ">
              {day.day}
            </h1>
            <h1 className=" text-[15px] text-[#290900] font-[500] ">
              {day.start_time.slice(0, 5)} - {day.end_time.slice(0, 5)}
            </h1>
          </section>
          <h1 className=" text-[24px] text-[#290900] font-[800] my-10 ">
            {day.title}
          </h1>
          <p className="my-2 text-[#290900] text-[18px] text-[500] ">
            {day.description}
          </p>
          {!!day.files && (
          <>
            {JSON.parse(day.files).length > 0 && (
              <>
                <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
                  ملف الحدث
                </h1>
                <section className=" flex gap-2 flex-wrap ">
                  {JSON.parse(day.files).map((e, inx) => (
                    <File key={inx} da={`${process.env.REACT_APP_URL}/${e}`} />
                  ))}
                </section>
              </>
            )}
          </>
        )}
        </div>
      ))}
    </div>
  );
}
