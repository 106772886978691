import React, { useCallback, useEffect, useState } from "react";
// import BlogBanner from "../../components/pages/BlogBanner";
// import HeadCover from "../../components/pages/HeadCover";
import Filter from "./Filter";
import AllBlogs from "./AllBlogs";
import useFetchGlobal from "../../utils/useGetGlobal";
import NewOver from "../../components/pages/NewOver";

export default function Blogs() {
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "newest",
  });
  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);
  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const [checkedFilters, setCheckedFilters] = useState([
    "races",
    "auctions",
    "genral information",
  ]);
  const [checkedLocations, setCheckedLocations] = useState([
    "riyhad",
    "jada",
    "uae",
    "qutar",
    "bahreen",
  ]);

  const [filteredData, setFilteredData] = useState([]);

  const { data, error } = useFetchGlobal("global/blogs/all");

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data;
      console.log(data);
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.title.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [data, mainFilter]);
  return (
    <div>
      {/* <HeadCover
        img="./imgs/swipper/1.jpg"
        title="المدونة"
        desc="أكتشف كل الموضوعات التي تخص عالم الجمال"
      /> */}
      <NewOver img="./imgs/blog.png" title="المدونة" />
      <div className="p-6 md:py-20 md:px-12">
        <div className="flex items-center justify-between flex-wrap md:gap-2">
          {/* Search */}
          <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
            <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
              <i className="fa-solid fa-magnifying-glass m-2"></i>
              <input
                type="text"
                value={mainFilter.search}
                name="search"
                onChange={handleMainFilterChange}
                placeholder="أبحث هنا..."
                className="outline-none bg-[black]/0 flex-1 p-2"
              />
            </div>
          </section>

          {/* Sort */}
          <section className="hidden lg:flex items-center gap-2">
            <h1 className="text-[15px] font-[500]">ترتيب بواسطة:</h1>
            <select
              onChange={handleMainFilterChange}
              value={mainFilter.sort}
              name="sort"
              className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
            >
              <option value="newest">من الأحدث الي الأقدم</option>
              <option value="oldest">من الأقدم الي الأحدث</option>
            </select>
          </section>

          {/* Mobile Filter & Sort */}
          <section className="lg:hidden my-2 flex gap-2">
            {/* <button
              onClick={toggleFilterOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> فلتر
            </button> */}
            <button
              onClick={toggleSortOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> ترتيب
            </button>
          </section>
        </div>
        {isSortOpen && (
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
          >
            <option value="newest">من الأحدث الي الأقدم</option>
            <option value="oldest">من الأقدم الي الأحدث</option>
          </select>
        )}

        {isFilterOpen && (
          <Filter
            filter={checkedFilters}
            setCheckedState={setCheckedFilters}
            locationOptions={checkedLocations}
            setLocationsOptions={setCheckedLocations}
          />
        )}
        <div className="flex items-start gap-4 mt-10 mb-3">
          {/* <section className="hidden lg:grid w-[210px]">
            <Filter
              filter={checkedFilters}
              setCheckedState={setCheckedFilters}
              locationOptions={checkedLocations}
              setLocationsOptions={setCheckedLocations}
            />
          </section> */}
          <section className="flex-1">
            {error ? (
              <h1 className=" text-center text-2xl text-red-500 my-3 ">
                هناك مشكلة
              </h1>
            ) : (
              <>
                {mainFilter.sort === "newest" ? (
                  <AllBlogs alldata={[...filteredData].reverse()} />
                ) : (
                  <AllBlogs alldata={filteredData} />
                )}
              </>
            )}
            {filteredData.length === 0 && (
              <h1 className=" text-red-800 my-4 font-bold text-center ">
                لا توجد مدونات
              </h1>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
