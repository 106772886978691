import React, { useContext } from "react";
import { isLogged } from "../../../../utils/inStore";
import { useLocation } from "react-router";
import Error from "../../../../components/Error/Error";
import useFetchGlobal from "../../../../utils/useGetGlobal";
import { Link } from "react-router-dom";
import Pref from "./Pref";
import OrderForm from "./OrderForm";

export default function Order() {
  const { logged } = useContext(isLogged);
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("product");
  const owner = new URLSearchParams(search).get("role");
  const { data, error } = useFetchGlobal(
    `global/store/item/show/${invest}/${owner}`
  );

  if (!logged || error) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <section className="   text-[16px] font-[600] ">
        <section className="flex items-center gap-1 my-1 ">
          <Link className="text-[#432315BF] " to="/stores">
            المتجر
          </Link>
          <h1 className="text-[#432315] ">{`> شراء المنتج`}</h1>
        </section>
        <hr className=" w-24 h-[3px] bg-[black] border-black " />
      </section>
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1  w-deta  ">
          <>
            {data && (
              <>
                {Number(data.item.quantity) > 0 ? (
                  <>
                    <OrderForm type={owner} id={invest} data={data.item} />
                  </>
                ) : (
                  <section className=" px-2 my-20 text-red-800 rounded-full w-fit mx-auto text-2xl border-red-500 ">
                    هذا المنتج غير متوفر الان
                  </section>
                )}
              </>
            )}
          </>
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && <Pref data={data.item} />}
        </section>
      </div>
    </div>
  );
}
