import React from "react";

export default function NewOver({ img, title, desc }) {
  return (
    <div className=" z-10 relative  ">
      <div className=" absolute bg-origin-content bg-cover   w-full h-full">
        <img
          loading="lazy"
          className={`  transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover brightness-[]`}
          src={img}
          alt="cover"
        />
      </div>
      <div className="  it w-full h-[300px] lg:h-[430px] overflow-hidden    bg-[#3F3F3F82] flex items-center justify-center md:justify-start  lg:px-[100px]  relative z-20 ">
        <section className=" z-0 absolute top-[-75px] right-[-75px] md:top-[-100px] md:right-[-250px]">
          <img
            className=" w-[200px] md:w-[400px] opacity-40 md:opacity-55 "
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className=" z-0 absolute bottom-[-75px] left-[-75px]  md:bottom-[-100px] md:left-[-250px]">
          <img
            className="  w-[200px] md:w-[400px] opacity-40 md:opacity-55"
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className="text-center  z-10 relative w-full text-[#F5EFDC]">
          <section className=" absolute z-10 hidden md:flex   top-[-250px] right-0 ">
            <svg
              width="700"
              height="751"
              viewBox="0 0 814 751"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_6810_140941)">
                <circle cx="440.5" cy="310.5" r="140.5" fill="#5C240B" />
              </g>
              <defs>
                <filter
                  id="filter0_f_6810_140941"
                  x="0.799988"
                  y="-129.2"
                  width="879.4"
                  height="879.4"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="149.6"
                    result="effect1_foregroundBlur_6810_140941"
                  />
                </filter>
              </defs>
            </svg>
          </section>
          <section className=" absolute z-10 flex items-center justify-center w-full   md:hidden top-[-120px] right-0 ">
            <svg
              width="400"
              height="351"
              viewBox="0 0 814 751"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_6810_140941)">
                <circle cx="440.5" cy="310.5" r="140.5" fill="#5C240B" />
              </g>
              <defs>
                <filter
                  id="filter0_f_6810_140941"
                  x="0.799988"
                  y="-129.2"
                  width="879.4"
                  height="879.4"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="149.6"
                    result="effect1_foregroundBlur_6810_140941"
                  />
                </filter>
              </defs>
            </svg>
          </section>
          <h1 className=" md:w-[700px] text-center relative z-20 text-[30px] md:text-[40px] lg:text-[85px] font-[600] leading-[74.96px] md:leading-[150.9px]  ">
            {title}
          </h1>
          <h4 className=" md:w-[700px] text-[20px] font-[600]   relative z-20 ">
            {desc}
          </h4>
        </section>
      </div>
    </div>
  );
}
