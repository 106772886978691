import React, { useEffect } from "react";
import usePostWithToken from "../../../utils/usePostJson";
// import NewSucces from "../../../components/pages/NewSucces";

export default function JoinBtn({ id, role }) {
  const { postData, response, error } = usePostWithToken(
    `global/field/join/${role}`
  );
  const handelJoin = () => {
    const data = {
      field_id: id,
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=squre-details&type=squre&value=${id}&role=${role}`,
    };
    postData(data);
  };
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);

  return (
    <div>
      <button onClick={handelJoin} className="btn2">
        المشاركة في الساحة
      </button>
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message
            ? error.response.data.message
            : "هناك مشكلة"}
        </section>
      )}
      {/* {response && <NewSucces />} */}
    </div>
  );
}
