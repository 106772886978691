import React from "react";
import ReactDOM from "react-dom";

export default function View({ selectedId, setSelectedId }) {
  const data = selectedId;
  return ReactDOM.createPortal(
    <div className=" almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0 ">
      <section className=" max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8D6B2] w-full rounded-[16px] ">
        <button
          onClick={() => setSelectedId(null)}
          className=" text-xl font-bold text-red-600 absolute top-4 right-4 "
        >
          X
        </button>
        <section className=" my-8 ">
          {/* <section className=" w-full flex items-center gap-3 ">
            <img
              alt="img publisher "
              src={data.user.img}
              className="h-[40px] w-[40px] rounded-full "
            />
            <h1 className=" text-[18px] font-[400] text-[#432315] ">
              {data.user.name}
            </h1>
          </section> */}
        </section>
        <section className="  flex items-center ">
          {data.media_type === "image" ? (
            <img
              alt=""
              className=" mx-auto rounded-[16px] max-w-full max-h-[500px] "
              src={`${process.env.REACT_APP_URL}/${data.path}`}
            />
          ) : (
            <video
              className=" mx-auto rounded-[16px] max-w-full max-h-[500px] "
              src={`${process.env.REACT_APP_URL}/${data.path}`}
              controls
            />
          )}
        </section>
      </section>
    </div>,
    document.body // Renders the component at the root level of the DOM
  );
}
