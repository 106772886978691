import React, { createContext, useContext, useEffect, useState } from "react";
// import useFetchWithReload from "./useGetWithReload";
import useFetchWithoutLoading from "./useGetWithoutLoading";
import { isLogged } from "./inStore";

export const count = createContext();

export default function CountStore({ children }) {
  const [countNum, setCountNum] = useState(0);
  const [reload, setReload] = useState(false);
  const re = () => {
    setReload(!reload);
  };
  const { data } = useFetchWithoutLoading(
    `global/notifications/getUnreadCount`,
    reload
  );
  const { logged } = useContext(isLogged);

  setTimeout(() => {
    if (logged) {
      re();
    }
  }, [10000]);
  useEffect(() => {
    if (data) {
      setCountNum(data.unread_count);
    }
  }, [data]);
  return <count.Provider value={{ countNum, re }}>{children}</count.Provider>;
}
