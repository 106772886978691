import React from "react";
import { Link } from "react-router-dom";

export default function PartnerCompnent({ data }) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  // const checkOptions = [
  //   { name: "الاحدث", val: "newest" },
  //   { name: "الاستثمار", val: "investment" },
  //   { name: "التربية", val: "raising" },
  //   { name: "التجارة", val: "trades" },
  //   { name: "الافكار", val: "thinks" },
  // ];
  return (
    <div className=" text-[#432315] w-full max-w-[300px] md:max-w-[300px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.name}</h1>
      <p className=" min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.description} />
      </p>
      <section className=" my-2 text-[15px] font-[500] text-[#432315] leading-[28px] flex items-center gap-2 ">
        <i className=" fa-solid fa-location-dot text-xl  " />
        <p>{data.address}</p>
      </section>
      {/* <section className=" flex items-center gap-2 flex-wrap ">
        {checkOptions.map((e, inx) => {
          if (data.types.includes(e.val)) {
            return (
              <p
                className=" w-fit px-3  text-[#432315] border border-[#432315] text-[15px] font-[500] rounded-full "
                key={inx}
              >
                {e.name}
              </p>
            );
          } else {
            return <></>;
          }
        })}
      </section> */}
      <section className=" text-center mt-4 ">
        <Link
          to={`/partnership-details?partnership=${data.id}&role=${data.role}`}
        >
          <button className=" btn2 ">تفاصيل التحالف</button>
        </Link>
      </section>
    </div>
  );
}
