import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import SquerInfo from "../Squer/SquerInfo";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import JoinBtn from "./JoinBtn";
import { isLogged } from "../../../utils/inStore";

export default function JoinSquer() {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("squre");
  const role = new URLSearchParams(search).get("role");
  const { logged } = useContext(isLogged);

  const { data, error } = useFetchGlobal(`global/field/show/${invest}/${role}`);
  if (error || !logged) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/squres">
              ساحات
            </Link>
            <h1 className="text-[#432315] ">{`> الانضمام للساحة`}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
      </div>
      {/* Body */}
      <div className=" my-4 md:flex items-start justify-center gap-2  ">
        {/* details */}
        <div className=" w-full max-w-[1000px]  ">
          <section className=" flex items-center gap-3 ">
            <Tab text={"الانضمام"} val={1} tab={1} setTabState={() => {}} />
          </section>
          {data && (
            <>
              <h1 className=" my-3 text-xl font-bold text-[#432315] ">
                اسم الساحة :{data.field.name}
              </h1>
              <JoinBtn id={invest} role={role} />
            </>
          )}
          {/* <SendForm data={data} /> */}
        </div>
        {/* Owner */}
        {!!data && <SquerInfo join={true} data={data.field} />}
      </div>
    </div>
  );
}
