/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CopyBtn from "../../../../components/pages/CopyBtn";

export default function Info({ data }) {
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          رقم الهاتف
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.phone}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          البريد الإلكتروني
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.email}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <CopyBtn />
      <section className=" my-4 ">
        <section className=" text-[#432315] text-3xl flex items-center justify-center gap-5 ">
          {!!data.facebook && (
            <a href={data.facebook}>
              <i className="fa-brands fa-facebook-f"></i>
            </a>
          )}
          {!!data.twitter && (
            <a href={data.twitter}>
              <i className="fa-brands fa-twitter"></i>
            </a>
          )}
          {!!data.instagram && (
            <a href={data.instagram}>
              <i className="fa-brands fa-instagram"></i>
            </a>
          )}
          {!!data.whatsapp && (
            <a href={data.whatsapp}>
              <i className="fa-brands fa-whatsapp"></i>
            </a>
          )}
        </section>
      </section>
    </div>
  );
}
