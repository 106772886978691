import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
// import Send from "../../SuperAdmin/contact/Send";

export default function Reports({ back }) {
  let link = "global/Report/alliance/owner/detailed-report";
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={back} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <>
          {data.report && (
            <>
              {data.report.alliances && (
                <div>
                  {data.report.alliances.map((par, indx) => (
                    <div
                      className=" my-2 border border-black/70 p-2 rounded-[16px] "
                      key={indx}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>التحاف</th>
                            <th>نسبة التحالف</th>
                            <th>تاريخ النشر</th>
                            <th>عدد المشتركين</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className=" text-center ">
                              {par.alliance_name}
                            </td>
                            <td className=" text-center ">{par.percentage}%</td>
                            <td className=" text-center ">
                              {par.published_date}
                            </td>
                            <td className=" text-center ">
                              {par.subscribers_count}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {par.subscribers.length > 0 && (
                        <>
                          <h1 className=" text-center my-3 font-bold text-xl  ">
                            المشتركين
                          </h1>
                          <table>
                            <thead>
                              <tr>
                                <th>الاسم</th>
                                <th>البريد الاكتروني</th>
                                <th>رقم الهاتف</th>
                              </tr>
                            </thead>
                            <tbody>
                              {par.subscribers.map((sub, inx) => (
                                <tr key={inx}>
                                  <td className=" text-center ">{sub.name}</td>
                                  <td className=" text-center ">{sub.email}</td>
                                  <td className=" text-center ">{sub.phone}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* <Send id={par.id} /> */}
                         
                        </>
                      )}
                      {par.subscribers.length === 0 && (
                        <h1 className=" text-red-600 text-center my-3 font-bold text-lg  ">
                          لا يوجد مشتركين
                        </h1>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
