import React from "react";

export default function HeadCover({ img, title, desc }) {
  return (
    <div className=" z-10 relative  ">
      <div className=" absolute bg-origin-content bg-cover   w-full h-full">
        <img
          className={`  transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover brightness-[]`}
          src={img}
          alt="cover"
        />
      </div>
      <div className="  it w-full h-[300px] md:h-[350px] overflow-hidden    bg-[#3F3F3F82]/50 flex items-center justify-center relative z-20 ">
        <section className=" z-0 absolute top-[-75px] right-[-75px] md:top-[-200px] md:right-[-200px]">
          <img
            className=" w-[200px] md:w-[400px] opacity-60 md:opacity-85 "
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className=" z-0 absolute bottom-[-75px] left-[-75px]  md:bottom-[-200px] md:left-[-200px]">
          <img
            className="  w-[200px] md:w-[400px] opacity-60 md:opacity-85"
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className="text-center z-10 relative text-[#F5EFDC]">
          <h1 className=" text-[40px] md:text-[60px] lg:text-[96px] font-[600] leading-[74.96px] md:leading-[150.9px]  ">
            {title}
          </h1>
          <h4 className=" text-[20px] font-[600] md:w-[75%] mx-auto  ">
            {desc}
          </h4>
        </section>
      </div>
    </div>
  );
}
