import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function InvestmentDetails({ data, join }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("invest");
  const role = new URLSearchParams(search).get("role");

  const [copied, setCopiedState] = useState(false);
  const code = window.location.href;
  const title = "";
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code).then(() => {
        setCopiedState(true);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      //   textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        setCopiedState(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };

  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const { logged } = useContext(isLogged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {!join ? (
        <>
          {+data.available_shares !== 0 && (
            <section className=" text-center ">
              {logged ? (
                <Link to={`/join-invest?invest=${invest}&role=${role}`}>
                  <button className="btn2">انضمام للاستثمار</button>
                </Link>
              ) : (
                <button onClick={toggleRegisterMessage} className="btn2">
                  انضمام للاستثمار
                </button>
              )}
            </section>
          )}
        </>
      ) : (
        <></>
      )}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          اسم الشركة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.company_name}
        </h1>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          اسم مالك الأستثمار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.owner}
        </h1>
      </section> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          اجمالي مبلغ الاستثمار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.total_investment} ر.س
        </h1>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          تاريخ بداية الاستثمار:
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.start_date}
        </h1>
      </section> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          الاسهم المتاحة للاستثمار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.available_shares} سهم
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          قيمة السهم الواحد
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.price_per_share} ر.س
        </h1>
      </section>
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          عدد المشاركين
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.subs} شخص
        </h1>
      </section> */}
      {/* <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          رقم الهاتف
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.phone}
        </h1>
      </section> */}
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          نسخ الرابط
        </h1>
        <button
          onClick={handleCopy}
          className=" text-[#290900] font-[500] text-[18px] leading-[28px] "
        >
          {copied ? "تم النسخ" : <i className="fa-solid fa-copy text-4xl"></i>}
        </button>
        <div className="flex items-center gap-4 my-3">
          <section className=" border border-[#432315] rounded-[5px] text-[#663520] px-[12px] py-[2px] ">
            <i className="fa-solid fa-share-nodes ml-2 "></i>
            مشاركة
          </section>

          <FacebookShareButton
            url={code}
            quote={title}
            className="share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton
            url={code}
            title={title}
            className="share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton url={code} title={title} className="share-button">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
      </section>
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء مكانك في الاستثمار"
        />
      )}
    </div>
  );
}
