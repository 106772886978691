import React from "react";
import InvestMestComponent from "../../components/pages/Investmesnt/InvestMestComponent";

export default function Invests({ alldata }) {
  return (
    <div className=" flex items-start justify-center gap-3 flex-wrap " >
      {alldata.map((e, inx) => (
        <InvestMestComponent key={inx} data={e} />
      ))}
    </div>
  );
}
