import React from "react";
// import HeadCover3 from "../../components/HeadCover3";
import VeterinaryComponent from "../../components/pages/Veterinary/VeterinaryComponent";
import NewOver from "../../components/pages/NewOver";

export default function Veterinary() {
  const data = [
    {
      name: "مستشفيات و عيادات متكاملة",
      desc: "احجز مواعيدك الطبية واطلب الأدوية البيطرية الخاصة بالإبل عبر الإنترنت",
      img: "./imgs/11.png",
      link: "/veterinary-hospitals",
      btn: "اكتشف الخدمات",
    },
    {
      name: "استشارات طبية متخصصة",
      desc: "استفد من خبرات أطباء بيطريين متمرسين في مجال طب الإبل",
      img: "./imgs/12.png",
      link: "/veterinary-consultatives",
      btn: "استشر الخبراء",
    },
  ];
  return (
    <div>
      {/* <HeadCover3
        img="./imgs/cover22.png"
        title="الطب البيطري"
        desc="تأكد من صحة وسلامة جمالك الثمينة مع خدمات الطب البيطري المتخصصة للجمال."
      /> */}{" "}
      <NewOver img="./imgs/vet 1.png" title="الطب البيطري" />
      <div className=" flex items-center justify-center gap-4 flex-wrap p-6 ">
        {data.map((el, inx) => (
          <VeterinaryComponent key={inx} data={el} />
        ))}
      </div>
    </div>
  );
}
