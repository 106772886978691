import React, { useEffect, useState } from "react";
// import InfoForm from "./InfoForm";
import CamelInfo from "./CamelInfo";
import TypeInfo from "./TypeInfo";
import Accept from "./Accept";
import { useLocation } from "react-router";
import usePostWithToken from "../../../utils/usePostJson";
// import NewSucces from "../../../components/pages/NewSucces";

export default function SendForm({ val, setValState, data }) {
  const next = () => {
    setValState((pres) => pres + 1);
  };
  const pre = () => {
    setValState((pres) => pres - 1);
  };
  const [userInfo, setUserInfo] = useState({
    nameCamel: "",
    ageCamel: 1,
    colorCamel: "",
    typeCamel: "",
    idCamel: "",
    cirtificateCamel: "",
    typeOfrace: "",
  });

  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("race");
  const role = new URLSearchParams(search).get("role");

  const { postData, response, error } = usePostWithToken(
    `global/race/join/${role}`
  );
  const sendHandeller = () => {
    let items = {
      race_id: invest,
      camel_name: userInfo.nameCamel,
      age: `${userInfo.ageCamel}`,
      color: userInfo.colorCamel,
      type: userInfo.typeCamel,
      definition_number: userInfo.idCamel,
      certificate_ownership: userInfo.cirtificateCamel,
      category: userInfo.typeOfrace,
      callback_url: `${process.env.REACT_APP_CAll_BACK}?redirect=race-details&type=race&value=${invest}&role=${role}`,
    };
    postData(items);
  };
  useEffect(() => {
    if (response) {
      window.location.href = response.redirect_url;
    }
  }, [response]);
  return (
    <div>
      {/* {val === 1 && (
        <InfoForm userInfo={userInfo} setUserInfo={setUserInfo} next={next} />
      )} */}
      {val === 1 && (
        <CamelInfo
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          next={next}
          pre={pre}
        />
      )}
      {val === 2 && (
        <TypeInfo
          data={data}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          next={next}
          pre={pre}
        />
      )}
      {val === 3 && <Accept submit={sendHandeller} pre={pre} data={data} />}
      {error && (
        <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
          <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
            <i className="fa-solid fa-exclamation  "></i>
          </span>
          {error.response.data.message}
        </section>
      )}
      {/* {response && <NewSucces />} */}
    </div>
  );
}
