import React from "react";
import HeadCover from "../../../components/pages/HeadCover";
import PaginatedItems from "./News";
import useFetchGlobal from "../../../utils/useGetGlobal";

export default function AllNews() {
  const { data, error } = useFetchGlobal("global/news/all");

  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }

  return (
    <div>
      <HeadCover
        img="./imgs/cover1.jpg"
        title="الأخبار"
        desc="تابع معنا جميع الأخبار"
      />
      {data && (
        <div className="  p-5 md:p-8  ">
          <section className=" my-8 lg:flex flex-wrap items-start gap-3 ">
            <section className=" flex-1 ">
              <section className=" flex items-center justify-between ">
                <h1 className=" text-[32px] text-black font-[700] leading-[60px] ">
                  احدث الأخبار
                </h1>
              </section>
              <PaginatedItems data={data} />
            </section>
          </section>
        </div>
      )}
    </div>
  );
}
