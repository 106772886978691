import React from "react";
import Profile from "./Profile";
import ProfileProuduct from "../../components/pages/OwnerProfile/ProfileProuduct";

export default function AllProductsOfOwner() {
  const products = [
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
    {
      img: "./imgs/home/user3.jpg",
      name: "إبل الفخر العربي",
      price: "45,000",
      date: "20 مارس 2024",
      time: "10:00صباحاً",
    },
  ];
  return (
    <div>
      <Profile />
      <div className=" my-12 mx-3 md:mx-24 ">
        <section className=" mt-3  justify-between">
          <h1 className=" text-center text-[#432315] text-[22px] font-[700] ">
            المتجر
          </h1>
        </section>
        <section className=" py-2 flex gap-3 flex-wrap  items-center justify-center   ">
          {products.map((prod, inx) => (
            <ProfileProuduct key={inx} data={prod} />
          ))}
        </section>
      </div>
    </div>
  );
}
