import React, { useEffect, useState } from "react";
import usePostFormData from "../../../../../utils/usePostForm";

export default function PayBtn({ re, id, type }) {
  const { postFormData, error, response } = usePostFormData(
    `superAdmin/Report/Investment/payout/${id}/${type}`
  );
  const [err, setErr] = useState(false);
  const [suc, setSuc] = useState(false);
  const payHandeller = () => {
    postFormData();
  };
  useEffect(() => {
    if (response) {
      re();
      setSuc(true);
      setTimeout(() => {
        setSuc(false);
      }, [3000]);
    }
    if (error) {
      setErr(true);
      setTimeout(() => {
        setErr(false);
      }, [3000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, response]);
  return (
    <div>
      <button
        onClick={payHandeller}
        className=" flex items-center gap-2 text-white text-[20px] py-1 px-2 rounded-md bg-green-600 "
      >
        دفع <i className="fa-solid fa-money-bill"></i>
      </button>
      {err && (
        <section className=" fixed w-full bottom-3 left-0 flex items-center justify-center ">
          <h1 className=" text-lg py-2 px-4 rounded-md bg-red-600 text-white ">
            {error ? (
              <>
                {error.response ? (
                  <>
                    {error.response.data ? (
                      <>
                        {error.response.data.message
                          ? error.response.data.message
                          : " هناك مشكلة في الدفع"}
                      </>
                    ) : (
                      " هناك مشكلة في الدفع"
                    )}
                  </>
                ) : (
                  " هناك مشكلة في الدفع"
                )}
              </>
            ) : (
              " هناك مشكلة في الدفع"
            )}
          </h1>
        </section>
      )}
      {suc && (
        <section className=" fixed w-full bottom-3 left-0 flex items-center justify-center ">
          <h1 className=" text-lg py-2 px-4 rounded-md bg-green-600 text-white ">
            تم الدفع بنجاح
          </h1>
        </section>
      )}
    </div>
  );
}
