import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import { Link } from "react-router-dom";

export default function MyRequests() {
  const { data, error } = useFetchWithToken(
    "global/store/requested-items/my-sold-items"
  );
  const status = {
    Pending: "منتظر ",
    OutOfStock: "خرج من المتجر ",
    Shipped: "  قيد التوصيل",
    Delivered: " تم التوصيل ",
    Delayed: "ملغي",
  };
  return (
    <div>
      <h1 className=" text-center my-4 font-bold text-2xl  ">طلباتي</h1>
      {error && (
        <h1 className=" text-center my-4 font-bold text-lg text-red-700  ">
          لا توجد اي طلبات
        </h1>
      )}
      {data && (
        <>
          {data.items ? (
            <table>
              <thead>
                <tr>
                  <th>تاريخ الطلب</th>
                  <th>الكمية</th>
                  <th>السعر</th>
                  <th>عنوان التوصيل</th>
                  <th>حالة المنتج</th>
                  <th>عرض المنتج</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item) => (
                  <tr key={item.id}>
                    <td className=" text-center ">
                      {item.created_at.substring(0, 10)}
                    </td>
                    <td className=" text-center ">{item.quantity}</td>
                    <td className=" text-center ">{item.total_amount}ر.س</td>
                    <td className=" text-center ">{item.details_address}</td>
                    <td className=" text-center font-bold  text-green-800 ">
                      {status[item.product_arrival_status]}
                    </td>
                    <td className=" text-center ">
                      <Link
                        className=" underline "
                        to={`/product-details?product=${item.item_id}&role=${item.owner_type}`}
                      >
                        عرض
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1 className=" text-center my-4 font-bold text-lg text-red-700  ">
              لا توجد اي طلبات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
