import React from "react";
import RaceComponentDashboard from "../../components/race/RaceComponentDashboard";

export default function Races({ data, setDeleteId, setEditId, setDetailsId }) {
  return (
    <div className=" flex items-satrt justify-center gap-3 flex-wrap ">
      {data.map((e, inx) => (
        <RaceComponentDashboard
          setEditId={setEditId}
          setDeleteId={setDeleteId}
          setDetailsId={setDetailsId}
          key={inx}
          data={e}
        />
      ))}
    </div>
  );
}
