import React, { useEffect } from "react";
import usePostWithToken from "../../../../../../utils/usePostJson";
import Status from "./Status";

export default function Trends({ type, reload }) {
  const { postData, response } = usePostWithToken(
    `superAdmin/Report/package/top_consumed`
  );
  const getData = () => {
    const data = {
      guard_type: type,
    };
    postData(data);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  // const test = [
  //   {
  //     package_name: " 2الباقه الماسية",
  //     total_features: 0,
  //     fully_consumed_features: 0,
  //     usage_percentage: 20,
  //   },
  //   {
  //     package_name: " 3الباقه الماسية",
  //     total_features: 0,
  //     fully_consumed_features: 0,
  //     usage_percentage: 30,
  //   },
  //   {
  //     package_name: "الباقه الماسية",
  //     total_features: 0,
  //     fully_consumed_features: 0,
  //     usage_percentage: 40,
  //   },
  //   {
  //     package_name: "الباقه الماسية",
  //     total_features: 0,
  //     fully_consumed_features: 0,
  //     usage_percentage: 10,
  //   },
  // ];

  // if (error) {
  //   return (
  //     <div>
  //       <h1 className=" text-red-600 text-xl font-bold text-center ">
  //         هناك مشكلة
  //       </h1>
  //     </div>
  //   );
  // }
  return (
    <>
      <div>
        {response && (
          <h1 className=" text-center my-2 text-lg font-bold ">
            الباقات الاكثر استخدام
          </h1>
        )}
        {/* {response && <Status packages={test.slice(0, 3)} />} */}
        {response && <Status packages={response.data.slice(0, 3)} />}
      </div>

      {/* {response && (
        <div>
          <table>
            <thead>
              <tr>
                <th>الباقة الاكثر مبيعا</th>
                <th>عدد المزايا</th>
                <th>عدد المزايا المستخدمة بالكامل</th>
                <th>نسبة الاستخدام</th>
              </tr>
            </thead>
            <tbody>
              {response.data.map((item, inx) => (
                <tr key={inx}>
                  <td className="text-center">{item.package_name}</td>
                  <td className="text-center">{item.total_features}</td>
                  <td className="text-center">
                    {item.fully_consumed_features}
                  </td>
                  <td className="text-center">{item.usage_percentage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )} */}
    </>
  );
}
