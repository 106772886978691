import React from "react";
import Hr from "../../components/pages/Hr";
import useFetchWithReload from "../../utils/useGetWithReload";

export default function Vision() {
  let link = "global/vision";
  const { data } = useFetchWithReload(link);
  return (
    <div className=" py-28 px-8 md:px-16 relative ">
      <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
        <img
          loading="lazy"
          src="./imgs/home/vision.png"
          alt="Vision"
          className="absolute left-0 top-0 h-full w-full object-cover "
        />
      </div>
      <div className=" w-full  max-w-[540px] py-[30px] px-[15px] bg-[#6B65618F]/50 text-center rounded-[15px] xl:mr-[6rem] 2xl:mr-[15rem] relative z-10">
        <h1 className=" my-2 text-[#E8DCC0] text-[32px] md:text-[48px] font-[700] ">
          الرؤية
        </h1>
        <Hr color="#E8DCC0" />
        {data && (
          <p className=" my-2 text-[#E8DCC0]/60 leading-[40px] text-[18px] font-[600] ">
            {/* "نحن نحلم بمجتمع هجين حيث يعبر عنه التميز والتطور المستدام، نسعى لبناء
          عالم يعكس جمال وفخامة الإبل، حيث يتبادل عشاق الهجن الخبرات ويسهمون في
          رسم مستقبل مشرق لهذا التراث الفريد." */}
            {/* منصة عالمية تخدم قطاع الإبل وتوفر للمهتمين بها أدوات وخدمات نوعية وذات
          ابداع وتطور مستمر لتكون منطلقًا لكل طموح */}
            {data.description}
          </p>
        )}
      </div>
    </div>
  );
}
