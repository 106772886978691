import React, { useContext, useState } from "react";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";
import EventDetailsComponent from "../../components/pages/Events/EventDetailsComponent";
import { Link } from "react-router-dom";
import useFetchGlobal from "../../utils/useGetGlobal";

export default function EventsOfMonth({ month, other }) {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const [open, setOpen] = useState(false);
  const toggelOpenRegisterMessage = () => {
    setOpen(!open);
  };

  const { data, error } = useFetchGlobal("global/events/all");

  const filteredEvents = data
    ? data.events.filter(
        (ev) => Number(ev.start_date.substring(5, 7)) === +month
      )
    : [];

  // let day = "2024-09-26";
  // console.log(Number(day.substring(5, 7)));

  return (
    <div className=" px-4 md:px-24">
      {!other && (
        <section className=" py-3 flex max-w-[1200px] mx-auto justify-end">
          {!logged ? (
            <>
              <button className="btn" onClick={toggelOpenRegisterMessage}>
                <i className="fa-solid fa-circle-plus mx-2 "></i>
                انشاء فعالية خاص بك
              </button>
            </>
          ) : (
            <>
              {role === "camel-owner" && (
                <Link to={"/dashboard?type=1"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  انشاء فعالية خاص بك
                </Link>
              )}
              {role === "squer" && (
                <Link to={"/dashboard?type=1"} className="btn">
                  <i className="fa-solid fa-circle-plus mx-2 "></i>
                  انشاء فعالية خاص بك
                </Link>
              )}
            </>
          )}
        </section>
      )}
      {error ? (
        <h1 className=" my-12 text-center text-2xl text-red-500  ">
          هناك مشكلة
        </h1>
      ) : (
        <>
          {!!data && (
            <div className=" flex justify-center flex-wrap ">
              {filteredEvents.map((event) => (
                <EventDetailsComponent key={event.id} data={event} />
              ))}
            </div>
          )}
          {filteredEvents.length === 0 && !!data && (
            <h1 className="text-red-500 my-10 text-xl text-center">
              لا توجد فعاليات في هذا الشهر
            </h1>
          )}
        </>
      )}
      {open && (
        <Notegister
          close={toggelOpenRegisterMessage}
          msg=" الرجاء التسجيل لانشاء مكانك في الفعاليات"
        />
      )}
    </div>
  );
}
