import React from "react";
import Profile from "./Profile";
import ProfileEvent from "./ProfileEvent";

export default function AllEventsOfProduct() {
  const events = [
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
    {
      name: "فعالية السباق السنوي للإبل",
      desc: "انضموا إلينا في هذا الحدث المثير حيث يتنافس أصحاب الإبل في سباقات مثيرة وفعاليات ممتعة للعائلة.",
      loc: "صحارى المملكة العربية السعودية - ساحة السباقات الكبير",
      dateStart: " 15 فبراير 2024",
      dateEnd: " 18 فبراير 2024",
      img: "./imgs/home/ga2.jfif",
    },
  ];
  return (
    <div>
      <Profile />
      <div className=" my-12 mx-3 md:mx-24 ">
        <section className=" mt-3  justify-between">
          <h1 className=" text-center text-[#432315] text-[22px] font-[700] ">
            اخر الفعاليات تم مشاركتها
          </h1>
        </section>
        <section className=" py-2 flex gap-3 flex-wrap  items-center justify-center   ">
          {events.map((prod, inx) => (
            <ProfileEvent key={inx} data={prod} />
          ))}
        </section>
      </div>
    </div>
  );
}
