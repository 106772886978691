import React, { useCallback, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CopyBtn from "../../../components/pages/CopyBtn";
import { isLogged } from "../../../utils/inStore";
import Notegister from "../../../components/Notegister";

export default function SquerInfo({ data, join }) {
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("squre");
  const role = new URLSearchParams(search).get("role");

  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);
  const { logged } = useContext(isLogged);
  return (
    <div className=" w-full md:w-[300px] p-4 rounded-[15px] border border-black ">
      {!join ? (
        <>
          <>
            {logged ? (
              <section className=" text-center ">
                <Link to={`/join-squer?squre=${invest}&role=${role}`}>
                  <button className="btn2">مشاركة في الساحة</button>
                </Link>
              </section>
            ) : (
              <section className=" text-center ">
                <button onClick={toggleRegisterMessage} className="btn2">
                  مشاركة في الساحة
                </button>
              </section>
            )}
          </>
        </>
      ) : (
        <></>
      )}
      <section className=" my-8 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          سعر الانضمام
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.subscription_price} ر.س
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          مساحة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.space}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          طول مضمار
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.race_track_length}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          طول مضمار التدريبي
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.training_track_length}
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          سعة المدرجات
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.spectator_capacity} متفرج
        </h1>
      </section>
      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          المدينة
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.city}
        </h1>
      </section>

      <section className=" my-4 ">
        <h1 className=" text-[#290900] font-[600] text-[20px] leading-[38px] ">
          العنوان
        </h1>
        <h1 className=" text-[#290900] font-[500] text-[18px] leading-[28px] ">
          {data.address}
        </h1>
      </section>
      <CopyBtn />
      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لحجز مكانك في الساحة"
        />
      )}
    </div>
  );
}
