import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import AddPackageManually from "./AddPackageManuly";

export default function ManuallyAdd() {
  const [mainFilter, setMainFilter] = useState({
    search: "",
    filter: "",
  });

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, search: e.target.value });
  };
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };
  // let link = `superAdmin/packages/get_all_users/${type}`;
  let link = `superAdmin/packages/inactiveUsers/${type}`;
  const { data } = useFetchWithReload(link, reload);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let users = [];

    if (data) {
      users = data.users;

      if (mainFilter.search) {
        users = users.filter((user) => {
          let name = user.first_name + " " + user.last_name;

          return name
            .toLowerCase()
            .trim()
            .includes(mainFilter.search.toLowerCase().trim());
        });
      }
    }

    setFilteredData(users);
  }, [data, mainFilter]);
  const [userId, setUserId] = useState(null);
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-y-3 md:gap-2">
        {/* Search */}
        <section className="w-full max-w-[650px] flex items-center gap-6 justify-between">
          <div className="flex items-center bg-[#E8DCC0] text-[#A59382] flex-1 rounded-[10px] border border-[#E8DCC0]">
            <i className="fa-solid fa-magnifying-glass m-2"></i>
            <input
              type="text"
              value={mainFilter.search}
              name="search"
              onChange={handleMainFilterChange}
              placeholder="أبحث هنا..."
              className="outline-none bg-[black]/0 flex-1 p-2"
            />
          </div>
        </section>
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"visitor"}>زائر</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <table className=" my-6 ">
        <thead>
          <tr>
            <th>الاسم</th>
            <th>رقم الهاتف</th>
            <th>البريد الاكتروني</th>
            <th>اضافة باقة</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((user) => (
            <tr key={user.id}>
              <td className="text-center">
                {user.first_name} {user.last_name}
              </td>
              <td className="text-center">{user.phone}</td>
              <td className="text-center">{user.email}</td>
              <td className="text-center">
                <button
                  onClick={() => setUserId(user)}
                  className=" py-1 px-2 rounded-md bg-green-800 text-white text-[18px] "
                >
                  اضافة باقة
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {filteredData.length === 0 && (
        <h1 className=" text-center my-3 text-xl font-bold ">
          لا يوجد مستخدمين
        </h1>
      )}
      {userId && (
        <AddPackageManually
          user={userId}
          type={type}
          close={() => setUserId(null)}
          re={() => setReload(!reload)}
        />
      )}
    </div>
  );
}
