import React, { useCallback, useContext, useEffect, useState } from "react";
import { isLogged } from "../../utils/inStore";
import Notegister from "../../components/Notegister";
import Filter from "./Filter";
import SquersAndClubs from "./SquersAndClubs";
import { Link } from "react-router-dom";
import useFetchGlobal from "../../utils/useGetGlobal";
import { Helmet } from "react-helmet-async";
import NewOver from "../../components/pages/NewOver";

export default function Squers() {
  const { logged } = useContext(isLogged);
  const { role } = useContext(isLogged);
  const [isRegisterMessageOpen, setRegisterMessageOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleRegisterMessage = useCallback(() => {
    setRegisterMessageOpen((prev) => !prev);
  }, []);

  const [checkedFilters, setCheckedFilters] = useState([
    "newest",
    "squers",
    "clubs",
  ]);
  const [checkedLocations, setCheckedLocations] = useState([
    "riyhad",
    "jada",
    "uae",
    "qutar",
    "bahreen",
  ]);

  const [filteredData, setFilteredData] = useState([]);

  const toggleSortOpen = useCallback(() => {
    setSortOpen((prev) => !prev);
    setFilterOpen(false);
  }, []);

  const [mainFilter, setMainFilter] = useState({
    search: "",
    sort: "newest",
  });
  const handleMainFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setMainFilter((prev) => ({ ...prev, [name]: value }));
  }, []);
  const { data, error } = useFetchGlobal("global/field/all");

  useEffect(() => {
    let filtered = [];
    if (!!data) {
      filtered = data.fields;
      if (mainFilter.search) {
        filtered = filtered.filter((item) =>
          item.name.includes(mainFilter.search)
        );
      }
    }

    setFilteredData(filtered);
  }, [mainFilter, data]);
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
{
"@context": "https://schema.org",
"@type": "WebPage",
"name": "منصة الابل",
"description":"الساحات والنوادي المهتمة بالابل ومساحات مخصصة لها ومميزة",
"url": "https://mitracamel.com/squres"
}
`}
        </script>
      </Helmet>
      <NewOver img="./imgs/aquerCover.png" title="ساحات الإبل" />

      <div className="p-6 md:py-20 md:px-12">
        <div className="flex items-center justify-between flex-wrap md:gap-2">
          {/* Search */}
          <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
            <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
              <i className="fa-solid fa-magnifying-glass m-2"></i>
              <input
                type="text"
                value={mainFilter.search}
                name="search"
                onChange={handleMainFilterChange}
                placeholder="أبحث هنا..."
                className="outline-none bg-[black]/0 flex-1 p-2"
              />
            </div>
            <h1 className="hidden md:flex">{filteredData.length} ساحة ونادي</h1>
          </section>

          {/* Sort */}
          <section className="hidden md:flex items-center gap-2">
            <h1 className="text-[15px] font-[500]">ترتيب بواسطة:</h1>
            <select
              onChange={handleMainFilterChange}
              value={mainFilter.sort}
              name="sort"
              className="rounded-[10px] border border-[#243F32] outline-none bg-[black]/0 flex-1 p-2"
            >
              <option value="newest">من الأحدث الي الأقدم</option>
              <option value="oldest">من الأقدم الي الأحدث</option>
            </select>
          </section>

          {/* Mobile Filter & Sort */}
          <section className="md:hidden flex gap-2">
            <button
              onClick={toggleSortOpen}
              className="p-3 bg-[#E8DCC0] text-[#432315] text-[14px] font-[700]"
            >
              <i className="fa-solid fa-filter ml-1"></i> ترتيب
            </button>
          </section>

          {/* Create Investment */}
          <section className="py-3 flex justify-end">
            {!logged ? (
              <>
                <button
                  className="btn hidden md:flex"
                  onClick={toggleRegisterMessage}
                >
                  انشاء ساحة خاصة بك
                </button>
                <button
                  className="btn md:hidden"
                  onClick={toggleRegisterMessage}
                >
                  انشاء ساحة
                </button>
              </>
            ) : (
              <>
                {role === "squer" && (
                  <Link to={"/dashboard?type=2"} className="btn">
                    <i className="fa-solid fa-circle-plus mx-2 "></i>
                    انشاء ساحة
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link to={"/dashboard?type=4"} className="btn">
                    ادارة الساحات
                  </Link>
                )}
              </>
            )}
          </section>
        </div>
        {isSortOpen && (
          <select
            onChange={handleMainFilterChange}
            value={mainFilter.sort}
            name="sort"
            className="rounded-[10px] border border-[#243F32] outline-none w-full bg-[black]/0 flex-1 p-2"
          >
            <option value="newest">من الأحدث الي الأقدم</option>
            <option value="oldest">من الأقدم الي الأحدث</option>
          </select>
        )}

        {isFilterOpen && (
          <Filter
            filter={checkedFilters}
            setCheckedState={setCheckedFilters}
            locationOptions={checkedLocations}
            setLocationsOptions={setCheckedLocations}
          />
        )}
        <div className="flex items-start gap-4 mt-10 mb-3">
          {/* <section className="hidden md:grid w-[210px]">
            <Filter
              filter={checkedFilters}
              setCheckedState={setCheckedFilters}
              locationOptions={checkedLocations}
              setLocationsOptions={setCheckedLocations}
            />
          </section> */}
          <section className="flex-1">
            {error ? (
              <h1 className=" text-center text-2xl text-red-500 my-3 ">
                هناك مشكلة
              </h1>
            ) : (
              <>
                {mainFilter.sort === "newest" ? (
                  <SquersAndClubs alldata={[...filteredData].reverse()} />
                ) : (
                  <SquersAndClubs alldata={filteredData} />
                )}
              </>
            )}
          </section>
        </div>
      </div>

      {isRegisterMessageOpen && (
        <Notegister
          close={toggleRegisterMessage}
          msg="الرجاء التسجيل لانشاء ساحة خاص بك"
        />
      )}
    </div>
  );
}
