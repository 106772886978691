import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function DropDownPhone({ name, links, colseHandeller }) {
  const [clicked, setClicked] = useState(false);
  return (
    <div>
      <button onClick={() => setClicked(!clicked)}>
        {name}{" "}
        <i
          className={`fa-solid fa-caret-down ${clicked && "rotate-180"} mx-1`}
        ></i>
      </button>
      {clicked && (
        <div>
          {links.map((li) => (
            <Link key={li.link} className="w-full" to={li.link}>
              <button className="  w-full  text-start" onClick={colseHandeller}>
                <section className=" px-3 py-2 font-[400] text-[18px]  w-full ">
                  {li.text}
                </section>
              </button>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
