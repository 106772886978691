import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default function Details({ data }) {
  return (
    <div>
      {data.details_images && (
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 4000, // Time delay in milliseconds
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper "
        >
          {data.details_images.map((e, index) => (
            <SwiperSlide className="  " key={index}>
              <div className="   relative bg-origin-content bg-cover  w-full   h-[330px]">
                <img
                  className={` rounded-[16px] transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover `}
                  key={e}
                  src={`${process.env.REACT_APP_URL}/${e}`}
                  alt="cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <h1 className=" my-10 text-[24px] text-[#432315] font-[800] ">
        عن السباق
      </h1>
      <div
        className="styled-content rtl leading-loose relative py-4  "
        dangerouslySetInnerHTML={{ __html: data.details }}
        style={{ direction: "rtl", textAlign: "right" }}
      />
    </div>
  );
}
