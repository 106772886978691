import React from "react";
import Header from "./Header";
import Camels from "./Camels";
// import Stores from "./Stores";
// import Events from "./Events";
// import Gallery from "./Gallery";

export default function CamelOwnerProfile() {
  return (
    <div>
      <Header />
      <Camels />
      {/* <Stores /> */}
      {/* <Events /> */}
      {/* <Gallery /> */}
    </div>
  );
}
