import React, { useState } from "react";
import NewComponent from "../../../components/pages/News/NewComponent";

const PaginatedItems = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;

  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the starting and ending indices for the items to display
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to only include items for the current page
  const currentItems = data.slice(startIndex, endIndex);

  // Handle "Next" button click
  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle "Previous" button click
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle page number click
  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  // Generate pagination buttons

  const renderPageNumbers = () => {
    const pages = [];
    const ellipsis = (
      <span key="ellipsis" className="px-4 py-2">
        ...
      </span>
    );

    if (totalPages > 3) {
      // Show ellipsis or current page number
      if (currentPage >= 2 && currentPage < totalPages - 1) {
        for (let i = 0; i < 1; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`px-4 py-2  ${
                currentPage === i ? "bg-[#432315] text-white" : "bg-[#FFFFFF00]"
              } rounded-md`}
            >
              {i + 1}
            </button>
          );
        }
        // pages.push(ellipsis);
        pages.push(
          <button
            key={currentPage}
            onClick={() => handlePageClick(currentPage)}
            className={`px-4 py-2 bg-[#432315] text-white rounded-md`}
          >
            {currentPage + 1}
          </button>
        );
        pages.push(ellipsis);
      } else {
        for (let i = 0; i < 2; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`px-4 py-2 ${
                currentPage === i ? "bg-[#432315] text-white" : "bg-[#FFFFFF00]"
              } rounded-md`}
            >
              {i + 1}
            </button>
          );
        }
        pages.push(ellipsis);
      }

      // Show last page
      pages.push(
        <button
          key={totalPages - 1}
          onClick={() => handlePageClick(totalPages - 1)}
          className={`px-4 py-2 ${
            currentPage === totalPages - 1
              ? "bg-[#432315] text-white"
              : "bg-[#FFFFFF00] "
          } rounded-md`}
        >
          {totalPages}
        </button>
      );
    } else {
      for (let i = 0; i < totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`px-4 py-2  ${
              currentPage === i ? "bg-[#432315] text-white" : "bg-[#FFFFFF00]"
            } rounded-md`}
          >
            {i + 1}
          </button>
        );
      }
    }

    return pages;
  };

  return (
    <div>
      <div className="flex mb-4 gap-3 flex-wrap justify-center">
        {currentItems.map((e, inx) => (
          <NewComponent data={e} key={inx} />
        ))}
      </div>

      <div className="flex justify-start my-4">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 0}
          className="px-4 py-2  bg-[#FFFFFF00] disabled:text-gray-800 text-[#432315]"
        >
          {`<`}
        </button>

        {/* Page Numbers */}
        {renderPageNumbers()}

        <button
          onClick={handleNext}
          disabled={currentPage >= totalPages - 1}
          className="px-4 py-2  bg-[#FFFFFF00] disabled:text-gray-800 text-[#432315]"
        >
          {`>`}
        </button>
      </div>
    </div>
  );
};

export default PaginatedItems;
