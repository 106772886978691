import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../../components/pages/Tab";
import Info from "./Info";
import File from "../../../components/pages/File";
import Details from "./Details";
import Sechad from "./Sechad";
import Terms from "./Terms";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";

export default function RaceDeatails() {
  const [tab, setTabState] = useState(1);
  const search = useLocation().search;
  const invest = new URLSearchParams(search).get("race");
  const role = new URLSearchParams(search).get("role");
  const { data, error } = useFetchGlobal(`global/race/show/${invest}/${role}`);
  const currentDate = new Date();
  if (error) {
    return <Error />;
  }
  return (
    <div className=" p-4 md:px-8  ">
      <div className=" flex items-center justify-between  ">
        <section className="   text-[16px] font-[600] ">
          <section className="flex items-center gap-1 my-1 ">
            <Link className="text-[#432315BF] " to="/races">
              السباقات
            </Link>
            <h1 className="text-[#432315] ">{`> تفاصيل السباق `}</h1>
          </section>
          <hr className=" w-24 h-[3px] bg-[black] border-black " />
        </section>
        {!!data && (
          <section className=" flex items-center gap-1 ">
            <h1>حالة السباق : </h1>

            <span className=" p-[5px] rounded-full border border-black ">
              {new Date(data.race.end_date) < currentDate && (
                <p className=" text-red-600 ">منتهي</p>
              )}
              {(new Date(data.race.start_date) < currentDate) &
              (new Date(data.race.end_date) > currentDate) ? (
                <p className=" text-sky-600 ">جاري</p>
              ) : (
                <></>
              )}
              {new Date(data.race.start_date) > currentDate && (
                <p className=" text-green-600 ">قادم</p>
              )}
            </span>
          </section>
        )}
      </div>
      {/* Body */}
      <div className=" my-4  md:flex grid-cols-12 items-start justify-center  gap-2  ">
        {/* details */}
        <div className=" col-span-9  flex-1  w-deta  ">
          <section className="   flex items-center gap-3 ">
            <Tab
              text={"التفاصيل"}
              val={1}
              tab={tab}
              setTabState={setTabState}
            />
            <Tab
              text={"المواعيد"}
              val={2}
              tab={tab}
              setTabState={setTabState}
            />
            {/* <Tab text={"النتائج"} val={3} tab={tab} setTabState={setTabState} /> */}
            <Tab
              text={"شروط السباق"}
              val={3}
              tab={tab}
              setTabState={setTabState}
            />
          </section>
          {!!data && (
            <section>
              {tab === 1 && <Details data={data.race} />}
              {tab === 2 && <Sechad data={data.agendas} />}
              {tab === 3 && <Terms data={data.race} />}
              {data.race.race_files.length > 0 && (
                <section className="my-3">
                  <h1 className="text-[#333333] text-[24px] font-[800] my-2 ">
                    ملفات تعريفية للسباق
                  </h1>
                  <section className="  flex gap-2 flex-wrap ">
                    {data.race.race_files.map((e, inx) => (
                      <File key={inx} da={e} />
                    ))}
                  </section>
                </section>
              )}
            </section>
          )}
        </div>
        {/* Owner */}
        <section className="   col-span-3 ">
          {!!data && <Info data={data.race} />}
        </section>
      </div>
    </div>
  );
}
