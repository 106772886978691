import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import useFetchGlobal from "../../../../utils/useGetGlobal";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";
import FileUploded from "../../../../components/pages/FileUploded";

export default function EditProduct({ close, id, set }) {
  const [form, setForm] = useState({
    name: "",
    price: "",
    short_description: "",
    details: "",
    quantity: "",
    category_id: "",
    card_image: "",
    details_image: [],
  });
  const [details, setDetails] = useState("");
  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };
  let linkToFetch = "";
  let linkOfCatgs = "global/store/all-Category";
  const { role } = useContext(isLogged);
  if (role === "camel-owner") {
    linkToFetch = `camelOwner/item/show/${id}`;
  }
  if (role === "squer") {
    linkToFetch = `field-owner/item/show/${id}`;
  }
  if (role === "provider") {
    linkToFetch = `serviceProvider/item/show/${id}`;
  }
  if (role === "vistor") {
    linkToFetch = `visitor/item/show/${id}`;
  }
  if (role === "super_admin") {
    linkToFetch = `superAdmin/item/show/${id}`;
  }
  const { data } = useFetchWithToken(linkToFetch);
  const categs = useFetchGlobal(linkOfCatgs).data;
  const [categrs, setCategrs] = useState([]);
  useEffect(() => {
    if (!!categs) {
      setCategrs(categs);
    }
  }, [categs]);

  useEffect(() => {
    if (!!data) {
      setDetails(data.details);
      setForm({
        name: data.name,
        price: data.price,
        short_description: data.short_description,
        details: "",
        quantity: data.quantity,
        category_id: data.category_id,
        card_image: "",
        details_image: [],
      });
    }
  }, [data]);
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};
    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  let link = "";
  if (role === "camel-owner") {
    link = `camelOwner/item/update/${id}`;
  }
  if (role === "squer") {
    link = `field-owner/item/update/${id}`;
  }
  if (role === "provider") {
    link = `serviceProvider/item/update/${id}`;
  }
  if (role === "vistor") {
    link = `visitor/item/update/${id}`;
  }
  if (role === "super_admin") {
    link = `superAdmin/item/update/${id}`;
  }
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();
  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();

      // Append regular fields
      formdata.append("name", form.name);
      formdata.append("price", form.price);
      formdata.append("short_description", form.short_description);
      formdata.append("details", details);
      formdata.append("quantity", form.quantity);
      formdata.append("category_id", form.category_id);
      if (form.card_image) {
        formdata.append("card_image", form.card_image);
      }

      form.details_image.forEach((image) => {
        formdata.append("details_image[]", image); // Check if your server expects "details_image" or "details_image[]"
      });

      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      navigate(`/product-details?product=${response.id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل منتج</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تعديل الصورة المصغرة
            </h1>
            {form.card_image ? (
              <section>
                <button
                  onClick={() => {
                    setForm({ ...form, card_image: null });
                  }}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[170px] rounded-[15px] max-w-[330px] mx-auto"
                  src={URL.createObjectURL(form.card_image)}
                />
              </section>
            ) : (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, card_image: e.target.files[0] });
                  }}
                />
              </section>
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم المنتج
            </h1>
            <input
              name="name"
              value={form.name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم المنتج"
              required
            />
            {<p className="error">{errors.name}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تصنيف المنتج
            </h1>
            <select
              name="category_id"
              value={form.category_id}
              onChange={handelChanege}
              className=" inpt "
            >
              <option hidden> تصنيف المنتج </option>
              {categrs.map((e, inx) => (
                <option key={inx} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>

            {<p className="error">{errors.category_id}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر المنتج
            </h1>
            <input
              name="price"
              value={form.price}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder="سعر المنتج بالريال السعودي"
              required
            />
            {<p className="error">{errors.price}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              كمية المنتج
            </h1>
            <input
              name="quantity"
              value={form.quantity}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder="كمية المنتج"
              required
            />
            {<p className="error">{errors.quantity}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن المنتج
            </h1>
            <textarea
              name="short_description"
              cols={2}
              value={form.short_description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن المنتج "
              required
            />
            {<p className="error">{errors.short_description}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل المنتج
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تعديل صور المنتج
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_image.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_image;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_image: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_image: [...form.details_image, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            تعديل
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
