import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isLogged } from "../../utils/inStore";

export default function Banner() {
  const imgs = [
    "./imgs/swipper/1.jpg",
    "./imgs/swipper/2.jpg",
    "./imgs/swipper/3.jpg",
    "./imgs/swipper/4.jpg",
    "./imgs/swipper/5.jpg",
  ];
  const [imgNum, setImgNum] = useState(3);

  useEffect(() => {
    setTimeout(() => {
      if (imgNum < 4) {
        setImgNum(imgNum + 1);
      } else {
        setImgNum(0);
      }
    }, 7000);
  }, [imgNum]);

  const { logged } = useContext(isLogged);

  return (
    <div className=" z-10 relative ">
      <div className=" absolute bg-origin-content bg-cover   w-full h-full">
        {imgs.map((e, index) => (
          <img
            className={` ${
              index === +imgNum ? "opacity-100" : "opacity-0"
            } transition-all ease-in-out duration-[3000ms] absolute left-0 top-0 h-full w-full object-cover brightness-50`}
            key={e}
            src={e}
            alt="cover"
          />
        ))}
      </div>
      <div className=" overflow-hidden w-full h-fit px-10  py-8 md:py-12 bg-black/20 flex justify-center relative z-20 ">
        <section className=" z-0 absolute top-[-200px] right-[-200px]">
          <img
            className=" w-[400px] opacity-60 md:opacity-85 "
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className=" z-0 absolute bottom-[-200px] left-[-200px]">
          <img
            className=" w-[400px] opacity-60 md:opacity-85"
            src="./imgs/frame.png"
            alt="frame"
          />
        </section>
        <section className=" z-10 relative w-fit max-w-[700px] bg-black/20 rounded-[20px] p-6 text-center ">
          <h1 className=" mt-3  text-[22px] md:text-[55px] font-[800] text-[#E8DCC0] ">
            {/* مرحبًا بك في منصة الإبل */}
            مرحبًا بك في فحل المنصات
          </h1>
          <h4 className=" leading-[42px] my-6 text-[#E8DCC0] font-[600] text-[18px] ">
            {/* نحن هنا لنقدم لك تجربة فريدة في عالم الإبل، حيث يمكنك استكشاف مختلف
            أنواع الإبل، المشاركة في سباقات الهجن، والاستمتاع بأحدث المزادات. ،
            نسعى لتقديم كل ما تحتاجه لرعاية وتدريب الإبل والمشاركة في الفعاليات
            المثيرة. */}
            نحن هنا لنقدم لك تجربة فريدة في عالم الإبل، حيث يمكنك استكشاف مختلف
            أنواع الإبل، المشاركة في سباقات الهجن، والاستمتاع بأحدث المزادات و
            اكثر
          </h4>
          {!logged && (
            <>
              <h4 className=" my-2 text-[#E8DCC0] font-[600] text-[18px] ">
                سجل ك:
              </h4>
              <section className=" flex gap-2 items-center justify-center flex-wrap">
                <Link to="/register?type=camel_owner">
                  <button className="btn2">مالك الإبل</button>
                </Link>
                <Link to="/register?type=field_owner">
                  <button className="btn2">ساحة</button>
                </Link>
                <Link to="/register?type=service_provider">
                  <button className="btn2">مزود خدمة</button>
                </Link>
              </section>
            </>
          )}
        </section>
      </div>
    </div>
  );
}
