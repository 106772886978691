import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../utils/useGetWithReload";
import EditSuper from "./EditSuper";

export default function SuperAdminInfo() {
  const [re, setRe] = useState(false);
  const reload = () => {
    setRe((pre) => !pre);
  };
  const { data, error } = useFetchWithReload(`superAdmin/Profile/data`, re);
  const [show, setShow] = useState({
    mail: "",
    firstName: "",
    lastName: "",
  });
  useEffect(() => {
    if (data) {
      setShow({
        mail: data.superAdmin.email,
        firstName: data.superAdmin.first_name,
        lastName: data.superAdmin.last_name,
      });
    }
  }, [data]);
  const [openEdit, setOpenEdit] = useState(false);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section className=" p-[16px] rounded-[16px] my-5 border border-[#A59382] ">
        <section className=" py-3 flex items-center justify-between border-b border-[#A59382]  ">
          <h1 className=" text-[24px] font-[700] text-[#432315] ">
            معلومات شخصية
          </h1>
          <button
            onClick={() => setOpenEdit(true)}
            className=" w-[60px] h-[30px] md:w-[100px] md:h-[50px] border border-[#432315] rounded-[8px] "
          >
            تعديل
          </button>
        </section>
        <section className=" mt-6  grid grid-cols-1 md:grid-cols-2 gap-12  ">
          <section className=" col-span-1 ">
            <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
              الاسم الاول
            </h4>
            <section className=" p-2 border border-[#290900] rounded-[5px] ">
              {show.firstName}
            </section>
          </section>
          <section className=" col-span-1 ">
            <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
              الاسم الاخير
            </h4>
            <section className=" p-2 border border-[#290900] rounded-[5px] ">
              {show.lastName}
            </section>
          </section>
          <section className=" col-span-1 ">
            <h4 className=" mb-2 text-[16px] font-[600] text-[#290900] ">
              بريد الاكتروني
            </h4>
            <section className=" p-2 border border-[#290900] rounded-[5px] ">
              {show.mail}
            </section>
          </section>
        </section>
      </section>
      {openEdit && <EditSuper re={reload} data={show} close={setOpenEdit} />}
    </div>
  );
}
