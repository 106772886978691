import React from "react";
import { Link } from "react-router-dom";

export default function HospitalsCompoent({ data, hospital }) {
  function TruncatedParagraph({ text }) {
    // Define the maximum number of characters
    const maxCharacters = 100;

    // Check if the text length exceeds maxCharacters
    const shouldTruncate = text.length > maxCharacters;

    // Create the truncated text
    const truncatedText = shouldTruncate
      ? text.slice(0, maxCharacters) + "...."
      : text;

    return truncatedText;
  }
  return (
    <div className=" text-[#432315] w-full max-w-[330px] rounded-[16px] border border-[#7F7F7F] p-2 ">
      <div className=" relative bg-origin-content bg-cover rounded-[16px]  w-full h-[180px]">
        <img
          className={`  absolute left-0 top-0 h-full w-full rounded-[16px] object-cover `}
          src={`${process.env.REACT_APP_URL}/${data.card_image}`}
          alt="cover"
        />
      </div>
      <h1 className=" my-2 text-[22px] font-[800] ">{data.name}</h1>
      <p className=" min-h-[110px] text-[15px] font-[600] leading-[28px] ">
        <TruncatedParagraph text={data.bio} />
      </p>
      <section className=" my-2 text-[15px] font-[500] text-[#432315] leading-[28px] flex items-center gap-2 ">
        <i className=" fa-solid fa-location-dot text-xl  " />
        <p>{data.location}</p>
      </section>
      <section className=" text-center mt-4 ">
        {hospital ? (
          <Link to={`/hospital-details?hospital=${data.id}`}>
            <button className=" btn2 ">اعرف اكثر</button>
          </Link>
        ) : (
          <Link to={`/consultative-details?consultative=${data.id}`}>
            <button className=" btn2 ">اعرف اكثر</button>
          </Link>
        )}
      </section>
    </div>
  );
}
