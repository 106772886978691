/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CopyBtn from "../../components/pages/CopyBtn";
import useFetchGlobal from "../../utils/useGetGlobal";

export default function Info() {
  const { data, error } = useFetchGlobal(`global/social_links`);

  if (error) {
    return;
  }
  return (
    <div className=" w-full md:w-[320px] p-4 rounded-[15px] border border-[#43231580] ">
      {data && (
        <>
          {data.phone && (
            <section className=" my-4 flex items-center gap-3 w-full p-[16px] rounded-[16px] bg-[#E8DCC0] ">
              <i className="fa-solid fa-phone rotate-[270deg] text-[24px] "></i>
              <section>
                <h1 className=" text-[20px] fot-[600] text-[#290900] ">
                  رقم الهاتف
                </h1>
                <h1 className=" mt-3 text-[18px] fot-[600] text-[#000000] ">
                  {data.phone}
                </h1>
              </section>
            </section>
          )}
          {data.email && (
            <section className=" my-4 flex items-center gap-3 w-full p-[16px] rounded-[16px] bg-[#E8DCC0] ">
              <i className="fa-regular fa-envelope  text-[24px] "></i>
              <section>
                <h1 className=" text-[20px] fot-[600] text-[#290900] ">
                  البريد الإلكتروني
                </h1>
                <h1 className=" mt-3 text-[18px] fot-[600] text-[#000000] ">
                  {data.email}
                </h1>
              </section>
            </section>
          )}
          {data.address && (
            <section className=" my-4 flex items-center gap-3 w-full p-[16px] rounded-[16px] bg-[#E8DCC0] ">
              <i className="fa-solid fa-location-dot text-[24px] "></i>
              <section>
                <h1 className=" text-[20px] fot-[600] text-[#290900] ">
                  العنوان
                </h1>
                <h1 className=" mt-3 text-[16px] fot-[600] text-[#000000] ">
                  {data.address}
                </h1>
              </section>
            </section>
          )}
        </>
      )}
      {data && (
        <section className=" my-4 ">
          <h1 className=" text-[#432315] text-[20px] font-[600] ">تابعنا </h1>
          <section className=" mt-2 ">
            <section className=" text-[#432315] text-4xl flex items-center justify-around">
              {data.facebook && (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a target="_blank" href={data.facebook}>
                  <i className="fa-brands fa-facebook"></i>
                </a>
              )}
              {data.twitter && (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a target="_blank" href={data.twitter}>
                  <i className="fa-brands fa-twitter"></i>
                </a>
              )}
              {data.instagram && (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a target="_blank" href={data.instagram}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              )}
              {data.whatsapp && (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a target="_blank" href={data.whatsapp}>
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              )}
            </section>
          </section>
        </section>
      )}
      <CopyBtn />
    </div>
  );
}
