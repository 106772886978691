import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import usePostFormData from "../../utils/usePostForm";
import { load } from "../../utils/isLoadingStore";
import Loader from "../../components/Loader/Loader";

export default function EditCamel({ close, data, re }) {
  const [phone, setPhone] = useState(data.phone);
  const validationSchema = Yup.object({
    mail: Yup.string()
      .email("البريد الإلكتروني غير صحيح")
      .required("بريد الالكتروني مطلوب"),
    phone: Yup.string()
      .required("رقم الهاتف مطلوب")
      .matches(/^\d{10,14}$/, "رقم الهاتف غير صحيح"),
  });
  const { postFormData, error, response } = usePostFormData(
    "camelOwner/Profile/update"
  );
  const handleSubmit = async (values) => {
    const formdata = new FormData();
    formdata.append("phone", values.phone);
    if (data.mail !== values.mail) {
      formdata.append("email", values.mail);
    }

    await postFormData(formdata);
  };
  useEffect(() => {
    if (response) {
      close();
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            معلومات شخصية
          </h1>
          <button
            onClick={() => close(false)}
            className="text-red-600 font-[600]"
          >
            X
          </button>
        </section>

        <Formik
          initialValues={{
            mail: data.mail,
            phone: data.phone,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <>
              <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    رقم الهاتف
                  </h1>
                  <PhoneInput
                    // country={"sa"}
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      setFieldValue("phone", value);
                    }}
                    className="  ltr outline-none my-1 w-full  bg-[#F5EFDC] p-2 rounded-[10px] border-b-[2px] border-[#432315] text-[#432315C7] text-[15px] font-[600] "
                    inputStyle={{
                      outline: "none",
                      width: "100%",
                      maxWidth: "340px",
                      backgroundColor: "#F5EFDC",
                      padding: "12px",
                      paddingLeft: "45px",
                      borderRadius: "10px",
                      border: "none",
                      // borderBottom: "2px solid #432315",
                      color: "#432315C7",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    dropdown={{
                      outline: "none",
                      width: "100%",
                      maxWidth: "340px",
                      backgroundColor: "#F5EFDC",
                      padding: "12px",
                      paddingLeft: "45px",
                      borderRadius: "10px",
                      border: "none",
                      // borderBottom: "2px solid #432315",
                      color: "#432315C7",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    containerClass="custom-phone-input"
                    inputClass="custom-phone-input-input"
                    dropdownClass="custom-phone-dropdown"
                  />
                  <ErrorMessage
                    name="phone"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    بريد الالكتروني
                  </h1>
                  <Field
                    name="mail"
                    className="inpt"
                    type="text"
                    placeholder="بريد الالكتروني"
                  />
                  <ErrorMessage
                    name="mail"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>

                <section className="col-span-1 lg:col-span-2 flex justify-end">
                  <button type="submit" className="btn2">
                    حفظ
                  </button>
                </section>
              </Form>
              <>
                {error && (
                  <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                    <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                      <i className="fa-solid fa-exclamation  "></i>
                    </span>
                    {!!error.response.data.message
                      ? error.response.data.message
                      : "  هناك مشكلة"}
                  </section>
                )}
              </>
            </>
          )}
        </Formik>
      </section>
    </div>,
    document.body
  );
}
