import React from "react";

export default function Img({ photo }) {
  return (
    <div className=" relative overflow-   ">
      <section className=" z-0 absolute right-0 bottom-0 ">
        {" "}
        <svg
          width="921"
          height="323"
          viewBox="0 0 921 323"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_5446_90130)">
            <path
              d="M927.965 10C711.857 10 424.373 350 10 350H1381V13.3278L927.965 10Z"
              fill="#432315"
              fill-opacity="0.55"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_5446_90130"
              x="0"
              y="0"
              width="1391"
              height="360"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5446_90130"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5446_90130"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </section>
      <section className=" z-0 absolute right-0 bottom-0 ">
        <svg
          width="902"
          height="317"
          viewBox="0 0 902 317"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 347C472 322.5 748 -56 972 10.5"
            stroke="#C89150"
            stroke-width="4.23"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </section>
      <section className=" z-10 min-h-[400px] overflow-hidden ">
        <section className=" absolute overflow-hidden w-[400px]    bottom-0 ">
          <section className="  absolute bottom-[-200px]">
            <img className=" w-[400px]" src="./imgs/frame.png" alt="frame" />
          </section>
          <section className=" w-[400px] z-10 relative ">
            {photo && (
              <img
                className=" h-[400px] z-[100] mx-auto "
                src={`${process.env.REACT_APP_URL}/${photo}`}
                // src={`./imgs/user.png`}
                alt="owner"
              />
            )}
          </section>
        </section>
      </section>
    </div>
  );
}
